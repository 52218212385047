import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { GroupHierarchy } from '../../../model/system/groups/GroupHierarchy';


export const ON_GROUP_HIERARCHY_LIST       = createAction ( 'shared.system-group-hierarchy.list', props<{ force: boolean }> ( ) );


export const GET_GROUP_HIERARCHY         = createAction ( 'shared.system-group-hierarchy.get', props<{ op: string }> ( ) );
export const GET_GROUP_HIERARCHY_SUCCESS = createAction ( 'shared.system-group-hierarchy.get.success', props<{ op: string, hierarchy: GroupHierarchy }> ( ) );
export const GET_GROUP_HIERARCHY_FAILURE = createAction ( 'shared.system-group-hierarchy.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );
