import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CREATE_DOCUMENT_TEMPLATE, DELETE_DOCUMENT_TEMPLATE, DOWNLOAD_DOCUMENT_TEMPLATE, ON_DOCUMENT_TEMPLATES_LIST, UPDATE_DOCUMENT_TEMPLATE } from '../../state/study/documents/study-documents.actions';
import { SELECT_STUDY_DOCUMENT_TEMPLATES, SELECT_STUDY_DOCUMENT_TEMPLATES_LOADING, SELECT_STUDY_DOCUMENT_TEMPLATES_SAVING } from '../../state/study/documents/study-documents.selectors';
import { DocumentTemplate } from '../../model/study/documents/DocumentTemplate';
import { SubjectType } from '../../model/published/SubjectType';


@Injectable ( {
  providedIn: 'root'
} )
export class StudyDocumentsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_DOCUMENT_TEMPLATES_LIST ( { op: 'list', force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_DOCUMENT_TEMPLATES_LOADING ),
      map ( loading => loading ? true : false )
    );
  }

  getSaving( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_DOCUMENT_TEMPLATES_SAVING ),
      map ( loading => loading ? true : false ) 
    );
  }

  getDocumentTemplates( ): Observable<Map<number, Map<number, DocumentTemplate>>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_DOCUMENT_TEMPLATES ),
      map ( templates => templates ? templates : new Map ( ) )
    );
  }

  createDocumentTemplate ( subjectType: SubjectType, metaId: number, documentId: number, file: File )
  {
    this.store.dispatch ( CREATE_DOCUMENT_TEMPLATE ( { op: CREATE_DOCUMENT_TEMPLATE.type, subjectType, metaId, documentId, file } ) );
  }

  updateDocumentTemplate ( template: DocumentTemplate, file: File )
  {
    this.store.dispatch ( UPDATE_DOCUMENT_TEMPLATE ( { op: UPDATE_DOCUMENT_TEMPLATE.type, template, file } ) );
  }

  deleteDocumentTemplate ( template: DocumentTemplate )
  {
    this.store.dispatch ( DELETE_DOCUMENT_TEMPLATE ( { op: DELETE_DOCUMENT_TEMPLATE.type, template } ) );
  }

  downloadDocumentTemplate ( template: DocumentTemplate )
  {
    this.store.dispatch ( DOWNLOAD_DOCUMENT_TEMPLATE ( { op: DOWNLOAD_DOCUMENT_TEMPLATE.type, template } ) );
  }
}
