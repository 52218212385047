import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { GET_PUBLISHED_SITES, GET_PUBLISHED_SITES_FAILURE, GET_PUBLISHED_SITES_SUCCESS, ON_PUBLISHED_SITES_LIST, ON_PUBLISHED_SITES_SINGLE } from './published-sites.actions';
import { SELECT_PUBLISHED_SITES_SITES } from './published-sites.selectors';
import { SiteMeta } from '../../../model/published/sites/SiteMeta';

@Injectable ( )
export class PublishedSitesEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_PUBLISHED_SITES_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_SITES_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_SITES_SITES ) ),
        mergeMap ( ([action, sites]) => {
            if ( action.force || sites == null || sites.size == 0 )
            {
                return [ GET_PUBLISHED_SITES ( { op: 'list', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_PUBLISHED_SITES_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_SITES_SINGLE ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_SITES_SITES ) ),
        mergeMap ( ([action, sites]) => {
            if ( action.force || sites == null || sites.size == 0 )
            {
                return [ GET_PUBLISHED_SITES ( { op: 'single', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PUBLISHED_SITES$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PUBLISHED_SITES ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.published_api}/sites/`, { params } ) ),
            map ( response => {
                const sites = new Array<SiteMeta> ( );
                for ( const json of response )
                {
                    const site = SiteMeta.fromJson ( json );
                    if ( site )
                    {
                        sites.push ( site );
                    }
                }
                return sites;
            } ),
            map ( sites => GET_PUBLISHED_SITES_SUCCESS ( { op: action.op, sites } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PUBLISHED_SITES_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
