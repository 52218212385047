import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormMeta } from '../../model/meta/FormMeta';
import { SELECT_FORM_LIBRARY_DELETING, SELECT_FORM_LIBRARY_FORM, SELECT_FORM_LIBRARY_FORMS, SELECT_FORM_LIBRARY_LOADING, SELECT_FORM_LIBRARY_SAVING } from '../../state/meta/form-library.selectors';
import { CREATE_FORM_LIBRARY_FORM, DELETE_FORM_LIBRARY_FORM, ON_FORM_LIBRARY_LIST, ON_FORM_LIBRARY_SINGLE, UPDATE_FORM_LIBRARY_FORM } from '../../state/meta/form.library.actions';

@Injectable ( {
  providedIn: 'root'
} )
export class FormLibraryService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_FORM_LIBRARY_LIST ( { force } ) );
  }

  onSingle( id: number, force: boolean = false )
  {
    this.store.dispatch ( ON_FORM_LIBRARY_SINGLE ( { id, force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_FORM_LIBRARY_LOADING ) 
    );
  }

  getSaving( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_FORM_LIBRARY_SAVING ) 
    );
  }

  getDeleting( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_FORM_LIBRARY_DELETING ) 
    );
  }

  getForms( ): Observable<Array<FormMeta>>
  {
    return this.store.pipe ( 
      select ( SELECT_FORM_LIBRARY_FORMS )
    );
  }

  getForm( id: number ): Observable<FormMeta | null>
  {
    return this.store.pipe ( 
      select ( SELECT_FORM_LIBRARY_FORM, { id } )
    );
  }

  createForm ( form: FormMeta )
  {
    this.store.dispatch ( CREATE_FORM_LIBRARY_FORM ( { op: CREATE_FORM_LIBRARY_FORM.type, form } ) );
  }

  updateForm ( form: FormMeta )
  {
    this.store.dispatch ( UPDATE_FORM_LIBRARY_FORM ( { op: UPDATE_FORM_LIBRARY_FORM.type, form } ) );
  }

  deleteForm ( id: number )
  {
    this.store.dispatch ( DELETE_FORM_LIBRARY_FORM ( { op: DELETE_FORM_LIBRARY_FORM.type, id } ) );
  }
}
