import { ContentNodeMeta } from './ContentNodeMeta';
import { ContentNodeType } from './ContentNodeType';
import { FormType, FormTypeUtils } from './FormType';
import { QuestionMeta } from './questions/QuestionMeta';
import { QuestionMetaFactory } from './questions/QuestionMetaFactory';


export class FormMeta extends ContentNodeMeta
{
    public static toJson( form: FormMeta ): any
    {
        let out: any = {};

        out = ContentNodeMeta._toJson ( form, out );
        out.formType = form.FormType ? FormType [ form.FormType ] : FormType.ALL_IN_ONE;
        out.questions = new Array<any> ( );
        for ( const question of form.questions ? form.questions : [] )
        {
            out.questions.push ( QuestionMetaFactory.toJson ( question ) );
        }

        return out;
    }

    public static fromJson( json: any ): FormMeta
    {
        let out: FormMeta = new FormMeta ( );

        out = ContentNodeMeta._fromJson ( json, out );
        out.FormType = json.formType ? FormTypeUtils.fromString ( json.formType ) : FormType.ALL_IN_ONE;
        out.Questions = new Array<QuestionMeta> ( );
        for ( const question of json.questions ? json.questions : [] )
        {
            const parsed = QuestionMetaFactory.fromJson ( question );
            if ( parsed != null )
            {
                out.Questions.push ( parsed );
            }
        }

        return out;
    }

    public constructor( id: number | null  = null, seq: number | null  = null, timestamp: Date | null  = null,
                         name: string | null  = null, description: string | null = null,
                         private formType: FormType | null = FormType.ALL_IN_ONE,
                         private questions: Array<QuestionMeta> | null  = null )
    {
       super ( id, seq, ContentNodeType.FORM, timestamp, name, description );
    }

    public get FormType( ): FormType | null
    {
        return this.formType;
    }

    public set FormType( formType: FormType | null )
    {
        this.formType = formType;
    }
    
    public get Questions( ): Array<QuestionMeta> | null
    {
        return this.questions;
    }

    public set Questions( questions: Array<QuestionMeta> | null )
    {
        this.questions = questions;
    }
}
