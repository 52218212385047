import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FORM_LIBRARY_FEATURE_STATE_NAME, FormLibraryState } from './form.library.state';

export const SELECT_FORM_LIBRARY = createFeatureSelector<FormLibraryState> ( FORM_LIBRARY_FEATURE_STATE_NAME );

export const SELECT_FORM_LIBRARY_LOADING  = createSelector ( SELECT_FORM_LIBRARY, ( state: FormLibraryState ) => state.formsLoading );
export const SELECT_FORM_LIBRARY_SAVING   = createSelector ( SELECT_FORM_LIBRARY, ( state: FormLibraryState ) => state.formSaving );
export const SELECT_FORM_LIBRARY_DELETING = createSelector ( SELECT_FORM_LIBRARY, ( state: FormLibraryState ) => state.formDeleting );

export const SELECT_FORM_LIBRARY_FORMS = createSelector ( SELECT_FORM_LIBRARY, ( state: FormLibraryState ) => state.forms );
export const SELECT_FORM_LIBRARY_FORM  = createSelector ( SELECT_FORM_LIBRARY, ( state: FormLibraryState, props: { id: number } ) => {
    const res = state.forms.find ( entry => entry.Id == props.id );
    return res ? res : null;
 } );
