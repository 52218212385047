import * as moment from 'moment';
import { ChangeRecord } from '../../../model/study/ChangeRecord';

export const STUDY_CHANGE_RECORDS_FEATURE_STATE_NAME = 'study-change-records';

export class StudyChangeRecordsState
{
    records = new Array<ChangeRecord> ( );
    start: moment.Moment | null = null;
    end: moment.Moment | null = null;
    loading = false;
}
