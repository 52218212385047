import * as moment from 'moment';
import { Field } from '../published/Field';
import { FieldType } from '../published/FieldType';
import { FieldValue } from './FieldValue';


export class NumericFieldValue extends FieldValue
{
    public static clone( field: NumericFieldValue ): NumericFieldValue
    {
        return new NumericFieldValue ( field.Id,
                                       field.Version, 
                                       field.MetaId,
                                       field.Timestamp,
                                       field.Value );
    }

    public static toJson( field: NumericFieldValue ): any
    {
        let out: any = {};

        out = FieldValue._toJson ( field, out );

        out.value = field.Value;
        
        return out;
    }

    public static fromJson( json: any ): NumericFieldValue
    {
        let out = new NumericFieldValue ( );

        out = FieldValue._fromJson ( json, out );

        out.Value = json.value as number;

        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null, 
                        timestamp : moment.Moment | null  = null,
                        private value: number | null = null )
    {
        super ( id, version, metaId, FieldType.NUMERIC, timestamp );
    }

    public getValues ( ) : Map<string, any> | null
    {
        return new Map<string, any> ( [ [ "", this.value ] ] );
    }

    public get ValueAsString ( ) : string | null
    {
        if ( this.Value )
        {
            return this.Value.toString ( );
        }
        else
        {
            return null;
        }
    }

    public updateValue ( meta: Field, value: any ) : void
    {
        if ( value )
        {
            if ( typeof value == "number" )
            {
                this.Value = value
            }
            else
            {
                this.Value = Number.parseFloat ( value );
            }
        }
        else
        {
            this.Value = null;
        }
    }

    public get Value( ): number | null
    {
        return this.value;
    }

    public set Value( value: number | null )
    {
        this.value = value;
    }
}