import { Role } from "../roles/Role";
import { Group } from "../groups/Group";

export class User
{
    public static toJson( user: User ): any
    {
        let out: any = {};

        out.id = user.Id;
        out.first_name = user.FirstName;
        out.last_name = user.LastName;
        out.email = user.Email;
        out.enabled = user.Enabled;
        out.groups = new Array<any> ( );
        for ( let group of user.Groups ? user.Groups : [] )
        {
            out.groups.push ( Group.toJson ( group ) );
        }
        out.role = user.Role ? Role.toJson ( user.Role ) : null;
        out.sites = new Array<any> ( );
        for ( let site of user.Sites ? user.Sites : [] )
        {
            out.sites.push ( site );
        }
        
        return out;
    }

    public static fromJson( json: any ): User
    {
        let out = new User ( );

        out.Id = json.id as string;
        out.FirstName = json.first_name as string;
        out.LastName = json.last_name as string;
        out.Email = json.email as string;
        out.Enabled = json.enabled as boolean;
        out.Groups = new Array<Group> ( );
        for ( let group of json.groups ? json.groups : [] )
        {
            out.Groups.push ( Group.fromJson ( group ) );
        }
        out.Role = json.role ? Role.fromJson ( json.role ) : null;
        out.Sites = new Array<string> ( );
        for ( let site of json.sites ? json.sites : [] )
        {
            out.Sites.push ( site );
        }

        return out;
    }

    public constructor( private id: string | null = null,
                        private firstName: string | null = null,
                        private lastName: string | null = null,
                        private email: string | null = null,
                        private enabled: boolean | null = null,
                        private groups: Array<Group> | null = null,
                        private role: Role | null = null,
                        private sites: Array<string> | null = null )
    {
        // Null.
    }

    public get Id( ): string | null
    {
        return this.id;
    }

    public set Id( id: string | null )
    {
        this.id = id;
    }

    public get FirstName( ): string | null
    {
        return this.firstName;
    }

    public set FirstName( firstName: string | null )
    {
        this.firstName = firstName;
    }

    public get LastName( ): string | null
    {
        return this.lastName;
    }

    public set LastName( lastName: string | null )
    {
        this.lastName = lastName;
    }

    public get Email( ): string | null
    {
        return this.email;
    }

    public set Email( email: string | null )
    {
        this.email = email;
    }

    public get Enabled( ): boolean | null
    {
        return this.enabled;
    }

    public set Enabled( enabled: boolean | null )
    {
        this.enabled = enabled;
    }

    public get Groups( ): Array<Group> | null
    {
        return this.groups;
    }

    public set Groups( groups: Array<Group> | null )
    {
        this.groups = groups;
    }

    public get Role( ): Role | null
    {
        return this.role;
    }

    public set Role( role: Role | null )
    {
        this.role = role;
    }

    public get Sites( ): Array<string> | null
    {
        return this.sites;
    }

    public set Sites( sites: Array<string> | null )
    {
        this.sites = sites;
    }
}
