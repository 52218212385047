import { QuestionType, QuestionTypeUtils } from './QuestionType';

export abstract class QuestionMeta
{
    protected static _toJson<T extends QuestionMeta>( question: T, output: any ): any
    {
        output.id = question.Id;
        output.seq = question.Seq;
        output.type = question.Type ? QuestionType [ question.Type ] : QuestionType.NONE;
        output.timestamp = question.Timestamp ? question.Timestamp.toISOString ( ) : null;
        output.text = question.Text;
        output.required = question.Required;

        return output;
    }

    protected static _fromJson<T extends QuestionMeta>( json: any, output: T ): T
    {
        output.Id = json.id;
        output.Seq = json.seq;
        // output.type = json.type ? QuestionTypeUtils.fromString ( json.type ) : QuestionType.NONE;
        output.Timestamp = new Date ( json.timestamp );
        output.Text = json.text;
        output.Required = json.required;

        return output;
    }

    protected constructor( private id: number | null = null, private seq: number | null = null,
                           private type: QuestionType = QuestionType.NONE, private timestamp: Date | null = null,
                           private text: string | null = null, private required: boolean | null = false )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Type( ): QuestionType
    {
        return this.type;
    }

    public get Timestamp( ): Date | null
    {
        return this.timestamp;
    }

    public set Timestamp( timestamp: Date | null )
    {
        this.timestamp = timestamp;
    }

    public get Required( ): boolean | null
    {
        return this.required;
    }

    public set Required( required: boolean | null )
    {
        this.required = required;
    }

    public get Text( ): string | null
    {
        return this.text;
    }

    public set Text( text: string | null )
    {
        this.text = text;
    }
}
