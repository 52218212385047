
export class SiteId
{
    public static clone( siteId: SiteId ): SiteId
    {
        let out = new SiteId ( );

        out.Id = siteId.Id;

        return out;
    }

    public static toJson( siteId: SiteId ): any
    {
        let out: any = {};

        out.id = siteId.Id;

        return out;
    }

    public static fromJson( json: any ): SiteId
    {
        let out = new SiteId ( );
    
        out.Id = json.id;
        
        return out;
    }

    public constructor( private id: number | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }
}
