import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectAccessGuard } from 'shared';
import { AutoSignoutComponent } from './auto.signout.component';
import { ErrorComponent } from './error.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'private', loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [ DirectAccessGuard ]
  },
  {
    path: 'auto-signout',
    component: AutoSignoutComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
