import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { BooleanFieldValue } from '../../model/study/BooleanFieldValue';
import { BooleanField } from '../../model/published/BooleanField';
import { FieldComponent } from './field.component';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';

@Component({
  selector: 'lib-boolean-field',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => BooleanFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => BooleanFieldComponent ),
      multi: true
    }
  ]
})
export class BooleanFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: BooleanField | null = null;
  
  value: BooleanFieldValue | null = null;

  disabled = false;

  validation: string | null = null;

  constructor ( fb: FormBuilder, eFactory: ExpressionEngineFactory, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  ngOnInit ( )
  {
    this.form = this.fb.group ( {
      value: [null, this.meta?.Required ? [ Validators.required ] : null ],
      timestamp: null
    } );

    if ( this.meta == null )
    {
      this.form.controls [ "value" ].disable ( );
    }
  }

  writeValue ( val: any )
  {
    this.value = BooleanFieldValue.clone ( val as BooleanFieldValue );
    
    if ( this.value )
    {
      this.form.patchValue ( { "value": this.value.Value, "timestamp": this.value.Timestamp }, { emitEvent: false } );
    }
  }

  registerOnChange ( fn: any )
  {
    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          this.value.Value = change['value'];
          this.value.Timestamp = change['timestamp'];
          return this.value
        }
        else
        {
          return change;
        }
      } ),
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    if ( this.disabled != disable )
    {
      this.disabled = disable;
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    if ( results && "required" in results )
    {
      this.validation = "Value is required";
    }
    else if ( results && "validityExpression" in results )
    {
      this.validation = results ["validityExpression" ];
    }
    else
    {
      this.validation = null;
    }
  }

  onYes ( )
  {
    if ( this.meta )
    {
      if ( this.form.value.value == this.meta.YesNumeric ) 
      {
        this.form.patchValue ( { "value": null } );
      }
      else
      {
        this.form.patchValue ( { "value": this.meta.YesNumeric } );
      }
    }
  }

  onNo ( )
  {
    if ( this.meta )
    {
      if ( this.form.value.value == this.meta.NoNumeric ) 
      {
        this.form.patchValue ( { "value": null } );
      }
      else
      {
        this.form.patchValue ( { "value": this.meta.NoNumeric } );
      }
    }
  }

  isYes ( value: number | undefined | null ) : boolean
  {
    if ( value != null && this.meta )
    {
      const res = ( this.meta.YesNumeric == value );
      return res;
    }

    return false;
  }

  isNo ( value: number | undefined | null ) : boolean
  {
    if ( value != null && this.meta )
    {
      const res = ( this.meta.NoNumeric == value );
      return res;
    }

    return false;
  }
}
