<form [formGroup]="form">
    
    <!-- Enumerations -->
    <div class="row">
        <div class="col-12">
            <input type="hidden" formControlName="timestamp">
            <div class="input-group">
                <input class="form-control" placeholder="dd/mm/yyyy" [ngClass]="{ 'is-invalid': form.controls.value.errors }"
                       name="dp" ngbDatepicker #picker="ngbDatepicker" [maxDate]="maximum" formControlName="value">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="picker.toggle()" type="button" [disabled]="disabled"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon></button>
                </div>
                <div class="invalid-feedback">
                    <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                        Value is required
                    </ng-container>
                    <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                        {{ValueFormControl?.errors?.['validityExpression']}}
                    </ng-container>
                </div>
            </div>
            
        </div>
    </div>

</form>