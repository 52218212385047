import { Field } from './Field';
import { FieldType } from './FieldType';


export class StringField extends Field
{
    public static clone( input: StringField ): StringField
    {
        let output = Field._clone ( input, new StringField ( ) );
        output.LineCount = input.LineCount;
        output.MinLength = input.MinLength;
        output.MaxLength = input.MaxLength;
        return output;
    }

    public static toJson( field: StringField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.line_count = field.LineCount;
        out.min_length = field.MinLength;
        out.max_length = field.MaxLength;
        
        return out;
    }

    public static fromJson( json: any ): StringField
    {
        let out = new StringField ( );

        out = Field._fromJson ( json, out );

        out.LineCount = json.line_count as number;
        out.MinLength = json.min_length as number;
        out.MaxLength = json.max_length as number;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private lineCount: number | null  = null,
                        private minLength: number | null  = null,
                        private maxLength: number | null = null )
    {
       super ( id, version, seq, FieldType.STRING, variable, name, description, required );
    }

    public get LineCount( ): number | null
    {
        return this.lineCount;
    }

    public set LineCount( lineCount: number | null )
    {
        this.lineCount = lineCount;
    }

    public get MinLength( ): number | null
    {
        return this.minLength;
    }

    public set MinLength( minLength: number | null )
    {
        this.minLength = minLength;
    }

    public get MaxLength( ): number | null
    {
        return this.maxLength;
    }

    public set MaxLength( maxLength: number | null )
    {
        this.maxLength = maxLength;
    }
}
