import * as moment from 'moment';
import { Field } from '../published/Field';
import { FieldType } from '../published/FieldType';
import { FieldValue } from './FieldValue';


export class DateFieldValue extends FieldValue
{
    public static clone( field: DateFieldValue ): DateFieldValue
    {
        return new DateFieldValue ( field.Id,
                                    field.Version, 
                                    field.MetaId,
                                    field.Timestamp,
                                    field.Value );
    }

    public static toJson( field: DateFieldValue ): any
    {
        let out: any = {};

        out = FieldValue._toJson ( field, out );

        out.value = field.Value ? field.Value.format ( "YYYY-MM-DD" ) : null;
        
        return out;
    }

    public static fromJson( json: any ): DateFieldValue
    {
        let out = new DateFieldValue ( );

        out = FieldValue._fromJson ( json, out );

        out.Value = json.value ? moment ( json.value as string ) : null;

        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null,
                        timestamp : moment.Moment | null  = null,
                        private value: moment.Moment | null = null )
    {
        super ( id, version, metaId, FieldType.DATE, timestamp );
    }

    public getValues ( ) : Map<string, any> | null
    {
        return new Map<string, any> ( [ [ "", this.value ] ] );
    }

    public get ValueAsString ( ) : string | null
    {
        return this.Value ? this.Value.format ( ) : null;
    }

    public updateValue ( field: Field, value: any ) : void
    {
        if ( value )
        {
            if ( moment.isMoment ( value ) )
            {
                this.Value = value;
            }
            else if ( typeof value == "string" )
            {
                this.Value = moment ( value as string );
            }
            else
            {
                // This is bad, mmmmkay.
                this.Value = value;
            }
        }
        else
        {
            this.Value = null;
        }
    }

    public get Value( ): moment.Moment | null
    {
        return this.value;
    }

    public set Value( value: moment.Moment | null )
    {
        this.value = value;
    }
}