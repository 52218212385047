import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParticipantMeta } from '../../model/published/participants/ParticipantMeta';
import { Form } from '../../model/published/Form';
import { ON_PUBLISHED_PARTICIPANTS_LIST, ON_PUBLISHED_PARTICIPANTS_SINGLE } from '../../state/published/participants/published-participants.actions';
import { SELECT_PUBLISHED_PARTICIPANTS_LOADING, SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANT, SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANTS } from '../../state/published/participants/published-participants.selectors';
import { CollectionPoint } from '../../model/published/CollectionPoint';


@Injectable ( {
  providedIn: 'root'
} )
export class PublishedParticipantsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_PUBLISHED_PARTICIPANTS_LIST ( { force } ) );
  }

  onSingle( id: number | null, force: boolean = false )
  {
    if ( id )
    {
      this.store.dispatch ( ON_PUBLISHED_PARTICIPANTS_SINGLE ( { id, force } ) );
    }
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PARTICIPANTS_LOADING ) 
    );
  }

  getParticipants( ): Observable<Map<number, ParticipantMeta>>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANTS )
    );
  }

  getParticipant( id: number | null ): Observable<ParticipantMeta | null>
  {
    if ( id )
    {
      return this.store.pipe ( 
        select ( SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANT, { id } ),
        map ( site => {
          if ( site )
          {
            return site;
          }
          else
          {
            return null;
          }
        } )
      );
    }
    else
    {
      return new BehaviorSubject ( null );
    }
  }

  getParticipantForm ( id: number, formId: number ) : Observable<[Form, CollectionPoint] | null>
  {
      return this.getParticipant ( id ).pipe (
          map ( participant => {
              if ( participant  )
              {
                  for ( const cp of participant.AllCollectionPoints )
                  {
                    for ( const form of cp.Forms ? cp.Forms : [] )
                    {
                        if ( form.Id == formId )
                        {
                            return [form, cp];
                        }
                    }
                  }
              }
            
              return null;
          } )
      );
  }
}
