
export enum ExpressionDataType
{
    BOOLEAN = 1,

    NUMBER = 2,

    STRING = 3,

    DATE_TIME = 4,

    PARTICIPANT_LABEL = 5,

    INTEGER = 6,
}

export abstract class ExpressionDataTypeUtils
{
    public static fromString( value: string ): ExpressionDataType
    {
        return Object.entries(ExpressionDataType).find(([key, val]) => key === value)?.[1] as ExpressionDataType;
    }

    public static toString ( value: ExpressionDataType ): string
    {
        return Object.entries(ExpressionDataType).find(([key, val]) => val === value)?.[0] as string;
    }

}
