import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { Site } from '../../../model/study/sites/Site';
import { CollectionPointValue } from '../../../model/study/CollectionPointValue';
import { FormValue } from '../../../model/study/FormValue';
import { CollectionPoint } from '../../../model/published/CollectionPoint';
import { FileField } from '../../../model/published/FileField';
import { FileFieldValue } from '../../../model/study/FileFieldValue';


export const ON_SITES_LIST       = createAction ( 'shared.study-sites.list', props<{ force: boolean }> ( ) );
export const ON_SITES_SINGLE     = createAction ( 'shared.study-sites.single', props<{ id: number, force: boolean }> ( ) );


export const GET_SITES         = createAction ( 'shared.study-sites.get', props<{ op: string, ids: number[] | null }> ( ) );
export const GET_SITES_SUCCESS = createAction ( 'shared.study-sites.get.success', props<{ op: string, sites: Site [ ] }> ( ) );
export const GET_SITES_FAILURE = createAction ( 'shared.study-sites.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const CREATE_SITE         = createAction ( 'shared.study-sites.create', props<{ op: string, site: Site }> ( ) );
export const CREATE_SITE_SUCCESS = createAction ( 'shared.study-sites.create.success', props<{ op: string, site: Site }> ( ) );
export const CREATE_SITE_FAILURE = createAction ( 'shared.study-sites.create.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_SITE         = createAction ( 'shared.study-sites.update', props<{ op: string, site: Site }> ( ) );
export const UPDATE_SITE_SUCCESS = createAction ( 'shared.study-sites.update.success', props<{ op: string, site: Site }> ( ) );
export const UPDATE_SITE_FAILURE = createAction ( 'shared.study-sites.update.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const DELETE_SITE         = createAction ( 'shared.study-sites.delete', props<{ op: string, id: number }> ( ) );
export const DELETE_SITE_SUCCESS = createAction ( 'shared.study-sites.delete.success', props<{ op: string, id: number }> ( ) );
export const DELETE_SITE_FAILURE = createAction ( 'shared.study-sites.delete.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const CREATE_SITE_FORM         = createAction ( 'shared.study-sites.create-form', props<{ op: string, site: Site, cp: CollectionPoint, form: FormValue }> ( ) );
export const CREATE_SITE_FORM_SUCCESS = createAction ( 'shared.study-sites.create-form.success', props<{ op: string, site: Site }> ( ) );
export const CREATE_SITE_FORM_FAILURE = createAction ( 'shared.study-sites.create-form.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_SITE_FORM         = createAction ( 'shared.study-sites.update-form', props<{ op: string, site: Site, cpv: CollectionPointValue, form: FormValue }> ( ) );
export const UPDATE_SITE_FORM_SUCCESS = createAction ( 'shared.study-sites.update-form.success', props<{ op: string, site: Site }> ( ) );
export const UPDATE_SITE_FORM_FAILURE = createAction ( 'shared.study-sites.update-form.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPLOAD_SITE_FILE         = createAction ( 'shared.study-sites.upload-file', props<{ op: string, site: Site, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue, file: File }> ( ) );
export const UPLOAD_SITE_FILE_SUCCESS = createAction ( 'shared.study-sites.upload-file.success', props<{ op: string, site: Site, field: FileField, fieldValue: FileFieldValue }> ( ) );
export const UPLOAD_SITE_FILE_FAILURE = createAction ( 'shared.study-sites.upload-file.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );

export const DOWNLOAD_SITE_FILE       = createAction ( 'shared.study-sites.download-file', props<{ op: string, site: Site, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue }> ( ) );
