<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand d-flex align-items-center" href="#">
        <div class="toolbar-icon pr-3"></div>
    </a>
  </nav>
  
  <main role="main pt-5">
    <div class="row">
      <div class="col-12 vh-100 d-flex flex-column justify-content-center align-items-center">
        <h1 class="pt-3 display-1 text-center text-primary"><fa-icon [icon]="['far', 'frown']"></fa-icon></h1>
        <h1 class="pt-3 display-3 text-center text-primary">Something appears to have gone wrong.</h1>
        <h5 class="pb-3">We're terribly sorry about that, please click <a href="/">here</a> to return to the home page.</h5>
        <p>Please contact the <a *ngIf="supportEmailLink$ | async as supportEmailLink" [href]="supportEmailLink">trial team</a> if you have any questions or queries.</p>
        <div class="col pt-5 d-flex justify-content-center flex-grow-0">
          <a class="btn btn-primary btn-lg" href="/"><fa-icon [icon]="['fas', 'home']" [fixedWidth]="true" class="pr-2"></fa-icon>Back to home</a>
        </div>
      </div>
    </div>
  </main>
    
  <footer class="container pb-3">
    <div class="row">
        <div class="col-12 text-center">
            <small class="text-muted"><a href="https://www.xeropoint.co.uk" target="_blank">Xeropoint Ltd.</a> &#169; 2018-2023</small>
        </div>
    </div>
  </footer>
  