import { createReducer, on } from '@ngrx/store';
import { CREATE_SITE, CREATE_SITE_FAILURE, CREATE_SITE_FORM, CREATE_SITE_FORM_FAILURE, CREATE_SITE_FORM_SUCCESS, CREATE_SITE_SUCCESS, DELETE_SITE, DELETE_SITE_FAILURE, DELETE_SITE_SUCCESS, GET_SITES, GET_SITES_FAILURE, GET_SITES_SUCCESS, UPDATE_SITE, UPDATE_SITE_FAILURE, UPDATE_SITE_FORM, UPDATE_SITE_FORM_FAILURE, UPDATE_SITE_FORM_SUCCESS, UPDATE_SITE_SUCCESS } from './study-sites.actions';
import { StudySitesState } from './study-sites.state';
import { Site } from '../../../model/study/sites/Site';

export const INITIAL_STATE = new StudySitesState ( );

const _STUDY_SITES_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_SITES, ( state, { op, ids } ) => {
      const newState = { ...state };

      newState.sitesLoading = true;
      newState.sites = new Map<number, Site> ( );

      return newState;
    } ),
    on ( GET_SITES_SUCCESS, ( state, { op, sites } ) => {
      const newState = { ...state };

      newState.sites = new Map<number, Site> ( );
      newState.sitesLoading = false;

      for ( const site of sites ? sites : [] )
      {
        if ( site.Id )
        {
          newState.sites.set ( site.Id, site );
        }
      }
      
      return newState;
    } ),
    on ( GET_SITES_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sites = new Map<number, Site> ( );
      newState.sitesLoading = false;

      return newState;
    } ),


    on ( CREATE_SITE, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = true;

      return newState;
    } ),
    on ( CREATE_SITE_SUCCESS, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;
      if ( site.Id )
      {
        newState.sites = new Map ( newState.sites );
        newState.sites.set ( site.Id, site );
      }
      
      return newState;
    } ),
    on ( CREATE_SITE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;

      return newState;
    } ),


    on ( UPDATE_SITE, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = true;

      return newState;
    } ),
    on ( UPDATE_SITE_SUCCESS, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;
      if ( site.Id )
      {
        newState.sites = new Map ( newState.sites );
        newState.sites.set ( site.Id, site );
      }
      
      return newState;
    } ),
    on ( UPDATE_SITE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;

      return newState;
    } ),


    on ( DELETE_SITE, ( state, { op, id } ) => {
      const newState = { ...state };

      newState.sitesSaving = true;

      return newState;
    } ),
    on ( DELETE_SITE_SUCCESS, ( state, { op, id } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;
      if ( id )
      {
        newState.sites.delete ( id );
      }
      
      return newState;
    } ),
    on ( DELETE_SITE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;

      return newState;
    } ),


    on ( CREATE_SITE_FORM, ( state, { op, site, cp, form } ) => {
      const newState = { ...state };

      newState.sitesSaving = true;

      return newState;
    } ),
    on ( CREATE_SITE_FORM_SUCCESS, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;
      if ( site.Id )
      {
        newState.sites = new Map ( newState.sites );
        newState.sites.set ( site.Id, site );
      }
      
      return newState;
    } ),
    on ( CREATE_SITE_FORM_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;

      return newState;
    } ),


    on ( UPDATE_SITE_FORM, ( state, { op, site, cpv, form } ) => {
      const newState = { ...state };

      newState.sitesSaving = true;

      return newState;
    } ),
    on ( UPDATE_SITE_FORM_SUCCESS, ( state, { op, site } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;
      if ( site.Id )
      {
        newState.sites = new Map ( newState.sites );
        newState.sites.set ( site.Id, site );
      }
      
      return newState;
    } ),
    on ( UPDATE_SITE_FORM_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesSaving = false;

      return newState;
    } ),
);

export function STUDY_SITES_REDUCER( state: any, action: any )
{
  return _STUDY_SITES_REDUCER ( state, action );
}
