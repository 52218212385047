import { Site } from '../../../model/study/sites/Site';

export const STUDY_SITES_FEATURE_STATE_NAME = 'study-sites';

export class StudySitesState
{
    sites = new Map<number, Site> ( );
    sitesLoading = false;
    sitesSaving = false;
}
