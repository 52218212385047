import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Participant } from '../../model/study/participants/Participant';
import { ParticipantMeta } from '../../model/published/participants/ParticipantMeta';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { ParticipantField } from '../../model/published/ParticipantField';
import { ParticipantFieldValue } from '../../model/study/ParticipantFieldValue';
import { combineLatest, Observable } from 'rxjs';
import { ParticipantUtils } from '../../model/study/participants/ParticipantUtils';
import { StudyParticipantsFascadeService } from '../../services/study-participants-fascade-service';
import { FieldComponent } from './field.component';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';
import { StudySitesFascadeService } from '../../services/study-sites-fascade-service';
import { Site } from '../../model/study/sites/Site';
import { SiteMeta } from '../../model/published/sites/SiteMeta';
import { SiteUtils } from '../../model/study/sites/SiteUtils';

@Component({
  selector: 'lib-participant-field',
  templateUrl: './participant-field.component.html',
  styleUrls: ['./participant-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => ParticipantFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => ParticipantFieldComponent ),
      multi: true
    }
  ]
})
export class ParticipantFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: ParticipantField | null = null;

  @Input()
  public siteId: number | null = null;
  
  value: ParticipantFieldValue | null = null;

  loading$: Observable<boolean> | null = null;

  participants$: Observable<Array<[Participant, ParticipantMeta]> | null> | null = null;

  site$: Observable<[Site, SiteMeta] | null> | null = null;

  constructor ( fb: FormBuilder, eFactory: ExpressionEngineFactory, private service: StudyParticipantsFascadeService, private sites: StudySitesFascadeService, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  get ValueFormControl ( ) : FormControl
  { 
    return this.form.get ( 'value' ) as FormControl;
  }

  ngOnInit ( )
  {
    this.form = this.fb.group ( {
      value: [null, this.meta?.Required ? [ Validators.required ] : null ],
      timestamp: null
    } );

    if ( this.meta != null && this.meta.ParticipantType != null && this.siteId != null )
    {
      this.loading$ = combineLatest ( [ this.service.getLoading ( this.meta.ParticipantType ), this.sites.getLoading ( ) ] ).pipe (
        map ( ( [ participants, site ] ) => participants || site )
      );

      this.site$ = this.sites.getSite ( this.siteId );

      this.participants$ = combineLatest ( [ this.service.getParticipants ( this.meta.ParticipantType, this.siteId ), this.site$ ] ).pipe (
        map ( ( [ participants, site ] ) => {
          let out = new Array<[Participant, ParticipantMeta]> ( );
          if ( participants && site )
          {
            const utils = new ParticipantUtils ( );
            for ( let [id, participant] of participants )
            {
              if ( participant[0].MetaId != null && this.siteId == participant[0].SiteId )
              {
                if ( this.meta?.FilterExpression && participant[1].Variable )
                {
                  const vals = utils.getSubjectVariableValues ( participant[1], participant[0], participant[1].Variable, new SiteUtils ( ).getSiteFormattedId ( site[1], site[0] ) );
                  if ( this.expressionEngine.eval ( this.meta.FilterExpression, vals ) )
                  {
                    out.push ( participant );
                  }
                }
                else
                {
                  out.push ( participant );
                }
              }
            }
          }

          return out;
        } )
      );

      this.service.onList ( this.meta.ParticipantType, this.siteId );
    }
  }

  writeValue ( val: any )
  {
    this.value = ParticipantFieldValue.clone ( val as ParticipantFieldValue );
    if ( this.value )
    {
      this.form.patchValue ( { "value": this.value.Value, "timestamp": this.value.Timestamp }, { emitEvent: true } );
    }

    (val!==this.value) && this.onChange(this.value);
  }

  registerOnChange ( fn: any )
  {
    this.onChange = fn;
    this.form.updateValueAndValidity();

    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          const result = ParticipantFieldValue.clone ( this.value );
          result.Value = change['value'];
          result.Timestamp = change['timestamp'];
          return result
        }
        else
        {
          return change;
        }
      } )
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    if ( disable )
    {
      if ( this.ValueFormControl.enabled ) this.ValueFormControl.disable ( );
    }
    else
    {
      if ( this.ValueFormControl.disabled ) this.ValueFormControl.enable ( );
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    if ( results && "validityExpression" in results )
    {
      const combo = { ...this.ValueFormControl.errors, ... results };
      this.ValueFormControl.setErrors ( combo );
    }
    else if ( this.ValueFormControl.errors && Object.keys ( this.ValueFormControl.errors ).length > 0 )
    {
      let existing = { ...this.ValueFormControl.errors };
      delete existing["validityExpression"];
      this.ValueFormControl.setErrors ( existing );
    }
    else
    {
      this.ValueFormControl.setErrors ( null );
    }
  }

  getParticipantLabel ( participant: Participant, meta: ParticipantMeta, site: Site, siteMeta: SiteMeta ) : string
  {
    return new ParticipantUtils ( ).getParticipantLabel ( this.expressionEngine, meta, participant, new SiteUtils ( ).getSiteFormattedId ( siteMeta, site ) );
  }

  getParticipantId ( site: Site, siteMeta: SiteMeta, participant: Participant, meta: ParticipantMeta ) : string | null
  {
    const siteId = new SiteUtils ( ).getSiteFormattedId ( siteMeta, site );
    const participantId = new ParticipantUtils ( ).getParticipantFormattedId ( meta, participant );
    return `${siteId}-${participantId}`;
  }
}
