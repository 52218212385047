import { Expression } from '../published/expressions/Expression';
import { Field } from './Field';
import { FieldType } from './FieldType';

export class ParticipantField extends Field
{
    public static clone( input: ParticipantField ): ParticipantField
    {
        let output = Field._clone ( input, new ParticipantField ( ) );
        output.ParticipantType = input.ParticipantType;
        output.FilterExpression = input.FilterExpression ? Expression.clone ( input.FilterExpression ) : null;
        return output;
    }

    public static toJson( field: ParticipantField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.participant_type = field.ParticipantType;
        out.filter_expression = field.FilterExpression ? Expression.toJson ( field.FilterExpression ) : null;
        
        return out;
    }

    public static fromJson( json: any ): ParticipantField
    {
        let out = new ParticipantField ( );

        out = Field._fromJson ( json, out );

        out.ParticipantType = json.participant_type as number;
        out.FilterExpression = "filter_expression" in json && json.filter_expression ? Expression.fromJson ( json.filter_expression ) : null;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private participantType: number | null = null,
                        private filterExpression: Expression | null = null, )
    {
       super ( id, version, seq, FieldType.PARTICIPANT, variable, name, description, required );
    }

    public get ParticipantType( ): number | null
    {
        return this.participantType;
    }

    public set ParticipantType( participantType: number | null )
    {
        this.participantType = participantType;
    }

    public get FilterExpression( ): Expression | null
    {
        return this.filterExpression;
    }

    public set FilterExpression( filterExpression: Expression | null )
    {
        this.filterExpression = filterExpression;
    }
}
