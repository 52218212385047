import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap, filter } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { SELECT_STUDY_DOCUMENT_TEMPLATES } from './study-documents.selectors';
import { ErrorType } from '../../../errors/ErrorType';
import { CREATE_DOCUMENT_TEMPLATE, CREATE_DOCUMENT_TEMPLATE_FAILURE, CREATE_DOCUMENT_TEMPLATE_SUCCESS, DELETE_DOCUMENT_TEMPLATE, DELETE_DOCUMENT_TEMPLATE_FAILURE, DELETE_DOCUMENT_TEMPLATE_SUCCESS, DOWNLOAD_DOCUMENT_TEMPLATE, GET_DOCUMENT_TEMPLATES, GET_DOCUMENT_TEMPLATES_FAILURE, GET_DOCUMENT_TEMPLATES_SUCCESS, ON_DOCUMENT_TEMPLATES_LIST, UPDATE_DOCUMENT_TEMPLATE, UPDATE_DOCUMENT_TEMPLATE_FAILURE, UPDATE_DOCUMENT_TEMPLATE_SUCCESS } from './study-documents.actions';
import { saveAs } from 'file-saver';
import { DocumentTemplate } from '../../../model/study/documents/DocumentTemplate';

@Injectable ( )
export class StudyDocumentsEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_DOCUMENT_TEMPLATES_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_DOCUMENT_TEMPLATES_LIST ),
        concatLatestFrom ( action => this.store.select ( SELECT_STUDY_DOCUMENT_TEMPLATES ) ),
        mergeMap ( ( [ action, templates ]) => {
            if ( action.force || templates == null || templates.size == 0 )
            {
                return [ GET_DOCUMENT_TEMPLATES ( { op: action.op } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_DOCUMENT_TEMPLATES$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_DOCUMENT_TEMPLATES ),
        mergeMap ( action => of ( action ).pipe (
            mergeMap ( action => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.study_api}/templates/` ) ),
            map ( response => {
                const templates = new Array<DocumentTemplate> ( );
                for ( const json of response )
                {
                    const template = DocumentTemplate.fromJson ( json );
                    if ( template )
                    {
                        templates.push ( template );
                    }
                }
                return templates;
            } ),
            map ( templates => GET_DOCUMENT_TEMPLATES_SUCCESS ( { op: action.op, templates } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_DOCUMENT_TEMPLATES_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );

    CREATE_DOCUMENT_TEMPLATE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_DOCUMENT_TEMPLATE ),
        mergeMap ( action => of ( action ).pipe (
            map ( action => {
                let formData = new FormData ( );
                if ( action.subjectType != null ) formData.append ( 'type', action.subjectType.toString ( ) );
                if ( action.metaId != null ) formData.append ( 'meta_id', action.metaId.toString ( ) );
                if ( action.documentId  != null ) formData.append ( 'document_id', action.documentId.toString ( ) );
                formData.append ( 'file', action.file, action.file.name );
                return [ action.op, formData ] as const;
            } ),
            mergeMap ( ( [ op, formData ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, formData ] as const ) ) ),
            mergeMap ( ( [ op, config, formData ] ) => this.http.post<any> ( `${config.study_api}/templates/`, formData ).pipe ( 
                map ( response => [ op, response ] as const )
            ) ),
            map ( ( [ op, response ] ) => [ op, DocumentTemplate.fromJson ( response ) ] as const ),
            map ( ( [ op, template ] ) => CREATE_DOCUMENT_TEMPLATE_SUCCESS ( { op, template } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( CREATE_DOCUMENT_TEMPLATE_FAILURE ( { op: CREATE_DOCUMENT_TEMPLATE.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
        ) )
    ) );

    UPDATE_DOCUMENT_TEMPLATE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_DOCUMENT_TEMPLATE ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.template.SubjectType == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update document template without a valid subject type." );
                }
                else if ( action.template.MetaId == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update document template without a valid subject meta id." );
                }
                else if ( action.template.DocumentId == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update document template without a valid id." );
                }
            } ),
            map ( action => {
                let formData = new FormData ( );
                if ( action.template.SubjectType != null ) formData.append ( 'type', action.template.SubjectType.toString ( ) );
                if ( action.template.MetaId  != null ) formData.append ( 'meta_id', action.template.MetaId.toString ( ) );
                if ( action.template.DocumentId != null ) formData.append ( 'document_id', action.template.DocumentId.toString ( ) );
                formData.append ( 'file', action.file, action.file.name );
                return [ action.op, formData ] as const;
            } ),
            mergeMap ( ( [ op, formData ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, formData ] as const ) ) ),
            mergeMap ( ( [ op, config, formData] ) => this.http.post<any> ( `${config.study_api}/templates/`, formData, ).pipe ( 
                map ( response => [ op, DocumentTemplate.fromJson ( response ) ] as const )
            ) ),
            map ( ( [ op, template ] ) => UPDATE_DOCUMENT_TEMPLATE_SUCCESS ( { op, template } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPDATE_DOCUMENT_TEMPLATE_FAILURE ( { op: UPDATE_DOCUMENT_TEMPLATE.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );


    DELETE_DOCUMENT_TEMPLATE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DELETE_DOCUMENT_TEMPLATE ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.template.SubjectType == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete document template without a valid subject type." );
                }
                else if ( action.template.MetaId == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete document template without a valid subject meta id." );
                }
                else if ( action.template.DocumentId == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete document template without a valid id." );
                }
            } ),
            map ( action => {
                let params = new HttpParams  ( );
                if ( action.template.SubjectType != null ) params = params.set ( 'type', action.template.SubjectType.toString ( ) );
                if ( action.template.MetaId  != null ) params = params.set ( 'meta_id', action.template.MetaId.toString ( ) );
                if ( action.template.DocumentId != null ) params = params.set ( 'document_id', action.template.DocumentId.toString ( ) );
                return [ action.op, action.template, params ] as const;
            } ),
            mergeMap ( ( [ op, template, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, template, config, params ] as const ) ) ),
            mergeMap ( ( [ op, template, config, params] ) => this.http.delete<any> ( `${config.study_api}/templates/`, {params} ).pipe ( 
                map ( response => [ op, template ] as const )
            ) ),
            map ( ( [ op, template ] ) => DELETE_DOCUMENT_TEMPLATE_SUCCESS ( { op, template } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( DELETE_DOCUMENT_TEMPLATE_FAILURE ( { op: DELETE_DOCUMENT_TEMPLATE.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );

    DOWNLOAD_DOCUMENT_TEMPLATE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DOWNLOAD_DOCUMENT_TEMPLATE ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                if ( action.template.SubjectType != null ) params = params.set ( 'type', action.template.SubjectType.toString ( ) );
                if ( action.template.MetaId != null ) params = params.set ( 'meta_id', action.template.MetaId.toString ( ) );
                if ( action.template.DocumentId != null ) params = params.set ( 'document_id', action.template.DocumentId.toString ( ) );
                return [ params, action.template.FileName, action.template.MimeType ] as const;
            } ),
            mergeMap ( ( [ params, filename, mime ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ config, params, filename, mime ] as const ) ) ),
            mergeMap ( ( [ config, params, filename, mime ] ) => this.http.get ( `${config.study_api}/templates/`, { params, responseType: 'blob' } ).pipe (
                map ( response => [ response, filename, mime ] as const )
            ) ),
            filter ( ( [ response, filename, mime ] ) => filename != null && mime != null ),
            tap ( ( [ response, filename, mime ]) => saveAs ( response, filename ? filename : "download.file" ) )
        ) )
    ), { dispatch: false } );

}
