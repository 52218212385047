
export class SideBar
{
    public static toJson ( sideBar: SideBar ): any
    {
        let out: any = {};

        out.id = sideBar.Id;
        out.version = sideBar.Version;
        out.hide_tasks = sideBar.HideTasks;

        return out;
    }

    public static fromJson( json: any ): SideBar
    {
        let out = new SideBar ( );

        out.Id = json.id;
        out.Version = json.version;
        out.HideTasks = json.hide_tasks;
        
        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private hideTasks: boolean | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get HideTasks( ): boolean | null
    {
        return this.hideTasks;
    }

    public set HideTasks( hideTasks: boolean | null )
    {
        this.hideTasks = hideTasks;
    }
}
