

export enum SubjectType
{
    NONE = 1,

    SITE = 2,

    PARTICIPANT = 3,
}

export abstract class SubjectTypeUtils
{
//     public static readonly STRING_LABEL = "Text";

//     public static readonly BOOLEAN_LABEL = "Yes / No";

//     public static get Labels ( ) : string [ ]
//     {
//         return [ this.STRING_LABEL, this.BOOLEAN_LABEL ];
//     }

//     public static toLabel ( type: FieldType ): string | null
//     {
//         switch ( type )
//         {
//         case FieldType.STRING:
//             return this.STRING_LABEL;
//         case FieldType.BOOLEAN:
//             return this.BOOLEAN_LABEL;
//         default:
//             return null;
//         }
//     }

//     public static fromLabel ( label: string ): FieldType
//     {
//         switch ( label )
//         {
//         case this.STRING_LABEL:
//             return FieldType.STRING;
//         case this.BOOLEAN_LABEL:
//             return FieldType.BOOLEAN;
//         default:
//             return FieldType.NONE;
//         }
//     }

    public static fromString( value: string ): SubjectType
    {
        return Object.entries(SubjectType).find(([key, val]) => key === value)?.[1] as SubjectType;
    }

    public static fromNumber( value: number ): SubjectType
    {
        return Object.entries(SubjectType).find(([key, val]) => val === value)?.[1] as SubjectType;
    }

    public static toString ( value: SubjectType ): string
    {
        return Object.entries(SubjectType).find(([key, val]) => val === value)?.[0] as string;
    }

}
