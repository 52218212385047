import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { SELECT_STUDY_CHANGE_RECORDS_RECORDS,  } from './study-change-records.selectors';
import { GET_CHANGE_RECORDS, GET_CHANGE_RECORDS_FAILURE, GET_CHANGE_RECORDS_SUCCESS, ON_CHANGE_RECORDS_LIST } from './study-change-records.actions';
import { ChangeRecord } from '../../../model/study/ChangeRecord';

@Injectable ( )
export class StudyChangeRecordsEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_CHANGE_RECORDS_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_CHANGE_RECORDS_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_STUDY_CHANGE_RECORDS_RECORDS ) ),
        mergeMap ( ([action, records]) => {
            if ( action.force || records == null || records.length == 0 )
            {
                return [ GET_CHANGE_RECORDS ( { op: 'list', start: action.start, end: action.end } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_CHANGE_RECORDS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_CHANGE_RECORDS ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                params = params.append ( 'start', action.start.toISOString ( ) );
                params = params.append ( 'end', action.end.toISOString ( ) );
                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.study_api}/change-records/`, { params } ) ),
            map ( response => {
                const records = new Array<ChangeRecord> ( );
                for ( const json of response )
                {
                    const record = ChangeRecord.fromJson ( json );
                    if ( record )
                    {
                        records.push ( record );
                    }
                }
                return records;
            } ),
            map ( records => GET_CHANGE_RECORDS_SUCCESS ( { op: action.op, records } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_CHANGE_RECORDS_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
