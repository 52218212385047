import { createReducer, on } from '@ngrx/store';
import { GET_GROUP_HIERARCHY, GET_GROUP_HIERARCHY_FAILURE, GET_GROUP_HIERARCHY_SUCCESS } from './system-groups.actions';
import { SystemGroupsState } from './system-groups.state';

export const INITIAL_STATE = new SystemGroupsState ( );

const _SYSTEM_GROUPS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_GROUP_HIERARCHY, ( state, { op } ) => {
      const newState = { ...state };

      newState.hierarchyLoading = true;
      newState.hierarchy = null;

      return newState;
    } ),
    on ( GET_GROUP_HIERARCHY_SUCCESS, ( state, { op, hierarchy } ) => {
      const newState = { ...state };

      newState.hierarchy = hierarchy;
      newState.hierarchyLoading = false;
      
      return newState;
    } ),
    on ( GET_GROUP_HIERARCHY_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.hierarchy = null;
      newState.hierarchyLoading = false;

      return newState;
    } ),
);

export function SYSTEM_GROUPS_REDUCER( state: any, action: any )
{
  return _SYSTEM_GROUPS_REDUCER ( state, action );
}
