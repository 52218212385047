<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <div class="form-group">        
        <ng-container *ngIf="LineCount < 1 || LineCount == 1; else multiLine">
            <input type="text" class="form-control" id="value" placeholder="" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }">
        </ng-container>
        <ng-template #multiLine>
            <textarea class="form-control" style="resize: none;" [rows]="LineCount" id="value" placeholder="" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }"></textarea>
        </ng-template>
        <div class="invalid-feedback">
            <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                Value is required
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                {{ValueFormControl?.errors?.['validityExpression']}}
            </ng-container>
        </div>
    </div>

</form>