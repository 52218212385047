import { Expression } from "../expressions/Expression";

export class ReportColumn 
{
    public static clone ( input: ReportColumn ) : ReportColumn
    {
        const output = new ReportColumn ( );
        output.Id = input.Id;
        output.Version = input.Version;
        output.Name = input.Name;
        output.Seq = input.Seq;
        output.Description = output.Description;
        output.ValueExpression = input.ValueExpression ? Expression.toJson ( input.ValueExpression ) : null;
        output.LabelColourExpression = input.LabelColourExpression ? Expression.toJson ( input.LabelColourExpression ) : null;                        
        return output;
    }

    public static toJson ( column: ReportColumn ): any
    {
        let out: any = {};

        out.id = column.Id;
        out.version = column.Version;
        out.seq = column.Seq;
        out.name = column.Name;
        out.description = column.Description;
        out.exclude_expression = column.ValueExpression ? Expression.toJson ( column.ValueExpression ) : null;
        out.disabled_expression = column.LabelColourExpression ? Expression.toJson ( column.LabelColourExpression ) : null;

        return out;
    }

    public static fromJson( json: any ): ReportColumn
    {
        let out = new ReportColumn ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Seq = json.seq;
        out.Name = json.name;
        out.Description = json.description;
        out.ValueExpression = "value_expression" in json && json.value_expression ? Expression.fromJson ( json.value_expression ) : null;
        out.LabelColourExpression = "label_colour_expression" in json && json.label_colour_expression ? Expression.fromJson ( json.label_colour_expression ) : null;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private seq: number | null = null,
                        private name: string | null  = null, 
                        private description: string | null  = null, 
                        private valueExpression: Expression | null  = null,
                        private labelColourExpression: Expression | null  = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    } 

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    } 
    
    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    } 

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    } 

    public get ValueExpression( ): Expression | null
    {
        return this.valueExpression;
    }

    public set ValueExpression( expression: Expression | null )
    {
        this.valueExpression = expression;
    }

    public get LabelColourExpression( ): Expression | null
    {
        return this.labelColourExpression;
    }

    public set LabelColourExpression( expression: Expression | null )
    {
        this.labelColourExpression = expression;
    }
}