import { FieldValueFactory } from "./FieldValueFactory";
import { FieldValue } from "./FieldValue";

export class SectionValue
{
    public static clone ( section: SectionValue ) : SectionValue
    {
        const fieldValues = new Array<FieldValue> ( );
        for ( const field of section.FieldValues ? section.FieldValues : [] )
        {
            fieldValues.push ( FieldValueFactory.clone ( field ) );
        }

        return new SectionValue ( section.Id,
                                  section.Version,
                                  section.MetaId,
                                  fieldValues );

    }

    public static toJson( section: SectionValue ): any
    {
        let out: any = {};

        out.id = section.Id;
        // out.version = section.Version;
        out.meta_id = section.MetaId;
        out.field_values = new Array<any> ( );
        for ( const field of section.FieldValues ? section.FieldValues : [] )
        {
            out.field_values.push ( FieldValueFactory.toJson ( field ) );
        }

        return out;
    }

    public static fromJson( json: any ): SectionValue
    {
        let out = new SectionValue ( );

        out.Id = json.id as number
        out.Version = json.version as number;
        out.MetaId = json.meta_id as number;
    
        out.FieldValues = new Array<FieldValue> ( );
        for ( const field of json.field_values ? json.field_values : [] )
        {
            const parsed = FieldValueFactory.fromJson ( field );
            if ( parsed != null )
            {
                out.FieldValues.push ( parsed );
            }
        }

        return out;
    }

    public  constructor( private id: number | null = null,
                         private version: number | null = null,
                         private metaId: number | null = null,
                         private fieldValues: Array<FieldValue> | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get FieldValues( ): Array<FieldValue> | null
    {
        return this.fieldValues;
    }

    public set FieldValues( fieldValues: Array<FieldValue> | null )
    {
        this.fieldValues = fieldValues;
    }
}
