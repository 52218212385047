import { FieldFactory } from "./FieldFactory";
import { Field } from "./Field";
import { Role } from "./Role";

export class Section
{
    public static clone ( input: Section ) : Section
    {
        const output = new Section ( );
        output.Id = input.Id;
        output.Version = input.Version;
        output.Seq = input.Seq;
        output.ViewACL = new Map<string, Role> ( );
        for ( const [key, value] of input.ViewACL ? input.ViewACL.entries ( ) : [] )
        {
            output.ViewACL.set ( key, Role.clone ( value ) );
        }
        output.EditACL = new Map<string, Role> ( );
        for ( const [key, value] of input.EditACL ? input.EditACL.entries ( ) : [] )
        {
            output.EditACL.set ( key, Role.clone ( value ) );
        }
        output.CompleteEditACL = new Map<string, Role> ( );
        for ( const [key, value] of input.CompleteEditACL ? input.CompleteEditACL.entries ( ) : [] )
        {
            output.CompleteEditACL.set ( key, Role.clone ( value ) );
        }
        output.Variable = input.Variable;
        output.Name = input.Name;
        output.Description = input.Description;
        output.Preamble = input.Preamble;
        output.Fields = new Array<Field> ( );
        for ( const field of input.Fields ? input.Fields : [] )
        {
            const clone = FieldFactory.clone ( field );
            if ( clone )
            {
                output.Fields.push ( clone );
            }
        }
                        
        return output;
    }

    public static toJson( section: Section ): any
    {
        let out: any = {};

        out.id = section.Id;
        out.version = section.Version;
        out.seq = section.Seq;
        out.edit_acl = new Array<any> ( );
        for ( const [, role] of section.EditACL ? section.EditACL : [] )
        {
            out.edit_acl.push ( Role.toJson ( role ) );
        }
        out.view_acl = new Array<any> ( );
        for ( const [, role] of section.ViewACL ? section.ViewACL : [] )
        {
            out.view_acl.push ( Role.toJson ( role ) );
        }
        out.complete_edit_acl = new Array<any> ( );
        for ( const [, role] of section.CompleteEditACL ? section.CompleteEditACL : [] )
        {
            out.complete_edit_acl.push ( Role.toJson ( role ) );
        }
        out.variable = section.Variable;
        out.name = section.Name;
        out.description = section.Description;
        out.preamble = section.Preamble;
        out.fields = new Array<any> ( );
        for ( const field of section.Fields ? section.Fields : [] )
        {
            out.fields.push ( FieldFactory.toJson ( field ) );
        }

        return out;
    }

    public static fromJson( json: any ): Section
    {
        let out = new Section ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Seq = json.seq;
        out.EditACL = new Map<string, Role> ( );
        for ( const role of json.edit_acl ? json.edit_acl : [] )
        {
            const value = Role.fromJson ( role );
            if ( value.Name )
            {
                out.EditACL.set ( value.Name, value );
            }
        }
        out.ViewACL = new Map<string, Role> ( );
        for ( const role of json.view_acl ? json.view_acl : [] )
        {
            const value = Role.fromJson ( role );
            if ( value.Name )
            {
                out.ViewACL.set ( value.Name, value );
            }
        }
        out.CompleteEditACL = new Map<string, Role> ( );
        for ( const role of json.complete_edit_acl ? json.complete_edit_acl : [] )
        {
            const value = Role.fromJson ( role );
            if ( value.Name )
            {
                out.CompleteEditACL.set ( value.Name, value );
            }
        }
        out.Variable = json.variable;
        out.Name = json.name;
        out.Description = json.description;
        out.Preamble = json.preamble;
        out.Fields = new Array<Field> ( );
        for ( const field of json.fields ? json.fields : [] )
        {
            const parsed = FieldFactory.fromJson ( field );
            if ( parsed != null )
            {
                out.Fields.push ( parsed );
            }
        }

        return out;
    }

    protected constructor( private id: number | null = null,
                           private version: number | null = null,
                           private seq: number | null = null,
                           private editACL: Map<string, Role> | null = null,
                           private viewACL: Map<string, Role> | null = null,
                           private completeEditACL: Map<string, Role> | null = null,
                           private variable: string | null = null,
                           private name: string | null = null,
                           private description: string | null = null,
                           private preamble: string | null = null,
                           private fields: Array<Field> | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get EditACL( ): Map<string, Role> | null
    {
        return this.editACL;
    }

    public set EditACL( editACL: Map<string, Role> | null )
    {
        this.editACL = editACL;
    }

    public get ViewACL( ): Map<string, Role> | null
    {
        return this.viewACL;
    }

    public set ViewACL( viewACL: Map<string, Role> | null )
    {
        this.viewACL = viewACL;
    }

    public get CompleteEditACL( ): Map<string, Role> | null
    {
        return this.completeEditACL;
    }

    public set CompleteEditACL( completeEditACL: Map<string, Role> | null )
    {
        this.completeEditACL = completeEditACL;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get Preamble( ): string | null
    {
        return this.preamble;
    }

    public set Preamble( preamble: string | null )
    {
        this.preamble = preamble;
    }

    public get Fields( ): Array<Field> | null
    {
        return this.fields;
    }

    public set Fields( fields: Array<Field> | null )
    {
        this.fields = fields;
    }
}
