import { BooleanField } from "./BooleanField";
import { DateField } from "./DateField";
import { EnumeratedField } from "./EnumeratedField";
import { Field } from "./Field";
import { FileField } from "./FileField";
import { ListField } from "./ListField";
import { NumericField } from "./NumericField";
import { ParticipantField } from "./ParticipantField";
import { StringField } from "./StringField";

export class FieldWrapper
{
  constructor ( private field: Field )
  {
    // Null.
  }

  get Field ( ) : Field | null
  {
    if ( this.field instanceof Field )
    {
      return this.field as Field
    }
    
    return null;
  }

  get BooleanField ( ) : BooleanField | null
  {
    if ( this.field instanceof BooleanField )
    {
      return this.field as BooleanField
    }
    
    return null;
  }

  get DateField ( ) : DateField | null
  {
    if ( this.field instanceof DateField )
    {
      return this.field as DateField
    }
    
    return null;
  }

  get EnumeratedField ( ) : EnumeratedField | null
  {
    if ( this.field instanceof EnumeratedField )
    {
      return this.field as EnumeratedField
    }
    
    return null;
  }

  get NumericField ( ) : NumericField | null
  {
    if ( this.field instanceof NumericField )
    {
      return this.field as NumericField
    }
    
    return null;
  }

  get StringField ( ) : StringField | null
  {
    if ( this.field instanceof StringField )
    {
      return this.field as StringField
    }
    
    return null;
  }

  get ParticipantField ( ) : ParticipantField | null
  {
    if ( this.field instanceof ParticipantField )
    {
      return this.field as ParticipantField
    }
    
    return null;
  }

  get FileField ( ) : FileField | null
  {
    if ( this.field instanceof FileField )
    {
      return this.field as FileField
    }
    
    return null;
  }

  get ListField ( ) : ListField | null
  {
    if ( this.field instanceof ListField )
    {
      return this.field as ListField
    }
    
    return null;
  }
}