import { createReducer, on } from '@ngrx/store';
import { StudyParticipantsState, TypeSubState, SiteSubState } from './study-participants.state';
import { Participant } from '../../../model/study/participants/Participant';
import { CREATE_PARTICIPANT, CREATE_PARTICIPANT_FAILURE, CREATE_PARTICIPANT_FORM, CREATE_PARTICIPANT_FORM_FAILURE, CREATE_PARTICIPANT_FORM_SUCCESS, CREATE_PARTICIPANT_SUCCESS, DELETE_PARTICIPANT, DELETE_PARTICIPANTS, DELETE_PARTICIPANTS_FAILURE, DELETE_PARTICIPANTS_SUCCESS, DELETE_PARTICIPANT_FAILURE, DELETE_PARTICIPANT_SUCCESS, GET_PARTICIPANTS, GET_PARTICIPANTS_FAILURE, GET_PARTICIPANTS_SUCCESS, ON_PARTICIPANTS_UNLOAD, UPDATE_PARTICIPANT, UPDATE_PARTICIPANT_FAILURE, UPDATE_PARTICIPANT_FORM, UPDATE_PARTICIPANT_FORM_FAILURE, UPDATE_PARTICIPANT_FORM_SUCCESS, UPDATE_PARTICIPANT_SUCCESS } from './study-participants.actions';

export const INITIAL_STATE = new StudyParticipantsState ( );

const _STUDY_PARTICIPANTS_REDUCER = createReducer ( INITIAL_STATE,

    on ( ON_PARTICIPANTS_UNLOAD, ( state, { op, siteId, pType } ) => {
      const newState = { ...state };

      if ( siteId == null )
      {
        newState.typeSubStates = new Map<number, TypeSubState> ( );
      }
      else
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

        siteSubState.participantsLoading = false;
        siteSubState.participants = new Map<number, Participant> ( );

        typeSubState.siteSubStates.set ( siteId, siteSubState );
        newState.typeSubStates.set ( pType, typeSubState );
      }

      return newState;
    } ),

    on ( GET_PARTICIPANTS, ( state, { op, siteId, pType, ids } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsLoading = true;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( GET_PARTICIPANTS_SUCCESS, ( state, { op, siteId, pType, fullLoad, participants } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsLoading = false;
      siteSubState.participants = new Map<number, Participant> ( );
      for ( const participant of participants ? participants : [] )
      {
        if ( participant.Id != null )
        {
          siteSubState.participants.set ( participant.Id, participant );
        }
      }
      siteSubState.fullLoad = fullLoad;

      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( GET_PARTICIPANTS_FAILURE, ( state, { siteId, pType, errorDetails } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsLoading = false;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),


    on ( CREATE_PARTICIPANT, ( state, { op, participant } ) => {
      const newState = { ...state };

      if ( participant.MetaId && participant.SiteId )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = true;
        
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( CREATE_PARTICIPANT_SUCCESS, ( state, { op, participant } ) => {
      const newState = { ...state };

      if ( participant.MetaId && participant.SiteId )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = false;
        
        if ( participant.Id != null )
        {
          siteSubState.participants = new Map<number, Participant> ( siteSubState.participants );
          siteSubState.participants.set ( participant.Id, participant );
        }

        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( CREATE_PARTICIPANT_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      if ( pType != null && siteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

        siteSubState.participantsSaving = false;
        
        typeSubState.siteSubStates.set ( siteId, siteSubState );
        newState.typeSubStates.set ( pType, typeSubState );
      }
      return newState;
    } ),


    on ( UPDATE_PARTICIPANT, ( state, { op, participant } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = true;
        
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( UPDATE_PARTICIPANT_SUCCESS, ( state, { op, participant } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        if ( participant.Id != null )
        {
          siteSubState.participants = new Map<number, Participant> ( siteSubState.participants );
          siteSubState.participants.set ( participant.Id, participant );
        }
      
        siteSubState.participantsSaving = false;
        
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( UPDATE_PARTICIPANT_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      if ( pType != null && siteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

        siteSubState.participantsSaving = false;
        
        typeSubState.siteSubStates.set ( siteId, siteSubState );
        newState.typeSubStates.set ( pType, typeSubState );
      }
      return newState;
    } ),


    on ( DELETE_PARTICIPANT, ( state, { op, pType, siteId, id } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = true;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( DELETE_PARTICIPANT_SUCCESS, ( state, { op, pType, siteId, id } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = false;
    
      if ( id != null )
      {
        siteSubState.participants.delete ( id );
        siteSubState.participants = new Map<number, Participant> ( siteSubState.participants );
      }
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( DELETE_PARTICIPANT_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = false;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );
      return newState;
    } ),


    on ( DELETE_PARTICIPANTS, ( state, { op, pType, siteId } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = true;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( DELETE_PARTICIPANTS_SUCCESS, ( state, { op, pType, siteId } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = false;
  
      siteSubState.participants = new Map<number, Participant> ( );
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );

      return newState;
    } ),
    on ( DELETE_PARTICIPANTS_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
      let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

      siteSubState.participantsSaving = false;
      
      typeSubState.siteSubStates.set ( siteId, siteSubState );
      newState.typeSubStates.set ( pType, typeSubState );
      return newState;
    } ),


    on ( CREATE_PARTICIPANT_FORM, ( state, { op, participant, cp, form, fileUploads } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = true;
        
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( CREATE_PARTICIPANT_FORM_SUCCESS, ( state, { op, participant, collectionPointValueId, formValueId } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = false;
        
        
        if ( participant.Id != null )
        {
          siteSubState.participants = new Map<number, Participant> ( siteSubState.participants );
          siteSubState.participants.set ( participant.Id, participant );
        }
      
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( CREATE_PARTICIPANT_FORM_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      if ( pType != null && siteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

        siteSubState.participantsSaving = false;
        
        typeSubState.siteSubStates.set ( siteId, siteSubState );
        newState.typeSubStates.set ( pType, typeSubState );
      }
      return newState;
    } ),


    on ( UPDATE_PARTICIPANT_FORM, ( state, { op, participant, cpv, form, fileUploads } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = true;
        
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( UPDATE_PARTICIPANT_FORM_SUCCESS, ( state, { op, participant } ) => {
      const newState = { ...state };

      if ( participant.MetaId != null && participant.SiteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, participant.MetaId );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, participant.SiteId );

        siteSubState.participantsSaving = false;
        
        if ( participant.Id != null )
        {
          siteSubState.participants = new Map<number, Participant> ( siteSubState.participants );
          siteSubState.participants.set ( participant.Id, participant );
        }
      
        typeSubState.siteSubStates.set ( participant.SiteId, siteSubState );
        newState.typeSubStates.set ( participant.MetaId, typeSubState );
      }
      return newState;
    } ),
    on ( UPDATE_PARTICIPANT_FORM_FAILURE, ( state, { pType, siteId, errorDetails } ) => {
      const newState = { ...state };

      if ( pType != null && siteId != null )
      {
        let typeSubState = StudyParticipantsState.getTypeState ( newState, pType );
        let siteSubState = TypeSubState.getSiteState ( typeSubState, siteId );

        siteSubState.participantsSaving = false;
        
        typeSubState.siteSubStates.set ( siteId, siteSubState );
        newState.typeSubStates.set ( pType, typeSubState );
      }
      return newState;
    } ),

);

export function STUDY_PARTICIPANTS_REDUCER( state: any, action: any )
{
  return _STUDY_PARTICIPANTS_REDUCER ( state, action );
}
