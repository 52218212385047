import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { GET_GROUP_HIERARCHY, GET_GROUP_HIERARCHY_FAILURE, GET_GROUP_HIERARCHY_SUCCESS, ON_GROUP_HIERARCHY_LIST } from './system-groups.actions';
import { GroupHierarchy } from '../../../model/system/groups/GroupHierarchy';
import { SELECT_SYSTEM_GROUPS_HIERARCHY } from './system-groups.selectors';

@Injectable ( )
export class SystemGroupsEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_GROUP_HIERARCHY_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_GROUP_HIERARCHY_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_SYSTEM_GROUPS_HIERARCHY ) ),
        mergeMap ( ([action, groups]) => {
            if ( action.force || groups == null )
            {
                return [ GET_GROUP_HIERARCHY ( { op: 'list' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_GROUP_HIERARCHY$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_GROUP_HIERARCHY ),
        switchMap ( action => of ( action ).pipe (
            mergeMap ( action => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.system_api}/group-hierarchy/` ) ),
            map ( response => {
                const hierarchy = GroupHierarchy.fromJson ( response );
                return hierarchy;
            } ),
            map ( hierarchy => GET_GROUP_HIERARCHY_SUCCESS ( { op: action.op, hierarchy } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_GROUP_HIERARCHY_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
