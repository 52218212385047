import { Component } from '@angular/core';

@Component({
  selector: 'app-auto-signout',
  templateUrl: './auto.signout.component.html',
  styleUrls: ['./auto.signout.component.scss']
})
export class AutoSignoutComponent
{
  constructor ( )
  {

  }
}
