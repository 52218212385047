import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Site } from "../model/study/sites/Site";
import { ExpressionEngineFactory } from "../expressions/ExpressionEngineFactory";
import { PublishedSitesService } from "./published/published-sites.service";
import { StudySitesService } from "./study/study-sites.service";
import { SiteMeta } from "../model/published/sites/SiteMeta";
import { CollectionPoint } from "../model/published/CollectionPoint";
import { CollectionPointValue } from "../model/study/CollectionPointValue";
import { Form } from "../model/published/Form";
import { FormValue } from "../model/study/FormValue";
import { SiteUtils } from "../model/study/sites/SiteUtils";
import { FileField } from "../model/published/FileField";
import { FileFieldValue } from "../model/study/FileFieldValue";


@Injectable ( {
    providedIn: 'root'
} )
export class StudySitesFascadeService
{
    constructor( private published: PublishedSitesService, private study: StudySitesService, private eFactory: ExpressionEngineFactory )
    {
        // Null.
    }

    public onList ( force = false )
    {
        this.published.onList ( force );
        this.study.onList ( force );
    }

    public onSingle ( id: number, force = false )
    {
        // This needs optimising at somepoint...
        this.published.onList ( force );
        this.study.onList ( force );
    }

    getLoading( ): Observable<boolean>
    {
        return combineLatest ( [ this.published.getLoading ( ), this.study.getLoading ( ) ] ).pipe (
            map ( ( [ published, study ] ) => ( published || study ) )
        );
    }

    getSaving( ): Observable<boolean>
    {
        return this.study.getSaving ( );
    }

    getPublished ( ) : PublishedSitesService
    {
        return this.published;
    }

    // getPublishedLoading ( ) : Observable<boolean>
    // {
    //     return this.published.getLoading ( );
    // }

    // getPublishedSite ( id: number ) : Observable<SiteMeta | null>
    // {
    //     return this.published.getSite ( id );
    // } 

    getSite ( id: number ) : Observable<[Site, SiteMeta] | null>
    {
        return this.getSites ( ).pipe (
            map ( sites => {
                if ( sites )
                {
                    let site = sites.get ( id );
                    if ( site )
                    {
                        return site;
                    }
                }

                return null;
            } )
        );
    }

    // getPublishedSites ( ) : Observable<Map<number, SiteMeta> | null>
    // {
    //     return this.published.getSites ( );
    // }

    getSites ( ) : Observable<Map<number, [Site, SiteMeta]> | null>
    {
        return combineLatest ( [ this.published.getSites ( ), this.study.getSites ( ) ] ).pipe (
            map ( ( [ meta, sites ] ) => {
                if ( meta && sites )
                {
                    let out = new Map<number, [Site, SiteMeta]> ( );
                    for ( let [id, site] of sites )
                    {
                        if ( site.MetaId )
                        {
                            let type = meta.get ( site.MetaId );
                            if ( type )
                            {
                                out.set ( id, [site, type] );
                            }
                        }
                    }

                    return out;
                }
                return null;
            } )
        );
    }

    getFilteredSites ( filterFunc: (site: Site, meta: SiteMeta) => boolean ) : Observable<Map<number, [Site, SiteMeta]> | null>
    {
        return this.getSites ( ).pipe (
            map ( sites => {
                if ( sites )
                {
                    const activeSites = new Map<number, [Site, SiteMeta]> ( );
                    for ( const site of sites.values ( ) )
                    {
                        if ( site[0].Id && filterFunc ( site[0], site[1] ) )
                        {
                            activeSites.set ( site[0].Id, site );
                        }
                    }
                    return activeSites;
                }

                return null;
            } )
        );
    }

    public getSiteForm ( site$: Observable<[Site, SiteMeta] | null>, formId: number ) : Observable<[FormValue, Form, CollectionPointValue, CollectionPoint] | null>
    {
        return site$.pipe (
            map ( response => {
                if ( response && response[0] && response[1] )
                {
                    const siteUtils = new SiteUtils ( );
                    for ( const cpv of response[0].AllCollectionPointValues )
                    {
                        if ( cpv.MetaId != null )
                        {
                            const formValue = siteUtils.getFormValue ( cpv, formId );
                            if ( formValue && formValue.MetaId != null )
                            {
                                const cp = siteUtils.getCollectionPoint ( response[1], cpv.MetaId );
                                if ( cp )
                                {
                                    const form = siteUtils.getForm ( cp, formValue.MetaId );
                                    if ( form )
                                    {
                                        return [formValue, form, cpv, cp];
                                    }
                                }
                            }
                        }
                    }
                }
              
                return null;
            } )
        );
    }

    public getSiteFormByName ( site$: Observable<[Site, SiteMeta] | null>, name: string ) : Observable<[FormValue, Form, CollectionPointValue, CollectionPoint] | null>
    {
        return site$.pipe (
            map ( response => {
                if ( response && response[0] && response[1] )
                {
                    for ( const cp of response[1].AllCollectionPoints )
                    {
                        const cpFragment = `${cp.Variable}`;
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            const formFragment = `${cpFragment}.${form.Variable}`;
                            if ( name.startsWith ( formFragment ) )
                            {
                                for ( const cpv of response[0].AllCollectionPointValues )
                                {
                                    for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                    {
                                        if ( formValue.MetaId == form.Id )
                                        {
                                            return [formValue, form, cpv, cp];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
              
                return null;
            } )
        );
    }

    public getSiteRegistrationForms ( site$: Observable<[Site, SiteMeta] | null> ) : Observable<Array<[FormValue | null, Form]>>
    {
        return site$.pipe (
            map ( response => {
                let results = new Array<[FormValue | null, Form]> ( );
                if ( response && response[0] && response[1] )
                {
                    const cp = response[1].RegistrationCollectionPoint;
                    if ( cp )
                    {
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            let found = false;
                            const cpv = response[0].RegistrationCollectionPointValue;
                            if ( cpv )
                            {
                                for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                {
                                    if ( formValue.MetaId == form.Id )
                                    {
                                        results.push ( [formValue, form] );
                                        found = true;
                                        break;
                                    }
                                }
                            }

                            // if ( found == false )
                            // {
                            //     results.push ( [ null, form ] );
                            // }
                        }
                    }
                }
              
                return results;
            } )
        );
    }

    public getSiteBaselineForms ( site$: Observable<[Site, SiteMeta] | null> ) : Observable<Array<[FormValue | null, Form]>>
    {
        return site$.pipe (
            map ( response => {
                let results = new Array<[FormValue | null, Form]> ( );
                if ( response && response[0] && response[1] )
                {
                    const cp = response[1].BaselineCollectionPoint;
                    if ( cp )
                    {
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            let found = false;
                            const cpv = response[0].BaselineCollectionPointValue;
                            if ( cpv )
                            {
                                for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                {
                                    if ( formValue.MetaId == form.Id )
                                    {
                                        results.push ( [formValue, form] );
                                        found = true;
                                        break;
                                    }
                                }
                            }

                            // if ( found == false )
                            // {
                            //     results.push ( [ null, form ] );
                            // }
                        }
                    }
                }
              
                return results;
            } )
        );
    }

    public getSiteCompletionForms ( site$: Observable<[Site, SiteMeta] | null> ) : Observable<Array<[FormValue | null, Form]>>
    {
        return site$.pipe (
            map ( response => {
                let results = new Array<[FormValue | null, Form]> ( );
                if ( response && response[0] && response[1] )
                {
                    const cp = response[1].CompletionCollectionPoint;
                    if ( cp )
                    {
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            let found = false;
                            const cpv = response[0].CompletionCollectionPointValue;
                            if ( cpv )
                            {
                                for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                {
                                    if ( formValue.MetaId == form.Id )
                                    {
                                        results.push ( [formValue, form] );
                                        found = true;
                                        break;
                                    }
                                }
                            }

                            // if ( found == false )
                            // {
                            //     results.push ( [ null, form ] );
                            // }
                        }
                    }
                }
              
                return results;
            } )
        );
    }

    public isSiteEligible ( type: SiteMeta | null, site: Site | null ) : boolean
    {
        let eligible = true; // Default to being eligible, as if no expression is defined, all sites are eligible
        if ( type && site )
        {
            if ( type.EligibilityExpression && type.Variable )
            {
                const engine = this.eFactory.create ( );
                const values = new SiteUtils ( ).getSubjectVariableValues ( type, site, type.Variable );
                const res = engine.eval ( type.EligibilityExpression, values );
                if ( typeof res == "boolean" )
                {
                    return res;
                }
                else
                {
                    // Type conversion issue!
                    return false;
                }
            }

        }
      
        return eligible;
    }

    public isSiteEligible$ ( site$: Observable<[Site, SiteMeta] | null> ) : Observable<boolean>
    {
        return site$.pipe (
            map ( response => {
                if ( response )
                {
                    return this.isSiteEligible ( response[1], response[0]);
                }
              
                return false;
            } )
        );
    }

    public createSite ( site: Site, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.study.createSite ( site );
    }

    public updateSite ( site: Site, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.study.updateSite ( site );
    }

    public createForm ( site: Site, cp: CollectionPoint, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.study.createForm ( site, cp, form );
    }

    public updateForm ( site: Site, cpv: CollectionPointValue, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.study.updateForm ( site, cpv, form );

        for ( const [fileField, fileFieldValue, file] of fileUploads ? fileUploads : [] )
        {
            this.study.uploadFile ( site, cpv, form, fileField, fileFieldValue, file );
        }
    }

    public downloadFile ( site: Site, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue )
    {
        this.study.downloadFile ( site, cpv, fv, field, fieldValue );
    }
}