import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudyTasksState, STUDY_TASKS_FEATURE_STATE_NAME } from './study-tasks.state';

export const SELECT_STUDY_TASKS = createFeatureSelector<StudyTasksState> ( STUDY_TASKS_FEATURE_STATE_NAME );

export const SELECT_STUDY_TASKS_LOADING  = createSelector ( SELECT_STUDY_TASKS, ( state: StudyTasksState ) => state.loading );
export const SELECT_STUDY_TASKS_INCLUDES_COMPLETED  = createSelector ( SELECT_STUDY_TASKS, ( state: StudyTasksState ) => state.includesCompleted );

export const SELECT_STUDY_TASKS_TASKS = createSelector ( SELECT_STUDY_TASKS, ( state: StudyTasksState ) => state.tasks );
export const SELECT_STUDY_TASKS_TASK  = createSelector ( SELECT_STUDY_TASKS, ( state: StudyTasksState, props: { id: number } ) => state.tasks.get ( props.id ) );
