import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Participant } from '../../model/study/participants/Participant';
import { map } from 'rxjs/operators';
import { CREATE_PARTICIPANT, CREATE_PARTICIPANT_FORM, DELETE_PARTICIPANT, DELETE_PARTICIPANTS, DOWNLOAD_PARTICIPANT_DOCUMENT, DOWNLOAD_PARTICIPANT_FILE, ON_PARTICIPANTS_LIST, ON_PARTICIPANTS_SINGLE, ON_PARTICIPANTS_UNLOAD, UPDATE_PARTICIPANT, UPDATE_PARTICIPANT_FORM, UPLOAD_PARTICIPANT_FILE } from '../../state/study/participants/study-participants.actions';
import { SELECT_STUDY_PARTICIPANTS_LOADING, SELECT_STUDY_PARTICIPANTS_LOADING_ALL, SELECT_STUDY_PARTICIPANTS_PARTICIPANT, SELECT_STUDY_PARTICIPANTS_PARTICIPANTS, SELECT_STUDY_PARTICIPANTS_PARTICIPANTS_ALL, SELECT_STUDY_PARTICIPANTS_SAVING } from '../../state/study/participants/study-participants.selectors';
import { CollectionPointValue } from '../../model/study/CollectionPointValue';
import { FormValue } from '../../model/study/FormValue';
import { CollectionPoint } from '../../model/published/CollectionPoint';
import { FileField } from '../../model/published/FileField';
import { FileFieldValue } from '../../model/study/FileFieldValue';
import { DocumentMeta } from '../../model/published/documents/DocumentMeta';


@Injectable ( {
  providedIn: 'root'
} )
export class StudyParticipantsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onUnload( type: number, siteId: number )
  {
    this.store.dispatch ( ON_PARTICIPANTS_UNLOAD ( { op: 'unload', pType: +type, siteId: +siteId } ) );
  }

  onUnloadAll( type: number )
  {
    this.store.dispatch ( ON_PARTICIPANTS_UNLOAD ( { op: 'unload', pType: +type, siteId: null } ) );
  }

  onList( type: number, siteId: number, force: boolean = false )
  {
    this.store.dispatch ( ON_PARTICIPANTS_LIST ( { op: 'list', pType: +type, siteId: +siteId, ids: null, force } ) );
  }

  onListWithIds( type: number, siteId: number, ids: number[], force: boolean = false )
  {
    this.store.dispatch ( ON_PARTICIPANTS_LIST ( { op: 'list', pType: +type, siteId: +siteId, ids, force } ) );
  }

  onSingle( type: number, siteId: number, id: number, force: boolean = false )
  {
    this.store.dispatch ( ON_PARTICIPANTS_SINGLE ( { pType: +type, siteId: +siteId, id, force } ) );
  }

  getLoading( type: number ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_LOADING, { type: +type } ),
      map ( loading => loading ? true : false )
    );
  }

  getAllLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_LOADING_ALL ),
      map ( loading => loading ? true : false )
    );
  }

  getSaving( type: number ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_SAVING, { type: +type } ),
      map ( loading => loading ? true : false ) 
    );
  }

  getParticipants( type: number, siteId: number ): Observable<Map<number, Participant>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_PARTICIPANTS, { type: +type, siteId: +siteId } ),
      map ( participants => participants ? participants : new Map ( ) )
    );
  }

  getAllParticipants( ): Observable<Map<number, Participant>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_PARTICIPANTS_ALL ),
      map ( participants => participants ? participants : new Map ( ) )
    );
  }

  getParticipant( type: number, siteId: number, id: number ): Observable<Participant | null>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_PARTICIPANTS_PARTICIPANT, { type: +type, siteId: +siteId, id } ),
      map ( participant => {
        if ( participant )
        {
          return participant;
        }
        else
        {
          return null;
        }
      } )
    );
  }

  createParticipant ( participant: Participant )
  {
    this.store.dispatch ( CREATE_PARTICIPANT ( { op: CREATE_PARTICIPANT.type, participant } ) );
  }

  updateParticipant ( participant: Participant )
  {
    this.store.dispatch ( UPDATE_PARTICIPANT ( { op: UPDATE_PARTICIPANT.type, participant } ) );
  }

  deleteParticipant ( type: number, siteId: number, id: number )
  {
    this.store.dispatch ( DELETE_PARTICIPANT ( { op: DELETE_PARTICIPANT.type, pType: type, siteId: +siteId, id } ) );
  }

  deleteParticipants ( type: number, siteId: number )
  {
    this.store.dispatch ( DELETE_PARTICIPANTS ( { op: DELETE_PARTICIPANTS.type, pType: type, siteId: +siteId } ) );
  }

  updateForm ( participant: Participant, cpv: CollectionPointValue, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
  {
    this.store.dispatch ( UPDATE_PARTICIPANT_FORM ( { op: UPDATE_PARTICIPANT_FORM.type, participant, cpv, form, fileUploads } ) );
  }

  createForm ( participant: Participant, cp: CollectionPoint, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
  {
    this.store.dispatch ( CREATE_PARTICIPANT_FORM ( { op: CREATE_PARTICIPANT_FORM.type, participant, cp, form, fileUploads } ) );
  }

  uploadFile ( participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue, file: File )
  {
    this.store.dispatch ( UPLOAD_PARTICIPANT_FILE ( { op: UPLOAD_PARTICIPANT_FILE.type, participant, cpv, fv, field, fieldValue, file } ) );
  }

  downloadFile ( participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue )
  {
    this.store.dispatch ( DOWNLOAD_PARTICIPANT_FILE ( { op: DOWNLOAD_PARTICIPANT_FILE.type, participant, cpv, fv, field, fieldValue } ) );
  }

  downloadDocument ( participant: Participant, doc: DocumentMeta )
  {
    this.store.dispatch ( DOWNLOAD_PARTICIPANT_DOCUMENT ( { op: DOWNLOAD_PARTICIPANT_DOCUMENT.type, participant, doc } ) );
  }
}
