import { createAction, props } from '@ngrx/store';
import { ParticipantMeta } from '../../../model/published/participants/ParticipantMeta';
import { ErrorDetails } from '../../../errors/ErrorDetails';


export const ON_PUBLISHED_PARTICIPANTS_LIST   = createAction ( 'shared.published-participants.list', props<{ force: boolean }> ( ) );
export const ON_PUBLISHED_PARTICIPANTS_SINGLE = createAction ( 'shared.published-participants.single', props<{ id: number, force: boolean }> ( ) );


export const GET_PUBLISHED_PARTICIPANTS         = createAction ( 'shared.published-participants.get', props<{ op: string, ids: number[] | null }> ( ) );
export const GET_PUBLISHED_PARTICIPANTS_SUCCESS = createAction ( 'shared.published-participants.get.success', props<{ op: string, participants: ParticipantMeta [ ] }> ( ) );
export const GET_PUBLISHED_PARTICIPANTS_FAILURE = createAction ( 'shared.published-participants.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );

