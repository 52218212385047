import { Group } from "./Group";

export class GroupHierarchy
{
    public static toJson( group: GroupHierarchy ): any
    {
        let out: any = {};

        out.system = group.System ? Group.toJson ( group.System ) : null;
        out.organisation = group.Organisation ? Group.toJson ( group.Organisation ) : null;
        out.sites = group.Sites ? Group.toJson ( group.Sites ) : null;
        out.participants = group.Participants ? Group.toJson ( group.Participants ) : null;
        
        return out;
    }

    public static fromJson( json: any ): GroupHierarchy
    {
        let out = new GroupHierarchy ( );

        out.System = json.system ? Group.fromJson ( json.system ) : null;
        out.Organisation = json.organisation ? Group.fromJson ( json.organisation ) : null;
        out.Sites = json.sites ? Group.fromJson ( json.sites ) : null;
        out.Participants = json.participants ? Group.fromJson ( json.participants ) : null;

        return out;
    }

    public constructor( private system: Group | null = null,
                        private organisation: Group | null = null,
                        private sites: Group | null = null,
                        private participants: Group | null = null )
    {
        // Null.
    }

    public get System( ): Group | null
    {
        return this.system;
    }

    public set System( system: Group | null )
    {
        this.system = system;
    }

    public get Organisation( ): Group | null
    {
        return this.organisation;
    }

    public set Organisation( organisation: Group | null )
    {
        this.organisation = organisation;
    }

    public get Sites( ): Group | null
    {
        return this.sites;
    }

    public set Sites( sites: Group | null )
    {
        this.sites = sites;
    }

    public get Participants( ): Group | null
    {
        return this.participants;
    }

    public set Participants( participants: Group | null )
    {
        this.participants = participants;
    }

    public getGroupById ( id: string ) : Group | null
    {
        if ( this.System?.Id == id )
        {
            return this.System;
        }
        else if ( this.Organisation?.Id == id )
        {
            return this.Organisation;
        }
        else if ( this.Sites?.Id == id )
        {
            return this.Sites;
        }
        else if ( this.Participants?.Id == id )
        {
            return this.Participants;
        }
        else
        {
            return null;
        }
    }

    public getGroupByName ( name: string ) : Group | null
    {
        if ( this.System?.Name == name )
        {
            return this.System;
        }
        else if ( this.Organisation?.Name == name )
        {
            return this.Organisation;
        }
        else if ( this.Sites?.Name == name )
        {
            return this.Sites;
        }
        else if ( this.Participants?.Name == name )
        {
            return this.Participants;
        }
        else
        {
            return null;
        }
    }
}
