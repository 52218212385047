import { QuestionMeta } from './QuestionMeta';
import { QuestionType } from './QuestionType';


export class NumberRangeQuestionMeta extends QuestionMeta
{
    public static toJson( question: NumberRangeQuestionMeta ): any
    {
        let out: any = {};

        out = QuestionMeta._toJson ( question, out );

        out.default = question.DefaultValue;
        out.minimum = question.MinimumValue;
        out.maximum = question.MaximumValue;
        out.units = question.Units;

        return out;
    }

    public static fromJson( json: any ): NumberRangeQuestionMeta
    {
        let out: NumberRangeQuestionMeta = new NumberRangeQuestionMeta ( );

        out = QuestionMeta._fromJson ( json, out );

        out.DefaultValue = json.default as number;
        out.MinimumValue = json.minimum as number;
        out.MaximumValue = json.maximum as number;
        out.Units = json.units as string;

        return out;
    }

    public constructor( id: number | null  = null, seq: number | null  = null, timestamp: Date | null  = null,
                        text: string | null  = null, required: boolean | null = null,
                        private defaultValue: number | null  = null, private minimumValue: number | null  = null, 
                        private maximumValue: number | null  = null, private units: string | null  = null )
    {
       super ( id, seq, QuestionType.NUMBER_RANGE, timestamp, text, required );
    }

    public get DefaultValue( ): number | null
    {
        return this.defaultValue;
    }

    public set DefaultValue( defaultValue: number | null )
    {
        this.defaultValue = defaultValue;
    }

    public get MinimumValue( ): number | null
    {
        return this.minimumValue;
    }

    public set MinimumValue( minimumValue: number | null )
    {
        this.minimumValue = minimumValue;
    }

    public get MaximumValue( ): number | null
    {
        return this.maximumValue;
    }

    public set MaximumValue( maximumValue: number | null )
    {
        this.maximumValue = maximumValue;
    }

    public get Units( ): string | null
    {
        return this.units;
    }

    public set Units( units: string | null )
    {
        this.units = units;
    }
}
