import * as moment from 'moment';
import { BooleanField } from '../published/BooleanField';
import { Field } from '../published/Field';
import { FieldType } from '../published/FieldType';
import { FieldValue } from './FieldValue';


export class BooleanFieldValue extends FieldValue
{
    public static clone( field: BooleanFieldValue ): BooleanFieldValue
    {
        return new BooleanFieldValue ( field.Id,
                                       field.Version, 
                                       field.MetaId,
                                       field.Timestamp,
                                       field.Value );
    }

    public static toJson( field: BooleanFieldValue ): any
    {
        let out: any = {};

        out = FieldValue._toJson ( field, out );

        out.value = field.Value;
        
        return out;
    }

    public static fromJson( json: any ): BooleanFieldValue
    {
        let out = new BooleanFieldValue ( );

        out = FieldValue._fromJson ( json, out );

        out.Value = json.value as number;

        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null,
                        timestamp : moment.Moment | null  = null,
                        private value: number | null = null )
    {
        super ( id, version, metaId, FieldType.BOOLEAN, timestamp );
    }

    public getValues ( ) : Map<string, any> | null
    {
        return new Map<string, any> ( [ [ "", this.value ] ] );
    }

    public get ValueAsString ( ) : string | null
    {
        return this.Value ? this.Value.toString ( ) : null;
    }

    public updateValue ( meta: Field, value: any ) : void
    {
        // Initially assume failure
        this.Value = null;

        if ( typeof value == "number" )
        {
            this.Value = +value;
        }
        else if ( typeof value == "string" && meta instanceof BooleanField )
        {
            const booleanMeta = meta as BooleanField;
            if ( value == booleanMeta.YesLabel )
            {
                this.Value = booleanMeta.YesNumeric;
            } 
            else if ( value == booleanMeta.NoLabel )
            {
                this.Value = booleanMeta.NoNumeric;
            }
        }
    }

    public get Value( ): number | null
    {
        return this.value;
    }

    public set Value( value: number | null )
    {
        this.value = value;
    }
}