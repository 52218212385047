import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[libSectionToolbar]',
})
export class SectionToolbarDirective
{
    constructor( public viewContainerRef: ViewContainerRef )
    {
        // Null.
    }
}
