import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../errors/ErrorDetails';
import { FormMeta } from '../../model/meta/FormMeta';


export const ON_FORM_LIBRARY_LIST       = createAction ( 'shared.form-library.list', props<{ force: boolean }> ( ) );
export const ON_FORM_LIBRARY_SINGLE     = createAction ( 'shared.form-library.single', props<{ id: number, force: boolean }> ( ) );


// export const CLEAR_METRICS         = createAction ( 'operate.metrics.metrics.clear' );


export const GET_FORM_LIBRARY         = createAction ( 'shared.form-library.get', props<{ op: string, ids: number[] | null }> ( ) );
export const GET_FORM_LIBRARY_SUCCESS = createAction ( 'shared.form-library.get.success', props<{ op: string, forms: FormMeta [ ] }> ( ) );
export const GET_FORM_LIBRARY_FAILURE = createAction ( 'shared.form-library.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const CREATE_FORM_LIBRARY_FORM         = createAction ( 'shared.form-library.create', props<{ op: string, form: FormMeta }> ( ) );
export const CREATE_FORM_LIBRARY_FORM_SUCCESS = createAction ( 'shared.form-library.create.success', props<{ op: string, form: FormMeta }> ( ) );
export const CREATE_FORM_LIBRARY_FORM_FAILURE = createAction ( 'shared.form-library.create.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_FORM_LIBRARY_FORM         = createAction ( 'shared.form-library.update', props<{ op: string, form: FormMeta }> ( ) );
export const UPDATE_FORM_LIBRARY_FORM_SUCCESS = createAction ( 'shared.form-library.update.success', props<{ op: string, form: FormMeta }> ( ) );
export const UPDATE_FORM_LIBRARY_FORM_FAILURE = createAction ( 'shared.form-library.update.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const DELETE_FORM_LIBRARY_FORM         = createAction ( 'shared.form-library.delete', props<{ op: string, id: number }> ( ) );
export const DELETE_FORM_LIBRARY_FORM_SUCCESS = createAction ( 'shared.form-library.delete.success', props<{ op: string, id: number }> ( ) );
export const DELETE_FORM_LIBRARY_FORM_FAILURE = createAction ( 'shared.form-library.delete.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );