import { FormMeta } from '../../model/meta/FormMeta';

export const FORM_LIBRARY_FEATURE_STATE_NAME = 'form-library';

export class FormLibraryState
{
    forms = new Array<FormMeta> ( );
    formsLoading = false;
    formSaving = false;
    formDeleting = false;
}
