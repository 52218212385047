import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ListFieldValue, ListFieldValueSubValue } from '../../model/study/ListFieldValue';
import { ListField } from '../../model/published/ListField';
import { FieldComponent } from './field.component';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';
import { FieldValueFactory } from '../../model/study/FieldValueFactory';
import { FieldWrapper } from '../../model/published/FieldWrapper';
import { FieldType } from '../../model/published/FieldType';
import { FieldValue } from '../../model/study/FieldValue';

@Component({
  selector: 'lib-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => ListFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => ListFieldComponent ),
      multi: true
    }
  ]
})
export class ListFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: ListField | null = null;

  @Input()
  public siteId: number | null = null;
  
  value: ListFieldValue | null = null;

  subMeta!: FieldWrapper;

  disabled = false;

  fixedSize = false;

  constructor ( fb: FormBuilder, eFactory: ExpressionEngineFactory, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  get Fields ( )
  { 
    return this.form.get ( 'fields' ) as FormArray;
  }

  get FIELD_TYPE ( )
  {
    return FieldType;
  }

  get DoesntHaveRequiredNumber ( ) : boolean
  {
    if ( this.meta && this.meta.MinimumCount != null )
    {
      return this.Fields.controls.length < this.meta.MinimumCount;
    }

    return false;
  }

  ngOnInit ( )
  {
    let fields = this.fb.array ( [] );
    
    if ( this.meta == null )
    {
      this.form.controls [ "fields" ].disable ( );
    }
    else if ( this.meta.SubField )
    {
      this.fixedSize = this.meta.MinimumCount != null && this.meta.MaximumCount != null && this.meta.MinimumCount == this.meta.MaximumCount;
      this.subMeta = new FieldWrapper ( this.meta.SubField );
    }

    this.form = this.fb.group ( {
      fields: fields,
      timestamp: null,
    } );
  }

  writeValue ( val: any )
  {
    this.value = ListFieldValue.clone ( val as ListFieldValue, FieldValueFactory.clone );

    let rawValues = new Array<FieldValue | null> ( );
    
    if ( this.value )
    {
      for ( const field of this.value.SubFieldValues ? this.value.SubFieldValues : [] )
      {
        rawValues.push ( field.SubFieldValue );
        this.Fields.push ( new FormControl ( field.SubFieldValue ) );
      }

      if ( this.meta && this.subMeta.Field && this.stateVector )
      {
        // Ensure there is the minimum required
        if ( this.meta.MinimumCount && this.Fields.length < this.meta.MinimumCount )
        {
          const diff = this.meta.MinimumCount - this.Fields.length;
          for ( let i = 0; i < diff; i++ )
          {
            const fieldValue = new FieldValueFactory ( this.expressionEngine ).fromMeta ( this.subMeta.Field, this.stateVector );

            if ( this.value.SubFieldValues == null )
            {
              this.value.SubFieldValues = new Array<ListFieldValueSubValue> ( );
            }

            this.value.SubFieldValues.push ( new ListFieldValueSubValue ( null, fieldValue ) );
            rawValues.push ( fieldValue );
            this.Fields.push ( new FormControl ( fieldValue ) );
          }
        }
      }
      
      this.form.patchValue ( { "fields": rawValues, "timestamp": this.value.Timestamp }, { emitEvent: false } );
    }
  }

  registerOnChange ( fn: any )
  {
    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          const updatedValues = new Array<ListFieldValueSubValue> ( );
          for ( let i = 0; this.value.SubFieldValues && i <  ( this.value.SubFieldValues ? this.value.SubFieldValues : [] ).length; i++ )
          {
            const clone = ListFieldValueSubValue.clone ( this.value.SubFieldValues [ i ], FieldValueFactory.clone );
            clone.SubFieldValue = change['fields'][i];
            updatedValues.push ( clone );
          }
          this.value.SubFieldValues = updatedValues;
          this.value.Timestamp = change['timestamp'];
          return this.value
        }
        else
        {
          return change;
        }
      } ),
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    if ( this.disabled != disable )
    {
      this.disabled = disable;
    }
  }

  onAdd ( )
  {
    if ( this.value && this.subMeta.Field && this.stateVector )
    {
      const fieldValue = new FieldValueFactory ( this.expressionEngine ).fromMeta ( this.subMeta.Field, this.stateVector );

      if ( this.value.SubFieldValues == null )
      {
        this.value.SubFieldValues = new Array<ListFieldValueSubValue> ( );
      }

      this.value.SubFieldValues.push ( new ListFieldValueSubValue ( null, fieldValue ) );
      
      this.Fields.push ( new FormControl ( fieldValue ) );
    }
  }

  onRemove ( index : number )
  {
    if ( this.value?.SubFieldValues )
    {
      this.value.SubFieldValues.splice ( index, 1 );
    }
    this.Fields.removeAt ( index );
  }

}
