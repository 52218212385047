
export class LandingPage
{
    public static toJson ( landingPage: LandingPage ): any
    {
        let out: any = {};

        out.id = landingPage.Id;
        out.version = landingPage.Version;
        out.main_title = landingPage.MainTitle;
        out.main_description = landingPage.MainDescription;
        out.main_link_label = landingPage.MainLinkLabel;
        out.main_link = landingPage.MainLink;
        out.left_title = landingPage.LeftTitle;
        out.left_description = landingPage.LeftDescription;
        out.left_link_label = landingPage.LeftLinkLabel;
        out.left_link = landingPage.LeftLink;
        out.middle_title = landingPage.MiddleTitle;
        out.middle_description = landingPage.MiddleDescription;
        out.middle_link_label = landingPage.MiddleLinkLabel;
        out.middle_link = landingPage.MiddleLink;
        out.right_title = landingPage.RightTitle;
        out.right_description = landingPage.RightDescription;
        out.right_link_label = landingPage.RightLinkLabel;
        out.right_link = landingPage.RightLink;

        return out;
    }

    public static fromJson( json: any ): LandingPage
    {
        let out = new LandingPage ( );

        out.Id = json.id;
        out.Version = json.version;
        out.MainTitle = json.main_title;
        out.MainDescription = json.main_description;
        out.MainLinkLabel = json.main_link_label;
        out.MainLink = json.main_link;
        out.LeftTitle = json.left_title;
        out.LeftDescription = json.left_description;
        out.LeftLinkLabel = json.left_link_label;
        out.LeftLink = json.left_link;
        out.MiddleTitle = json.middle_title;
        out.MiddleDescription = json.middle_description;
        out.MiddleLinkLabel = json.middle_link_label;
        out.MiddleLink = json.middle_link;
        out.RightTitle = json.right_title;
        out.RightDescription = json.right_description;
        out.RightLinkLabel = json.right_link_label;
        out.RightLink = json.right_link;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private mainTitle: string | null  = null, 
                        private mainDescription: string | null  = null, 
                        private mainLinkLabel: string | null = null,
                        private mainLink: string | null  = null,
                        private leftTitle: string | null  = null, 
                        private leftDescription: string | null  = null, 
                        private leftLinkLabel: string | null = null,
                        private leftLink: string | null  = null,
                        private middleTitle: string | null  = null, 
                        private middleDescription: string | null  = null, 
                        private middleLinkLabel: string | null = null,
                        private middleLink: string | null  = null,
                        private rightTitle: string | null  = null, 
                        private rightDescription: string | null  = null, 
                        private rightLinkLabel: string | null = null,
                        private rightLink: string | null  = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get MainTitle( ): string | null
    {
        return this.mainTitle;
    }

    public set MainTitle( mainTitle: string | null )
    {
        this.mainTitle = mainTitle;
    }

    public get MainDescription( ): string | null
    {
        return this.mainDescription;
    }

    public set MainDescription( mainDescription: string | null )
    {
        this.mainDescription = mainDescription;
    }

    public get MainLinkLabel( ): string | null
    {
        return this.mainLinkLabel;
    }

    public set MainLinkLabel( mainLinkLabel: string | null )
    {
        this.mainLinkLabel = mainLinkLabel;
    }
 
    public get MainLink( ): string | null
    {
        return this.mainLink;
    }

    public set MainLink( mainLink: string | null )
    {
        this.mainLink = mainLink;
    }

    public get LeftTitle( ): string | null
    {
        return this.leftTitle;
    }

    public set LeftTitle( leftTitle: string | null )
    {
        this.leftTitle = leftTitle;
    }

    public get LeftDescription( ): string | null
    {
        return this.leftDescription;
    }

    public set LeftDescription( leftDescription: string | null )
    {
        this.leftDescription = leftDescription;
    }

    public get LeftLinkLabel( ): string | null
    {
        return this.leftLinkLabel;
    }

    public set LeftLinkLabel( leftLinkLabel: string | null )
    {
        this.leftLinkLabel = leftLinkLabel;
    }
 
    public get LeftLink( ): string | null
    {
        return this.leftLink;
    }

    public set LeftLink( leftLink: string | null )
    {
        this.leftLink = leftLink;
    }

    public get MiddleTitle( ): string | null
    {
        return this.middleTitle;
    }

    public set MiddleTitle( middleTitle: string | null )
    {
        this.middleTitle = middleTitle;
    }

    public get MiddleDescription( ): string | null
    {
        return this.middleDescription;
    }

    public set MiddleDescription( middleDescription: string | null )
    {
        this.middleDescription = middleDescription;
    }

    public get MiddleLinkLabel( ): string | null
    {
        return this.middleLinkLabel;
    }

    public set MiddleLinkLabel( middleLinkLabel: string | null )
    {
        this.middleLinkLabel = middleLinkLabel;
    }
 
    public get MiddleLink( ): string | null
    {
        return this.middleLink;
    }

    public set MiddleLink( middleLink: string | null )
    {
        this.middleLink = middleLink;
    }

    public get RightTitle( ): string | null
    {
        return this.rightTitle;
    }

    public set RightTitle( rightTitle: string | null )
    {
        this.rightTitle = rightTitle;
    }

    public get RightDescription( ): string | null
    {
        return this.rightDescription;
    }

    public set RightDescription( rightDescription: string | null )
    {
        this.rightDescription = rightDescription;
    }

    public get RightLinkLabel( ): string | null
    {
        return this.rightLinkLabel;
    }

    public set RightLinkLabel( rightLinkLabel: string | null )
    {
        this.rightLinkLabel = rightLinkLabel;
    }
 
    public get RightLink( ): string | null
    {
        return this.rightLink;
    }

    public set RightLink( rightLink: string | null )
    {
        this.rightLink = rightLink;
    }
}
