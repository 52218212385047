import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../errors/ErrorDetails';
import * as JSZip from 'jszip';
import { EXPORT_DATA_FORMAT } from './export.data.format';



export const EXPORT_SINGLE_ZIP         = createAction ( 'shared.export.single-zip', props<{ contextualNames: boolean, dataFormat: EXPORT_DATA_FORMAT }> ( ));
export const EXPORT_SINGLE_ZIP_SUCCESS = createAction ( 'shared.export.single-zip.success', props<{ results: JSZip }> ( ) );
export const EXPORT_SINGLE_ZIP_FAILURE = createAction ( 'shared.export.single-zip.failure', props<{ errorDetails: ErrorDetails }> ( ) );

export const EXPORT_SINGLE_ZIP_PER_SITE         = createAction ( 'shared.export.single-zip-per-site', props<{ contextualNames: boolean, dataFormat: EXPORT_DATA_FORMAT, perParticipant: boolean }> ( ));
export const EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS = createAction ( 'shared.export.single-zip-per-site.success', props<{ results: Blob }> ( ) );
export const EXPORT_SINGLE_ZIP_PER_SITE_FAILURE = createAction ( 'shared.export.single-zip-per-site.failure', props<{ errorDetails: ErrorDetails }> ( ) );

export const EXPORT_ZIP_DOWNLOAD = createAction ( 'shared.export.zip.download', props<{ results: Blob }> ( ) );

