
export enum ChangeOperation
{
    CREATE,

    UPDATE,
}

export abstract class ChangeOperationUtils
{
    public static fromString( value: string ): ChangeOperation
    {
        return Object.entries(ChangeOperation).find(([key, val]) => key === value)?.[1] as ChangeOperation;
    }

    public static toString ( value: ChangeOperation ): string
    {
        return Object.entries(ChangeOperation).find(([key, val]) => val === value)?.[0] as string;
    }
}
