import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { SELECT_STUDY_TASKS_INCLUDES_COMPLETED, SELECT_STUDY_TASKS_TASKS } from './study-tasks.selectors';
import { GET_TASKS, GET_TASKS_FAILURE, GET_TASKS_SUCCESS, ON_TASKS_LIST } from './study-tasks.actions';
import { Task } from '../../../model/study/Task';
import { SubjectType } from '../../../model/published/SubjectType';
import { GET_SITES } from '../sites/study-sites.actions';
import { GET_PARTICIPANTS, ON_PARTICIPANTS_LIST } from '../participants/study-participants.actions';

@Injectable ( )
export class StudyTasksEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_TASKS_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_TASKS_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_STUDY_TASKS_TASKS ) ),
        concatLatestFrom ( () => this.store.select ( SELECT_STUDY_TASKS_INCLUDES_COMPLETED ) ),
        mergeMap ( ([[action, tasks], completed]) => {
            if ( action.force || completed != action.completed || tasks == null || tasks.size == 0 )
            {
                return [ GET_TASKS ( { op: 'list', completed: action.completed, dependencies: action.dependencies } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_TASKS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_TASKS ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                params = params.append ( 'completed', String ( action.completed ) );
                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.study_api}/tasks/`, { params } ) ),
            map ( response => {
                const tasks = new Array<Task> ( );
                for ( const json of response )
                {
                    const task = Task.fromJson ( json );
                    if ( task )
                    {
                        tasks.push ( task );
                    }
                }
                return tasks;
            } ),
            map ( tasks => GET_TASKS_SUCCESS ( { op: action.op, tasks } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_TASKS_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );

    // GET_TASKS_SUCCESS$ = createEffect ( ( ) => this.actions.pipe (
    //     ofType ( GET_TASKS_SUCCESS ),
    //     map ( result => {
    //         const sites = new Array<number> ( );
    //         const participants = new Map<number, Map<number, Array<number>>> ( ); // Site ID the Participant Type, then Participant ID
    //         for ( const task of result.tasks ? result.tasks : [] )
    //         {
    //             if ( task.SubjectType == SubjectType.SITE && task.SubjectValueId != null )
    //             {
    //                 sites.push ( task.SubjectValueId );   
    //             }
    //             else if ( task.SubjectType == SubjectType.PARTICIPANT && task.ParticipantType != null && task.SiteId != null && task.SubjectValueId != null )
    //             {
    //                 if ( participants.has ( task.SiteId ) == false )
    //                 {
    //                     participants.set ( task.SiteId, new Map<number, Array<number>> ( ) );
    //                 }

    //                 const types = participants.get ( task.SiteId );
    //                 if ( types )
    //                 {
    //                     if ( types.has ( task.ParticipantType ) == false )
    //                     {
    //                         types.set ( task.ParticipantType, new Array<number> ( ) );
    //                     }

    //                     types.get ( task.ParticipantType )?.push ( task.SubjectValueId );
    //                 }
    //             }
    //         }

    //         return [ sites, participants ] as const;
    //     } ),
    //     switchMap ( ( [ sites, participants ] ) => {
    //         const actions = new Array<Action> ( );

    //         if ( sites.length > 0 )
    //         {
    //             actions.push ( GET_SITES ( { op: GET_SITES.type, ids: sites } ) );
    //         }

    //         for ( const siteId of participants.keys ( ) )
    //         {
    //             const sites = participants.get ( siteId );
    //             for ( const [pType, ids] of sites ? sites : [] )
    //             {
    //                 // Fix this once that the id filter is sorted
    //                 // actions.push ( ON_PARTICIPANTS_LIST ( { op: 'list', siteId, pType, ids, force: false } ) );
    //                 actions.push ( ON_PARTICIPANTS_LIST ( { op: 'list', siteId, pType, ids: [], force: false } ) );
    //             }
    //         }

    //         return actions;
    //     } )
    // ) );
}
