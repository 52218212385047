import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Task } from '../../model/study/Task';
import { ON_TASKS_LIST } from '../../state/study/tasks/study-tasks.actions';
import { SELECT_STUDY_TASKS_INCLUDES_COMPLETED, SELECT_STUDY_TASKS_LOADING, SELECT_STUDY_TASKS_TASKS } from '../../state/study/tasks/study-tasks.selectors';


@Injectable ( {
  providedIn: 'root'
} )
export class StudyTasksService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( completed: boolean, dependencies: boolean, force: boolean = false )
  {
    this.store.dispatch ( ON_TASKS_LIST ( { completed, dependencies, force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_TASKS_LOADING ) 
    );
  }

  getIncludesCompleted( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_TASKS_INCLUDES_COMPLETED ) 
    );
  }

  getTasks( ): Observable<Map<number, Task>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_TASKS_TASKS )
    );
  }
}
