import { createReducer, on } from '@ngrx/store';
import { ErrorsState } from './errors.state';
import { ON_ERROR } from './errors.actions';

export const ERRORS_FEATURE_STATE_NAME = 'errors';

export const INITIAL_STATE = new ErrorsState ( );

const _ERRORS_REDUCER = createReducer ( INITIAL_STATE,

    on ( ON_ERROR, ( state, { error } ) => {
      const newState = { ...state };

      newState.lastError = error;

      console.warn ( `Error: ${error.Type} ==> ${JSON.stringify(error.Message)}` );

      return newState;
    } )
);

export function ERRORS_REDUCER( state: any, action: any )
{
  return _ERRORS_REDUCER ( state, action );
}
