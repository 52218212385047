import { createReducer, on } from '@ngrx/store';
import { PublishedParticipantsState } from './published-participants.state';
import { GET_PUBLISHED_PARTICIPANTS, GET_PUBLISHED_PARTICIPANTS_FAILURE, GET_PUBLISHED_PARTICIPANTS_SUCCESS } from './published-participants.actions';
import { ParticipantMeta } from '../../../model/published/participants/ParticipantMeta';

export const INITIAL_STATE = new PublishedParticipantsState ( );

const _PUBLISHED_PARTICIPANTS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_PUBLISHED_PARTICIPANTS, ( state, { op, ids } ) => {
      const newState = { ...state };

      newState.participantsLoading = true;
      newState.participants = new Map<number, ParticipantMeta> ( );

      return newState;
    } ),
    on ( GET_PUBLISHED_PARTICIPANTS_SUCCESS, ( state, { op, participants } ) => {
      const newState = { ...state };

      newState.participants = new Map<number, ParticipantMeta> ( );
      for ( let participant of participants ? participants : [] )
      {
        if ( participant.Id )
        {
          newState.participants.set ( participant.Id, participant );
        }
      }
      newState.participantsLoading = false;

      return newState;
    } ),
    on ( GET_PUBLISHED_PARTICIPANTS_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.participants = new Map<number, ParticipantMeta> ( );
      newState.participantsLoading = false;

      return newState;
    } ),

);

export function PUBLISHED_PARTICIPANTS_REDUCER( state: any, action: any )
{
  return _PUBLISHED_PARTICIPANTS_REDUCER ( state, action );
}
