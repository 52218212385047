import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { CurrentUserUtilsService, ErrorDetails, ErrorType } from 'shared';

interface LooseObject {
  [key: string]: any
}

@Injectable()
export class HandyErrorHandler implements ErrorHandler
{
  constructor ( private injector: Injector, private zone: NgZone )
  {
    // Null.
  }

  handleError ( error: any ): void
  {
    if ( error instanceof ErrorDetails )
    {
      if ( error.Type == ErrorType.DOWNLOAD_FAILED )
      {
        const router = this.injector.get ( Router );
        router.navigate ( [ '/', 'error' ] ) 
        return ;
      }
    }
    // Log it locally
    console.error ( error );

    const currentUser = this.injector.get ( CurrentUserUtilsService );
    let user: LooseObject = {};
    if ( currentUser.getEmail ( ) != null )
    {      
      user.email = currentUser.getEmail ( );
    }
    if ( currentUser.getName ( ) != null )
    {      
      user.name = currentUser.getName ( );
    }

    const dialogOptions: Sentry.ReportDialogOptions = { user: user };

    const sentry = Sentry.createErrorHandler ( { showDialog: true, dialogOptions: { user } } );
    sentry.handleError ( error );

    // Redirect to error page, this might not be ideal in a dev build, lets see?
    this.zone.run ( ( ) => {
        const router = this.injector.get ( Router );
        if ( error.statusCode == 403 || error.statusCode == 401 )
        {
          router.navigate ( [ '/', 'unauthorized' ] ) 
        }
        else
        {
          router.navigate ( [ '/', 'error' ] )
        }
    } );
  }
}
