import { Argument } from "./Argument";
import { ExpressionDataType, ExpressionDataTypeUtils } from "./ExpressionDataType";

export class Expression
{
    public static clone( input: Expression ): Expression
    {
        const args = new Array<Argument> ( );
        for ( const arg of input.Args ? input.Args : [] )
        {
            args.push ( Argument.clone ( arg ) );
        }
        const output = new Expression ( input.Id,
                                        input.Version,
                                        input.Name,
                                        input.Body,
                                        input.ReturnType,
                                        args );

        return output;
    }
    public static toJson( expression: Expression ): any
    {
        let out: any = {};

        out.id = expression.Id;
        out.version = expression.Version;
        out.name = expression.Name;
        out.body = expression.Body;
        out.return_type = ExpressionDataTypeUtils.toString ( expression.ReturnType );
        out.args = new Array<any> ( );
        for ( const arg of expression.Args ? expression.Args : [] )
        {
            out.args.push ( Argument.toJson ( arg ) );
        }
        
        return out;
    }

    public static fromJson( json: any ): Expression
    {
        const args = new Array<Argument> ( );
        for ( const arg of "args" in json && json.args ? json.args : [] )
        {
            args.push ( Argument.fromJson ( arg ) );
        }

        return new Expression ( 
            json.id,
            json.version,
            json.name,
            json.body,
            ExpressionDataTypeUtils.fromString ( json.return_type ),
            args
        );
    }

    public constructor( private id: number | null = null,
                        private version: number | null = null,
                        private name: string,
                        private body: string,
                        private returnType: ExpressionDataType,
                        private args: Array<Argument> )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Name( ): string
    {
        return this.name;
    }

    public set Name( name: string )
    {
        this.name = name;
    }

    public get Body( ): string
    {
        return this.body;
    }

    public set Body( body: string )
    {
        this.body = body;
    }

    public get ReturnType( ): ExpressionDataType
    {
        return this.returnType;
    }

    public set ReturnType( returnType: ExpressionDataType )
    {
        this.returnType = returnType;
    }

    public get Args( ): Array<Argument>
    {
        return this.args;
    }

    public set Args( args: Array<Argument> )
    {
        this.args = args;
    }
}