import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import * as moment from "moment";
import { ChangeRecord } from '../../../model/study/ChangeRecord';

export const ON_CHANGE_RECORDS_LIST = createAction ( 'shared.study-change-records.list', props<{ start: moment.Moment, end: moment.Moment, force: boolean }> ( ) );


export const GET_CHANGE_RECORDS         = createAction ( 'shared.study-change-records.get', props<{ op: string, start: moment.Moment, end: moment.Moment }> ( ) );
export const GET_CHANGE_RECORDS_SUCCESS = createAction ( 'shared.study-change-records.get.success', props<{ op: string, records: ChangeRecord [ ] }> ( ) );
export const GET_CHANGE_RECORDS_FAILURE = createAction ( 'shared.study-change-records.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );
