import { Expression } from './expressions/Expression';
import { FieldType, FieldTypeUtils } from './FieldType';

export abstract class Field
{
    protected static _clone<T extends Field>( field: T, output: T ): T
    {
        output.Id = field.Id;
        output.Version = field.Version;
        output.Seq = field.Seq;
        output.Variable = field.Variable;
        output.Name = field.Name;
        output.Description = field.Description;
        output.Required = field.Required;
        output.HiddenExpression = field.HiddenExpression ? Expression.clone ( field.HiddenExpression ) : null;
        output.DisabledExpression = field.DisabledExpression ? Expression.clone ( field.DisabledExpression ) : null;
        output.ValidityExpression = field.ValidityExpression ? Expression.clone ( field.ValidityExpression ) : null;
        output.DerivationExpression = field.DerivationExpression ? Expression.clone ( field.DerivationExpression ) : null;

        return output;
    }

    protected static _toJson<T extends Field>( field: T, output: any ): any
    {
        output.id = field.Id;
        output.version = field.Version;
        output.seq = field.Seq;
        output.type = FieldTypeUtils.toString ( field.Type );
        output.variable = field.Variable;
        output.name = field.Name;
        output.description = field.Description;
        output.required = field.Required;
        output.hidden_expression = field.HiddenExpression ? Expression.toJson ( field.HiddenExpression ) : null;
        output.disabled_expression = field.DisabledExpression ? Expression.toJson ( field.DisabledExpression ) : null;
        output.validity_expression = field.ValidityExpression ? Expression.toJson ( field.ValidityExpression ) : null;
        output.derivation_expression = field.DerivationExpression ? Expression.toJson ( field.DerivationExpression ) : null;

        return output;
    }

    protected static _fromJson<T extends Field>( json: any, output: T ): T
    {
        output.Id = json.id;
        output.Version = json.version;
        output.Seq = json.seq;
        output.Variable = json.variable;
        output.Name = json.name;
        output.Description = json.description;
        output.Required = json.required;
        output.HiddenExpression = "hidden_expression" in json && json.hidden_expression ? Expression.fromJson ( json.hidden_expression ) : null;
        output.DisabledExpression = "disabled_expression" in json && json.disabled_expression ? Expression.fromJson ( json.disabled_expression ) : null;
        output.ValidityExpression = "validity_expression" in json && json.validity_expression  ? Expression.fromJson ( json.validity_expression ) : null;
        output.DerivationExpression = "derivation_expression" in json && json.derivation_expression  ? Expression.fromJson ( json.derivation_expression ) : null;

        return output;
    }

    protected constructor( private id: number | null = null,
                           private version: number | null = null,
                           private seq: number | null = null,
                           private type: FieldType = FieldType.NONE,
                           private variable: string | null = null,
                           private name: string | null = null,
                           private description: string | null = null,
                           private required: boolean | null = false,
                           private hiddenExpression: Expression | null = null,
                           private disabledExpression: Expression | null = null,
                           private validityExpression: Expression | null = null,
                           private derivationExpression: Expression | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Type( ): FieldType
    {
        return this.type;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get Required( ): boolean | null
    {
        return this.required;
    }

    public set Required( required: boolean | null )
    {
        this.required = required;
    }

    public get HiddenExpression( ): Expression | null
    {
        return this.hiddenExpression;
    }

    public set HiddenExpression( expression: Expression | null )
    {
        this.hiddenExpression = expression;
    }

    public get DisabledExpression( ): Expression | null
    {
        return this.disabledExpression;
    }

    public set DisabledExpression( expression: Expression | null )
    {
        this.disabledExpression = expression;
    }

    public get ValidityExpression( ): Expression | null
    {
        return this.validityExpression;
    }

    public set ValidityExpression( expression: Expression | null )
    {
        this.validityExpression = expression;
    }

    public get DerivationExpression( ): Expression | null
    {
        return this.derivationExpression;
    }

    public set DerivationExpression( expression: Expression | null )
    {
        this.derivationExpression = expression;
    }
}
