import { Site } from '../../../model/study/sites/Site';
import { User } from '../../../model/system/users/User';

export const SYSTEM_USERS_FEATURE_STATE_NAME = 'system-users';

export class SystemUsersState
{
    users = new Map<string, User> ( );
    loading = false;
    saving = false;
}
