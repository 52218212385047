import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudySitesState, STUDY_SITES_FEATURE_STATE_NAME } from './study-sites.state';

export const SELECT_STUDY_SITES = createFeatureSelector<StudySitesState> ( STUDY_SITES_FEATURE_STATE_NAME );

export const SELECT_STUDY_SITES_LOADING  = createSelector ( SELECT_STUDY_SITES, ( state: StudySitesState ) => state.sitesLoading );
export const SELECT_STUDY_SITES_SAVING   = createSelector ( SELECT_STUDY_SITES, ( state: StudySitesState ) => state.sitesSaving );

export const SELECT_STUDY_SITES_SITES = createSelector ( SELECT_STUDY_SITES, ( state: StudySitesState ) => state.sites );
export const SELECT_STUDY_SITES_SITE  = createSelector ( SELECT_STUDY_SITES, ( state: StudySitesState, props: { id: number } ) => state.sites.get ( props.id ) );
