import * as moment from 'moment';
import { Field } from '../published/Field';
import { FieldType } from '../published/FieldType';
import { ListField } from '../published/ListField';
import { FieldValue } from './FieldValue';


export class ListFieldValueSubValue
{
    public static clone( fieldValue: ListFieldValueSubValue, cloneFieldValue: (fieldValue: FieldValue) => FieldValue | null ): ListFieldValueSubValue
    {
        const clone = fieldValue.SubFieldValue ? cloneFieldValue ( fieldValue.SubFieldValue ) : null;
        
        return new ListFieldValueSubValue ( fieldValue.Id, clone );
    }

    public static toJson( fieldValue: ListFieldValueSubValue, fieldValueToJson: (fieldValue: FieldValue) => any ): any
    {
        let out: any = {};

        out.id = fieldValue.Id;
        out.sub_field_value = fieldValue.SubFieldValue ? fieldValueToJson ( fieldValue.SubFieldValue ) : null;      
        
        return out;
    }

    public static fromJson( json: any, fieldFromJson: (fieldValue: any) => FieldValue | null ): ListFieldValueSubValue
    {
        let out = new ListFieldValueSubValue ( );
        
        out.Id = json.id;
        out.SubFieldValue = json.sub_field_value ? fieldFromJson ( json.sub_field_value ) : null;

        return out;
    }

    public constructor( private id: number | null = null,
                        private subFieldValue: FieldValue | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get SubFieldValue( ): FieldValue | null
    {
        return this.subFieldValue;
    }

    public set SubFieldValue( subFieldValue: FieldValue | null )
    {
        this.subFieldValue = subFieldValue;
    }
}

export class ListFieldValue extends FieldValue
{
    public static clone( field: ListFieldValue, cloneFieldValue: (fieldValue: FieldValue) => FieldValue | null ): ListFieldValue
    {
        const fieldValues = new Array<ListFieldValueSubValue> ( );
        for ( const fieldValue of field.SubFieldValues ? field.SubFieldValues : [] )
        {
            const clone = ListFieldValueSubValue.clone ( fieldValue, cloneFieldValue );
            if ( clone )
            {
                fieldValues.push ( clone );
            }
        }
        return new ListFieldValue ( field.Id,
                                    field.Version, 
                                    field.MetaId,
                                    field.Timestamp,
                                    fieldValues );
    }

    public static toJson( field: ListFieldValue, fieldValueToJson: (fieldValue: FieldValue) => any ): any
    {
        let out: any = {};

        out = FieldValue._toJson ( field, out );

        out.sub_field_values = [];
        for ( const fieldValue of field.SubFieldValues ? field.SubFieldValues : [] )
        {
            out.sub_field_values.push ( ListFieldValueSubValue.toJson ( fieldValue, fieldValueToJson ) );
        }
        
        return out;
    }

    public static fromJson( json: any, fieldFromJson: (fieldValue: any) => FieldValue | null ): ListFieldValue
    {
        let out = new ListFieldValue ( );

        out = FieldValue._fromJson ( json, out );

        out.SubFieldValues = new Array<ListFieldValueSubValue> ( );
        for ( const fieldValue of json.sub_field_values )
        {
            const parsed = ListFieldValueSubValue.fromJson ( fieldValue, fieldFromJson );
            if ( parsed )
            {
                out.SubFieldValues.push ( parsed );
            }
        }

        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null,
                        timestamp : moment.Moment | null  = null,
                        private subFieldValues: Array<ListFieldValueSubValue> | null = null )
    {
        super ( id, version, metaId, FieldType.LIST, timestamp );
    }

    public getValues ( ) : Map<string, any> | null
    {
        const values = new Map<string, any> ( );
        let i = 1;
        for ( const subField of this.SubFieldValues ? this.SubFieldValues : [] )
        {
            if ( subField.SubFieldValue )
            {
                const subFieldValues = subField.SubFieldValue.getValues ( );
                for ( const [key, value] of subFieldValues ? subFieldValues.entries ( ) : [] )
                {
                    if ( key.length == 0 )
                    {
                        values.set ( `${i}`, value );
                    }
                    else
                    {
                        values.set ( `${i}.${key}`, value );
                    }
                }

                i++;
            }
        }
        return values;
    }

    public get ValueAsString ( ) : string | null
    {
        return this.SubFieldValues ? this.SubFieldValues.toString ( ) : null;
    }

    public updateValue ( meta: Field, value: any ) : void
    {
        // Initially assume failure
        this.SubFieldValues = null;

        if ( Array.isArray ( value ) && meta instanceof ListField )
        {
            this.SubFieldValues = value;
        }
    }

    public get SubFieldValues( ): Array<ListFieldValueSubValue> | null
    {
        return this.subFieldValues;
    }

    public set SubFieldValues( subFieldValues: Array<ListFieldValueSubValue> | null )
    {
        this.subFieldValues = subFieldValues;
    }
}