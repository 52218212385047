

export enum FormType
{
    ALL_IN_ONE = 1,

    WIZARD = 2
}

export abstract class FormTypeUtils
{
    public static fromString( value: string ): FormType
    {
        return Object.entries(FormType).find(([key, val]) => key === value)?.[1] as FormType;
    }
}
