import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { ErrorType } from '../../../errors/ErrorType';
import { CREATE_USER, CREATE_USER_FAILURE, CREATE_USER_SUCCESS, DISABLE_USER, DISABLE_USER_FAILURE, DISABLE_USER_SUCCESS, ENABLE_USER, ENABLE_USER_FAILURE, ENABLE_USER_SUCCESS, GET_USERS, GET_USERS_FAILURE, GET_USERS_SUCCESS, ON_USERS_LIST, ON_USERS_SINGLE, RESET_USER_PASSWORD, RESET_USER_PASSWORD_FAILURE, RESET_USER_PASSWORD_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from './system-users.actions';
import { SELECT_SYSTEM_USERS_USERS } from './system-users.selectors';
import { User } from '../../../model/system/users/User';
import { GET_GROUP_HIERARCHY } from '../groups/system-groups.actions';
import { GET_ROLE_HIERARCHY } from '../roles/system-roles.actions';

@Injectable ( )
export class SystemUsersEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_USERS_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_USERS_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_SYSTEM_USERS_USERS ) ),
        mergeMap ( ([action, users]) => {
            if ( action.force || users == null || users.size == 0 )
            {
                return [ GET_USERS ( { op: 'list', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_USERS_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_USERS_SINGLE ),
        concatLatestFrom ( () => this.store.select ( SELECT_SYSTEM_USERS_USERS ) ),
        mergeMap ( ([action, users]) => {
            if ( action.force || users == null || users.size == 0 )
            {
                return [ GET_USERS ( { op: 'single', ids: null } ),
                         GET_GROUP_HIERARCHY ( { op: 'list' } ),
                         GET_ROLE_HIERARCHY ( { op: 'list' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_USERS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_USERS ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.system_api}/users/`, { params } ) ),
            map ( response => {
                const users = new Array<User> ( );
                for ( const json of response )
                {
                    const user = User.fromJson ( json );
                    if ( user )
                    {
                        users.push ( user );
                    }
                }
                return users;
            } ),
            map ( users => GET_USERS_SUCCESS ( { op: action.op, users } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_USERS_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );

    CREATE_USER$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_USER ),
        map ( action => {
            return [ action.op, User.toJson ( action.user ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.post<any> ( `${config.system_api}/users/`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, User.fromJson ( response ) ] as const ),
        map ( ( [ op, user ] ) => CREATE_USER_SUCCESS ( { op, user } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( CREATE_USER_FAILURE ( { op: CREATE_USER.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );

    UPDATE_USER$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_USER ),
        tap ( action => {
            if ( action.user.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update user without a valid id." );
            }
        } ),
        map ( action => {
            return [ action.op, User.toJson ( action.user ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.put<any> ( `${config.system_api}/users/`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, User.fromJson ( response ) ] as const ),
        map ( ( [ op, user ] ) => UPDATE_USER_SUCCESS ( { op, user } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( UPDATE_USER_FAILURE ( { op: UPDATE_USER.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );

    DISABLE_USER$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DISABLE_USER ),
        tap ( action => {
            if ( action.user.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to disable user without a valid id." );
            }
        } ),
        map ( action => {
            let params = new HttpParams ( );
            params = params.append ( 'enable', 'false' );
            return [ action.op, User.toJson ( action.user ), params ] as const;
        } ),
        mergeMap ( ( [ op, body, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body, params ] as const ) ) ),
        mergeMap ( ( [ op, config, body, params ] ) => this.http.put<any> ( `${config.system_api}/users/`, body, { params } ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, User.fromJson ( response ) ] as const ),
        map ( ( [ op, user ] ) => DISABLE_USER_SUCCESS ( { op, user } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( DISABLE_USER_FAILURE ( { op: DISABLE_USER.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );


    ENABLE_USER$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ENABLE_USER ),
        tap ( action => {
            if ( action.user.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to enable user without a valid id." );
            }
        } ),
        map ( action => {
            let params = new HttpParams ( );
            params = params.append ( 'enable', 'true' );
            return [ action.op, User.toJson ( action.user ), params ] as const;
        } ),
        mergeMap ( ( [ op, body, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body, params ] as const ) ) ),
        mergeMap ( ( [ op, config, body, params ] ) => this.http.put<any> ( `${config.system_api}/users/`, body, { params } ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, User.fromJson ( response ) ] as const ),
        map ( ( [ op, user ] ) => ENABLE_USER_SUCCESS ( { op, user } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( ENABLE_USER_FAILURE ( { op: ENABLE_USER.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );


    RESET_USER_PASSWORD$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( RESET_USER_PASSWORD ),
        tap ( action => {
            if ( action.user.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to reset user password without a valid id." );
            }
        } ),
        map ( action => {
            let params = new HttpParams ( );
            params = params.append ( 'password-reset', 'true' );
            return [ action.op, User.toJson ( action.user ), params ] as const;
        } ),
        mergeMap ( ( [ op, body, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body, params ] as const ) ) ),
        mergeMap ( ( [ op, config, body, params ] ) => this.http.put<any> ( `${config.system_api}/users/`, body, { params } ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, User.fromJson ( response ) ] as const ),
        map ( ( [ op, user ] ) => RESET_USER_PASSWORD_SUCCESS ( { op, user } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( RESET_USER_PASSWORD_FAILURE ( { op: RESET_USER_PASSWORD.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );
}
