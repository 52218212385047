import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { User } from '../../model/system/users/User';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CREATE_USER, DISABLE_USER, ENABLE_USER, ON_USERS_LIST, ON_USERS_SINGLE, RESET_USER_PASSWORD, UPDATE_USER } from '../../state/system/users/system-users.actions';
import { SELECT_SYSTEM_USERS_LOADING, SELECT_SYSTEM_USERS_SAVING, SELECT_SYSTEM_USERS_USER, SELECT_SYSTEM_USERS_USERS } from '../../state/system/users/system-users.selectors';
import { Group } from '../../model/system/groups/Group';


type GroupComparator = ( group: Group ) => boolean;


@Injectable ( {
  providedIn: 'root'
} )
export class SystemUsersService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_USERS_LIST ( { force } ) );
  }

  onSingle( id: string, force: boolean = false )
  {
    this.store.dispatch ( ON_USERS_SINGLE ( { id, force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_USERS_LOADING ) 
    );
  }

  getSaving( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_USERS_SAVING ) 
    );
  }

  getAllUsers( ): Observable<Map<string, User>>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_USERS_USERS )
    );
  }

  protected getGroupMemberUsers( comparator: GroupComparator ): Observable<Map<string, User>>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_USERS_USERS ),
      map ( users => {
        let filtered = new Map<string, User> ( );
        for ( const [id, user] of users )
        {
          const count = (user.Groups ? user.Groups : []).reduce ( (accum, group) => {
            if ( comparator ( group ) )
            {
              return accum + 1;
            }
            else
            {
              return accum;
            }
          } , 0 );

          if ( count > 0 )
          {
            filtered.set ( id, user );
          }
        }
        return filtered;
      } )
    );
  }

  getSystemUsers( ): Observable<Map<string, User>>
  {
    return this.getGroupMemberUsers ( ( group ) => group.Path ? group.Path == Group.SYSTEM_GROUP_PATH : false );
  }

  getOrganisationUsers( ): Observable<Map<string, User>>
  {
    return this.getGroupMemberUsers ( ( group ) => group.Path ? group.Path == Group.ORGANISATION_GROUP_PATH : false );
  }

  getSiteUsers( ): Observable<Map<string, User>>
  {
    return this.getGroupMemberUsers ( ( group ) => group.Path ? group.Path.startsWith ( Group.SITES_GROUP_PATH ) : false );
  }

  getParticipantUsers( ): Observable<Map<string, User>>
  {
    return this.getGroupMemberUsers ( ( group ) => group.Path ? group.Path == Group.PARTICIPANTS_GROUP_PATH : false );
  }

  getUser( id: string ): Observable<User | null>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_USERS_USER, { id } ),
      map ( user => {
        if ( user )
        {
          return user;
        }
        else
        {
          return null;
        }
      } )
    );
  }

  createUser ( user: User )
  {
    this.store.dispatch ( CREATE_USER ( { op: CREATE_USER.type, user } ) );
  }

  updateUser ( user: User )
  {
    this.store.dispatch ( UPDATE_USER ( { op: UPDATE_USER.type, user } ) );
  }

  disableUser ( user: User )
  {
    this.store.dispatch ( DISABLE_USER ( { op: DISABLE_USER.type, user } ) );
  }

  enableUser ( user: User )
  {
    this.store.dispatch ( ENABLE_USER ( { op: ENABLE_USER.type, user } ) );
  }

  resetUserPassword ( user: User )
  {
    this.store.dispatch ( RESET_USER_PASSWORD ( { op: RESET_USER_PASSWORD.type, user } ) );
  }
}
