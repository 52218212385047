import { Expression } from '../../published/expressions/Expression';
import { CollectionPoint } from '../CollectionPoint';
import { Form } from '../Form';
import { Role } from '../Role';
import { Subject } from '../Subject';
import { SubjectType } from '../SubjectType';
import { Tag } from '../Tag';


export class SiteMeta extends Subject
{
    public static toJson ( site: SiteMeta ): any
    {
        let out: any = {};

        out = Subject._toJson ( site, out );

        out.id_digits = site.IdDigits;
        out.create_acl = new Array<any> ( );
        for ( const [, role] of site.CreateACL ? site.CreateACL : [] )
        {
            out.create_acl.push ( Role.toJson ( role ) );
        }
        out.address_field_name = site.AddressFieldName;
        out.contact_number_field_name = site.ContactNumberFieldName;
        out.main_poc_field_name = site.MainPocFieldName;
        out.email_field_name = site.EmailFieldName;

        return out;
    }

    public static fromJson( json: any ): SiteMeta
    {
        let out = new SiteMeta ( );

        out = Subject._fromJson ( json, out );

        out.IdDigits = json.id_digits;
        out.CreateACL = new Map<string, Role> ( );
        for ( const role of json.create_acl ? json.create_acl : [] )
        {
            const value = Role.fromJson ( role );
            if ( value.Name )
            {
                out.CreateACL.set ( value.Name, value );
            }
        }
        out.ActiveExpression = "active_expression" in json && json.active_expression ? Expression.fromJson ( json.active_expression ) : null;
        out.AddressFieldName = json.address_field_name;
        out.ContactNumberFieldName = json.contact_number_field_name;
        out.MainPocFieldName = json.main_poc_field_name;
        out.EmailFieldName = json.email_field_name;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null  = null, 
                        variable: string | null  = null, 
                        name: string | null  = null, 
                        description: string | null = null,
                        labelExpression: Expression | null = null,
                        tags: Array<Tag> | null = null,
                        registrationCollectionPoint: CollectionPoint | null  = null,
                        baselineCollectionPoint: CollectionPoint | null  = null,
                        intervalCollectionPoints: Array<CollectionPoint> | null  = null,
                        adhocCollectionPoints: Array<CollectionPoint> | null  = null,
                        completionCollectionPoint: CollectionPoint | null  = null,
                        eligibilityFormName: string | null = null,
                        eligibilityExpression: Expression | null = null,
                        private activeExpression: Expression | null = null,
                        private idDigits: number | null = null,
                        private createACL: Map<string, Role> | null = null,
                        private addressFieldName: string | null = null,
                        private contactNumberFieldName: string | null = null,
                        private mainPocFieldName: string | null = null,
                        private emailFieldName: string | null = null )
    {
       super ( id, version, SubjectType.SITE, variable, name, description, labelExpression, tags,
               registrationCollectionPoint, baselineCollectionPoint, intervalCollectionPoints, adhocCollectionPoints, completionCollectionPoint, eligibilityFormName, eligibilityExpression );
    }

    public get ActiveExpression( ): Expression | null
    {
        return this.activeExpression;
    }

    public set ActiveExpression( activeExpression: Expression | null )
    {
        this.activeExpression = activeExpression;
    }

    public get IdDigits( ): number | null
    {
        return this.idDigits;
    }

    public set IdDigits( idDigits: number | null )
    {
        this.idDigits = idDigits;
    }

    public get CreateACL( ): Map<string, Role> | null
    {
        return this.createACL;
    }

    public set CreateACL( createACL: Map<string, Role> | null )
    {
        this.createACL = createACL;
    }

    public get AddressFieldName( ): string | null
    {
        return this.addressFieldName;
    }

    public set AddressFieldName( fieldName: string | null )
    {
        this.addressFieldName = fieldName;
    }

    public get ContactNumberFieldName( ): string | null
    {
        return this.contactNumberFieldName;
    }

    public set ContactNumberFieldName( fieldName: string | null )
    {
        this.contactNumberFieldName = fieldName;
    }

    public get MainPocFieldName( ): string | null
    {
        return this.mainPocFieldName;
    }

    public set MainPocFieldName( fieldName: string | null )
    {
        this.mainPocFieldName = fieldName;
    }

    public get EmailFieldName( ): string | null
    {
        return this.emailFieldName;
    }

    public set EmailFieldName( fieldName: string | null )
    {
        this.emailFieldName = fieldName;
    }
}
