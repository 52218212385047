

export enum ContentNodeType
{
    NONE = 0,

    NODE = 1,

    GROUP = 2,

    FORM = 3

}

export abstract class ContentNodeTypeUtils
{
    public static fromString( value: string ): ContentNodeType
    {
        return Object.entries(ContentNodeType).find(([key, val]) => key === value)?.[1] as ContentNodeType;
    }
}
