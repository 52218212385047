import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SELECT_SYSTEM_GROUPS_HIERARCHY, SELECT_SYSTEM_GROUPS_LOADING } from '../../state/system/groups/system-groups.selectors';
import { GroupHierarchy } from '../../model/system/groups/GroupHierarchy';
import { ON_GROUP_HIERARCHY_LIST } from '../../state/system/groups/system-groups.actions';


@Injectable ( {
  providedIn: 'root'
} )
export class SystemGroupsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  on( force: boolean = false )
  {
    this.store.dispatch ( ON_GROUP_HIERARCHY_LIST ( { force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_GROUPS_LOADING ) 
    );
  }

  getHierarchy( ): Observable<GroupHierarchy | null>
  {
    return this.store.pipe ( 
      select ( SELECT_SYSTEM_GROUPS_HIERARCHY )
    );
  }
}
