import { LandingPage } from '../../../model/published/pages/LandingPage';
import { SideBar } from '../../../model/published/pages/SideBar';
import { SitesPage } from '../../../model/published/pages/SitesPage';

export const PUBLISHED_PAGES_FEATURE_STATE_NAME = 'published-pages';

export class PublishedPagesState
{
    landingPage: LandingPage | null = null;
    landingPageLoading = false;

    sideBar: SideBar | null = null;
    sideBarLoading = false;

    sitesPage: SitesPage | null = null;
    sitesPageLoading = false;
}
