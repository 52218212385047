import { ContentNodeType, ContentNodeTypeUtils } from './ContentNodeType';

export abstract class ContentNodeMeta
{
    protected static _toJson<T extends ContentNodeMeta>( node: T, output: any ): any
    {
        output.id = node.Id;
        output.seq = node.Seq;
        output.type = node.Type ? ContentNodeType [ node.Type ] : ContentNodeType.NONE;
        output.timestamp = node.Timestamp ? node.Timestamp.toISOString ( ) : null;
        output.name = node.Name;
        output.description = node.Description;

        return output;
    }

    protected static _fromJson<T extends ContentNodeMeta>( json: any, output: T ): T
    {
        output.Id = json.id;
        output.Seq = json.seq;
        output.type = json.type ? ContentNodeTypeUtils.fromString ( json.type ) : ContentNodeType.NONE;
        output.Timestamp = new Date ( json.timestamp );
        output.Name = json.name;
        output.Description = json.description;

        return output;
    }

    protected constructor( private id: number | null = null, private seq: number | null = null,
                            private type: ContentNodeType = ContentNodeType.NONE,
                            private timestamp: Date | null = null, private name: string | null = null,
                            private description: string | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Type( ): ContentNodeType
    {
        return this.type;
    }

    public get Timestamp( ): Date | null
    {
        return this.timestamp;
    }

    public set Timestamp( timestamp: Date | null )
    {
        this.timestamp = timestamp;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }
}
