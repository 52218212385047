<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <div class="input-group">
        <input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenfileinput>
        <input type="text" class="form-control" id="value" placeholder="" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }" readonly>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="hiddenfileinput.click()" type="button" ngbTooltip="Upload file" placement="bottom" [disabled]="disabled"><fa-icon [icon]="['fas', 'file-upload']"></fa-icon></button>
        </div>
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="onDownload()" type="button" ngbTooltip="Download file" placement="bottom" [disabled]="disabled || !(form.value.value != null && form.value.file == null)"><fa-icon [icon]="['fas', 'file-download']"></fa-icon></button>
        </div>
        <div class="invalid-feedback">
            <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                Value is required
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                {{ValueFormControl?.errors?.['validityExpression']}}
            </ng-container>
        </div>
    </div>

</form>