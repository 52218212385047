import { FormValue } from "./FormValue";
import * as moment from "moment";
import { FormValueFactory } from "./FormValueFactory";
import { CollectionPoint } from "../published/CollectionPoint";
import { CollectionPointValue } from "./CollectionPointValue";
import { ExpressionEngine } from "../../expressions/ExpressionEngine";
import { CurrentUserUtilsService } from "../../services/current.user.utils.service";
import { SubjectUtils } from "./SubjectUtils";


export class CollectionPointValueFactory
{
    readonly formValueFactory: FormValueFactory;

    constructor ( private engine: ExpressionEngine, private currentUser: CurrentUserUtilsService, private userRoles: string[] )
    {
        this.formValueFactory = new FormValueFactory ( engine, currentUser, userRoles );
    }

    fromMeta ( meta: CollectionPoint, values: Map<string, boolean | number | string | moment.Moment | null> ) : CollectionPointValue
    {
        let cpValue = new CollectionPointValue ( );
        cpValue.MetaId = meta.Id;
        cpValue.FormValues = new Array<FormValue> ( )
        for ( const form of meta.Forms ? meta.Forms : [] )
        {
            let includeForm = true;
            if ( form.ExcludeExpression )
            {
                if ( this.engine?.eval ( form.ExcludeExpression, values ) )
                {
                    includeForm = false;
                }
                else
                {
                    includeForm = true;
                }
            }

            if ( includeForm )
            {
                const formValue = this.formValueFactory.fromMeta ( form, values );
                if ( formValue )
                {
                    cpValue.FormValues.push ( formValue );
                }
            }
        }

        return cpValue;
    }

    clone ( meta: CollectionPoint, existing: CollectionPointValue, values: Map<string, boolean | number | string | moment.Moment | null> ) : CollectionPointValue
    {
        let cpValue = new CollectionPointValue ( );
        cpValue.Id = existing.Id;
        cpValue.Version = existing.Version;
        cpValue.MetaId = existing.MetaId;
        cpValue.TargetTimestamp = cpValue.TargetTimestamp;
        cpValue.FormValues = new Array<FormValue> ( )
        for ( const existingFormValue of existing.FormValues ? existing.FormValues : [] )
        {
            if ( existingFormValue.MetaId != null )
            {
                const form = new SubjectUtils ( ).getForm ( meta, existingFormValue.MetaId );
                if ( form )
                {
                    const formValue = this.formValueFactory.clone ( form, existingFormValue, values );
                    if ( formValue )
                    {
                        cpValue.FormValues.push ( formValue );
                    }
                }
            }
        }

        return cpValue;
    }
} 