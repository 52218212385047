<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <!-- Enumerations -->
    <div class="form-group">  
        <ng-container *ngIf="(loading$ | async) === true; else loaded">
            <div class="spinner-border spinner-border-sm mx-2 text-secondary" role="status">
            </div>
            <span class="text-secondary">LOADING...</span>
        </ng-container>
        <ng-template #loaded>
            <ng-container *ngIf="participants$ | async as participants">
                <ng-container *ngIf="site$ | async as site">
                    <select class="custom-select" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }">
                        <option selected [ngValue]="null">None selected</option>
                        <option *ngFor="let participant of participants" [ngValue]="participant[0].Id">[{{getParticipantId(site[0], site[1], participant[0], participant[1])}}] - {{getParticipantLabel(participant[0], participant[1], site[0], site[1])}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                            Value is required
                        </ng-container>
                        <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                            {{ValueFormControl?.errors?.['validityExpression']}}
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>  
        </ng-template>
    </div>

</form>