import { DocumentTemplate } from '../../../model/study/documents/DocumentTemplate';

export const STUDY_DOCUMENTS_FEATURE_STATE_NAME = 'study-documents';


export class StudyDocumentsState
{ 
    templates = new Map<number, Map<number, DocumentTemplate>> ( );

    loading = false;

    saving = false;
}
