import { QuestionMeta } from './QuestionMeta';
import { QuestionType } from './QuestionType';


export class BooleanQuestionMeta extends QuestionMeta
{
    public static toJson( question: BooleanQuestionMeta ): any
    {
        let out: any = {};

        out = QuestionMeta._toJson ( question, out );

        out.yesLabel = question.YesLabel;
        out.yesNumeric = question.YesNumeric;
        out.yesGood = question.YesGood;
        out.noLabel = question.NoLabel;
        out.noNumeric = question.NoNumeric;
        out.noGood = question.NoGood;

        return out;
    }

    public static fromJson( json: any ): BooleanQuestionMeta
    {
        let out: BooleanQuestionMeta = new BooleanQuestionMeta ( );

        out = QuestionMeta._fromJson ( json, out );

        out.YesLabel = json.yesLabel as string;
        out.YesNumeric = json.yesNumeric as number;
        out.YesGood = json.yesGood as boolean;
        out.NoLabel = json.noLabel as string;
        out.NoNumeric = json.noNumeric as number;
        out.NoGood = json.noGood as boolean;

        return out;
    }

    public constructor( id: number | null  = null, seq: number | null  = null, timestamp: Date | null  = null,
                        text: string | null = null, required: boolean | null = null,
                        private yesLabel: string | null  = 'Yes', private yesNumeric: number | null = 1,
                        private yesGood: boolean | null  = true, private noLabel: string | null  = 'No',
                        private noNumeric: number | null  = 0, private noGood: boolean | null = false )
    {
       super ( id, seq, QuestionType.BOOLEAN, timestamp, text, required );
    }

    public get YesLabel( ): string | null
    {
        return this.yesLabel;
    }

    public set YesLabel( yesLabel: string | null )
    {
        this.yesLabel = yesLabel;
    }

    public get YesNumeric( ): number | null
    {
        return this.yesNumeric;
    }

    public set YesNumeric( yesNumeric: number | null )
    {
        this.yesNumeric = yesNumeric;
    }

    public get YesGood( ): boolean | null
    {
        return this.yesGood;
    }

    public set YesGood( yesGood: boolean | null )
    {
        this.yesGood = yesGood;
    }

    public get NoLabel( ): string | null
    {
        return this.noLabel;
    }

    public set NoLabel( noLabel: string | null )
    {
        this.noLabel = noLabel;
    }

    public get NoNumeric( ): number | null
    {
        return this.noNumeric;
    }

    public set NoNumeric( noNumeric: number | null )
    {
        this.noNumeric = noNumeric;
    }

    public get NoGood( ): boolean | null
    {
        return this.noGood;
    }

    public set NoGood( noGood: boolean | null )
    {
        this.noGood = noGood;
    }
}
