import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionPoint } from '../../model/published/CollectionPoint';
import { Form } from '../../model/published/Form';
import { SiteMeta } from '../../model/published/sites/SiteMeta';
import { ON_PUBLISHED_SITES_LIST, ON_PUBLISHED_SITES_SINGLE } from '../../state/published/sites/published-sites.actions';
import { SELECT_PUBLISHED_SITES_LOADING, SELECT_PUBLISHED_SITES_SITE, SELECT_PUBLISHED_SITES_SITES } from '../../state/published/sites/published-sites.selectors';

@Injectable ( {
  providedIn: 'root'
} )
export class PublishedSitesService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_PUBLISHED_SITES_LIST ( { force } ) );
  }

  onSingle( id: number | null, force: boolean = false )
  {
    if ( id )
    {
      this.store.dispatch ( ON_PUBLISHED_SITES_SINGLE ( { id, force } ) );
    }
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_SITES_LOADING ) 
    );
  }

  getSites( ): Observable<Map<number, SiteMeta>>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_SITES_SITES )
    );
  }

  getSite( id: number | null ): Observable<SiteMeta | null>
  {
    if ( id )
    {
      return this.store.pipe ( 
        select ( SELECT_PUBLISHED_SITES_SITE, { id } ),
        map ( site => {
          if ( site )
          {
            return site;
          }
          else
          {
            return null;
          }
        } )
      );
    }
    else
    {
      return new BehaviorSubject ( null );
    }
  }

  public getSiteForm ( id: number, formId: number ) : Observable<[Form, CollectionPoint] | null>
    {
        return this.getSite ( id ).pipe (
            map ( site => {
                if ( site  )
                {
                  for ( const cp of site.AllCollectionPoints )
                  {
                    for ( const form of cp.Forms ? cp.Forms : [] )
                    {
                        if ( form.Id == formId )
                        {
                            return [form, cp];
                        }
                    }
                  }
                }
              
                return null;
            } )
        );
    }
}
