
export class Role
{
    public static clone ( input: Role ): Role
    {
        return new Role ( input.Id, input.Name, input.Label );
    }

    public static toJson ( role: Role ): any
    {
        let out: any = {};

        out.id = role.Id;
        out.name = role.Name;
        out.label = role.Label;

        return out;
    }

    public static fromJson( json: any ): Role
    {
        let out = new Role ( );

        out.Id = json.id;
        out.Name = json.name;
        out.Label = json.label;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private name: string | null  = null, 
                        private label: string | null = null, )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Label( ): string | null
    {
        return this.label;
    }

    public set Label( label: string | null )
    {
        this.label = label;
    }
}
