import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { Task } from '../../../model/study/Task';

export const ON_TASKS_LIST       = createAction ( 'shared.study-tasks.list', props<{ completed: boolean, dependencies: boolean, force: boolean }> ( ) );


export const GET_TASKS         = createAction ( 'shared.study-tasks.get', props<{ op: string, completed: boolean, dependencies: boolean }> ( ) );
export const GET_TASKS_SUCCESS = createAction ( 'shared.study-tasks.get.success', props<{ op: string, tasks: Task [ ] }> ( ) );
export const GET_TASKS_FAILURE = createAction ( 'shared.study-tasks.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


// export const CREATE_SITE         = createAction ( 'shared.study-sites.create', props<{ op: string, site: Site }> ( ) );
// export const CREATE_SITE_SUCCESS = createAction ( 'shared.study-sites.create.success', props<{ op: string, site: Site }> ( ) );
// export const CREATE_SITE_FAILURE = createAction ( 'shared.study-sites.create.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


// export const UPDATE_SITE         = createAction ( 'shared.study-sites.update', props<{ op: string, site: Site }> ( ) );
// export const UPDATE_SITE_SUCCESS = createAction ( 'shared.study-sites.update.success', props<{ op: string, site: Site }> ( ) );
// export const UPDATE_SITE_FAILURE = createAction ( 'shared.study-sites.update.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


// export const DELETE_SITE         = createAction ( 'shared.study-sites.delete', props<{ op: string, id: number }> ( ) );
// export const DELETE_SITE_SUCCESS = createAction ( 'shared.study-sites.delete.success', props<{ op: string, id: number }> ( ) );
// export const DELETE_SITE_FAILURE = createAction ( 'shared.study-sites.delete.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );