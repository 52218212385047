import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EXPORT_SINGLE_ZIP, EXPORT_SINGLE_ZIP_PER_SITE } from '../../state/export/export.actions';
import { EXPORT_DATA_FORMAT } from '../../state/export/export.data.format';
import { SELECT_EXPORT_EXPORTING } from '../../state/export/export.selectors';

@Injectable ( {
  providedIn: 'root'
} )
export class ExportService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  getExporting ( ) : Observable<boolean>
  {
    return this.store.select ( SELECT_EXPORT_EXPORTING );
  }

  exportSingleZip( contextualNames: boolean, dataFormat: EXPORT_DATA_FORMAT, perSite: boolean, perParticipant: boolean )
  {
    if ( perSite )
    {
      this.store.dispatch ( EXPORT_SINGLE_ZIP_PER_SITE ( { contextualNames, dataFormat, perParticipant } ) );
    }
    else
    {
      this.store.dispatch ( EXPORT_SINGLE_ZIP ( { contextualNames, dataFormat } ) );
    }
  }
}
