import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'shared';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent
{
  supportEmailLink$: Observable<SafeUrl> | null = null;
  constructor ( config: ConfigService, private sanitiser: DomSanitizer )
  {
    this.supportEmailLink$ = config.getConfigLazy ( ).pipe ( 
      map ( config => this.sanitiser.bypassSecurityTrustUrl ( `mailto:${config.support_email}` ) )
    )
  }
}
