import { Expression } from "../expressions/Expression";
import { Role } from "../Role";
import { ReportColumn } from "./ReportColumn";

export class Report
{ 
    public static clone ( input: Report ) : Report
    {
        const output = new Report ( );
        output.Id = input.Id;
        output.Version = input.Version;
        output.Name = input.Name;
        output.Description = output.Description;
        output.ACL = new Map<string, Role> ( );
        for ( const [key, value] of input.ACL ? input.ACL.entries ( ) : [] )
        {
            output.ACL.set ( key, Role.clone ( value ) );
        }
        output.Columns = new Array<ReportColumn> ( );
        for ( const column of input.Columns ? input.Columns : [] )
        {
            output.Columns.push ( ReportColumn.clone ( column ) );
        }
        output.EligibleExpression = input.EligibleExpression ? Expression.toJson ( input.EligibleExpression ) : null;
        output.ExcludeExpression = input.ExcludeExpression ? Expression.toJson ( input.ExcludeExpression ) : null;
        output.DisabledExpression = input.DisabledExpression ? Expression.toJson ( input.DisabledExpression ) : null;                        
        return output;
    }

    public static toJson ( report: Report ): any
    {
        let out: any = {};

        out.id = report.Id;
        out.version = report.Version;
        out.name = report.Name;
        out.description = report.Description;
        out.acl = new Array<any> ( );
        for ( const [, role] of report.ACL ? report.ACL : [] )
        {
            out.acl.push ( Role.toJson ( role ) );
        }
        out.columns = [];
        for ( const column of report.Columns ? report.Columns : [] )
        {
            out.columns.push ( ReportColumn.toJson ( column ) );
        }
        out.eligible_expression = report.EligibleExpression ? Expression.toJson ( report.EligibleExpression ) : null;
        out.exclude_expression = report.ExcludeExpression ? Expression.toJson ( report.ExcludeExpression ) : null;
        out.disabled_expression = report.DisabledExpression ? Expression.toJson ( report.DisabledExpression ) : null;

        return out;
    }

    public static fromJson( json: any ): Report
    {
        let out = new Report ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Name = json.name;
        out.Description = json.description;
        out.ACL = new Map<string, Role> ( );
        for ( const role of json.acl ? json.acl : [] )
        {
            const value = Role.fromJson ( role );
            if ( value.Name )
            {
                out.ACL.set ( value.Name, value );
            }
        }
        out.Columns = new Array<ReportColumn> ( );
        for ( const column of json.columns ? json.columns : [] )
        {
            out.Columns.push ( ReportColumn.fromJson ( column ) );
        }
        out.EligibleExpression = "eligible_expression" in json && json.eligible_expression ? Expression.fromJson ( json.eligible_expression ) : null;
        out.ExcludeExpression = "exclude_expression" in json && json.exclude_expression ? Expression.fromJson ( json.exclude_expression ) : null;
        out.DisabledExpression = "disabled_expression" in json && json.disabled_expression ? Expression.fromJson ( json.disabled_expression ) : null;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private subjectId: number | null  = null, 
                        private name: string | null  = null, 
                        private description: string | null  = null, 
                        private acl: Map<string, Role> | null = null,
                        private columns: Array<ReportColumn> | null = null,
                        private eligibleExpression: Expression | null  = null,
                        private excludeExpression: Expression | null  = null,
                        private disabledExpression: Expression | null  = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    } 
    
    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    } 

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    } 

    public get ACL( ): Map<string, Role> | null
    {
        return this.acl;
    }

    public set ACL( acl: Map<string, Role> | null )
    {
        this.acl = acl;
    }

    public get Columns( ): Array<ReportColumn> | null
    {
        return this.columns;
    }

    public set Columns( columns: Array<ReportColumn> | null )
    {
        this.columns = columns;
    }

    public get EligibleExpression( ): Expression | null
    {
        return this.eligibleExpression;
    }

    public set EligibleExpression( expression: Expression | null )
    {
        this.eligibleExpression = expression;
    }

    public get ExcludeExpression( ): Expression | null
    {
        return this.excludeExpression;
    }

    public set ExcludeExpression( expression: Expression | null )
    {
        this.excludeExpression = expression;
    }

    public get DisabledExpression( ): Expression | null
    {
        return this.disabledExpression;
    }

    public set DisabledExpression( expression: Expression | null )
    {
        this.disabledExpression = expression;
    }
}