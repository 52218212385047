import { SectionValue } from "./SectionValue";
import { Section } from "../published/Section";
import { FieldValue } from "./FieldValue";
import { FieldValueFactory } from "./FieldValueFactory";
import * as moment from "moment";
import { ExpressionEngine } from "../../expressions/ExpressionEngine";
import { SubjectUtils } from "./SubjectUtils";


export class SectionValueFactory
{
    readonly fieldValueFactory: FieldValueFactory;

    constructor ( private engine: ExpressionEngine )
    {
        this.fieldValueFactory = new FieldValueFactory ( engine );
    }

    fromMeta ( meta: Section, vals: Map<string, boolean | number | string | moment.Moment | null> ) : SectionValue
    {
        let sectionValue = new SectionValue ( );
        sectionValue.MetaId = meta.Id;
        sectionValue.FieldValues = new Array<FieldValue> ( );
        // let index = 0;
        for ( const field of meta.Fields ? meta.Fields : [] )
        {
            const fieldValue = this.fieldValueFactory.fromMeta ( field, vals );
            if ( fieldValue )
            {
                // fieldValue.Id = ++index;
                fieldValue.Timestamp = moment ( );
                sectionValue.FieldValues.push ( fieldValue );
            }
        }

        return sectionValue;
    }

    clone ( meta: Section, existing: SectionValue, vals: Map<string, boolean | number | string | moment.Moment | null> ) : SectionValue
    {
        let sectionValue = new SectionValue ( );
        sectionValue.Id = existing.Id;
        sectionValue.Version = existing.Version;
        sectionValue.MetaId = existing.MetaId;
        sectionValue.FieldValues = new Array<FieldValue> ( );
        for ( const existingFieldValue of existing.FieldValues ? existing.FieldValues : [] )
        {
            if ( existingFieldValue.MetaId != null )
            {
                const field = new SubjectUtils ( ).getField ( meta, existingFieldValue.MetaId );
                if ( field )
                {
                    const fieldValue = this.fieldValueFactory.clone ( field, existingFieldValue, vals );
                    if ( fieldValue )
                    {
                        sectionValue.FieldValues.push ( fieldValue );
                    }
                }
            }
        }

        return sectionValue;
    }
} 