import { Field } from './Field';
import { FieldType } from './FieldType';


export class EnumerationValue
{
    public static clone ( input: EnumerationValue ) : EnumerationValue
    {
        return new EnumerationValue ( input.Id, input.Version, input.Seq, input.Name, input.Description, input.Value );
    }

    public static toJson( value: EnumerationValue ): any
    {
        let out: any = {};

        out.id = value.Id;
        out.version = value.Version;
        out.seq = value.Seq;
        out.name = value.Name;
        out.description = value.Description;
        out.required = value.Value;
        
        return out;
    }

    public static fromJson( json: any ): EnumerationValue
    {
        let out = new EnumerationValue ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Seq = json.seq;
        out.Name = json.name;
        out.Description = json.description;
        out.Value = json.value;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null = null,
                        private seq: number | null = null,
                        private name: string | null = null,
                        private description: string | null = null,
                        private value: number | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get Value( ): number | null
    {
        return this.value;
    }

    public set Value( value: number | null )
    {
        this.value = value;
    }
}



export class EnumeratedField extends Field
{
    public static clone( input: EnumeratedField ): EnumeratedField
    {
        const vals = new Array<EnumerationValue> ( );
        for ( const val of input.Enumerations ? input.Enumerations : [] )
        {
            vals.push ( EnumerationValue.clone ( val ) );
        }

        let output = Field._clone ( input, new EnumeratedField ( ) );
        output.Enumerations = vals;
        return output;
    }

    public static toJson( field: EnumeratedField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        if ( field.Enumerations && field.Enumerations.length > 0 )
        {
            out.enumerations = [];
            for ( let value of field.Enumerations )
            {
                out.enumerations.push ( EnumerationValue.toJson ( value ) );
            }
        }
        
        return out;
    }

    public static fromJson( json: any ): EnumeratedField
    {
        let out = new EnumeratedField ( );

        out = Field._fromJson ( json, out );

        if ( 'enumerations' in json && json [ 'enumerations' ] instanceof Array )
        {
            out.Enumerations = new Array<EnumerationValue> ( );
            for ( let value of json [ 'enumerations' ] )
            {
                out.Enumerations.push ( EnumerationValue.fromJson ( value ) );
            }
        }

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private enumerations: Array<EnumerationValue> | null = null )
    {
       super ( id, version, seq, FieldType.ENUMERATED, variable, name, description, required );
    }

    public get Enumerations( ): Array<EnumerationValue> | null
    {
        return this.enumerations;
    }

    public set Enumerations( enumerations: Array<EnumerationValue> | null )
    {
        this.enumerations = enumerations;
    }
}
