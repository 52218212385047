import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, merge, Observable, of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, toArray, concatMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../config/config.service';
import { ErrorDetails } from '../../errors/ErrorDetails';
import { ON_ERROR } from '../../errors/errors.actions';
import { EXPORT_SINGLE_ZIP, EXPORT_SINGLE_ZIP_FAILURE, EXPORT_SINGLE_ZIP_PER_SITE, EXPORT_SINGLE_ZIP_PER_SITE_FAILURE, EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS, EXPORT_SINGLE_ZIP_SUCCESS, EXPORT_ZIP_DOWNLOAD } from './export.actions';
import { Site } from '../../model/study/sites/Site';
import { ParticipantMeta } from '../../model/published/participants/ParticipantMeta';
import * as JSZip from 'jszip';
import { Participant } from '../../model/study/participants/Participant';
import { SiteMeta } from '../../model/published/sites/SiteMeta';
import { ParticipantUtils } from '../../model/study/participants/ParticipantUtils';
import { SiteUtils } from '../../model/study/sites/SiteUtils';
import { saveAs } from 'file-saver';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { parse } from 'json2csv';
import { EXPORT_DATA_FORMAT } from './export.data.format';
import { CollectionPoint } from '../../model/published/CollectionPoint';
import { CollectionPointValue } from '../../model/study/CollectionPointValue';
import { ParticipantField } from '../../model/published/ParticipantField';
import { ParticipantFieldValue } from '../../model/study/ParticipantFieldValue';


@Injectable ( )
export class ExportEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store, private eFactory: ExpressionEngineFactory )
    {
        // Null.
    }

    collectFormDataCollectionPoint ( siteMeta: SiteMeta, site: Site, cp: CollectionPoint | null, cpvs: CollectionPointValue[], subjectIdLabel: string, subjectId: string, contextualNames: boolean, referenceParticipants: Map<number, [ParticipantMeta, Array<Participant>]> ) : Map<string, Array<any>>
    {
        const formTypes = new Map<string, Array<any>> ( );

        if ( cpvs.length > 0 && cp && cp.Variable && cp.Forms && cp.Forms.length > 0 )
        {
            for ( const cpv of cpvs )
            {
                for ( const form of cpv.FormValues ? cpv.FormValues : [] )
                {
                    const formType = cp.Forms.find ( formType => formType.Id == form.MetaId );
                    if ( formType && formType.Variable != null )
                    {
                        let formJson: {[index: string]:any} = {};
                        formJson [ subjectIdLabel ] = subjectId;

                        if ( cpv.TargetTimestamp )
                        {
                            formJson [ "target_timestamp" ] = cpv.TargetTimestamp;
                        }
                        
                        for ( const section of form.SectionValues ? form.SectionValues : [] )
                        {
                            const sectionType = formType.Sections?.find ( sectionType => sectionType.Id == section.MetaId );
                            if ( sectionType && sectionType.Variable )
                            {
                                for ( const field of section.FieldValues ? section.FieldValues : [] )
                                {
                                    const fieldType = sectionType.Fields?.find ( fieldType => fieldType.Id == field.MetaId );
                                    if ( fieldType && fieldType.Variable )
                                    {
                                        let fieldName = "";
                                        if ( contextualNames )
                                        {
                                            fieldName = `${sectionType.Variable}.${fieldType.Variable}`;
                                        }
                                        else
                                        {
                                            fieldName = `${fieldType.Variable}`;
                                        }

                                        // Default field value
                                        formJson [ fieldName ] = field.ValueAsString;

                                        // If this is a participant field, i.e. a reference to another participant of some sort, then swap the value for 
                                        // an appropriately formatted id for that referenced participant...
                                        if ( field instanceof ParticipantFieldValue && fieldType instanceof ParticipantField )
                                        {
                                            if ( fieldType.ParticipantType )
                                            {
                                                const refPartCache = referenceParticipants.get ( fieldType.ParticipantType );
                                                if ( refPartCache )
                                                {
                                                    for ( const refPart of refPartCache[1] ? refPartCache[1] : [] )
                                                    {
                                                        if ( refPart.Id != null && refPart.Id == field.Value )
                                                        {
                                                            const siteId = new SiteUtils ( ).getSiteFormattedId ( siteMeta, site );
                                                            const refPartId = new ParticipantUtils ( ).getParticipantFormattedId ( refPartCache[0], refPart);
                                                            formJson [ fieldName ] = `${siteId}-${refPartId}`
                                                        }
                                                    }
                                                }
                                            }
                                            
                                        }
                                    }
                                }
                            }
                        }

                        if ( formTypes.has ( formType.Variable ) == false )
                        {
                            formTypes.set ( formType.Variable, new Array<any> ( ) );
                        }
                        formTypes.get ( formType.Variable )?.push ( formJson );
                    }
                }
            }
        }

        return formTypes;
    }

    collectionPointHelper ( siteMeta: SiteMeta, site: Site, cp: CollectionPoint | null, cpvs: Array<CollectionPointValue>, subjectIdLabel: string, subjectId: string, contextualNames: boolean, referenceParticipants: Map<number, [ParticipantMeta, Array<Participant>]>, cpFormData: Map<string, Map<string, Array<any>>> )
    {
        if ( cp && cp.Variable )
        {
            const newValues = this.collectFormDataCollectionPoint ( siteMeta, site, cp, cpvs, subjectIdLabel, subjectId, contextualNames, referenceParticipants );
            if ( newValues.size  > 0 )
            {
                if ( cpFormData.has ( cp.Variable ) == false )
                {
                    cpFormData.set ( cp.Variable, new Map<string, Array<any>> ( ) );
                }
                
                const temp = cpFormData.get ( cp.Variable );
                const oldValues = temp ? temp : new Map<string, Array<any>> ( );

                for ( const [newKey, newValue] of newValues )
                {
                    if ( oldValues.has ( newKey ) )
                    {
                        oldValues.get ( newKey )?.push ( ...newValue );
                    }
                    else
                    {
                        oldValues.set ( newKey, newValue );
                    }
                }

                cpFormData.set ( cp.Variable, oldValues );
            }
        }
    }

    exportCollectionPoint ( cp: CollectionPoint | null, zipParent: JSZip, cpvs: CollectionPointValue[], contextualNames: boolean, dataFormat: EXPORT_DATA_FORMAT )
    {
        if ( cpvs.length == 0 )
        {
            return;
        }

        if ( cp && cp.Variable && cp.Forms && cp.Forms.length > 0 )
        {
            const zipCP = zipParent.folder ( cp.Variable );
            if ( zipCP )
            {
                const formTypes = new Map<string, Array<any>> ( );

                for ( const cpv of cpvs )
                {
                    for ( const form of cpv.FormValues ? cpv.FormValues : [] )
                    {
                        const formType = cp.Forms.find ( formType => formType.Id == form.MetaId );
                        if ( formType && formType.Variable != null )
                        {
                            let formJson: {[index: string]:any} = {};

                            if ( cpv.TargetTimestamp )
                            {
                                formJson [ "target_timestamp" ] = cpv.TargetTimestamp;
                            }
                            
                            for ( const section of form.SectionValues ? form.SectionValues : [] )
                            {
                                const sectionType = formType.Sections?.find ( sectionType => sectionType.Id == section.MetaId );
                                if ( sectionType && sectionType.Variable )
                                {
                                    for ( const field of section.FieldValues ? section.FieldValues : [] )
                                    {
                                        const fieldType = sectionType.Fields?.find ( fieldType => fieldType.Id == field.MetaId );
                                        if ( fieldType && fieldType.Variable )
                                        {
                                            let fieldName = "";
                                            if ( contextualNames )
                                            {
                                                fieldName = `${sectionType.Variable}.${fieldType.Variable}`;
                                            }
                                            else
                                            {
                                                fieldName = `${fieldType.Variable}`;
                                            }
                                            

                                            formJson [ fieldName ] = field.ValueAsString;
                                        }
                                    }
                                }
                            }

                            if ( formTypes.has ( formType.Variable ) == false )
                            {
                                formTypes.set ( formType.Variable, new Array<any> ( ) );
                            }
                            formTypes.get ( formType.Variable )?.push ( formJson );
                        }
                    }
                }

                for ( const [formType, values] of formTypes.entries ( ) )
                {
                    if ( dataFormat == EXPORT_DATA_FORMAT.JSON )
                    {
                        zipCP.file ( `${formType}.json`, JSON.stringify ( values, null, 2 ) );
                    }
                    else if ( dataFormat == EXPORT_DATA_FORMAT.CSV )
                    {
                        zipCP.file ( `${formType}.csv`, parse ( values ) );
                    }
                }
            }
        }
    }

    EXPORT_SINGLE_ZIP$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( EXPORT_SINGLE_ZIP ),
        switchMap ( () => this.config.getConfigLazy ( ) ),
        switchMap ( config => this.http.get ( `${config.study_api}/participants/export/`, {observe: 'response', responseType: 'blob'} ) ),
        map ( results => {
            if ( results.body == null )
            {
                throw new Error ( "No data returned from server" );
            }
            else
            {
                return EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS ( { results: results.body } )
            }
        } ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( EXPORT_SINGLE_ZIP_PER_SITE_FAILURE ( { errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } )
    ) );

    EXPORT_SINGLE_ZIP_PER_SITE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( EXPORT_SINGLE_ZIP_PER_SITE ),
        switchMap ( action => {
            return of ( action ).pipe (
                switchMap ( () => this.config.getConfigLazy ( ) ),
                // Get Published Site Definitions
                switchMap ( config => this.http.get<any[]> ( `${config.published_api}/sites/` ).pipe (
                    map ( response => {
                        const sites = new Array<SiteMeta> ( );
                        for ( const json of response )
                        {
                            const site = SiteMeta.fromJson ( json );
                            if ( site )
                            {
                                sites.push ( site );
                            }
                        }
                        return [ config, sites ] as const;
                    } ) 
                ) ),
                // Get Study Site Instances
                switchMap ( ([config, siteTypes]) => this.http.get<any[]> ( `${config.study_api}/sites/` ).pipe ( 
                    map ( response => {
                        const sites = new Array<[SiteMeta, Site]> ( );
                        for ( const json of response )
                        {
                            const site = Site.fromJson ( json );
                            if ( site )
                            {
                                const matchingType = siteTypes.find ( type => type.Id == site.MetaId );
                                if ( matchingType )
                                {
                                    sites.push ( [ matchingType, site ] );
                                }
                            }
                        }
                        return [ config, sites ] as const;
                    } ) 
                ) ),
                // Get Published Participant Definitions
                switchMap ( ( [config, sites] ) => this.http.get<any[]> ( `${config.published_api}/participants/` ).pipe (
                    map ( response => {
                        let participants = new Array<ParticipantMeta> ( );
                        for ( const json of response )
                        {
                            const participant = ParticipantMeta.fromJson ( json );
                            if ( participant )
                            {
                                participants.push ( participant );
                            }
                        }
                        return [ config, sites, participants ] as const;
                    } )
                ) ),
                // Get Study Participant Definitions
                mergeMap ( ([config, sites, participants]) => {

                    const reqs = new Array<Observable<[SiteMeta, Site, ParticipantMeta, Array<Participant>]>> ( );
                    
                    for ( const [siteType, site] of sites )
                    {
                        for ( const participantType of participants )
                        {
                            let params = new HttpParams ( ).append ( "site", String ( site.Id ) ).append ( "type", String ( participantType.Id ) );
                            reqs.push ( this.http.get<any> ( `${config.study_api}/participants/`, { params } ).pipe ( 
                                map ( response => {
                                    const participants = new Array<Participant> ( );
                                    for ( const json of response )
                                    {
                                        const participant = Participant.fromJson ( json );
                                        if ( participant )
                                        {
                                            participants.push ( participant );
                                        }
                                    }
                                    
                                    return [ siteType, site, participantType, participants ];
                                } ) 
                            ) );
                        }
                    }

                    return merge ( ...reqs ); // Limit the number of concurrent requests
                } ),
                toArray ( ),
                map ( results => {
                    
                    let zipRoot = new JSZip ( );
                    for ( const [siteType, site, participantType, parts] of results )
                    {
                        const siteUtils = new SiteUtils ( );
                        const siteId = siteUtils.getSiteFormattedId ( siteType, site );
                        if ( siteId )
                        {
                            const zipSite = zipRoot.folder ( siteUtils.getSiteLabel ( this.eFactory.create ( ), siteType, site ) );
                            if ( zipSite && siteType.Name )
                            {
                                // Used for dereferencing participant fields
                                const referenceParticipants = new Map<number, [ParticipantMeta, Array<Participant>]> ( );
                                for ( const [innerSiteType, innerSite, innerParticipantType, innerParts] of results )
                                {
                                    if ( site.Id == innerSite.Id && innerParticipantType.Id )
                                    {
                                        referenceParticipants.set ( innerParticipantType.Id, [innerParticipantType, innerParts] );
                                    }
                                }
                                
                                const zipSiteData = zipSite.folder ( siteType.Name );
                                if ( zipSiteData )
                                {
                                    const cpFormData = new Map<string, Map<string, Array<any>>>( );

                                    this.collectionPointHelper ( siteType, site, siteType.RegistrationCollectionPoint, site.RegistrationCollectionPointValue ? [site.RegistrationCollectionPointValue] : [], "site_id", siteId, action.contextualNames, referenceParticipants, cpFormData );
                                    this.collectionPointHelper ( siteType, site, siteType.BaselineCollectionPoint, site.BaselineCollectionPointValue ? [site.BaselineCollectionPointValue] : [], "site_id", siteId, action.contextualNames, referenceParticipants, cpFormData );
                                    for ( const cp of siteType.IntervalCollectionPoints ? siteType.IntervalCollectionPoints : [] )
                                    {
                                        const cpvs = site.IntervalCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                        this.collectionPointHelper ( siteType, site, cp, cpvs ? cpvs : [], "site_id", siteId, action.contextualNames, referenceParticipants, cpFormData );
                                    }
                                    for ( const cp of siteType.AdHocCollectionPoints ? siteType.AdHocCollectionPoints : [] )
                                    {
                                        const cpvs = site.AdHocCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                        this.collectionPointHelper ( siteType, site, cp, cpvs ? cpvs : [], "site_id", siteId, action.contextualNames, referenceParticipants, cpFormData );
                                    }
                                    this.collectionPointHelper ( siteType, site, participantType.CompletionCollectionPoint, site.CompletionCollectionPointValue ? [site.CompletionCollectionPointValue] : [], "site_id", siteId, action.contextualNames, referenceParticipants, cpFormData );

                                    for ( const [cp, formTypes] of cpFormData.entries ( ) )
                                    {
                                        const zipCP = zipSiteData.folder ( cp );
                                        if ( zipCP )
                                        {
                                            for ( const [formType, values] of formTypes.entries ( ) )
                                            {
                                                if ( action.dataFormat == EXPORT_DATA_FORMAT.JSON )
                                                {
                                                    zipCP.file ( `${formType}.json`, JSON.stringify ( values, null, 2 ) );
                                                }
                                                else if ( action.dataFormat == EXPORT_DATA_FORMAT.CSV )
                                                {
                                                    zipCP.file ( `${formType}.csv`, parse ( values ) );
                                                }
                                            }
                                        }
                                    }
                                }

                                const cpFormData = new Map<string, Map<string, Array<any>>>( );
                                if ( participantType.Name )
                                {
                                    const zipPartType = zipSite.folder ( participantType.Name );
                                    if ( zipPartType )
                                    {
                                        for ( const participant of parts )
                                        {
                                            const participantId = new ParticipantUtils ( ).getParticipantFormattedId ( participantType, participant );
                                            if ( participantId )
                                            {
                                                if ( action.perParticipant )
                                                {
                                                    const zipPart = zipPartType.folder ( `${siteId}-${participantId}` );
                                                    if ( zipPart )
                                                    {
                                                        this.exportCollectionPoint ( participantType.RegistrationCollectionPoint, zipPart, participant.RegistrationCollectionPointValue ? [participant.RegistrationCollectionPointValue] : [], action.contextualNames, action.dataFormat );
                                                        this.exportCollectionPoint ( participantType.BaselineCollectionPoint, zipPart, participant.BaselineCollectionPointValue ? [participant.BaselineCollectionPointValue] : [], action.contextualNames, action.dataFormat );
                                                        for ( const cp of participantType.IntervalCollectionPoints ? participantType.IntervalCollectionPoints : [] )
                                                        {
                                                            const cpvs = participant.IntervalCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                                            this.exportCollectionPoint ( cp, zipPart, cpvs ? cpvs : [], action.contextualNames, action.dataFormat );        
                                                        }
                                                        for ( const cp of participantType.AdHocCollectionPoints ? participantType.AdHocCollectionPoints : [] )
                                                        {
                                                            const cpvs = participant.AdHocCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                                            this.exportCollectionPoint ( cp, zipPart, cpvs ? cpvs : [], action.contextualNames, action.dataFormat );        
                                                        }
                                                        this.exportCollectionPoint ( participantType.CompletionCollectionPoint, zipPart, participant.CompletionCollectionPointValue ? [participant.CompletionCollectionPointValue] : [], action.contextualNames, action.dataFormat );
                                                    }
                                                }
                                                else
                                                {
                                                    this.collectionPointHelper ( siteType, site, participantType.RegistrationCollectionPoint, participant.RegistrationCollectionPointValue ? [participant.RegistrationCollectionPointValue] : [], "participant_id", `${siteId}-${participantId}`, action.contextualNames, referenceParticipants, cpFormData );
                                                    this.collectionPointHelper ( siteType, site, participantType.BaselineCollectionPoint, participant.BaselineCollectionPointValue ? [participant.BaselineCollectionPointValue] : [], "participant_id", `${siteId}-${participantId}`, action.contextualNames, referenceParticipants, cpFormData );
                                                    for ( const cp of participantType.IntervalCollectionPoints ? participantType.IntervalCollectionPoints : [] )
                                                    {
                                                        const cpvs = participant.IntervalCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                                        this.collectionPointHelper ( siteType, site, cp, cpvs ? cpvs : [], "participant_id", `${siteId}-${participantId}`, action.contextualNames, referenceParticipants, cpFormData );
                                                    }
                                                    for ( const cp of participantType.AdHocCollectionPoints ? participantType.AdHocCollectionPoints : [] )
                                                    {
                                                        const cpvs = participant.AdHocCollectionPointValues?.filter ( cpv => cpv.MetaId == cp.Id );
                                                        this.collectionPointHelper ( siteType, site, cp, cpvs ? cpvs : [], "participant_id", `${siteId}-${participantId}`, action.contextualNames, referenceParticipants, cpFormData );
                                                    }
                                                    this.collectionPointHelper ( siteType, site, participantType.CompletionCollectionPoint, participant.CompletionCollectionPointValue ? [participant.CompletionCollectionPointValue] : [], "participant_id", `${siteId}-${participantId}`, action.contextualNames, referenceParticipants, cpFormData );
                                                }
                                            }
                                        }

                                        if ( action.perParticipant == false )
                                        {
                                            for ( const [cp, formTypes] of cpFormData.entries ( ) )
                                            {
                                                const zipCP = zipPartType.folder ( cp );
                                                if ( zipCP )
                                                {
                                                    for ( const [formType, values] of formTypes.entries ( ) )
                                                    {
                                                        if ( action.dataFormat == EXPORT_DATA_FORMAT.JSON )
                                                        {
                                                            zipCP.file ( `${formType}.json`, JSON.stringify ( values, null, 2 ) );
                                                        }
                                                        else if ( action.dataFormat == EXPORT_DATA_FORMAT.CSV )
                                                        {
                                                            zipCP.file ( `${formType}.csv`, parse ( values ) );
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return zipRoot;
                } )
            );
        } ),
        mergeMap ( results => {
            // Generate and save the zip file...
            return from ( results.generateAsync ( { type: "blob" } ) );
        }),
        map ( results => EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS ( { results } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( EXPORT_SINGLE_ZIP_PER_SITE_FAILURE ( { errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } )
    ) );

    EXPORT_SINGLE_ZIP_PER_SUCCESS_SUCCESS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS ),
        map ( action => EXPORT_ZIP_DOWNLOAD ( { results: action.results } ) ) 
    ) );

    EXPORT_ZIP_DOWNLOAD$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( EXPORT_ZIP_DOWNLOAD ),
        tap ( action => {
            // Generate and save the zip file...
            saveAs ( action.results, `export-${new Date ( Date.now ( ) ).toLocaleDateString ( )}.zip` );
        } ) 
    ), { dispatch: false } );
}
