import { BooleanQuestionMeta } from './BooleanQuestionMeta';
import { NumberRangeQuestionMeta } from './NumberRangeQuestionMeta';
import { QuestionMeta } from './QuestionMeta';
import { QuestionType, QuestionTypeUtils } from './QuestionType';



export abstract class QuestionMetaFactory
{
    public static toJson<T extends QuestionMeta>( question: T ): any
    {
        if ( question instanceof BooleanQuestionMeta )
        {
            return BooleanQuestionMeta.toJson ( question );
        }
        else if ( question instanceof NumberRangeQuestionMeta )
        {
            return NumberRangeQuestionMeta.toJson ( question );
        }
        else
        {
            console.error ( `Encountered unknown question type: ${question.Type}.` );
            return null;
        }
    }

    public static fromJson( json: any ): QuestionMeta | null
    {
        const type = QuestionTypeUtils.fromString ( json.type );
        switch ( type )
        {
        case QuestionType.BOOLEAN:
            return BooleanQuestionMeta.fromJson ( json );
        case QuestionType.NUMBER_RANGE:
            return NumberRangeQuestionMeta.fromJson ( json );
        case QuestionType.NONE:
        default:
            console.error ( `Encountered unknown question type: ${type}.` );
            return null;
        }
    }

    public static fromType ( type: QuestionType ) : QuestionMeta | null
    {
        switch ( type )
        {
        case QuestionType.BOOLEAN:
            return new BooleanQuestionMeta ( );
        case QuestionType.NUMBER_RANGE:
            return new NumberRangeQuestionMeta ( );
        case QuestionType.NONE:
        default:
            console.error ( `Encountered unknown question type: ${type}.` );
            return null;
        }
    }
}
