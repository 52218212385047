import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Participant } from '../../../model/study/participants/Participant';
import { StudyParticipantsState, STUDY_PARTICIPANTS_FEATURE_STATE_NAME } from './study-participants.state';

export const SELECT_STUDY_PARTICIPANTS = createFeatureSelector<StudyParticipantsState> ( STUDY_PARTICIPANTS_FEATURE_STATE_NAME );

export const SELECT_STUDY_PARTICIPANTS_FULL_LOAD      = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState, props: { type: number } ) => {
    const typeSubState = state.typeSubStates.get ( props.type );
    if ( typeSubState )
    {
        for ( const siteSubState of typeSubState.siteSubStates.values ( ) )
        {
            if ( siteSubState.fullLoad )
            {
                return true;
            }
        }
    }
    return false;
} );

export const SELECT_STUDY_PARTICIPANTS_LOADING      = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState, props: { type: number } ) => {
    const typeSubState = state.typeSubStates.get ( props.type );
    if ( typeSubState )
    {
        for ( const siteSubState of typeSubState.siteSubStates.values ( ) )
        {
            if ( siteSubState.participantsLoading )
            {
                return true;
            }
        }
    }
    return false;
} );

export const SELECT_STUDY_PARTICIPANTS_SAVING       = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState, props: { type: number } ) => {
    const typeSubState = state.typeSubStates.get ( props.type );
    if ( typeSubState )
    {
        for ( const siteSubState of typeSubState.siteSubStates.values ( ) )
        {
            if ( siteSubState.participantsSaving )
            {
                return true;
            }
        }
    }
    return false;
} );

export const SELECT_STUDY_PARTICIPANTS_LOADING_ALL  = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState ) => {
    for ( const typeSubState of state.typeSubStates.values ( ) )
    {
        for ( const siteSubState of typeSubState.siteSubStates.values ( ) )
        {
            if ( siteSubState.participantsLoading )
            {
                return true;
            }
        }
    }
    return false;
} );

export const SELECT_STUDY_PARTICIPANTS_PARTICIPANTS     = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState, props: { type: number, siteId: number } ) => {
    return state.typeSubStates.get ( props.type )?.siteSubStates.get ( props.siteId )?.participants;
} );

export const SELECT_STUDY_PARTICIPANTS_PARTICIPANT      = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState, props: { type: number, siteId: number, id: number } ) => {
    return state.typeSubStates.get ( props.type )?.siteSubStates.get ( props.siteId )?.participants.get ( props.id );
} );

export const SELECT_STUDY_PARTICIPANTS_PARTICIPANTS_ALL = createSelector ( SELECT_STUDY_PARTICIPANTS, ( state: StudyParticipantsState ) => {
    const res = new Map<number, Participant> ( );
    for ( const typeSubState of state.typeSubStates.values ( ) )
    {
        for ( const siteSubState of typeSubState.siteSubStates.values ( ) )
        {
            for ( const [id, participant] of siteSubState.participants )
            {
                res.set ( id, participant );
            }
        }
    }
    return res;
} );

