import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { FieldComponent } from './field.component';
import { Field } from '../../model/published/Field';
import { FileField } from '../../model/published/FileField';
import { FileFieldValue } from '../../model/study/FileFieldValue';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';


@Component({
  selector: 'lib-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => FileFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => FileFieldComponent ),
      multi: true
    }
  ]
})
export class FileFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: FileField | null = null;

  @Output()
  public fileUpload = new EventEmitter<File> ( );

  @Output()
  public fileDownload = new EventEmitter<[FileField, FileFieldValue]> ( );

  value: FileFieldValue | null = null;

  disabled = false;

  constructor ( fb: FormBuilder, eFactory: ExpressionEngineFactory, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  get ValueFormControl ( ) : FormControl
  { 
    return this.form.get ( 'value' ) as FormControl;
  }

  ngOnInit ( )
  {
    this.form = this.fb.group ( {
      value: [null, this.meta?.Required ? [ Validators.required ] : null ],
      file: null,
      timestamp: null
    } );
  }

  writeValue ( val: any )
  {
    this.value = FileFieldValue.clone ( val as FileFieldValue );
    if ( this.value )
    {
      this.form.patchValue ( { "value": this.value.Value, "timestamp": this.value.Timestamp }, { emitEvent: false } );
    }
  }

  registerOnChange ( fn: any )
  {
    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          if ( "value" in change )
          {
            this.value.Value = change['value'];
          }
          this.value.Timestamp = change['timestamp'];
          return this.value
        }
        else
        {
          return change;
        }
      }),
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    this.disabled = disable;
    if ( disable )
    {
      if ( this.ValueFormControl.enabled ) this.ValueFormControl.disable ( );
    }
    else
    {
      if ( this.ValueFormControl.disabled ) this.ValueFormControl.enable ( );
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    if ( results && "validityExpression" in results )
    {
      const combo = { ...this.ValueFormControl.errors, ... results };
      this.ValueFormControl.setErrors ( combo );
    }
    else if ( this.ValueFormControl.errors && Object.keys ( this.ValueFormControl.errors ).length > 0 )
    {
      let existing = { ...this.ValueFormControl.errors };
      delete existing["validityExpression"];
      this.ValueFormControl.setErrors ( existing );
    }
    else
    {
      this.ValueFormControl.setErrors ( null );
    }
  }

  onFileSelect ( evt: any )
  {
    const target = <DataTransfer> ( evt.target );
    if ( target.files.length !== 1 ) throw new Error ( 'Only single file selection supported.' );

    const [file] = evt.target.files;

    this.form.patchValue({
      value: file.name,
      file: file
    });

    this.fileUpload.emit ( file );
  }

  onDownload ( )
  {
    if ( this.Meta && this.value )
    {
      this.fileDownload.emit ( [ this.Meta, this.value ] );
    }
  }
}
