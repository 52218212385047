<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  <a class="navbar-brand d-flex align-items-center" href="#">
      <div class="toolbar-icon pr-3"></div>
  </a>
</nav>

<main role="main pt-5" >
  <div class="row">
    <div class="col-12 vh-100 d-flex flex-column justify-content-center align-items-center">
      <h1 class="pt-3 display-1 text-center text-primary"><fa-icon [icon]="['fas', 'lock']"></fa-icon></h1>
      <h1 class="pt-3 display-1 text-center text-primary">Signed out</h1>
      <h2 class="pt-3 text-center">You have been automatically signed out due to inactivity.</h2>
      <p class="pt-3 text-center">This is because the application was idle for more than 1 hour. <a [routerLink]="['..', 'private']">Please sign in again.</a></p>
      <div class="col pt-5 d-flex justify-content-center flex-grow-0">
        <a class="btn btn-primary btn-lg" href="/"><fa-icon [icon]="['fas', 'home']" [fixedWidth]="true" class="pr-2"></fa-icon>Back to home</a>
      </div>
    </div>
  </div>
</main>
  
<footer class="container pb-3">
  <div class="row">
      <div class="col-12 text-center">
          <small class="text-muted"><a href="https://www.xeropoint.co.uk" target="_blank">Xeropoint Ltd.</a> &#169; 2018-2023</small>
      </div>
  </div>
</footer>