<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <!-- Enumerations -->
    <div class="form-group">
        <button class="btn mr-1" [ngClass]="[( isYes ( value?.Value ) == true ) ? 'btn-success' : 'btn-outline-success', (form.controls.value.errors && !disabled) ? 'is-invalid' : '']" (click)="onYes()" [disabled]="disabled">{{meta?.YesLabel}}</button>
        <button class="btn ml-1" [ngClass]="[( isNo ( value?.Value ) == true ) ? 'btn-danger' : 'btn-outline-danger', (form.controls.value.errors && !disabled) ? 'is-invalid' : '']" (click)="onNo()" [disabled]="disabled">{{meta?.NoLabel}}</button>
        <div class="invalid-feedback">
            <ng-container *ngIf="validation != null && validation.length > 0; else noValidation">
                {{validation}}
            </ng-container>
            <ng-template #noValidation>
                Value is required
            </ng-template>
        </div>
    </div>
</form>