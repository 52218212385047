import { Participant } from "./Participant";
import { ParticipantMeta } from "../../published/participants/ParticipantMeta";
import { CollectionPointValueFactory } from "../CollectionPointValueFactory";
import * as moment from "moment";
import { ExpressionEngine } from "../../../expressions/ExpressionEngine";
import { ParticipantUtils } from "./ParticipantUtils";
import { CurrentUserUtilsService } from "../../../services/current.user.utils.service";


export class ParticipantFactory
{
    readonly cpValueFactory;

    constructor ( private engine: ExpressionEngine, private currentUser: CurrentUserUtilsService, userRoles: string[] )
    {
        this.cpValueFactory = new CollectionPointValueFactory ( engine, currentUser, userRoles );
    }

    fromMeta ( meta: ParticipantMeta, siteValues: Map<string, boolean | number | string | moment.Moment | null> ) : Participant
    {
        let participant = new Participant ( );
        participant.MetaId = meta.Id;

        if ( meta.RegistrationCollectionPoint && meta.Variable )
        {
            const utils = new ParticipantUtils ( );
            const participantValues = utils.getSubjectVariableValues ( meta, participant, meta.Variable, null );

            let vals = new Map ( [...Array.from ( siteValues.entries ( ) ), ...Array.from ( participantValues.entries ( ) ) ] );
            participant.RegistrationCollectionPointValue = this.cpValueFactory.fromMeta ( meta.RegistrationCollectionPoint, vals );
        } 

        return participant;
    }
} 