import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorsService, PublishedPagesService } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  title = 'management';

  constructor ( private titleService: Title, private landing: PublishedPagesService, private errors: ErrorsService, private router: Router )
  {
    // Null.
  }

  ngOnInit ( )
  {
    // this.errors.getLastError ( ).subscribe ( errors => {
    //   this.router.navigate ( [ "error" ] );
    // } )

    this.landing.getLanding ( ).subscribe ( landing => {
      if ( landing && landing.MainTitle )
      {
        this.titleService.setTitle ( landing?.MainTitle );
      }
    } );
    this.landing.onLanding ( );
  }
}
