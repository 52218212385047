import { createReducer, on } from '@ngrx/store';
import { EXPORT_SINGLE_ZIP, EXPORT_SINGLE_ZIP_SUCCESS, EXPORT_SINGLE_ZIP_FAILURE, EXPORT_SINGLE_ZIP_PER_SITE, EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS, EXPORT_SINGLE_ZIP_PER_SITE_FAILURE } from './export.actions';
import { ExportState } from './export.state';

export const INITIAL_STATE = new ExportState ( );

const _EXPORT_REDUCER = createReducer ( INITIAL_STATE,

    on ( EXPORT_SINGLE_ZIP, ( state ) => {
      const newState = { ...state };

      newState.exporting = true;

      return newState;
    } ),
    on ( EXPORT_SINGLE_ZIP_SUCCESS, ( state, { results } ) => {
      const newState = { ...state };

      newState.exporting = false;

      return newState;
    } ),
    on ( EXPORT_SINGLE_ZIP_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.exporting = false;

      return newState;
    } ),


    on ( EXPORT_SINGLE_ZIP_PER_SITE, ( state ) => {
      const newState = { ...state };

      newState.exporting = true;

      return newState;
    } ),
    on ( EXPORT_SINGLE_ZIP_PER_SITE_SUCCESS, ( state, { results } ) => {
      const newState = { ...state };

      newState.exporting = false;

      return newState;
    } ),
    on ( EXPORT_SINGLE_ZIP_PER_SITE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.exporting = false;

      return newState;
    } ),

);

export function EXPORT_REDUCER( state: any, action: any )
{
  return _EXPORT_REDUCER ( state, action );
}
