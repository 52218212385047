import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SystemUsersState, SYSTEM_USERS_FEATURE_STATE_NAME } from './system-users.state';

export const SELECT_SYSTEM_USERS = createFeatureSelector<SystemUsersState> ( SYSTEM_USERS_FEATURE_STATE_NAME );

export const SELECT_SYSTEM_USERS_LOADING  = createSelector ( SELECT_SYSTEM_USERS, ( state: SystemUsersState ) => state.loading );
export const SELECT_SYSTEM_USERS_SAVING   = createSelector ( SELECT_SYSTEM_USERS, ( state: SystemUsersState ) => state.saving );

export const SELECT_SYSTEM_USERS_USERS = createSelector ( SELECT_SYSTEM_USERS, ( state: SystemUsersState ) => state.users );
export const SELECT_SYSTEM_USERS_USER  = createSelector ( SELECT_SYSTEM_USERS, ( state: SystemUsersState, props: { id: string } ) => state.users.get ( props.id ) );
