import { Field } from './Field';
import { FieldType } from './FieldType';


export class FileField extends Field
{
    public static clone( input: FileField ): FileField
    {
        let output = Field._clone ( input, new FileField ( ) );

        return output;
    }

    public static toJson( field: FileField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );
        
        return out;
    }

    public static fromJson( json: any ): FileField
    {
        let out = new FileField ( );

        out = Field._fromJson ( json, out );

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null)
    {
       super ( id, version, seq, FieldType.FILE, variable, name, description, required );
    }
}
