import { Component, Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { StringField } from '../../model/published/StringField';
import { StringFieldValue } from '../../model/study/StringFieldValue';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { ExpressionEngine } from '../../expressions/ExpressionEngine';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';
import { Observable } from 'rxjs';

@Directive()
export abstract class FieldComponent implements OnChanges, ControlValueAccessor, Validator
{
  @Input()
  public stateVector: Map<string, boolean | number | string | moment.Moment | null> | null = null;

  @Input()
  public permanentlyDisable = false;

  form!: FormGroup;

  public onTouched: () => void = () => {};

  public onChange: (value: any) => void = (value: any) => {};

  protected expressionEngine: ExpressionEngine;

  isSysAdmin$: Observable<boolean> | null = null;

  constructor ( protected fb: FormBuilder, eFactory: ExpressionEngineFactory, currentUser: CurrentUserUtilsService )
  {
    this.expressionEngine = eFactory.create ( );

    this.isSysAdmin$ = currentUser.hasSysAdminRole ( );
  }

  abstract get Meta ( ) : Field | null;

  ngOnChanges ( changes: SimpleChanges )
  {
    if ( "stateVector" in changes )
    {
      this.stateVector = changes.stateVector.currentValue;
      if ( this.form )
      {
        const doda = this;
        setTimeout ( ( ) => {
          doda.onDisable ( doda.disabledExpression ( ) );
          doda.onValidate ( doda.validityExpression ( ) );
        } );
        // this.form.updateValueAndValidity({onlySelf: true, emitEvent: true})
      }
    }
  }

  writeValue(obj: any)
  {
    // Here to satisfy the compiler, check out the sub-classes for details!
    throw new Error ( "writeValue(obj) not implemented, please define in sub-class." );
  }
  
  registerOnChange(fn: any)
  {
    // Here to satisfy the compiler, check out the sub-classes for details!
    throw new Error ( "registerOnChange(obj) not implemented, please define in sub-class." );
  }

  registerOnTouched ( fn: any )
  {
    this.onTouched = fn;
  }

  validate ( c: AbstractControl ) : ValidationErrors | null
  {
    return this.form.valid ? null : { invalidForm: {valid: false, message: "Field value is invalid" } };
  }

  protected onDisable ( disable: boolean )
  {
    // Default implementation: Do nothing...
  }

  protected disabledExpression ( ) : boolean
  {
    if ( this.permanentlyDisable )
    {
      return true;
    }
    else
    {
      if ( this.stateVector && this.Meta?.DisabledExpression )
      {
        const disable = this.expressionEngine.eval ( this.Meta.DisabledExpression, this.stateVector ) as boolean;
        return disable;

      }
          
      return false;
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    // Default implementation: Do nothing...
  }

  protected validityExpression ( control: AbstractControl | null = null ) : ValidationErrors | null
  {
    if ( this.stateVector && this.Meta?.ValidityExpression )
    {
      // const clone = new Map ( this.stateVector );
      // clone.set ( "value", this.value ? this.value.Value : null );
      const valid = this.expressionEngine.eval ( this.Meta.ValidityExpression, this.stateVector ) as string;
      if ( valid != null && valid.length > 0 )
      {
        return { validityExpression: valid };
      }

    }
        
    return null;
  }
}
