import * as moment from "moment";
import { SubjectType, SubjectTypeUtils } from "../published/SubjectType";

export class Task
{
    public static clone( task: Task ): Task
    {
        return new Task ( task.Id,
                          task.SiteId,
                          task.SubjectValueId, 
                          task.SubjectType,
                          task.ParticipantType,
                          task.CollectionPointValueId,
                          task.FormValueId,
                          task.Created,
                          task.Due,
                          task.Completed );
    }

    public static toJson( task: Task ): any
    {
        let out: any = {};

        out.id = task.Id;
        out.site_id = task.SiteId;
        out.subject_value_id = task.SubjectValueId;
        out.subject_type = task.SubjectType ? SubjectTypeUtils.toString ( task.SubjectType ) : null;
        out.participant_type = task.ParticipantType;
        out.collection_point_value_id = task.CollectionPointValueId;
        out.form_value_id = task.FormValueId;
        out.created = task.Created ? task.Created.format ( ) : null;
        out.due = task.Due ? task.Due.format ( ) : null;
        out.completed = task.Completed ? task.Completed.format ( ) : null;

        return out;
    }

    public static fromJson( json: any ): Task
    {
        let out = new Task ( );

        out.Id = json.id as number;
        out.SiteId = json.site_id as number;
        out.SubjectValueId = json.subject_value_id as number;
        out.SubjectType = json.subject_type ? SubjectTypeUtils.fromString ( json.subject_type ) : null;
        out.ParticipantType = json.participant_type as number;
        out.CollectionPointValueId = json.collection_point_value_id as number;
        out.FormValueId = json.form_value_id as number;
        out.Created = "created" in json && json [ "created" ] ? moment ( json.created as string ) : null;
        out.Due = "due" in json && json [ "due" ] ? moment ( json.due as string ) : null;
        out.Completed = "completed" in json && json [ "completed" ] ? moment ( json.completed as string ) : null;

        return out;
    }

    public constructor( private id: number | null = null,
                        private siteId: number | null = null,
                        private subjectValueId: number | null = null,
                        private subjectType: SubjectType | null = null,
                        private participantType: number | null = null,
                        private collectionPointValueId: number | null = null,
                        private formValueId: number | null = null,
                        private created: moment.Moment | null = null,
                        private due: moment.Moment | null = null,
                        private completed: moment.Moment | null = null, )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get SiteId( ): number | null
    {
        return this.siteId;
    }

    public set SiteId( siteId: number | null )
    {
        this.siteId = siteId;
    }

    public get SubjectValueId( ): number | null
    {
        return this.subjectValueId;
    }

    public set SubjectValueId( subjectValueId: number | null )
    {
        this.subjectValueId = subjectValueId;
    }

    public get SubjectType( ): SubjectType | null
    {
        return this.subjectType;
    }

    public set SubjectType( subjectType: SubjectType | null )
    {
        this.subjectType = subjectType;
    }

    public get ParticipantType( ): number | null
    {
        return this.participantType;
    }

    public set ParticipantType( participantType: number | null )
    {
        this.participantType = participantType;
    }

    public get CollectionPointValueId( ): number | null
    {
        return this.collectionPointValueId;
    }

    public set CollectionPointValueId( collectionPointValueId: number | null )
    {
        this.collectionPointValueId = collectionPointValueId;
    }

    public get FormValueId( ): number | null
    {
        return this.formValueId;
    }

    public set FormValueId( formValueId: number | null )
    {
        this.formValueId = formValueId;
    }

    public get Created( ): moment.Moment | null
    {
        return this.created;
    }

    public set Created( created: moment.Moment | null )
    {
        this.created = created;
    }

    public get Due( ): moment.Moment | null
    {
        return this.due;
    }

    public set Due( due: moment.Moment | null )
    {
        this.due = due;
    }

    public get Completed( ): moment.Moment | null
    {
        return this.completed;
    }

    public set Completed( completed: moment.Moment | null )
    {
        this.completed = completed;
    }
}
