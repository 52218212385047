import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { RoleHierarchy } from '../../../model/system/roles/RoleHierarchy';


export const ON_ROLE_HIERARCHY_LIST       = createAction ( 'shared.system-role-hierarchy.list', props<{ force: boolean }> ( ) );


export const GET_ROLE_HIERARCHY         = createAction ( 'shared.system-role-hierarchy.get', props<{ op: string }> ( ) );
export const GET_ROLE_HIERARCHY_SUCCESS = createAction ( 'shared.system-role-hierarchy.get.success', props<{ op: string, hierarchy: RoleHierarchy }> ( ) );
export const GET_ROLE_HIERARCHY_FAILURE = createAction ( 'shared.system-role-hierarchy.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );
