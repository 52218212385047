import * as moment from "moment";
import { Expression } from "../model/published/expressions/Expression";
import { ExpressionDataType } from "../model/published/expressions/ExpressionDataType";
import { ExpressionEngine } from "./ExpressionEngine";
import * as Sentry from "@sentry/angular";

// import * as fengari from "../../fengari"
// import * as fengari_interp from "fengari-interop";

declare var fengari: any;

export class LuaExpressionEngine implements ExpressionEngine
{
    private readonly luaconf  = fengari.luaconf;
    private readonly lua      = fengari.lua;
    private readonly lauxlib  = fengari.lauxlib;
    private readonly lualib   = fengari.lualib;

    public eval ( expr: Expression, values: Map<string, boolean | number | string | moment.Moment | null> ) : boolean | number | string | moment.Moment | null
    {
        try
        {
            const L = fengari.lauxlib.luaL_newstate();
            fengari.lualib.luaL_openlibs(L);

            Sentry.addBreadcrumb({
                category: "lua.expression",
                message: expr.Body,
                level: Sentry.Severity.Info,
              });

            if ( expr.Name == null || expr.Body == null )
            {
                console.warn("Bad input, expression name or body is null.")
            }

            let ok = fengari.lauxlib.luaL_dostring ( L, fengari.to_luastring ( expr.Body ) )
            if (ok)
            {
                console.log(`Error pcalling: ${ok} ${fengari.lua.lua_tojsstring(L, -1)}`)
                return null;
            }

            fengari.lua.lua_getglobal(L, fengari.to_luastring ( expr.Name ) );

            for ( const arg of expr.Args )
            {
                const val = values.get ( arg.Variable );
                if ( val != null && val != undefined )
                {
                    switch ( arg.Type )
                    {
                    case ExpressionDataType.BOOLEAN:
                        fengari.lua.lua_pushboolean ( L, val );
                        break;
                    case ExpressionDataType.DATE_TIME:
                        {
                            const i = val.valueOf ( );
                            fengari.lua.lua_pushnumber ( L, i );
                        }
                        break;
                    case ExpressionDataType.NUMBER:
                        fengari.lua.lua_pushnumber ( L, val );
                        break;
                    case ExpressionDataType.STRING:
                        fengari.lua.lua_pushstring ( L, fengari.to_luastring ( val ) );
                        break;
                    case ExpressionDataType.INTEGER:
                        fengari.lua.lua_pushnumber ( L, Math.floor ( val as number ) );
                        break;
                    default:
                        console.error ( `Unknown expression data type: ${arg.Type}` );
                        break;
                    }
                }
                else
                {
                    fengari.lua.lua_pushnil ( L );
                }
            }

            ok = fengari.lua.lua_pcall(L, expr.Args.length, 1, 0)
            if (ok)
            {
                console.log(`Error pcalling: ${ok} ${fengari.lua.lua_tojsstring(L, -1)}`)
                return null;
            }

            let result: boolean | number | string | moment.Moment | null = null;
            
           
            switch ( expr.ReturnType )
            {
            case ExpressionDataType.BOOLEAN:
                result = fengari.lua.lua_toboolean(L, -1);
                break;
            case ExpressionDataType.DATE_TIME:
                const raw = fengari.lua.lua_tojsstring(L, -1);
                if ( raw != null )
                {
                    const ts = Number.parseInt(raw);
                    result = moment(ts);
                }
                break;
            case ExpressionDataType.NUMBER:
                 // This checks whether the return is actually nil, else, for example nil will be coerced into zero...
                if ( fengari.lua.lua_tojsstring(L, -1) != null )
                {
                    result = fengari.lua.lua_tonumber(L, -1);
                }
                break;
            case ExpressionDataType.STRING:
                result = fengari.lua.lua_tojsstring(L, -1);
                break;
            case ExpressionDataType.INTEGER:
                // This checks whether the return is actually nil, else, for example nil will be coerced into zero...
                if ( fengari.lua.lua_tojsstring(L, -1) != null )
                {
                    result = Math.floor(fengari.lua.lua_tonumber(L, -1));
                }
                break;
            default:
                console.error ( `Unknown return data type: ${expr.ReturnType}` );
                break;
            }

            fengari.lua.lua_pop(L, 1)

            return result;
        }
        catch ( e: any )
        {
            console.warn(`Encountered an error during execution of expression: ${expr.Name}`)
            console.warn(e);
            return null;
        }
    }

}