import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

@Injectable({
    providedIn: 'root'
  })
export class MandatoryLoginAuthGuard extends KeycloakAuthGuard
{
    constructor ( protected readonly router: Router, protected readonly keycloak: KeycloakService )
    {
        super ( router, keycloak );
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree>
    {
        // Force the user to log in if currently unauthenticated.
        if ( !this.authenticated )
        {
            await this.keycloak.login ( { redirectUri: window.location.origin + state.url } );
        }

        return true;
    }

}