import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { ConfigService } from 'shared';


// Load config to get environment name for the sentry initialisation...
new ConfigService ( ).getConfig ( ).then ( config => {

  if ( environment.production )
  {
    enableProdMode ( );

    if ( config.sentry_dsn && config.sentry_dsn.length > 0 )
    {
      const release = environment.build;
      const environment_name = config.environment_name;
    
      Sentry.init({
        dsn: config.sentry_dsn ,
        environment: environment_name ? environment_name : undefined,
        release: release ? `x-trial@${release}` : undefined,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          new Integrations.BrowserTracing({
            tracingOrigins: ["api.dev.afri.study"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
    
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
    else
    {
      console.log ( "No DSN provided, Sentry data capture not enabled.")
    }
  }
  
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
} );


