<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <!-- Enumerations -->
    <div class="form-group">    
        <select class="custom-select" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }">
            <option selected  [ngValue]="null">None selected</option>
            <option *ngFor="let value of meta?.Enumerations" [ngValue]="value.Value">{{value.Name}}</option>
        </select>
        <div class="invalid-feedback">
            <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                Value is required
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                {{ValueFormControl?.errors?.['validityExpression']}}
            </ng-container>
        </div>
    </div>

</form>