import { Expression } from '../../published/expressions/Expression';
import { CollectionPoint } from '../CollectionPoint';
import { Form } from '../Form';
import { Subject } from '../Subject';
import { SubjectType } from '../SubjectType';
import { Tag } from '../Tag';


export class ParticipantMeta extends Subject
{
    public static toJson ( participant: ParticipantMeta ): any
    {
        let out: any = {};

        out = Subject._toJson ( participant, out );

        out.id_digits = participant.IdDigits;
        out.site_specific_ids = participant.SiteSpecificIds;

        out.consent_transition_expression = participant.ConsentTransitionExpression ? Expression.toJson ( participant.ConsentTransitionExpression ) : null;
        out.consent_label_colour_expression = participant.ConsentLabelColourExpression ? Expression.toJson ( participant.ConsentLabelColourExpression ) : null;

        return out;
    }

    public static fromJson( json: any ): ParticipantMeta
    {
        let out = new ParticipantMeta ( );

        out = Subject._fromJson ( json, out );

        out.IdDigits = json.id_digits;
        out.SiteSpecificIds = json.site_specific_ids;

        out.ConsentTransitionExpression = "consent_transition_expression" in json && json.consent_transition_expression ? Expression.fromJson ( json.consent_transition_expression ) : null;
        out.ConsentLabelColourExpression = "consent_label_colour_expression" in json && json.consent_label_colour_expression ? Expression.fromJson ( json.consent_label_colour_expression ) : null;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null  = null, 
                        variable: string | null  = null, 
                        name: string | null  = null, 
                        description: string | null = null,
                        labelExpression: Expression | null = null,
                        tags: Array<Tag> | null = null,
                        registrationCollectionPoint: CollectionPoint | null  = null,
                        baselineCollectionPoint: CollectionPoint | null  = null,
                        intervalCollectionPoints: Array<CollectionPoint> | null  = null,
                        adhocCollectionPoints: Array<CollectionPoint> | null  = null,
                        completionCollectionPoint: CollectionPoint | null  = null,
                        eligibilityFormName: string | null = null,
                        eligibilityExpression: Expression | null = null, 
                        private consentTransitionExpression: Expression | null = null,
                        private consentLabelColourExpression: Expression | null = null,
                        private idDigits: number | null = null,
                        private siteSpecificIds: boolean | null = null )
    {
       super ( id, version, SubjectType.PARTICIPANT, variable, name, description, labelExpression, tags,
               registrationCollectionPoint, baselineCollectionPoint, intervalCollectionPoints, adhocCollectionPoints, completionCollectionPoint, eligibilityFormName, eligibilityExpression );
    }

    public get IdDigits( ): number | null
    {
        return this.idDigits;
    }

    public set IdDigits( idDigits: number | null )
    {
        this.idDigits = idDigits;
    }

    public get SiteSpecificIds( ): boolean | null
    {
        return this.siteSpecificIds;
    }

    public set SiteSpecificIds( siteSpecificIds: boolean | null )
    {
        this.siteSpecificIds = siteSpecificIds;
    }

    public get ConsentTransitionExpression( ): Expression | null
    {
        return this.consentTransitionExpression;
    }

    public set ConsentTransitionExpression( consentTransitionExpression: Expression | null )
    {
        this.consentTransitionExpression = consentTransitionExpression;
    }

    public get ConsentLabelColourExpression( ): Expression | null
    {
        return this.consentLabelColourExpression;
    }

    public set ConsentLabelColourExpression( consentLabelColourExpression: Expression | null )
    {
        this.consentLabelColourExpression = consentLabelColourExpression;
    }
}
