import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { GET_ROLE_HIERARCHY, GET_ROLE_HIERARCHY_FAILURE, GET_ROLE_HIERARCHY_SUCCESS, ON_ROLE_HIERARCHY_LIST } from './system-roles.actions';
import { RoleHierarchy } from '../../../model/system/roles/RoleHierarchy';
import { SELECT_SYSTEM_ROLES_HIERARCHY } from './system-roles.selectors';

@Injectable ( )
export class SystemRolesEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_ROLE_HIERARCHY_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_ROLE_HIERARCHY_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_SYSTEM_ROLES_HIERARCHY ) ),
        mergeMap ( ([action, roles]) => {
            if ( action.force || roles == null )
            {
                return [ GET_ROLE_HIERARCHY ( { op: 'list' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_ROLE_HIERARCHY$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_ROLE_HIERARCHY ),
        switchMap ( action => of ( action ).pipe (
            mergeMap ( action => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.system_api}/role-hierarchy/` ) ),
            map ( response => {
                const hierarchy = RoleHierarchy.fromJson ( response );
                return hierarchy;
            } ),
            map ( hierarchy => GET_ROLE_HIERARCHY_SUCCESS ( { op: action.op, hierarchy } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_ROLE_HIERARCHY_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
