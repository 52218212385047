import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CREATE_SITE, CREATE_SITE_FORM, DELETE_SITE, DOWNLOAD_SITE_FILE, ON_SITES_LIST, ON_SITES_SINGLE, UPDATE_SITE, UPDATE_SITE_FORM, UPLOAD_SITE_FILE } from '../../state/study/sites/study-sites.actions';
import { SELECT_STUDY_SITES_LOADING, SELECT_STUDY_SITES_SAVING, SELECT_STUDY_SITES_SITE, SELECT_STUDY_SITES_SITES } from '../../state/study/sites/study-sites.selectors';
import { Site } from '../../model/study/sites/Site';
import { map } from 'rxjs/operators';
import { CollectionPointValue } from '../../model/study/CollectionPointValue';
import { FormValue } from '../../model/study/FormValue';
import { CollectionPoint } from '../../model/published/CollectionPoint';
import { FileField } from '../../model/published/FileField';
import { FileFieldValue } from '../../model/study/FileFieldValue';
import { UPLOAD_PARTICIPANT_FILE } from '../../state/study/participants/study-participants.actions';


@Injectable ( {
  providedIn: 'root'
} )
export class StudySitesService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( force: boolean = false )
  {
    this.store.dispatch ( ON_SITES_LIST ( { force } ) );
  }

  onSingle( id: number, force: boolean = false )
  {
    this.store.dispatch ( ON_SITES_SINGLE ( { id, force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_SITES_LOADING ) 
    );
  }

  getSaving( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_SITES_SAVING ) 
    );
  }

  getSites( ): Observable<Map<number, Site>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_SITES_SITES )
    );
  }

  getSite( id: number ): Observable<Site | null>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_SITES_SITE, { id } ),
      map ( site => {
        if ( site )
        {
          return site;
        }
        else
        {
          return null;
        }
      } )
    );
  }

  createSite ( site: Site )
  {
    this.store.dispatch ( CREATE_SITE ( { op: CREATE_SITE.type, site } ) );
  }

  updateSite ( site: Site )
  {
    this.store.dispatch ( UPDATE_SITE ( { op: UPDATE_SITE.type, site } ) );
  }

  deleteSite ( id: number )
  {
    this.store.dispatch ( DELETE_SITE ( { op: DELETE_SITE.type, id } ) );
  }

  createForm ( site: Site, cp: CollectionPoint, form: FormValue )
  {
    this.store.dispatch ( CREATE_SITE_FORM ( { op: CREATE_SITE_FORM.type, site, cp, form } ) );
  }

  updateForm ( site: Site, cpv: CollectionPointValue, form: FormValue )
  {
    this.store.dispatch ( UPDATE_SITE_FORM ( { op: UPDATE_SITE_FORM.type, site, cpv, form } ) );
  }

  uploadFile ( site: Site, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue, file: File )
  {
    this.store.dispatch ( UPLOAD_SITE_FILE ( { op: UPLOAD_SITE_FILE.type, site, cpv, fv, field, fieldValue, file } ) );
  }

  downloadFile ( site:Site, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue )
  {
    this.store.dispatch ( DOWNLOAD_SITE_FILE ( { op: DOWNLOAD_SITE_FILE.type, site, cpv, fv, field, fieldValue } ) );
  }
}
