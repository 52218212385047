import { Subject } from "../../published/Subject";
import { SubjectValue } from "../SubjectValue";
import { ParticipantMeta } from "../../published/participants/ParticipantMeta";
import { SubjectUtils } from "../SubjectUtils";
import { Participant } from "./Participant";
import { ExpressionEngine } from "../../../expressions/ExpressionEngine";
import { ParticipantField } from "../../published/ParticipantField";
import { CollectionPointValue } from "../CollectionPointValue";
import { FormValue } from "../FormValue";
import { ParticipantFieldValue } from "../ParticipantFieldValue";


export class ParticipantUtils extends SubjectUtils
{
    /**
     * This function gets the displayable label for the participant from the field value as 
     * defined within the meta data.
     * 
     * NOTE: THIS CAN BE DONE BETTER, AND SHOULD BE, ONE DAY!
     * 
     * @param type
     * @param participant 
     * @returns 
     */
    getParticipantLabel ( engine: ExpressionEngine, type: ParticipantMeta, participant: Participant, siteId: string | null, references: Map<number, [Participant, ParticipantMeta]> = new Map<number, [Participant, ParticipantMeta]> ( ) ) : string
    {
        return this.getSubjectLabel ( engine, type, participant, siteId, references );
    }

    getFormattedId ( type: Subject, value: SubjectValue | null ) : string | null
    {
        if ( type instanceof ParticipantMeta && value instanceof Participant )
        {
            return this.getParticipantFormattedId ( type, value );
        }

        return null;
    }

    getParticipantFormattedId ( type: ParticipantMeta, participant: Participant | number | null ) : string | null
    {
        if ( participant instanceof Participant && participant.ParticipantId && participant.ParticipantId.Id )
        {
            if ( type.IdDigits )
            {
                return String ( participant.ParticipantId.Id ).padStart ( type.IdDigits, "0" );
            }
            else
            {
                return participant.ParticipantId.Id.toString ( );
            }
        }
        else if ( typeof participant == "number" )
        {
            if ( type.IdDigits )
            {
                return String ( participant ).padStart ( type.IdDigits, "0" );
            }
            else
            {
                return participant.toString ( );
            }
        }

        return null;
    }

    getSubjectVariableValues ( type: ParticipantMeta, value: Participant,
        prefix: string, siteId: string | null, refs: Map<number, [Participant, ParticipantMeta]> = new Map<number, [Participant, ParticipantMeta]> ( ) ) : Map<string, boolean | number | string | moment.Moment | null>
    {
        const vals = super.getSubjectVariableValues ( type, value, prefix, siteId, refs );
        vals.set ( `${prefix}.___.consent`, value.Consent );
        vals.set ( `${prefix}.___.external_id`, value.ExternalId );
        vals.set ( `${prefix}.___.creation_time`, value.CreationTime );
        if ( siteId != null )
        {
            vals.set ( `${prefix}.___.site_id`, siteId );
        }
        return vals
    }

    getParticipantValueFields ( participant: Participant ) : Array<[CollectionPointValue, FormValue, ParticipantFieldValue]>
    {
        const out = new Array<[CollectionPointValue, FormValue, ParticipantFieldValue]> ( );

        for ( const cpv of participant.AllCollectionPointValues )
        {
            for ( const fv of cpv.FormValues ? cpv.FormValues : [] )
            {
                for ( const sv of fv.SectionValues ? fv.SectionValues : [] )
                {
                    for ( const v of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( v instanceof ParticipantFieldValue )
                        {
                            out.push ( [ cpv, fv, v ] );
                        }
                    }
                }
            }
        }

        return out;
    }

    getReferenceParticipants ( participant: Participant, meta: ParticipantMeta ) : Array<[number, number]>
    {
        const reqs = new Array<[number, number]> ( );

        const cache = new Map<number, number> ( );

        if ( participant.SiteId != null )
        {
            const fieldValues = this.getParticipantValueFields ( participant );
            for ( const fieldValue of fieldValues )
            {
                // If valid, and not already requested, i.e. dedupe cache
                if ( fieldValue[0].MetaId != null && fieldValue[2].Value != null && cache.has ( fieldValue[2].Value ) == false )
                {
                const cp = this.getCollectionPoint ( meta, fieldValue[0].MetaId );
                if ( cp != null && fieldValue[1].MetaId != null )
                {
                    const form = this.getForm ( cp, fieldValue[1].MetaId );
                    if ( form != null && fieldValue[2].MetaId != null )
                    {
                    const field = this.getField ( form, fieldValue[2].MetaId );
                    if ( field instanceof ParticipantField && field.ParticipantType && fieldValue[2].Value != null )
                    {
                        if ( cache.has ( fieldValue[2].Value ) == false )
                        {
                        cache.set ( fieldValue[2].Value, field.ParticipantType );
                        }
                    }
                    }
                }
                }
            }
        }

        for ( const [id, type] of cache.entries ( ) )
        {
            reqs.push ( [ id, type ] );
        }

        return reqs;
    }
}