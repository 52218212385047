import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { SiteMeta } from '../../../model/published/sites/SiteMeta';


export const ON_PUBLISHED_SITES_LIST   = createAction ( 'shared.published-sites.list', props<{ force: boolean }> ( ) );
export const ON_PUBLISHED_SITES_SINGLE = createAction ( 'shared.published-sites.single', props<{ id: number, force: boolean }> ( ) );


// export const CLEAR_METRICS         = createAction ( 'operate.metrics.metrics.clear' );


export const GET_PUBLISHED_SITES         = createAction ( 'shared.published-site.get', props<{ op: string, ids: number[] | null }> ( ) );
export const GET_PUBLISHED_SITES_SUCCESS = createAction ( 'shared.published-site.get.success', props<{ op: string, sites: SiteMeta [ ] }> ( ) );
export const GET_PUBLISHED_SITES_FAILURE = createAction ( 'shared.published-site.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );

