import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { GET_PUBLISHED_PAGES_LANDING, GET_PUBLISHED_PAGES_LANDING_FAILURE, GET_PUBLISHED_PAGES_LANDING_SUCCESS, GET_PUBLISHED_PAGES_SIDE_BAR, GET_PUBLISHED_PAGES_SIDE_BAR_FAILURE, GET_PUBLISHED_PAGES_SIDE_BAR_SUCCESS, GET_PUBLISHED_PAGES_SITES, GET_PUBLISHED_PAGES_SITES_FAILURE, GET_PUBLISHED_PAGES_SITES_SUCCESS, ON_PUBLISHED_PAGES_LANDING, ON_PUBLISHED_PAGES_SIDE_BAR, ON_PUBLISHED_PAGES_SITES } from './published-pages.actions';
import { SELECT_PUBLISHED_PAGES_LANDING_PAGE, SELECT_PUBLISHED_PAGES_SIDE_BAR, SELECT_PUBLISHED_PAGES_SITES_PAGE } from './published-pages.selectors';
import { LandingPage } from '../../../model/published/pages/LandingPage';
import { SitesPage } from '../../../model/published/pages/SitesPage';
import { SideBar } from '../../../model/published/pages/SideBar';

@Injectable ( )
export class PublishedPagesEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_PUBLISHED_PAGES_LANDING$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_PAGES_LANDING ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_PAGES_LANDING_PAGE ) ),
        mergeMap ( ([action, landingPage]) => {
            if ( action.force || landingPage == null )
            {
                return [ GET_PUBLISHED_PAGES_LANDING ( { op: 'landing-page-get' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PUBLISHED_PAGES_LANDING$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PUBLISHED_PAGES_LANDING ),
        switchMap ( action => of ( action ).pipe (
            mergeMap ( () => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.published_api}/landing-page/` ) ),
            map ( response => {
                const landingPage = LandingPage.fromJson ( response );
                return landingPage;
            } ),
            map ( landingPage => GET_PUBLISHED_PAGES_LANDING_SUCCESS ( { op: action.op, landingPage } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PUBLISHED_PAGES_LANDING_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );



    ON_PUBLISHED_PAGES_SITES$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_PAGES_SITES ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_PAGES_SITES_PAGE ) ),
        mergeMap ( ([action, sitesPage]) => {
            if ( action.force || sitesPage == null )
            {
                return [ GET_PUBLISHED_PAGES_SITES ( { op: 'sites-page-get' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PUBLISHED_PAGES_SITES$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PUBLISHED_PAGES_SITES ),
        switchMap ( action => of ( action ).pipe (
            mergeMap ( () => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.published_api}/sites-page/` ) ),
            map ( response => {
                const sitesPage = SitesPage.fromJson ( response );
                return sitesPage;
            } ),
            map ( sitesPage => GET_PUBLISHED_PAGES_SITES_SUCCESS ( { op: action.op, sitesPage } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PUBLISHED_PAGES_SITES_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );


    ON_PUBLISHED_PAGES_SIDE_BAR$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_PAGES_SIDE_BAR ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_PAGES_SIDE_BAR ) ),
        mergeMap ( ([action, sideBar]) => {
            if ( action.force || sideBar == null )
            {
                return [ GET_PUBLISHED_PAGES_SIDE_BAR ( { op: 'side-bar-get' } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PUBLISHED_PAGES_SIDE_BAR$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PUBLISHED_PAGES_SIDE_BAR ),
        switchMap ( action => of ( action ).pipe (
            mergeMap ( () => this.config.getConfigLazy ( ) ),
            mergeMap ( config => this.http.get<any[]> ( `${config.published_api}/side-bar/` ) ),
            map ( response => {
                const sideBar = SideBar.fromJson ( response );
                return sideBar;
            } ),
            map ( sideBar => GET_PUBLISHED_PAGES_SIDE_BAR_SUCCESS ( { op: action.op, sideBar } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PUBLISHED_PAGES_SIDE_BAR_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
