
export class Role
{
    public static toJson( role: Role ): any
    {
        let out: any = {};

        out.id = role.Id;
        out.name = role.Name;
        out.description = role.Description;
        
        return out;
    }

    public static fromJson( json: any ): Role
    {
        let out = new Role ( );

        out.Id = json.id as string;
        out.Name = json.name as string;
        out.Description = json.description as string;

        return out;
    }

    public constructor( private id: string | null = null,
                        private name: string | null = null,
                        private description: string | null = null )
    {
        // Null.
    }

    public get Id( ): string | null
    {
        return this.id;
    }

    public set Id( id: string | null )
    {
        this.id = id;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }
}
