import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudyChangeRecordsState, STUDY_CHANGE_RECORDS_FEATURE_STATE_NAME } from './study-change-records.state';

export const SELECT_STUDY_CHANGE_RECORDS = createFeatureSelector<StudyChangeRecordsState> ( STUDY_CHANGE_RECORDS_FEATURE_STATE_NAME );

export const SELECT_STUDY_CHANGE_RECORDS_LOADING  = createSelector ( SELECT_STUDY_CHANGE_RECORDS, ( state: StudyChangeRecordsState ) => state.loading );
export const SELECT_STUDY_CHANGE_RECORDS_START = createSelector ( SELECT_STUDY_CHANGE_RECORDS, ( state: StudyChangeRecordsState ) => state.start );
export const SELECT_STUDY_CHANGE_RECORDS_END = createSelector ( SELECT_STUDY_CHANGE_RECORDS, ( state: StudyChangeRecordsState ) => state.end );

export const SELECT_STUDY_CHANGE_RECORDS_RECORDS = createSelector ( SELECT_STUDY_CHANGE_RECORDS, ( state: StudyChangeRecordsState ) => state.records );
