import * as moment from "moment";
import { ChangeOperation, ChangeOperationUtils } from "./ChangeOperation";

export class ChangeRecord
{
    public static clone( record: ChangeRecord ): ChangeRecord
    {
        return new ChangeRecord ( 
            record.id,
            record.operation,
            record.user,
            record.timestamp,
            record.siteId,
            record.siteMetaId,
            record.siteStudyId,
            record.participantId,
            record.participantMetaId,
            record.participantStudyId,
            record.collectionPointId,
            record.collectionPointValueId,
            record.formId,
            record.formValueId,
            record.sectionId,
            record.sectionValueId,
            record.change,
        );
    }

    public static fromJson( json: any ): ChangeRecord
    {
        let out = new ChangeRecord ( 
            json.id as number,
            json.operation ? ChangeOperationUtils.fromString ( json.operation ) : null,
            json.user as string,
            "timestamp" in json && json [ "timestamp" ] ? moment ( json.timestamp as string ) : null,
            json.site_id as number,
            json.site_meta_id as number,
            json.site_study_id as number,
            json.participant_id as number,
            json.participant_meta_id as number,
            json.participant_study_id as number,
            json.collection_point_id as number,
            json.collection_point_value_id as number,
            json.form_id as number,
            json.form_value_id as number,
            json.section_id as number,
            json.section_value_id as number,
            JSON.parse(json.change as string),
        );

        return out;
    }

    public constructor ( 
        public readonly id: number,
        public readonly operation: ChangeOperation | null,
        public readonly user: string,
        public readonly timestamp: moment.Moment | null,
        public readonly siteId: number,
        public readonly siteMetaId: number,
        public readonly siteStudyId: number,
        public readonly participantId: number,
        public readonly participantMetaId: number,
        public readonly participantStudyId: number,
        public readonly collectionPointId: number,
        public readonly collectionPointValueId: number,
        public readonly formId: number,
        public readonly formValueId: number,
        public readonly sectionId: number,
        public readonly sectionValueId: number,
        public readonly change: any,
    )
    {
        // Null.
    }
}
