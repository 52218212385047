import { Role } from "./Role";

export class RoleHierarchy
{
    public static toJson( role: RoleHierarchy ): any
    {
        let out: any = {};

        out.app_user = role.AppUser ? Role.toJson ( role.AppUser ) : null;
        out.sys_admin = role.SysAdmin ? Role.toJson ( role.SysAdmin ) : null;
        out.sys_user = role.SysUser ? Role.toJson ( role.SysUser ) : null;
        out.org_admin = role.OrgAdmin ? Role.toJson ( role.OrgAdmin ) : null;
        out.org_user = role.OrgUser ? Role.toJson ( role.OrgUser ) : null;
        out.org_guest = role.OrgGuest ? Role.toJson ( role.OrgGuest ) : null;
        out.site_admin = role.SiteAdmin ? Role.toJson ( role.SiteAdmin ) : null;
        out.site_user = role.SiteUser ? Role.toJson ( role.SiteUser ) : null;
        out.participant = role.Participant ? Role.toJson ( role.Participant ) : null;
        
        return out;
    }

    public static fromJson( json: any ): RoleHierarchy
    {
        let out = new RoleHierarchy ( );

        out.AppUser = json.app_user ? Role.fromJson ( json.app_user ) : null;
        out.SysAdmin = json.sys_admin ? Role.fromJson ( json.sys_admin ) : null;
        out.SysUser = json.sys_user ? Role.fromJson ( json.sys_user ) : null;
        out.OrgAdmin = json.org_admin ? Role.fromJson ( json.org_admin ) : null;
        out.OrgUser = json.org_user ? Role.fromJson ( json.org_user ) : null;
        out.OrgGuest = json.org_guest ? Role.fromJson ( json.org_guest ) : null;
        out.SiteAdmin = json.site_admin ? Role.fromJson ( json.site_admin ) : null;
        out.SiteUser = json.site_user ? Role.fromJson ( json.site_user ) : null;
        out.Participant = json.participant ? Role.fromJson ( json.participant ) : null;

        return out;
    }

    public constructor( private appUser: Role | null = null,
                        private sysAdmin: Role | null = null,
                        private sysUser: Role | null = null,
                        private orgAdmin: Role | null = null,
                        private orgUser: Role | null = null,
                        private orgGuest: Role | null = null,
                        private siteAdmin: Role | null = null,
                        private siteUser: Role | null = null,
                        private participant: Role | null = null )
    {
        // Null.
    }

    public get AppUser( ): Role | null
    {
        return this.appUser;
    }

    public set AppUser( appUser: Role | null )
    {
        this.appUser = appUser;
    }

    public get SysAdmin( ): Role | null
    {
        return this.sysAdmin;
    }

    public set SysAdmin( sysAdmin: Role | null )
    {
        this.sysAdmin = sysAdmin;
    }

    public get SysUser( ): Role | null
    {
        return this.sysUser;
    }

    public set SysUser( sysUser: Role | null )
    {
        this.sysUser = sysUser;
    }

    public get OrgAdmin( ): Role | null
    {
        return this.orgAdmin;
    }

    public set OrgAdmin( orgAdmin: Role | null )
    {
        this.orgAdmin = orgAdmin;
    }

    public get OrgUser( ): Role | null
    {
        return this.orgUser;
    }

    public set OrgUser( orgUser: Role | null )
    {
        this.orgUser = orgUser;
    }

    public get OrgGuest( ): Role | null
    {
        return this.orgGuest;
    }

    public set OrgGuest( orgGuest: Role | null )
    {
        this.orgGuest = orgGuest;
    }

    public get SiteAdmin( ): Role | null
    {
        return this.siteAdmin;
    }

    public set SiteAdmin( siteAdmin: Role | null )
    {
        this.siteAdmin = siteAdmin;
    }

    public get SiteUser( ): Role | null
    {
        return this.siteUser;
    }

    public set SiteUser( siteUser: Role | null )
    {
        this.siteUser = siteUser;
    }

    public get Participant( ): Role | null
    {
        return this.participant;
    }

    public set Participant( participant: Role | null )
    {
        this.participant = participant;
    }

    public getRoleById ( id: string | null ) : Role | null
    {
        if ( id == null )
        {
            return null;
        }
        else if ( this.AppUser?.Id == id )
        {
            return this.AppUser;
        }
        else if ( this.OrgAdmin?.Id == id )
        {
            return this.OrgAdmin;
        }
        else if ( this.OrgUser?.Id == id )
        {
            return this.OrgUser;
        }
        else if ( this.OrgGuest?.Id == id )
        {
            return this.OrgGuest;
        }
        else if ( this.Participant?.Id == id )
        {
            return this.Participant;
        }
        else if ( this.SiteAdmin?.Id == id )
        {
            return this.SiteAdmin;
        }
        else if ( this.SiteUser?.Id == id )
        {
            return this.SiteUser;
        }
        else if ( this.SysAdmin?.Id == id )
        {
            return this.SysAdmin;
        }
        else if ( this.SysUser?.Id == id )
        {
            return this.SysUser;
        }
        else
        {
            return null;
        }
    }
}
