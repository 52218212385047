import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { SubjectType } from '../../../model/published/SubjectType';
import { DocumentTemplate } from '../../../model/study/documents/DocumentTemplate';


export const ON_DOCUMENT_TEMPLATES_LIST       = createAction ( 'shared.study-documents.list', props<{ op: string, force: boolean }> ( ) );


export const GET_DOCUMENT_TEMPLATES         = createAction ( 'shared.study-documents.get', props<{ op: string }> ( ) );
export const GET_DOCUMENT_TEMPLATES_SUCCESS = createAction ( 'shared.study-documents.get.success', props<{ op: string, templates: DocumentTemplate [ ] }> ( ) );
export const GET_DOCUMENT_TEMPLATES_FAILURE = createAction ( 'shared.study-documents.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const CREATE_DOCUMENT_TEMPLATE         = createAction ( 'shared.study-documents.create', props<{ op: string, subjectType: SubjectType, metaId: number, documentId: number, file: File }> ( ) );
export const CREATE_DOCUMENT_TEMPLATE_SUCCESS = createAction ( 'shared.study-documents.create.success', props<{ op: string, template: DocumentTemplate }> ( ) );
export const CREATE_DOCUMENT_TEMPLATE_FAILURE = createAction ( 'shared.study-documents.create.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_DOCUMENT_TEMPLATE         = createAction ( 'shared.study-documents.update', props<{ op: string, template: DocumentTemplate, file: File }> ( ) );
export const UPDATE_DOCUMENT_TEMPLATE_SUCCESS = createAction ( 'shared.study-documents.update.success', props<{ op: string, template: DocumentTemplate }> ( ) );
export const UPDATE_DOCUMENT_TEMPLATE_FAILURE = createAction ( 'shared.study-documents.update.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const DELETE_DOCUMENT_TEMPLATE         = createAction ( 'shared.study-documents.delete', props<{ op: string, template: DocumentTemplate }> ( ) );
export const DELETE_DOCUMENT_TEMPLATE_SUCCESS = createAction ( 'shared.study-documents.delete.success', props<{ op: string, template: DocumentTemplate }> ( ) );
export const DELETE_DOCUMENT_TEMPLATE_FAILURE = createAction ( 'shared.study-documents.delete.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const DOWNLOAD_DOCUMENT_TEMPLATE       = createAction ( 'shared.study-documents.download-template', props<{ op: string, template: DocumentTemplate }> ( ) );
