import { ErrorType } from './ErrorType';

export class ErrorDetails
{
    public static fromError( error: any, type: ErrorType = ErrorType.NONE ): ErrorDetails
    {
        if ( error instanceof ErrorDetails )
        {
            return error;
        }
        else
        {
            return new ErrorDetails ( type, error );
        }
    }

    public static fromString( type: ErrorType, message: string ): ErrorDetails
    {
        return new ErrorDetails ( type, message );
    }

    public constructor( private type: ErrorType = ErrorType.NONE, private message: string | null = null )
    {
        // Null.
    }

    public get Type( ): ErrorType
    {
        return this.type;
    }

    public get Message( ): string | null
    {
        return this.message;
    }

    public set Message( message: string | null )
    {
        this.message = message;
    }
}
