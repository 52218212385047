import { createReducer, on } from '@ngrx/store';
import { GET_PUBLISHED_PAGES_LANDING, GET_PUBLISHED_PAGES_LANDING_FAILURE, GET_PUBLISHED_PAGES_LANDING_SUCCESS, GET_PUBLISHED_PAGES_SIDE_BAR, GET_PUBLISHED_PAGES_SIDE_BAR_FAILURE, GET_PUBLISHED_PAGES_SIDE_BAR_SUCCESS, GET_PUBLISHED_PAGES_SITES, GET_PUBLISHED_PAGES_SITES_FAILURE, GET_PUBLISHED_PAGES_SITES_SUCCESS } from './published-pages.actions';
import { PublishedPagesState } from './published-pages.state';

export const INITIAL_STATE = new PublishedPagesState ( );

const _PUBLISHED_PAGES_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_PUBLISHED_PAGES_LANDING, ( state, { op } ) => {
      const newState = { ...state };

      newState.landingPageLoading = true;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_LANDING_SUCCESS, ( state, { op, landingPage } ) => {
      const newState = { ...state };

      newState.landingPage = landingPage;
      newState.landingPageLoading = false;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_LANDING_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.landingPage = null;
      newState.landingPageLoading = false;

      return newState;
    } ),


    on ( GET_PUBLISHED_PAGES_SITES, ( state, { op } ) => {
      const newState = { ...state };

      newState.sitesPageLoading = true;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_SITES_SUCCESS, ( state, { op, sitesPage } ) => {
      const newState = { ...state };

      newState.sitesPage = sitesPage;
      newState.sitesPageLoading = false;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_SITES_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sitesPage = null;
      newState.sitesPageLoading = false;

      return newState;
    } ),


    on ( GET_PUBLISHED_PAGES_SIDE_BAR, ( state, { op } ) => {
      const newState = { ...state };

      newState.sideBarLoading = true;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_SIDE_BAR_SUCCESS, ( state, { op, sideBar } ) => {
      const newState = { ...state };

      newState.sideBar = sideBar;
      newState.sideBarLoading = false;

      return newState;
    } ),
    on ( GET_PUBLISHED_PAGES_SIDE_BAR_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sideBar = null;
      newState.sideBarLoading = false;

      return newState;
    } ),

);

export function PUBLISHED_PAGES_REDUCER( state: any, action: any )
{
  return _PUBLISHED_PAGES_REDUCER ( state, action );
}
