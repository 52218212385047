import { SubjectType } from "../../published/SubjectType";
import { CollectionPointValue } from "../CollectionPointValue";
import { SubjectValue } from "../SubjectValue";
import { ParticipantId } from "./ParticipantId";

export class Participant extends SubjectValue
{
    public static clone( participant: Participant ): Participant
    {
        let out = new Participant ( );

        out = SubjectValue._clone ( participant, out );
        out.SiteId = participant.SiteId;
        out.Consent = participant.Consent;

        if ( participant.ParticipantId )
        {
            out.ParticipantId = ParticipantId.clone ( participant.ParticipantId );   
        }

        return out;
    }

    public static toJson( participant: Participant ): any
    {
        let out: any = {};

        out = SubjectValue._toJson ( participant, out );

        out.site_id = participant.SiteId;
        out.consent = participant.Consent;
        out.participant_id = participant.ParticipantId ? ParticipantId.toJson ( participant.ParticipantId ) : null;

        return out;
    }

    public static fromJson( json: any ): Participant
    {
        let out = new Participant ( );

        out = SubjectValue._fromJson ( json, out );
    
        out.SiteId = json.site_id;
        out.Consent = json.consent;
        out.ParticipantId = json.participant_id ? ParticipantId.fromJson ( json.participant_id ) : null;
        
        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null,
                        creationTime: moment.Moment | null = null,
                        state: string | null = null,
                        externalId: string | null = null,
                        registrationCollectionPointValue: CollectionPointValue | null = null,
                        baselineCollectionPointValue: CollectionPointValue | null = null,
                        intervalCollectionPointValues: Array<CollectionPointValue> | null = null,
                        adhocCollectionPointValues: Array<CollectionPointValue> | null = null,
                        completionCollectionPointValue: CollectionPointValue | null = null,
                        private siteId: number | null = null,
                        private participantId: ParticipantId | null = null,
                        private consent: string | null = null, )
    {
        super ( id, version, SubjectType.PARTICIPANT, metaId, creationTime, state, externalId, registrationCollectionPointValue, baselineCollectionPointValue, intervalCollectionPointValues, adhocCollectionPointValues, completionCollectionPointValue );
    }

    public get SiteId( ): number | null
    {
        return this.siteId;
    }

    public set SiteId( siteId: number | null )
    {
        this.siteId = siteId;
    }

    public get ParticipantId( ): ParticipantId | null
    {
        return this.participantId;
    }

    public set ParticipantId( participantId: ParticipantId | null )
    {
        this.participantId = participantId;
    }

    public get Consent( ): string | null
    {
        return this.consent;
    }

    public set Consent( consent: string | null )
    {
        this.consent = consent;
    }
}
