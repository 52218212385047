import { Type } from '@angular/core';


type StringFormatter = ( site: string | null, participantType: string | null, participant: string | null, form: string | null ) => string;

export class SectionDetails
{
    public constructor( private title: StringFormatter,
                        private description: StringFormatter | null = null,
                        private sites: boolean | null = null,
                        private participants: boolean | null = null,
                        private toolbar: Type<any> | null = null )
    {
        // Null.
    }

    public get Title( ): StringFormatter
    {
        return this.title;
    }

    public set Title( title: StringFormatter )
    {
        this.title = title;
    }

    public get Description( ): StringFormatter| null
    {
        return this.description;
    }

    public set Description( description: StringFormatter | null )
    {
        this.description = description;
    }

    public get Sites( ): boolean | null
    {
        return this.sites;
    }

    public set Sites( sites: boolean | null )
    {
        this.sites = sites;
    }

    public get Participants( ): boolean | null
    {
        return this.participants;
    }

    public set Participants( participants: boolean | null )
    {
        this.participants = participants;
    }

    public get Toolbar( ): Type<any> | null
    {
        return this.toolbar;
    }

    public set Toolbar( toolbar: Type<any> | null )
    {
        this.toolbar = toolbar;
    }
}
