import { createReducer, on } from '@ngrx/store';
import { GET_PUBLISHED_SITES, GET_PUBLISHED_SITES_FAILURE, GET_PUBLISHED_SITES_SUCCESS } from './published-sites.actions';
import { PublishedSitesState } from './published-sites.state';
import { SiteMeta } from '../../../model/published/sites/SiteMeta';

export const INITIAL_STATE = new PublishedSitesState ( );

const _PUBLISHED_SITES_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_PUBLISHED_SITES, ( state, { op, ids } ) => {
      const newState = { ...state };

      newState.sitesLoading = true;

      return newState;
    } ),
    on ( GET_PUBLISHED_SITES_SUCCESS, ( state, { op, sites } ) => {
      const newState = { ...state };

      newState.sites = new Map<number, SiteMeta> ( );
      for ( let site of sites ? sites : [] )
      {
        if ( site.Id )
        {
          newState.sites.set ( site.Id, site );
        }
      }
      newState.sitesLoading = false;

      return newState;
    } ),
    on ( GET_PUBLISHED_SITES_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.sites = new Map<number, SiteMeta> ( );
      newState.sitesLoading = false;

      return newState;
    } ),

);

export function PUBLISHED_SITES_REDUCER( state: any, action: any )
{
  return _PUBLISHED_SITES_REDUCER ( state, action );
}
