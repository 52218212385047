<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <!-- Enumerations -->
    <div class="form-group">
        <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="" formControlName="value" [ngClass]="{ 'is-invalid': form.controls.value.errors }">
        <div class="invalid-feedback">
            <ng-container *ngIf="ValueFormControl?.errors?.['required']">
                Value is required
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['min']">
                Value is below the minimum allowed of {{meta?.Minimum}}
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['max']">
                Value is above the maximum allowed of {{meta?.Maximum}}
            </ng-container>
            <ng-container *ngIf="ValueFormControl?.errors?.['validityExpression']">
                {{ValueFormControl?.errors?.['validityExpression']}}
            </ng-container>
        </div>
    </div>

</form>