import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { GET_PUBLISHED_PARTICIPANTS, GET_PUBLISHED_PARTICIPANTS_FAILURE, GET_PUBLISHED_PARTICIPANTS_SUCCESS, ON_PUBLISHED_PARTICIPANTS_LIST, ON_PUBLISHED_PARTICIPANTS_SINGLE } from './published-participants.actions';
import { SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANTS } from './published-participants.selectors';
import { ParticipantMeta } from '../../../model/published/participants/ParticipantMeta';

@Injectable ( )
export class PublishedParticipantsEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_PUBLISHED_PARTICIPANTS_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_PARTICIPANTS_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANTS ) ),
        mergeMap ( ([action, participants]) => {
            if ( action.force || participants == null || participants.size == 0 )
            {
                return [ GET_PUBLISHED_PARTICIPANTS ( { op: 'list', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_PUBLISHED_PARTICIPANTS_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PUBLISHED_PARTICIPANTS_SINGLE ),
        concatLatestFrom ( () => this.store.select ( SELECT_PUBLISHED_PARTICIPANTS_PARTICIPANTS ) ),
        mergeMap ( ([action, participants]) => {
            if ( action.force || participants == null || participants.size == 0 )
            {
                return [ GET_PUBLISHED_PARTICIPANTS ( { op: 'single', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PUBLISHED_PARTICIPANTS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PUBLISHED_PARTICIPANTS ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.published_api}/participants/`, { params } ) ),
            map ( response => {
                const participants = new Array<ParticipantMeta> ( );
                for ( const json of response )
                {
                    const participant = ParticipantMeta.fromJson ( json );
                    if ( participant )
                    {
                        participants.push ( participant );
                    }
                }
                return participants;
            } ),
            map ( participants => GET_PUBLISHED_PARTICIPANTS_SUCCESS ( { op: action.op, participants } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PUBLISHED_PARTICIPANTS_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );
}
