
export class Group
{
    public static readonly SYSTEM_GROUP_NAME = "System";

    public static readonly SYSTEM_GROUP_PATH = "/System";

    public static readonly ORGANISATION_GROUP_NAME = "Organisation";

    public static readonly ORGANISATION_GROUP_PATH = "/System/Organisation";

    public static readonly SITES_GROUP_NAME = "Sites";

    public static readonly SITES_GROUP_PATH = "/System/Organisation/Sites";

    public static readonly PARTICIPANTS_GROUP_NAME = "Participants";

    public static readonly PARTICIPANTS_GROUP_PATH = "/System/Organisation/Participants";

    public static toJson( group: Group ): any
    {
        let out: any = {};

        out.id = group.Id;
        out.name = group.Name;
        out.path = group.Path;
        
        return out;
    }

    public static fromJson( json: any ): Group
    {
        let out = new Group ( );

        out.Id = json.id as string;
        out.Name = json.name as string;
        out.Path = json.path as string;

        return out;
    }

    public constructor( private id: string | null = null,
                        private name: string | null = null,
                        private path: string | null = null )
    {
        // Null.
    }

    public get Id( ): string | null
    {
        return this.id;
    }

    public set Id( id: string | null )
    {
        this.id = id;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Path( ): string | null
    {
        return this.path;
    }

    public set Path( path: string | null )
    {
        this.path = path;
    }
}
