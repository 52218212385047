

export enum QuestionType
{
    NONE = 1,

    BOOLEAN = 2,

    NUMBER_RANGE = 3

}

export abstract class QuestionTypeUtils
{
    public static readonly BOOLEAN_LABEL = "Yes / No";

    public static readonly NUMBER_RANGE_LABEL = "Number Range";

    public static get Labels ( ) : string [ ]
    {
        return [ this.BOOLEAN_LABEL, this.NUMBER_RANGE_LABEL ];
    }

    public static toLabel ( type: QuestionType ): string | null
    {
        switch ( type )
        {
        case QuestionType.BOOLEAN:
            return this.BOOLEAN_LABEL;
        case QuestionType.NUMBER_RANGE:
            return this.NUMBER_RANGE_LABEL;
        default:
            return null;
        }
    }

    public static fromLabel ( label: string ): QuestionType
    {
        switch ( label )
        {
        case this.BOOLEAN_LABEL:
            return QuestionType.BOOLEAN;
        case this.NUMBER_RANGE_LABEL:
            return QuestionType.NUMBER_RANGE;
        default:
            return QuestionType.NONE;
        }
    }

    public static fromString( value: string ): QuestionType
    {
        return Object.entries(QuestionType).find(([key, val]) => key === value)?.[1] as QuestionType;
    }

}
