import { Expression } from './expressions/Expression';
import { Field } from './Field';
import { FieldType } from './FieldType';


export class Tag
{
    public static clone( input: Tag ): Tag
    {
        return new Tag ( input.Id, input.Version, input.Name, input.Description, input.Variable, input.MinCount, input.MaxCount,
                         input.LabelColourExpression, input.ActiveExpression, input.EligibilityExpression );
    }

    public static toJson( tag: Tag ): any
    {
        let out: any = {};

        out.id = tag.Id;
        out.version = tag.Version;
        out.name = tag.Name;
        out.description = tag.Description;
        out.min_count = tag.MinCount;
        out.max_count = tag.MaxCount;
        out.variable = tag.Variable;
        out.label_colour_expression = tag.LabelColourExpression ? Expression.toJson ( tag.LabelColourExpression ) : null;
        out.active_expression = tag.ActiveExpression ? Expression.toJson ( tag.ActiveExpression ) : null;
        out.eligibility_expression = tag.EligibilityExpression ? Expression.toJson ( tag.EligibilityExpression ) : null;

        
        return out;
    }

    public static fromJson( json: any ): Tag
    {
        let out = new Tag ( );

        out.Id = json.id as number;
        out.Version = json.version as number;
        out.Name = json.name as string;
        out.Description = json.description as string;
        out.Variable = json.variable as string;
        out.MinCount = json.min_count as number;
        out.MaxCount = json.max_count as number;
        out.LabelColourExpression = "label_colour_expression" in json && json.label_colour_expression ? Expression.fromJson ( json.label_colour_expression ) : null;
        out.ActiveExpression = "active_expression" in json && json.active_expression ? Expression.fromJson ( json.active_expression ) : null;
        out.EligibilityExpression = "eligibility_expression" in json && json.eligibility_expression ? Expression.fromJson ( json.eligibility_expression ) : null;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null = null,
                        private name: string | null = null,
                        private description: string | null = null,
                        private variable: string | null = null,
                        private minCount: number | null = null,
                        private maxCount: number | null = null,
                        private labelColourExpression: Expression | null = null,
                        private activeExpression: Expression | null = null,
                        private eligibilityExpression: Expression | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get MinCount( ): number | null
    {
        return this.minCount;
    }

    public set MinCount( minCount: number | null )
    {
        this.minCount = minCount;
    }

    public get MaxCount( ): number | null
    {
        return this.maxCount;
    }

    public set MaxCount( maxCount: number | null )
    {
        this.maxCount = maxCount;
    }

    public get LabelColourExpression( ): Expression | null
    {
        return this.labelColourExpression;
    }

    public set LabelColourExpression( labelColourExpression: Expression | null )
    {
        this.labelColourExpression = labelColourExpression;
    }

    public get ActiveExpression( ): Expression | null
    {
        return this.activeExpression;
    }

    public set ActiveExpression( activeExpression: Expression | null )
    {
        this.activeExpression = activeExpression;
    }

    public get EligibilityExpression( ): Expression | null
    {
        return this.eligibilityExpression;
    }

    public set EligibilityExpression( eligibilityExpression: Expression | null )
    {
        this.eligibilityExpression = eligibilityExpression;
    }
}
