import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorDetails } from './ErrorDetails';
import { SELECT_ERRORS_LAST_ERROR } from './errors.selectors';

@Injectable ( {
  providedIn: 'root'
} )
export class ErrorsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  getLastError( ): Observable<ErrorDetails | null>
  {
    return this.store.pipe ( select ( SELECT_ERRORS_LAST_ERROR ) );
  }
}
