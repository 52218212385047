import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ON_CHANGE_RECORDS_LIST } from '../../state/study/change-records/study-change-records.actions';
import { SELECT_STUDY_CHANGE_RECORDS_END, SELECT_STUDY_CHANGE_RECORDS_LOADING, SELECT_STUDY_CHANGE_RECORDS_RECORDS, SELECT_STUDY_CHANGE_RECORDS_START } from '../../state/study/change-records/study-change-records.selectors';
import { ChangeRecord } from '../../model/study/ChangeRecord';


@Injectable ( {
  providedIn: 'root'
} )
export class StudyChangeRecordsService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onList( start: moment.Moment, end: moment.Moment, force: boolean = false )
  {
    this.store.dispatch ( ON_CHANGE_RECORDS_LIST ( { start, end, force } ) );
  }

  getLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_CHANGE_RECORDS_LOADING ) 
    );
  }

  getStart( ): Observable<moment.Moment | null>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_CHANGE_RECORDS_START ) 
    );
  }

  getEnd( ): Observable<moment.Moment | null>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_CHANGE_RECORDS_END ) 
    );
  }

  getRecords( ): Observable<Array<ChangeRecord>>
  {
    return this.store.pipe ( 
      select ( SELECT_STUDY_CHANGE_RECORDS_RECORDS )
    );
  }
}
