import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PublishedPagesState, PUBLISHED_PAGES_FEATURE_STATE_NAME } from './published-pages.state';

export const SELECT_PUBLISHED_PAGES = createFeatureSelector<PublishedPagesState> ( PUBLISHED_PAGES_FEATURE_STATE_NAME );

export const SELECT_PUBLISHED_PAGES_LANDING_LOADING  = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.landingPageLoading );
export const SELECT_PUBLISHED_PAGES_LANDING_PAGE = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.landingPage );

export const SELECT_PUBLISHED_PAGES_SITES_LOADING  = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.sitesPageLoading );
export const SELECT_PUBLISHED_PAGES_SITES_PAGE = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.sitesPage );

export const SELECT_PUBLISHED_PAGES_SIDE_BAR_LOADING  = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.sideBarLoading );
export const SELECT_PUBLISHED_PAGES_SIDE_BAR = createSelector ( SELECT_PUBLISHED_PAGES, ( state: PublishedPagesState ) => state.sideBar );
