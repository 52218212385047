import { SubjectType } from "../../published/SubjectType";
import { CollectionPointValue } from "../CollectionPointValue";
import { FormValue } from "../FormValue";
import { SubjectValue } from "../SubjectValue";
import { SiteId } from "./SiteId";

export class Site extends SubjectValue
{
    public static clone( site: Site ): Site
    {
        let out = new Site ( );

        out = SubjectValue._clone ( site, out );
        out.GroupId = site.GroupId;
        out.Allocation = site.Allocation;

        if ( site.SiteId )
        {
            out.SiteId = SiteId.clone ( site.SiteId );   
        }

        return out;
    }

    public static toJson( site: Site ): any
    {
        let out: any = {};

        out = SubjectValue._toJson ( site, out );

        out.group_id = site.GroupId;
        out.allocation = site.Allocation;
        out.site_id = site.SiteId ? SiteId.toJson ( site.SiteId ) : null;

        return out;
    }

    public static fromJson( json: any ): Site
    {
        let out = new Site ( );

        out = SubjectValue._fromJson ( json, out );
    
        out.GroupId = json.group_id;
        out.Allocation = json.allocation;
        out.SiteId = json.site_id ? SiteId.fromJson ( json.site_id ) : null;
        
        return out;
    }

    public constructor( id: number | null = null,
                        version: number | null = null,
                        metaId: number | null = null,
                        creationTime: moment.Moment | null = null,
                        state: string | null = null,
                        private groupId: string | null = null,
                        externalId: string | null = null,
                        private allocation: string | null = null,
                        registrationCollectionPointValue: CollectionPointValue | null = null,
                        baselineCollectionPointValue: CollectionPointValue | null = null,
                        intervalCollectionPointValues: Array<CollectionPointValue> | null = null,
                        adhocCollectionPointValues: Array<CollectionPointValue> | null = null,
                        completionCollectionPointValue: CollectionPointValue | null = null,
                        private siteId: SiteId | null = null )
    {
        super ( id, version, SubjectType.SITE, metaId, creationTime, state, externalId, registrationCollectionPointValue, baselineCollectionPointValue, intervalCollectionPointValues, adhocCollectionPointValues, completionCollectionPointValue );
    }

    public get GroupId( ): string | null
    {
        return this.groupId;
    }

    public set GroupId( groupId: string | null )
    {
        this.groupId = groupId;
    }

    public get Allocation( ): string | null
    {
        return this.allocation;
    }

    public set Allocation( allocation: string | null )
    {
        this.allocation = allocation;
    }

    public get SiteId( ): SiteId | null
    {
        return this.siteId;
    }

    public set SiteId( siteId: SiteId | null )
    {
        this.siteId = siteId;
    }
}
