import { ExpressionDataType, ExpressionDataTypeUtils } from "./ExpressionDataType";

export class Argument
{
    public static clone( input: Argument ): Argument
    {
        return new Argument ( input.Id, input.Version, input.Seq, input.Name, input.Type, input.Variable );
    }

    public static toJson( arg: Argument ): any
    {
        let out: any = {};

        out.id = arg.Id;
        out.version = arg.Version;
        out.seq = arg.Seq;
        out.name = arg.Name;
        out.type = ExpressionDataTypeUtils.toString ( arg.Type );
        out.variable = arg.Variable;
        
        return out;
    }

    public static fromJson( json: any ): Argument
    {
        return new Argument ( 
            json.id,
            json.version,
            json.seq,
            json.name,
            ExpressionDataTypeUtils.fromString ( json.type ),
            json.variable
        );
    }

    public constructor ( private id: number | null = null,
                         private version: number | null = null,
                         private seq: number | null = null,
                         private name: string,
                         private type: ExpressionDataType,
                         private variable: string )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Name( ): string
    {
        return this.name;
    }

    public set Name( name: string )
    {
        this.name = name;
    }

    public get Type( ): ExpressionDataType
    {
        return this.type;
    }

    public set Type( type: ExpressionDataType )
    {
        this.type = type;
    }

    public get Variable( ): string
    {
        return this.variable;
    }

    public set Variable( variable: string )
    {
        this.variable = variable;
    }
}
