
export class SitesPage
{
    public static toJson ( sitesPage: SitesPage ): any
    {
        let out: any = {};

        out.id = sitesPage.Id;
        out.version = sitesPage.Version;
        out.main_title = sitesPage.Title;
        out.main_description = sitesPage.Description;
        out.preamble = sitesPage.Preamble;
        out.hide_site_type = sitesPage.HideSiteType;
        out.hide_site_state = sitesPage.HideSiteState;
        out.hide_site_allocation = sitesPage.HideSiteAllocation;

        return out;
    }

    public static fromJson( json: any ): SitesPage
    {
        let out = new SitesPage ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Title = json.title;
        out.Description = json.description;
        out.Preamble = json.preamble;
        out.HideSiteType = json.hide_site_type;
        out.HideSiteState = json.hide_site_state;
        out.HideSiteAllocation = json.hide_site_allocation;
        
        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private title: string | null  = null, 
                        private description: string | null  = null, 
                        private preamble: string | null = null,
                        private hideSiteType: boolean | null = null,
                        private hideSiteState: boolean | null = null,
                        private hideSiteAllocation: boolean | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Title( ): string | null
    {
        return this.title;
    }

    public set Title( title: string | null )
    {
        this.title = title;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get Preamble( ): string | null
    {
        return this.preamble;
    }

    public set Preamble( preamble: string | null )
    {
        this.preamble = preamble;
    }

    public get HideSiteType( ): boolean | null
    {
        return this.hideSiteType;
    }

    public set HideSiteType( hideSiteType: boolean | null )
    {
        this.hideSiteType = hideSiteType;
    }

    public get HideSiteState( ): boolean | null
    {
        return this.hideSiteState;
    }

    public set HideSiteState( hideSiteState: boolean | null )
    {
        this.hideSiteState = hideSiteState;
    }

    public get HideSiteAllocation( ): boolean | null
    {
        return this.hideSiteAllocation;
    }

    public set HideSiteAllocation( hideSiteAllocation: boolean | null )
    {
        this.hideSiteAllocation = hideSiteAllocation;
    }
}
