import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { Participant } from '../../../model/study/participants/Participant';
import { FormValue } from '../../../model/study/FormValue';
import { CollectionPointValue } from '../../../model/study/CollectionPointValue';
import { CollectionPoint } from '../../../model/published/CollectionPoint';
import { FileField } from '../../../model/published/FileField';
import { FileFieldValue } from '../../../model/study/FileFieldValue';
import { DocumentMeta } from '../../../model/published/documents/DocumentMeta';


export const ON_PARTICIPANTS_UNLOAD     = createAction ( 'shared.study-participants.unload', props<{ op: string, siteId: number | null, pType: number }> ( ) );
export const ON_PARTICIPANTS_LIST       = createAction ( 'shared.study-participants.list', props<{ op: string, siteId: number, pType: number, ids: Array<number> | null, force: boolean }> ( ) );
export const ON_PARTICIPANTS_SINGLE     = createAction ( 'shared.study-participants.single', props<{ siteId: number, pType: number, id: number, force: boolean }> ( ) );


export const GET_PARTICIPANTS         = createAction ( 'shared.study-participants.get', props<{ op: string, siteId: number, pType: number, ids: number[] | null }> ( ) );
export const GET_PARTICIPANTS_SUCCESS = createAction ( 'shared.study-participants.get.success', props<{ op: string, siteId: number, pType: number, fullLoad: boolean, participants: Participant [ ] }> ( ) );
export const GET_PARTICIPANTS_FAILURE = createAction ( 'shared.study-participants.get.failure', props<{ op: string, siteId: number, pType: number, errorDetails: ErrorDetails }> ( ) );


export const CREATE_PARTICIPANT         = createAction ( 'shared.study-participants.create', props<{ op: string, participant: Participant }> ( ) );
export const CREATE_PARTICIPANT_SUCCESS = createAction ( 'shared.study-participants.create.success', props<{ op: string, participant: Participant }> ( ) );
export const CREATE_PARTICIPANT_FAILURE = createAction ( 'shared.study-participants.create.failure', props<{ op: string, pType: number | null, siteId: number | null, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_PARTICIPANT         = createAction ( 'shared.study-participants.update', props<{ op: string, participant: Participant }> ( ) );
export const UPDATE_PARTICIPANT_SUCCESS = createAction ( 'shared.study-participants.update.success', props<{ op: string, participant: Participant }> ( ) );
export const UPDATE_PARTICIPANT_FAILURE = createAction ( 'shared.study-participants.update.failure', props<{ op: string, pType: number | null, siteId: number | null, errorDetails: ErrorDetails }> ( ) );


export const DELETE_PARTICIPANT         = createAction ( 'shared.study-participants.delete', props<{ op: string, pType: number, siteId: number, id: number }> ( ) );
export const DELETE_PARTICIPANT_SUCCESS = createAction ( 'shared.study-participants.delete.success', props<{ op: string, pType: number, siteId: number, id: number }> ( ) );
export const DELETE_PARTICIPANT_FAILURE = createAction ( 'shared.study-participants.delete.failure', props<{ op: string, pType: number, siteId: number, errorDetails: ErrorDetails }> ( ) );


export const DELETE_PARTICIPANTS         = createAction ( 'shared.study-participants.delete.all', props<{ op: string, pType: number, siteId: number }> ( ) );
export const DELETE_PARTICIPANTS_SUCCESS = createAction ( 'shared.study-participants.delete.all.success', props<{ op: string, pType: number, siteId: number }> ( ) );
export const DELETE_PARTICIPANTS_FAILURE = createAction ( 'shared.study-participants.delete.all.failure', props<{ op: string, pType: number, siteId: number, errorDetails: ErrorDetails }> ( ) );


export const CREATE_PARTICIPANT_FORM         = createAction ( 'shared.study-participants.create-form', props<{ op: string, participant: Participant, cp: CollectionPoint, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null }> ( ) );
export const CREATE_PARTICIPANT_FORM_SUCCESS = createAction ( 'shared.study-participants.create-form.success', props<{ op: string, participant: Participant, collectionPointValueId: number, formValueId: number, fileUploads: Array<[FileField, FileFieldValue, File]> | null }> ( ) );
export const CREATE_PARTICIPANT_FORM_FAILURE = createAction ( 'shared.study-participants.create-form.failure', props<{ op: string, pType: number | null, siteId: number | null, errorDetails: ErrorDetails }> ( ) );

export const UPDATE_PARTICIPANT_FORM         = createAction ( 'shared.study-participants.update-form', props<{ op: string, participant: Participant, cpv: CollectionPointValue, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null }> ( ) );
export const UPDATE_PARTICIPANT_FORM_SUCCESS = createAction ( 'shared.study-participants.update-form.success', props<{ op: string, participant: Participant, cpv: CollectionPointValue, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null }> ( ) );
export const UPDATE_PARTICIPANT_FORM_FAILURE = createAction ( 'shared.study-participants.update-form.failure', props<{ op: string, pType: number | null, siteId: number | null, errorDetails: ErrorDetails }> ( ) );


export const UPLOAD_PARTICIPANT_FILE         = createAction ( 'shared.study-participants.upload-file', props<{ op: string, participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue, file: File }> ( ) );
export const UPLOAD_PARTICIPANT_FILE_SUCCESS = createAction ( 'shared.study-participants.upload-file.success', props<{ op: string, participant: Participant, field: FileField, fieldValue: FileFieldValue }> ( ) );
export const UPLOAD_PARTICIPANT_FILE_FAILURE = createAction ( 'shared.study-participants.upload-file.failure', props<{ op: string, pType: number | null, siteId: number | null, errorDetails: ErrorDetails }> ( ) );

export const DOWNLOAD_PARTICIPANT_FILE         = createAction ( 'shared.study-participants.download-file', props<{ op: string, participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue }> ( ) );
export const DOWNLOAD_PARTICIPANT_FILE_SUCCESS = createAction ( 'shared.study-participants.download-file.success', props<{ fieldValue: FileFieldValue }> ( ) );
export const DOWNLOAD_PARTICIPANT_FILE_FAILURE = createAction ( 'shared.study-participants.download-file.failure', props<{ op: string, participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue, errorDetails: ErrorDetails }> ( ) );

export const DOWNLOAD_PARTICIPANT_DOCUMENT   = createAction ( 'shared.study-participants.download-document', props<{ op: string, participant: Participant, doc: DocumentMeta }> ( ) );


