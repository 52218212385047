import * as moment from "moment";
import { FormValue } from "./FormValue";

export class CollectionPointValue
{
    public static clone( cp: CollectionPointValue ): CollectionPointValue
    {
        const out = new CollectionPointValue ( );
        out.Id = cp.Id;
        out.MetaId = cp.MetaId;
        out.FormValues = new Array<FormValue> ( );
        for ( const fv of cp.FormValues ? cp.FormValues : [] )
        {
            out.FormValues.push ( FormValue.clone ( fv ) );
        }
        out.TargetTimestamp = cp.TargetTimestamp

        return out;
    }

    public static toJson( cp: CollectionPointValue ): any
    {
        let out: any = {};

        out.id = cp.Id;
        // out.version = site.Version;
        out.meta_id = cp.MetaId;
        out.form_values = new Array<any> ( );
        for ( const form of cp.FormValues ? cp.FormValues : [] )
        {
            out.form_values.push ( FormValue.toJson ( form ) );
        }
        out.target_timestamp = cp.TargetTimestamp ? cp.TargetTimestamp.format ( ) : null;

        return out;
    }

    public static fromJson( json: any ): CollectionPointValue
    {
        let out = new CollectionPointValue ( );

        out.Id = json.id as number;
        out.Version = json.version as number;
        out.MetaId = json.meta_id as number;
        out.FormValues = new Array<FormValue> ( );
        for ( const form of json.form_values ? json.form_values : [] )
        {
            const parsed = FormValue.fromJson ( form );
            if ( parsed != null )
            {
                out.FormValues.push ( parsed );
            }
        }
        out.TargetTimestamp = json.target_timestamp ? moment ( json.target_timestamp as string ) : null;

        return out;
    }

    public constructor( private id: number | null = null,
                        private version: number | null = null,
                        private metaId: number | null = null,
                        private formValues: Array<FormValue> | null = null,
                        private targetTimestamp: moment.Moment | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get FormValues( ): Array<FormValue> | null
    {
        return this.formValues;
    }

    public set FormValues( formValues: Array<FormValue> | null )
    {
        this.formValues = formValues;
    }

    public get TargetTimestamp( ): moment.Moment | null
    {
        return this.targetTimestamp;
    }

    public set TargetTimestamp( targetTimestamp: moment.Moment | null )
    {
        this.targetTimestamp = targetTimestamp;
    }
}
