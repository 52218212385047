
export class ParticipantId
{
    public static clone( participantId: ParticipantId ): ParticipantId
    {
        let out = new ParticipantId ( );

        out.Id = participantId.Id;

        return out;
    }

    public static toJson( participantId: ParticipantId ): any
    {
        let out: any = {};

        out.id = participantId.Id;

        return out;
    }

    public static fromJson( json: any ): ParticipantId
    {
        let out = new ParticipantId ( );
    
        out.Id = json.id;
        
        return out;
    }

    public constructor( private id: number | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }
}
