import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { User } from '../../../model/system/users/User';

export const ON_USERS_LIST       = createAction ( 'shared.system-users.list', props<{ force: boolean }> ( ) );
export const ON_USERS_SINGLE     = createAction ( 'shared.system-users.single', props<{ id: string, force: boolean }> ( ) );


export const GET_USERS         = createAction ( 'shared.system-users.get', props<{ op: string, ids: string[] | null }> ( ) );
export const GET_USERS_SUCCESS = createAction ( 'shared.system-users.get.success', props<{ op: string, users: User [ ] }> ( ) );
export const GET_USERS_FAILURE = createAction ( 'shared.system-users.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const CREATE_USER         = createAction ( 'shared.system-users.create', props<{ op: string, user: User }> ( ) );
export const CREATE_USER_SUCCESS = createAction ( 'shared.system-users.create.success', props<{ op: string, user: User }> ( ) );
export const CREATE_USER_FAILURE = createAction ( 'shared.system-users.create.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const UPDATE_USER         = createAction ( 'shared.system-users.update', props<{ op: string, user: User }> ( ) );
export const UPDATE_USER_SUCCESS = createAction ( 'shared.system-users.update.success', props<{ op: string, user: User }> ( ) );
export const UPDATE_USER_FAILURE = createAction ( 'shared.system-users.update.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const DISABLE_USER         = createAction ( 'shared.system-users.disable', props<{ op: string, user: User }> ( ) );
export const DISABLE_USER_SUCCESS = createAction ( 'shared.system-users.disable.success', props<{ op: string, user: User }> ( ) );
export const DISABLE_USER_FAILURE = createAction ( 'shared.system-users.disable.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const ENABLE_USER         = createAction ( 'shared.system-users.enable', props<{ op: string, user: User }> ( ) );
export const ENABLE_USER_SUCCESS = createAction ( 'shared.system-users.enable.success', props<{ op: string, user: User }> ( ) );
export const ENABLE_USER_FAILURE = createAction ( 'shared.system-users.enable.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const RESET_USER_PASSWORD         = createAction ( 'shared.system-users.reset-password', props<{ op: string, user: User }> ( ) );
export const RESET_USER_PASSWORD_SUCCESS = createAction ( 'shared.system-users.reset-password.success', props<{ op: string, user: User }> ( ) );
export const RESET_USER_PASSWORD_FAILURE = createAction ( 'shared.system-users.reset-password.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );