import { Expression } from '../published/expressions/Expression';
import { CollectionPoint } from './CollectionPoint';
import { DocumentMeta } from './documents/DocumentMeta';
import { Report } from './reports/Report';
import { SubjectType, SubjectTypeUtils } from './SubjectType';
import { Tag } from './Tag';

export abstract class Subject
{
    protected static _toJson<T extends Subject> ( subject: T, output: any ): any
    {
        output.id = subject.Id;
        output.version = subject.Version;
        output.type = SubjectTypeUtils.toString ( subject.Type );
        output.variable = subject.Variable;
        output.name = subject.Name;
        output.description = subject.Description;
        output.label_expression = subject.LabelExpression ? Expression.toJson ( subject.LabelExpression ) : null;
        output.tags = new Array<any> ( );
        for ( const tag of subject.Tags ? subject.Tags : [] )
        {
            output.tags.push ( Tag.toJson ( tag ) );
        }

        output.registration_collection_point = subject.RegistrationCollectionPoint ? CollectionPoint.toJson ( subject.RegistrationCollectionPoint ) : null;
        output.baseline_collection_point = subject.BaselineCollectionPoint ? CollectionPoint.toJson ( subject.BaselineCollectionPoint ) : null;
        output.interval_collection_points = new Array<any> ( );
        for ( const cp of subject.IntervalCollectionPoints ? subject.IntervalCollectionPoints : [] )
        {
            output.interval_collection_points.push ( CollectionPoint.toJson ( cp ) );
        }
        output.adhoc_collection_points = new Array<any> ( );
        for ( const cp of subject.AdHocCollectionPoints ? subject.AdHocCollectionPoints : [] )
        {
            output.adhoc_collection_points.push ( CollectionPoint.toJson ( cp ) );
        }
        output.completion_collection_point = subject.CompletionCollectionPoint ? CollectionPoint.toJson ( subject.CompletionCollectionPoint ) : null;

        output.eligibility_form_name = subject.EligibilityFormName;
        output.eligibility_expression = subject.EligibilityExpression ? Expression.toJson ( subject.EligibilityExpression ) : null;

        output.documents = new Array<any> ( );
        for ( const doc of subject.Documents ? subject.Documents : [] )
        {
            output.documents.push ( DocumentMeta.toJson ( doc ) );
        }

        output.reports = new Array<any> ( );
        for ( const report of subject.Reports ? subject.Reports : [] )
        {
            output.reports.push ( Report.toJson ( report ) );
        }

        return output;
    }

    protected static _fromJson<T extends Subject>( json: any, output: T ): T
    {
        output.Id = json.id;
        output.Version = json.version;
        output.Variable = json.variable;
        output.Name = json.name;
        output.Description = json.description;
        output.LabelExpression = "label_expression" in json && json.label_expression ? Expression.fromJson ( json.label_expression ) : null;
        output.Tags = new Array<Tag> ( );
        for ( const tag of json.tags ? json.tags : [] )
        {
            const parsed = Tag.fromJson ( tag );
            if ( parsed != null )
            {
                output.Tags.push ( parsed );
            }
        }
        
        output.RegistrationCollectionPoint = ("registration_collection_point" in json && json [ "registration_collection_point" ]) ? CollectionPoint.fromJson ( json.registration_collection_point ) : null;
        output.BaselineCollectionPoint = ("baseline_collection_point" in json && json [ "baseline_collection_point" ]) ? CollectionPoint.fromJson ( json.baseline_collection_point ) : null;
        output.IntervalCollectionPoints = new Array<CollectionPoint> ( );
        for ( const cp of json.interval_collection_points ? json.interval_collection_points : [] )
        {
            const parsed = CollectionPoint.fromJson ( cp );
            if ( parsed != null )
            {
                output.IntervalCollectionPoints.push ( parsed );
            }
        }
        output.AdHocCollectionPoints = new Array<CollectionPoint> ( );
        for ( const cp of json.adhoc_collection_points ? json.adhoc_collection_points : [] )
        {
            const parsed = CollectionPoint.fromJson ( cp );
            if ( parsed != null )
            {
                output.AdHocCollectionPoints.push ( parsed );
            }
        }
        output.CompletionCollectionPoint = ("completion_collection_point" in json && json [ "completion_collection_point" ]) ? CollectionPoint.fromJson ( json.completion_collection_point ) : null;

        output.EligibilityFormName = json.eligibility_form_name;
        output.EligibilityExpression = "eligibility_expression" in json && json.eligibility_expression ? Expression.fromJson ( json.eligibility_expression ) : null;

        output.Documents = new Array<DocumentMeta> ( );
        for ( const doc of json.documents ? json.documents : [] )
        {
            const parsed = DocumentMeta.fromJson ( doc );
            if ( parsed != null )
            {
                output.Documents.push ( parsed );
            }
        }

        output.Reports = new Array<Report> ( );
        for ( const report of json.reports ? json.reports : [] )
        {
            const parsed = Report.fromJson ( report );
            if ( parsed != null )
            {
                output.Reports.push ( parsed );
            }
        }

        return output;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private type: SubjectType = SubjectType.NONE,
                        private variable: string | null  = null, 
                        private name: string | null  = null, 
                        private description: string | null = null,
                        private labelExpression: Expression | null = null,
                        private tags: Array<Tag> | null = null,
                        private registrationCollectionPoint: CollectionPoint | null  = null,
                        private baselineCollectionPoint: CollectionPoint | null  = null,
                        private intervalCollectionPoints: Array<CollectionPoint> | null  = null,
                        private adhocCollectionPoints: Array<CollectionPoint> | null  = null,
                        private completionCollectionPoint: CollectionPoint | null  = null,
                        private eligibilityFormName: string | null = null,
                        private eligibilityExpression: Expression | null = null,
                        private documents: Array<DocumentMeta> | null = null,
                        private reports: Array<Report> | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Type( ): SubjectType
    {
        return this.type;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get LabelExpression( ): Expression | null
    {
        return this.labelExpression;
    }

    public set LabelExpression( labelExpression: Expression | null )
    {
        this.labelExpression = labelExpression;
    }
    
    public get Tags( ): Array<Tag> | null
    {
        return this.tags;
    }

    public set Tags( tags: Array<Tag> | null )
    {
        this.tags = tags;
    }

    public get RegistrationCollectionPoint( ): CollectionPoint | null
    {
        return this.registrationCollectionPoint;
    }

    public set RegistrationCollectionPoint( registrationCollectionPoint: CollectionPoint | null )
    {
        this.registrationCollectionPoint = registrationCollectionPoint;
    }

    public get BaselineCollectionPoint( ): CollectionPoint | null
    {
        return this.baselineCollectionPoint;
    }

    public set BaselineCollectionPoint( baselineCollectionPoint: CollectionPoint | null )
    {
        this.baselineCollectionPoint = baselineCollectionPoint;
    }

    public get IntervalCollectionPoints( ): Array<CollectionPoint> | null
    {
        return this.intervalCollectionPoints;
    }

    public set IntervalCollectionPoints( intervalCollectionPoints: Array<CollectionPoint> | null )
    {
        this.intervalCollectionPoints = intervalCollectionPoints;
    }

    public get AdHocCollectionPoints( ): Array<CollectionPoint> | null
    {
        return this.adhocCollectionPoints;
    }

    public set AdHocCollectionPoints( adhocCollectionPoints: Array<CollectionPoint> | null )
    {
        this.adhocCollectionPoints = adhocCollectionPoints;
    }

    public get CompletionCollectionPoint( ): CollectionPoint | null
    {
        return this.completionCollectionPoint;
    }

    public set CompletionCollectionPoint( completionCollectionPoint: CollectionPoint | null )
    {
        this.completionCollectionPoint = completionCollectionPoint;
    }

    public get EligibilityFormName( ): string | null
    {
        return this.eligibilityFormName;
    }

    public set EligibilityFormName( eligibilityFormName: string | null )
    {
        this.eligibilityFormName = eligibilityFormName;
    }

    public get EligibilityExpression( ): Expression | null
    {
        return this.eligibilityExpression;
    }

    public set EligibilityExpression( eligibilityExpression: Expression | null )
    {
        this.eligibilityExpression = eligibilityExpression;
    }

    public get Documents( ): Array<DocumentMeta> | null
    {
        return this.documents;
    }

    public set Documents( documents: Array<DocumentMeta> | null )
    {
        this.documents = documents;
    }

    public get Reports( ): Array<Report> | null
    {
        return this.reports;
    }

    public set Reports( reports: Array<Report> | null )
    {
        this.reports = reports;
    }

    public get AllCollectionPoints ( ) : Array<CollectionPoint>
    {
        let cps = new Array<CollectionPoint> ( );
        if ( this.RegistrationCollectionPoint )
        {
            cps.push ( this.RegistrationCollectionPoint );
        }

        if ( this.BaselineCollectionPoint )
        {
            cps.push ( this.BaselineCollectionPoint );
        }

        for ( const cp of this.IntervalCollectionPoints ? this.IntervalCollectionPoints : [] )
        {
            cps.push ( cp );
        }

        for ( const cp of this.AdHocCollectionPoints ? this.AdHocCollectionPoints : [] )
        {
            cps.push ( cp );
        }

        if ( this.CompletionCollectionPoint )
        {
            cps.push ( this.CompletionCollectionPoint );
        }

        return cps;
    }

    public get TagsMap ( ) : Map<number, Tag>
    {
        const res = new Map<number, Tag> ( );
        for ( const tag of this.Tags ? this.Tags : [] )
        {
            if ( tag.Id != null )
            {
                res.set ( tag.Id, tag );
            }
        }

        return res;
    }
}
