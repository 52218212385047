

export enum FieldType
{
    NONE = 1,

    STRING = 2,

    BOOLEAN = 3,

    DATE = 4,

    ENUMERATED = 5,

    NUMERIC = 6,

    PARTICIPANT = 7,

    REFERENCE = 8,

    FILE = 9,

    LIST = 10

}

export abstract class FieldTypeUtils
{
    public static readonly STRING_LABEL = "Text";

    public static readonly BOOLEAN_LABEL = "Yes / No";

    public static get Labels ( ) : string [ ]
    {
        return [ this.STRING_LABEL, this.BOOLEAN_LABEL ];
    }

    public static toLabel ( type: FieldType ): string | null
    {
        switch ( type )
        {
        case FieldType.STRING:
            return this.STRING_LABEL;
        case FieldType.BOOLEAN:
            return this.BOOLEAN_LABEL;
        default:
            return null;
        }
    }

    public static fromLabel ( label: string ): FieldType
    {
        switch ( label )
        {
        case this.STRING_LABEL:
            return FieldType.STRING;
        case this.BOOLEAN_LABEL:
            return FieldType.BOOLEAN;
        default:
            return FieldType.NONE;
        }
    }

    public static fromString( value: string ): FieldType
    {
        return Object.entries(FieldType).find(([key, val]) => key === value)?.[1] as FieldType;
    }

    public static toString ( value: FieldType ): string
    {
        return Object.entries(FieldType).find(([key, val]) => val === value)?.[0] as string;
    }

}
