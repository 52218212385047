import { Injectable } from '@angular/core';
import { ExpressionEngine } from './ExpressionEngine';
import { LuaExpressionEngine } from './LuaExpressionEngine';

@Injectable ( {
  providedIn: 'root'
} )
export class ExpressionEngineFactory
{

  create ( ) : ExpressionEngine
  {
    return new LuaExpressionEngine ( );
  }
}
