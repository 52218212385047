import { Field } from './Field';
import { FieldType } from './FieldType';


export class NumericField extends Field
{
    public static clone( input: NumericField ): NumericField
    {
        let output = Field._clone ( input, new NumericField ( ) );
        output.Minimum = input.Minimum;
        output.Maximum = input.Maximum;
        output.IsInteger = input.IsInteger;
        return output;
    }

    public static toJson( field: NumericField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.minimum = field.Minimum;
        out.maximum = field.Maximum;
        out.is_integer = field.IsInteger;
        
        return out;
    }

    public static fromJson( json: any ): NumericField
    {
        let out = new NumericField ( );

        out = Field._fromJson ( json, out );

        out.Minimum = json.minimum as number;
        out.Maximum = json.maximum as number;
        out.IsInteger = json.is_integer as boolean;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private minimum: number | null  = null,
                        private maximum: number | null = null,
                        private isInteger: boolean | null = null )
    {
       super ( id, version, seq, FieldType.NUMERIC, variable, name, description, required );
    }

    public get Minimum( ): number | null
    {
        return this.minimum;
    }

    public set Minimum( minimum: number | null )
    {
        this.minimum = minimum;
    }

    public get Maximum( ): number | null
    {
        return this.maximum;
    }

    public set Maximum( maximum: number | null )
    {
        this.maximum = maximum;
    }

    public get IsInteger( ): boolean | null
    {
        return this.isInteger;
    }

    public set IsInteger( isInteger: boolean | null )
    {
        this.isInteger = isInteger;
    }
}
