import * as moment from "moment";
import { Form } from "../published/Form";
import { ParticipantField } from "../published/ParticipantField";
import { Subject } from "../published/Subject";
import { FormValue } from "./FormValue";
import { ParticipantFieldValue } from "./ParticipantFieldValue";
import { SubjectUtils } from "./SubjectUtils";
import { SubjectValue } from "./SubjectValue";


export class FormUtils
{
    // public constructor ( participantGetter: ( type: number, id: number) => [ParticipantMeta, Participant] )
    // {
    //     // Null.
    // }

    getVariableValues ( subjectUtils: SubjectUtils, inType: Form | any, inValue: FormValue | any, prefix: string | null, references: Map<number, [SubjectValue, Subject]> = new Map<number, [SubjectValue, Subject]> ( ) ) : Map<string, boolean | number | string | moment.Moment | null>
    {
        let vals = new Map<string, boolean | number | string | moment.Moment | null> ( );

        let type = <Form>inType;
        let value = inValue as FormValue;

        for ( const section of type.Sections ? type.Sections : [] )
        {
            const sectionValue = value.SectionValues?.find ( sectionValue => sectionValue.MetaId == section.Id );
            if ( sectionValue )
            {
                for ( const field of section.Fields ? section.Fields : [] )
                {
                    const fieldValue = sectionValue.FieldValues?.find ( fieldValue => fieldValue.MetaId == field.Id );
                    if ( fieldValue )
                    {
                        let fqvn = `${type.Variable}.${section.Variable}.${field.Variable}`;
                        if ( prefix )
                        {
                            fqvn = `${prefix}.${fqvn}`;
                        }

                        if ( field instanceof ParticipantField && fieldValue instanceof ParticipantFieldValue )
                        {
                            const participantType = field.ParticipantType;
                            const participantId = fieldValue.Value;
                            if ( participantId != null )
                            {
                                const ref = references.get ( participantId );
                                if ( ref != null && ref[0] != null && ref[1] != null )
                                {
                                    const refVals = subjectUtils.getSubjectVariableValues ( ref[1], ref[0], fqvn );
                                    vals = new Map([...Array.from(vals.entries()), ...Array.from(refVals.entries())]);
                                }
                            }
                        }
                        else
                        {
                            const fieldValues = fieldValue.getValues ( );
                            if ( fieldValues == null || fieldValues.size == 0 )
                            {
                                vals.set ( fqvn, null );
                            }
                            else
                            {
                                for ( const [key, value] of fieldValues )
                                {
                                    if ( key.length == 0 )
                                    {
                                        vals.set ( fqvn, value );
                                    }
                                    else
                                    {
                                        vals.set ( `${fqvn}.${key}`, value );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return vals;
    }
}