import { Site } from "./Site";
import { SiteMeta } from "../../published/sites/SiteMeta";
import { CollectionPointValueFactory } from "../CollectionPointValueFactory";
import { CollectionPointValue } from "../CollectionPointValue";
import { ExpressionEngine } from "../../../expressions/ExpressionEngine";
import { CurrentUserUtilsService } from "../../../services/current.user.utils.service";


export class SiteFactory
{
    readonly cpValueFactory: CollectionPointValueFactory;

    constructor ( private engine: ExpressionEngine, private currentUser: CurrentUserUtilsService, userRoles: string[] )
    {
        this.cpValueFactory = new CollectionPointValueFactory ( engine, currentUser, userRoles );
    }

    fromMeta ( meta: SiteMeta ) : Site
    {
        let site = new Site ( );
        site.MetaId = meta.Id;
        
        site.RegistrationCollectionPointValue = meta.RegistrationCollectionPoint ? this.cpValueFactory.fromMeta ( meta.RegistrationCollectionPoint, new Map ( ) ) : null;        
        // site.BaselineCollectionPointValue = meta.BaselineCollectionPoint ? this.cpValueFactory.fromMeta ( meta.BaselineCollectionPoint ) : null;        
        // site.IntervalCollectionPointValues = new Array<CollectionPointValue> ( );
        // for ( const cp of meta.IntervalCollectionPoints ? meta.IntervalCollectionPoints : [] )
        // {
        //     site.IntervalCollectionPointValues.push ( this.cpValueFactory.fromMeta ( cp ) );
        // }
        // site.CompletionCollectionPointValue = meta.CompletionCollectionPoint ? this.cpValueFactory.fromMeta ( meta.CompletionCollectionPoint ) : null;

        return site;
    }

    appendRegistrationCollectionPoint ( meta: SiteMeta, site: Site ) : Site
    {
        site.RegistrationCollectionPointValue = meta.RegistrationCollectionPoint ? this.cpValueFactory.fromMeta ( meta.RegistrationCollectionPoint, new Map ( ) ) : null;
        return site;
    }

    appendBaselineCollectionPoint ( meta: SiteMeta, site: Site ) : Site
    {
        site.BaselineCollectionPointValue = meta.BaselineCollectionPoint ? this.cpValueFactory.fromMeta ( meta.BaselineCollectionPoint, new Map ( ) ) : null;
        return site;
    }

    appendCompletionCollectionPoint ( meta: SiteMeta, site: Site ) : Site
    {
        site.CompletionCollectionPointValue = meta.CompletionCollectionPoint ? this.cpValueFactory.fromMeta ( meta.CompletionCollectionPoint, new Map ( ) ) : null;
        return site;
    }
} 