import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LandingPage } from '../../model/published/pages/LandingPage';
import { SideBar } from '../../model/published/pages/SideBar';
import { SitesPage } from '../../model/published/pages/SitesPage';
import { ON_PUBLISHED_PAGES_LANDING, ON_PUBLISHED_PAGES_SIDE_BAR, ON_PUBLISHED_PAGES_SITES } from '../../state/published/pages/published-pages.actions';
import { SELECT_PUBLISHED_PAGES_LANDING_PAGE, SELECT_PUBLISHED_PAGES_LANDING_LOADING, SELECT_PUBLISHED_PAGES_SITES_LOADING, SELECT_PUBLISHED_PAGES_SITES_PAGE, SELECT_PUBLISHED_PAGES_SIDE_BAR_LOADING, SELECT_PUBLISHED_PAGES_SIDE_BAR } from '../../state/published/pages/published-pages.selectors';

@Injectable ( {
  providedIn: 'root'
} )
export class PublishedPagesService
{
  constructor( private store: Store<any> )
  {
    // Null.
  }

  onLanding( force: boolean = false )
  {
    this.store.dispatch ( ON_PUBLISHED_PAGES_LANDING ( { force } ) );
  }

  getLandingLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_LANDING_LOADING ) 
    );
  }

  getLanding( ): Observable<LandingPage | null>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_LANDING_PAGE )
    );
  }



  onSideBar( force: boolean = false )
  {
    this.store.dispatch ( ON_PUBLISHED_PAGES_SIDE_BAR ( { force } ) );
  }

  getSideBarLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_SIDE_BAR_LOADING ) 
    );
  }

  getSideBar( ): Observable<SideBar | null>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_SIDE_BAR )
    );
  }



  onSites( force: boolean = false )
  {
    this.store.dispatch ( ON_PUBLISHED_PAGES_SITES ( { force } ) );
  }

  getSitesLoading( ): Observable<boolean>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_SITES_LOADING ) 
    );
  }

  getSites( ): Observable<SitesPage | null>
  {
    return this.store.pipe ( 
      select ( SELECT_PUBLISHED_PAGES_SITES_PAGE )
    );
  }
}
