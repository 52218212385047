import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap, debounceTime, filter } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { SELECT_STUDY_PARTICIPANTS_FULL_LOAD, SELECT_STUDY_PARTICIPANTS_LOADING, SELECT_STUDY_PARTICIPANTS_PARTICIPANTS } from './study-participants.selectors';
import { Participant } from '../../../model/study/participants/Participant';
import { ErrorType } from '../../../errors/ErrorType';
import { FormValue } from '../../../model/study/FormValue';
import { CREATE_PARTICIPANT, CREATE_PARTICIPANT_FAILURE, CREATE_PARTICIPANT_FORM, CREATE_PARTICIPANT_FORM_FAILURE, CREATE_PARTICIPANT_FORM_SUCCESS, CREATE_PARTICIPANT_SUCCESS, DELETE_PARTICIPANT, DELETE_PARTICIPANTS, DELETE_PARTICIPANTS_FAILURE, DELETE_PARTICIPANTS_SUCCESS, DELETE_PARTICIPANT_FAILURE, DELETE_PARTICIPANT_SUCCESS, DOWNLOAD_PARTICIPANT_DOCUMENT, DOWNLOAD_PARTICIPANT_FILE, DOWNLOAD_PARTICIPANT_FILE_FAILURE, DOWNLOAD_PARTICIPANT_FILE_SUCCESS, GET_PARTICIPANTS, GET_PARTICIPANTS_FAILURE, GET_PARTICIPANTS_SUCCESS, ON_PARTICIPANTS_LIST, ON_PARTICIPANTS_SINGLE, UPDATE_PARTICIPANT, UPDATE_PARTICIPANT_FAILURE, UPDATE_PARTICIPANT_FORM, UPDATE_PARTICIPANT_FORM_FAILURE, UPDATE_PARTICIPANT_FORM_SUCCESS, UPDATE_PARTICIPANT_SUCCESS, UPLOAD_PARTICIPANT_FILE, UPLOAD_PARTICIPANT_FILE_FAILURE, UPLOAD_PARTICIPANT_FILE_SUCCESS } from './study-participants.actions';
import { saveAs } from 'file-saver';
import { ON_TASKS_LIST } from '../tasks/study-tasks.actions';
import { ParticipantUtils } from '../../../model/study/participants/ParticipantUtils';
import { FormUtils } from '../../../model/study/FormUtils';
import { SubjectUtils } from '../../../model/study/SubjectUtils';
import { FileFieldValue } from '../../../model/study/FileFieldValue';
import { CurrentUserUtilsService } from '../../../services/current.user.utils.service';

@Injectable ( )
export class StudyParticipantsEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store, private currentUser: CurrentUserUtilsService )
    {
        // Null.
    }

    ON_PARTICIPANTS_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PARTICIPANTS_LIST ),
        concatLatestFrom ( action => this.store.select ( SELECT_STUDY_PARTICIPANTS_LOADING, { type: action.pType } ) ),
        concatLatestFrom ( ( [ action, loading ] ) => this.store.select ( SELECT_STUDY_PARTICIPANTS_FULL_LOAD, { type: action.pType } ) ),
        concatLatestFrom ( ( [ [ action, loading ], fullLoad ] ) => this.store.select ( SELECT_STUDY_PARTICIPANTS_PARTICIPANTS, { type: action.pType, siteId: action.siteId } ) ),
        mergeMap ( ( [ [ [ action, loading ], fullLoad ], participants ] ) => {
            if ( /*loading == false &&*/ ( fullLoad == false || action.force || participants == null || participants.size == 0 ) )
            {
                return [ GET_PARTICIPANTS ( { op: action.op, siteId: action.siteId, pType: action.pType, ids: action.ids } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_PARTICIPANTS_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_PARTICIPANTS_SINGLE ),
        concatLatestFrom ( action => this.store.select ( SELECT_STUDY_PARTICIPANTS_PARTICIPANTS, { type: action.pType, siteId: action.siteId } ) ),
        mergeMap ( ([action, participants]) => {
            if ( action.force || participants == null || participants.size == 0 )
            {
                return [ GET_PARTICIPANTS ( { op: 'single', siteId: action.siteId, pType: action.pType, ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_PARTICIPANTS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_PARTICIPANTS ),
        mergeMap ( action => of ( action ).pipe (
            debounceTime ( 1000 ),
            map ( action => {
                let params = new HttpParams ( );
                params = params.append ( "type", String ( action.pType ) );
                params = params.append ( "site", String ( action.siteId ) );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return [ params, (action.ids ? action.ids.length > 0 : true) ] as const;
            } ),
            mergeMap ( ( [ params, fullLoad ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ config, params, fullLoad ] as const ) ) ),
            mergeMap ( ( [ config, params, fullLoad ] ) => this.http.get<any[]> ( `${config.study_api}/participants/`, { params } ).pipe ( 
                map ( response => [ fullLoad, response ] as const )
            ) ),
            map ( ( [ fullLoad, response ] ) => {
                const participants = new Array<Participant> ( );
                for ( const json of response )
                {
                    const participant = Participant.fromJson ( json );
                    if ( participant )
                    {
                        participants.push ( participant );
                    }
                }
                return [ fullLoad, participants ] as const;
            } ),
            map ( ( [ fullLoad, participants ] ) => GET_PARTICIPANTS_SUCCESS ( { op: action.op, siteId: action.siteId, pType: action.pType, fullLoad, participants } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_PARTICIPANTS_FAILURE ( { op: action.op, siteId: action.siteId, pType: action.pType, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );

    CREATE_PARTICIPANT$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_PARTICIPANT ),
        mergeMap ( action => of ( action ).pipe (
            map ( action => {
                return [ action.op, Participant.toJson ( action.participant ) ] as const;
            } ),
            mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
            mergeMap ( ( [ op, config, body] ) => this.http.post<any> ( `${config.study_api}/participants/`, body ).pipe ( 
                map ( response => [ op, response ] as const )
            ) ),
            map ( ( [ op, response ] ) => [ op, Participant.fromJson ( response ) ] as const ),
            map ( ( [ op, participant ] ) => CREATE_PARTICIPANT_SUCCESS ( { op, participant } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( CREATE_PARTICIPANT_FAILURE ( { op: CREATE_PARTICIPANT.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
        ) )
    ) );

    UPDATE_PARTICIPANT$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_PARTICIPANT ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.participant.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update participant without a valid id." );
                }
            } ),
            map ( action => {
                return [ action.op, Participant.toJson ( action.participant ) ] as const;
            } ),
            mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
            mergeMap ( ( [ op, config, body] ) => this.http.put<any> ( `${config.study_api}/participants/`, body, {observe: 'response'} ).pipe ( 
                map ( response => [ op, response ] as const )
            ) ),
            map ( ( [ op, response ] ) => [ op, response.body, response.headers.get ( "X-Participant-Refresh-Required" ), response.headers.get ( "X-Task-Refresh-Required" ) ] as const ),
            map ( ( [ op, response, refreshRequired, taskRefreshRequired ] ) => [ op, Participant.fromJson ( response ), refreshRequired ? Number.parseInt ( refreshRequired ) : null, taskRefreshRequired ? Number.parseInt ( taskRefreshRequired ) : null ] as const ),
            switchMap ( ( [ op, participant, refreshRequired, taskRefreshRequired ] ) => this.currentUser.userRequiresTaskRefresh ( ).pipe ( map ( userRequiresTaskRefresh => [ op, participant, refreshRequired, taskRefreshRequired && userRequiresTaskRefresh ] as const ) ) ),
            switchMap ( ( [ op, participant, refreshRequired, taskRefreshRequired ] ) => {
                let actions: Array<Action> = [ UPDATE_PARTICIPANT_SUCCESS ( { op, participant } ) ];
                if ( participant.SiteId )
                {
                    if ( refreshRequired )
                    {
                        actions.push ( GET_PARTICIPANTS ( { op, siteId: participant.SiteId, pType: refreshRequired, ids: null } ) );
                    }

                    if ( taskRefreshRequired )
                    {
                        actions.push ( ON_TASKS_LIST ( { completed: false, dependencies: false, force: true } ) );
                    }
                }

                return actions;
            } ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPDATE_PARTICIPANT_FAILURE ( { op: UPDATE_PARTICIPANT.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );


    DELETE_PARTICIPANT$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DELETE_PARTICIPANT ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete participant without a valid id." );
                }
            } ),
            map ( action => {
                let params = new HttpParams ( );
                params = params.append ( 'id', String ( action.id ) );
                return [ action.op, action.pType, action.siteId, action.id, params ] as const;
            } ),
            mergeMap ( ( [ op, pType, siteId, id, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, pType, siteId, id, config, params ] as const ) ) ),
            mergeMap ( ( [ op, pType, siteId, id, config, params ] ) => this.http.delete<any> ( `${config.study_api}/participants/`, { params } ).pipe ( 
                map ( ( ) => [ op, pType, siteId, id ] as const )
            ) ),
            map ( ( [ op, pType, siteId, id ] ) => DELETE_PARTICIPANT_SUCCESS ( { op, pType, siteId, id } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( DELETE_PARTICIPANT_FAILURE ( { op: DELETE_PARTICIPANT.type, pType: action.pType, siteId: action.siteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
        ) )
    ) );


    DELETE_PARTICIPANTS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DELETE_PARTICIPANTS ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                params = params.append ( "type", String ( action.pType ) );
                params = params.append ( "site", String ( action.siteId ) );
                
                return params;
            } ),
            map ( params => {
                return [ action.op, action.pType, action.siteId, params ] as const;
            } ),
            mergeMap ( ( [ op, pType, siteId, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, pType, siteId, params, config ] as const ) ) ),
            mergeMap ( ( [ op, pType, siteId, params, config ] ) => this.http.delete<any> ( `${config.study_api}/participants/`, { params } ).pipe ( 
                map ( ( ) => [ op, pType, siteId ] as const )
            ) ),
            map ( ( [ op, pType, siteId ] ) => DELETE_PARTICIPANTS_SUCCESS ( { op, pType, siteId } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( DELETE_PARTICIPANTS_FAILURE ( { op: DELETE_PARTICIPANTS.type, pType: action.pType, siteId: action.siteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
        ) )
    ) );


    CREATE_PARTICIPANT_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_PARTICIPANT_FORM ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.participant.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a participant form without a valid participant id." );
                }
                else if ( action.cp.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a participant form without a valid collection point id." );
                }
                else if ( action.form.Id != null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a participant form with a valid form id." );
                }
            } ),
            map ( action => {
                let maxFieldId: number | null = null;
                for ( const sv of action.form.SectionValues ? action.form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( maxFieldId == null && fv.Id != null )
                        {
                            maxFieldId = fv.Id;
                        }
                        else if ( maxFieldId != null && fv.Id != null && fv.Id > maxFieldId )
                        {
                            maxFieldId = fv.Id;
                        }
                    }
                }

                if ( maxFieldId == null )
                {
                    maxFieldId = 0;
                }

                let form = FormValue.clone ( action.form );
                for ( const sv of form.SectionValues ? form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( fv.Id == null )
                        {
                            fv.Id = ++maxFieldId;
                        }
                    }
                }

                return [ action, form, action.fileUploads ] as const;
            } ),
            map ( ( [ action, form, fileUploads ] ) => {
                return [ action.op, action.participant, action.cp, FormValue.toJson ( form ), fileUploads ] as const;
            } ),
            mergeMap ( ( [ op, participant, cp, body, fileUploads ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, participant, cp, config, body, fileUploads ] as const ) ) ),
            mergeMap ( ( [ op, participant, cp, config, body, fileUploads ] ) => this.http.post<any> ( `${config.study_api}/participants/forms/${participant.SiteId}/${participant.Id}/`, body, 
                                                                                         { params: new HttpParams ( ).append ( "cp", cp.Id != null ? cp.Id : "" ).
                                                                                                                      append ( "part_type", participant.MetaId ? participant.MetaId : "" ),
                                                                                           observe: 'response' } ).pipe ( 
                map ( response => [ op, response, fileUploads ] as const )
            ) ),
            map ( ( [ op, response, fileUploads ] ) => [ 
                op,
                response.body,
                response.headers.get ( "X-Participant-Form-Id" ),
                response.headers.get ( "X-Participant-Collection-Point-Id" ),
                response.headers.get ( "X-Participant-Refresh-Required" ),
                response.headers.get ( "X-Task-Refresh-Required" ),
                fileUploads,
            ] as const ),
            map ( ( [ op, response, formValueId, collectionPointValueId, refreshRequired, taskRefreshRequired, fileUploads ] ) => [ 
                op,
                Participant.fromJson ( response ),
                formValueId ? Number.parseInt ( formValueId ) : null,
                collectionPointValueId ? Number.parseInt ( collectionPointValueId ) : null,
                refreshRequired ? Number.parseInt ( refreshRequired ) : null,
                taskRefreshRequired ? Number.parseInt ( taskRefreshRequired ) : null,
                fileUploads,
            ] as const ),
            switchMap ( ( [ op, participant, formValueId, collectionPointValueId, refreshRequired, taskRefreshRequired, fileUploads ] ) => this.currentUser.userRequiresTaskRefresh ( ).pipe ( switchMap ( userRequiresTaskRefresh => {
                let actions: Array<Action> = [ ];
                if ( participant.SiteId && formValueId != null && collectionPointValueId != null )
                {
                    actions.push ( CREATE_PARTICIPANT_FORM_SUCCESS ( { op, participant, collectionPointValueId, formValueId, fileUploads } ) )

                    if ( refreshRequired )
                    {
                        actions.push ( GET_PARTICIPANTS ( { op, siteId: participant.SiteId, pType: refreshRequired, ids: null } ) );
                    }

                    if ( taskRefreshRequired && userRequiresTaskRefresh )
                    {
                        actions.push ( ON_TASKS_LIST ( { completed: false, dependencies: false, force: true } ) );
                    }
                }

                return actions;
            } ) ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( CREATE_PARTICIPANT_FORM_FAILURE ( { op: CREATE_PARTICIPANT_FORM.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );

    CREATE_PARTICIPANT_FORM_SUCCESS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_PARTICIPANT_FORM_SUCCESS ),
        switchMap ( action => of ( action ).pipe ( 
            switchMap ( action => {
                let actions: Array<Action> = [ ];
                for ( const file of action.fileUploads ? action.fileUploads : [] )
                {
                    const utils = new ParticipantUtils ( );
                    const cpv = utils.getCollectionPointValue ( action.participant, action.collectionPointValueId );
                    if ( cpv )
                    {
                        const fv = utils.getFormValue ( cpv, action.formValueId );
                        if ( fv )
                        {
                            if ( file[0].Id != null )
                            {
                                const fieldValue = utils.getFieldValueByMetaId ( fv, file[0].Id );
                                if ( fieldValue && fieldValue instanceof FileFieldValue )
                                {
                                    actions.push ( UPLOAD_PARTICIPANT_FILE ( { op: UPLOAD_PARTICIPANT_FILE.type,
                                                                            participant: action.participant,
                                                                            cpv,
                                                                            fv,
                                                                            field: file[0],
                                                                            fieldValue,
                                                                            file: file[2]
                                                                            } ) );
                                }
                                else
                                {
                                    throw new ErrorDetails ( ErrorType.PRE_OP_VALIDATION , "Couldn't find valid file form field data for file upload.") 
                                }
                            }
                            else
                            {
                                throw new ErrorDetails ( ErrorType.PRE_OP_VALIDATION , "Couldn't find file form field data for file upload." );  
                            }
                        }
                        else
                        {
                            throw new ErrorDetails ( ErrorType.PRE_OP_VALIDATION , "Couldn't find form data for file upload." );
                        }
                    }
                    else
                    {
                        throw new ErrorDetails ( ErrorType.PRE_OP_VALIDATION , "Couldn't find collection point data for file upload." );
                    }
                }
                
                return actions;
            } ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( CREATE_PARTICIPANT_FORM_FAILURE ( { op: CREATE_PARTICIPANT_FORM.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
        ) )
    ) );


    UPDATE_PARTICIPANT_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_PARTICIPANT_FORM ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.participant.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update participant form without a valid participant id." );
                }
                else if ( action.cpv.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update participant form without a valid collection point id." );
                }
                else if ( action.form.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update participant form without a valid form id." );
                }
            } ),
            map ( action => {
                let maxFieldId: number | null = null;
                for ( const sv of action.form.SectionValues ? action.form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( maxFieldId == null && fv.Id != null )
                        {
                            maxFieldId = fv.Id;
                        }
                        else if ( maxFieldId != null && fv.Id != null && fv.Id > maxFieldId )
                        {
                            maxFieldId = fv.Id;
                        }
                    }
                }

                if ( maxFieldId == null )
                {
                    maxFieldId = 0;
                }

                let form = FormValue.clone ( action.form );
                for ( const sv of form.SectionValues ? form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( fv.Id == null )
                        {
                            fv.Id = ++maxFieldId;
                        }
                    }
                }

                return [ action, form ] as const;
            } ),
            map ( ( [ action, form ] ) => {
                return [ action.op, action.participant, action.cpv, form, FormValue.toJson ( form ), action.fileUploads ] as const;
            } ),
            mergeMap ( ( [ op, participant, cpv, fv, body, fileUploads ] ) => this.config.getConfigLazy ( ).pipe (
                map ( config => [ op, participant, cpv, fv, config, body, fileUploads ] as const ) ) 
            ),
            mergeMap ( ( [ op, participant, cpv, fv, config, body, fileUploads ] ) => this.http.put<any> ( `${config.study_api}/participants/forms/${participant.SiteId}/${participant.Id}/${cpv.Id}/`, body, { observe: 'response' } ).pipe ( 
                map ( response => [ op, response, cpv, fv, fileUploads ] as const )
            ) ),
            map ( ( [ op, response, cpv, fv, fileUploads ] ) => [
                op,
                response.body,
                response.headers.get ( "X-Participant-Refresh-Required" ),
                response.headers.get ( "X-Task-Refresh-Required" ),
                cpv,
                fv,
                fileUploads
            ] as const ),
            map ( ( [ op, response, refreshRequired, taskRefreshRequired, cpv, fv, fileUploads ] ) => [
                op,
                Participant.fromJson ( response ),
                refreshRequired ? Number.parseInt ( refreshRequired ) : null,
                taskRefreshRequired ? Number.parseInt ( taskRefreshRequired ) : null,
                cpv,
                fv,
                fileUploads
            ] as const ),
            switchMap ( ( [ op, participant, refreshRequired, taskRefreshRequired, cpv, form, fileUploads ] ) => this.currentUser.userRequiresTaskRefresh ( ).pipe ( switchMap ( userRequiresTaskRefresh => {
                let actions: Array<Action> = [ ];
                if ( participant.SiteId && cpv != null && form != null )
                {
                    actions.push ( UPDATE_PARTICIPANT_FORM_SUCCESS ( { op, participant, cpv, form, fileUploads } ) );
                    if ( refreshRequired )
                    {
                        actions.push ( GET_PARTICIPANTS ( { op, siteId: participant.SiteId, pType: refreshRequired, ids: null } ) );
                    }

                    if ( taskRefreshRequired && userRequiresTaskRefresh )
                    {
                        actions.push ( ON_TASKS_LIST ( { completed: false, dependencies: false, force: true } ) );
                    }
                }

                return actions;
            } ) ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPDATE_PARTICIPANT_FORM_FAILURE ( { op: UPDATE_PARTICIPANT_FORM.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );

    UPLOAD_PARTICIPANT_FORM_SUCCESS$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_PARTICIPANT_FORM_SUCCESS ),
        switchMap ( action => of ( action ).pipe ( 
            switchMap ( action => {
                let actions: Array<Action> = [ ];
                for ( const file of action.fileUploads ? action.fileUploads : [] )
                {
                    actions.push ( UPLOAD_PARTICIPANT_FILE ( { op: UPLOAD_PARTICIPANT_FILE.type,
                                                               participant: action.participant,
                                                               cpv: action.cpv,
                                                               fv: action.form,
                                                               field: file[0],
                                                               fieldValue: file[1],
                                                               file: file[2]
                                                            } ) );
                }
                
                return actions;
            })
        ) )
    ) );

    UPLOAD_PARTICIPANT_FILE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPLOAD_PARTICIPANT_FILE ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let formData = new FormData ( );
                if ( action.participant.SiteId ) formData.append ( 'site_id', action.participant.SiteId.toString ( ) );
                if ( action.participant.Id ) formData.append ( 'participant_id', action.participant.Id.toString ( ) );
                if ( action.participant.MetaId ) formData.append ( 'participant_type', action.participant.MetaId.toString ( ) );
                if ( action.cpv.Id ) formData.append ( 'cpv_id', action.cpv.Id.toString ( ) );
                if ( action.fv.Id ) formData.append ( 'form_value_id', action.fv.Id.toString ( ) );
                if ( action.field.Id ) formData.append ( 'field', action.field.Id.toString ( ) );
                if ( action.fieldValue.Id ) formData.append ( 'field_value', action.fieldValue.Id.toString ( ) );
                formData.append ( 'file', action.file, action.file.name );
                return [ action.participant, action.field, action.fieldValue, formData ] as const;
            } ),
            mergeMap ( ( [ participant, field, value, formData ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ participant, field, value, config, formData ] as const ) ) ),
            mergeMap ( ( [ participant, field, value, config, formData]) => this.http.post<any> ( `${config.study_api}/files/`, formData ).pipe (
                map ( response => [ participant, field, value ] as const )
            ) ), // Make API request
            map ( ( [ participant, field, fieldValue ] ) => UPLOAD_PARTICIPANT_FILE_SUCCESS ( { op: UPLOAD_PARTICIPANT_FILE.type, participant, field, fieldValue } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPLOAD_PARTICIPANT_FILE_FAILURE ( { op: UPLOAD_PARTICIPANT_FILE.type, pType: action.participant.MetaId, siteId: action.participant.SiteId, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );


    DOWNLOAD_PARTICIPANT_FILE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DOWNLOAD_PARTICIPANT_FILE ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                if ( action.participant.SiteId != null ) params = params.set ( 'site_id', action.participant.SiteId.toString ( ) );
                if ( action.participant.Id != null ) params = params.set ( 'participant_id', action.participant.Id.toString ( ) );
                if ( action.participant.MetaId != null ) params = params.set ( 'participant_type', action.participant.MetaId.toString ( ) );
                if ( action.cpv.Id != null ) params = params.set ( 'cpv_id', action.cpv.Id.toString ( ) );
                if ( action.fv.Id != null ) params = params.set ( 'form_value_id', action.fv.Id.toString ( ) );
                if ( action.field.Id != null ) params = params.set ( 'field', action.field.Id.toString ( ) );
                if ( action.fieldValue.Id != null ) params = params.set ( 'field_value', action.fieldValue.Id.toString ( ) );
                return [ action.participant, action.field, action.fieldValue, params ] as const;
            } ),
            mergeMap ( ( [ participant, field, value, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ value, config, params ] as const ) ) ),
            mergeMap ( ( [ value, config, params ] ) => this.http.get ( `${config.study_api}/files/`, { params, responseType: 'blob' } ).pipe (
                map ( response => [ response, value ] as const )
            ) ),
            filter ( ( [ response, value ] ) => value.Value != null ),
            tap ( ( [ response, value ]) => saveAs ( response, value.Value ? value.Value : "download.file" ) ),
            map ( ( [ response, value ] ) => DOWNLOAD_PARTICIPANT_FILE_SUCCESS ( { fieldValue: value } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err, ErrorType.DOWNLOAD_FAILED );

                return of ( DOWNLOAD_PARTICIPANT_FILE_FAILURE ( { op: DOWNLOAD_PARTICIPANT_FILE.type, participant: action.participant, cpv: action.cpv, fv: action.fv, field: action.field, fieldValue: action.fieldValue, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ));

    DOWNLOAD_PARTICIPANT_DOCUMENT$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DOWNLOAD_PARTICIPANT_DOCUMENT ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                if ( action.participant.SiteId != null ) params = params.set ( 'site_id', action.participant.SiteId.toString ( ) );
                if ( action.participant.Id != null ) params = params.set ( 'participant_id', action.participant.Id.toString ( ) );
                if ( action.participant.MetaId != null ) params = params.set ( 'participant_type', action.participant.MetaId.toString ( ) );
                if ( action.doc.Id != null ) params = params.set ( 'document_id', action.doc.Id.toString ( ) );
                return [ action.participant, action.doc, params ] as const;
            } ),
            mergeMap ( ( [ participant, doc, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ config, participant, doc, params ] as const ) ) ),
            mergeMap ( ( [ config, participant, doc, params ] ) => this.http.get ( `${config.study_api}/docs/`, { params, responseType: 'blob' } ).pipe (
                map ( response => [ response, participant, doc ] as const )
            ) ),
            tap ( ( [ response, participant, doc ]) => saveAs ( response, doc.Name ? doc.Name : "document.docx" ) )
        ) )
    ), { dispatch: false } );
}
