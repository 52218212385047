import { Expression } from '../published/expressions/Expression';
import { Form } from './Form';

export class CollectionPoint
{
    public static toJson ( cp: CollectionPoint ): any
    {
        let out: any = {};

        out.id = cp.Id;
        out.version = cp.Version;
        out.seq = cp.Seq;
        out.variable = cp.Variable;
        out.name = cp.Name;
        out.description = cp.Description;
        out.label_field_name = cp.LabelFieldName;
        out.active_expression = cp.ActiveExpression ? Expression.toJson ( cp.ActiveExpression ) : null;
        out.eligible_expression = cp.EligibleExpression ? Expression.toJson ( cp.EligibleExpression ) : null;
        out.forms = new Array<any> ( );
        for ( const form of cp.Forms ? cp.Forms : [] )
        {
            out.forms.push ( Form.toJson ( form ) );
        }
        out.interval_period = cp.IntervalPeriod;

        return out;
    }

    public static fromJson( json: any ): CollectionPoint
    {
        let out = new CollectionPoint ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Seq = json.seq;
        out.Variable = json.variable;
        out.Name = json.name;
        out.Description = json.description;
        out.LabelFieldName = json.label_field_name;
        out.ActiveExpression = "active_expression" in json && json.active_expression ? Expression.fromJson ( json.active_expression ) : null;
        out.EligibleExpression = "eligible_expression" in json && json.eligible_expression ? Expression.fromJson ( json.eligible_expression ) : null;
        out.Forms = new Array<Form> ( );
        for ( const form of json.forms ? json.forms : [] )
        {
            const parsed = Form.fromJson ( form );
            if ( parsed != null )
            {
                out.Forms.push ( parsed );
            }
        }
        out.IntervalPeriod = json.interval_period;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private seq: number | null = null,
                        private variable: string | null  = null, 
                        private name: string | null  = null, 
                        private description: string | null = null,
                        private labelFieldName: string | null = null,
                        private activeExpression: Expression | null = null,
                        private eligibleExpression: Expression | null = null,
                        private forms: Array<Form> | null  = null,
                        private intervalPeriod: number | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get LabelFieldName( ): string | null
    {
        return this.labelFieldName;
    }

    public set LabelFieldName( labelFieldName: string | null )
    {
        this.labelFieldName = labelFieldName;
    }

    public get ActiveExpression( ): Expression | null
    {
        return this.activeExpression;
    }

    public set ActiveExpression( activeExpression: Expression | null )
    {
        this.activeExpression = activeExpression;
    }

    public get EligibleExpression( ): Expression | null
    {
        return this.eligibleExpression;
    }

    public set EligibleExpression( eligibleExpression: Expression | null )
    {
        this.eligibleExpression = eligibleExpression;
    }
    
    public get Forms( ): Array<Form> | null
    {
        return this.forms;
    }

    public set Forms( forms: Array<Form> | null )
    {
        this.forms = forms;
    }

    public get IntervalPeriod( ): number | null
    {
        return this.intervalPeriod;
    }

    public set IntervalPeriod( intervalPeriod: number | null )
    {
        this.intervalPeriod = intervalPeriod;
    }
}
