import { BooleanField } from './BooleanField';
import { DateField } from './DateField';
import { EnumeratedField } from './EnumeratedField';
import { Field } from './Field';
import { FieldType, FieldTypeUtils } from './FieldType';
import { NumericField } from './NumericField';
import { ParticipantField } from './ParticipantField';
import { StringField } from './StringField';
import { FileField } from './FileField';
import { ListField } from './ListField';


export abstract class FieldFactory
{
    public static clone( field: Field ): Field | null
    {
        if ( field instanceof BooleanField )
        {
            return BooleanField.clone ( field );
        }
        else if ( field instanceof DateField )
        {
            return DateField.clone ( field );
        }
        else if ( field instanceof EnumeratedField )
        {
            return EnumeratedField.clone ( field );
        }
        else if ( field instanceof NumericField )
        {
            return NumericField.clone ( field );
        }
        else if ( field instanceof StringField )
        {
            return StringField.clone ( field );
        }
        else if ( field instanceof ParticipantField )
        {
            return ParticipantField.clone ( field );
        }
        else if ( field instanceof FileField )
        {
            return FileField.clone ( field );
        }
        else if ( field instanceof ListField )
        {
            return ListField.clone ( field, FieldFactory.clone );
        }
        else
        {
            console.error ( `Encountered unknown field type: ${field.Type}.` );
            return null;
        }
    }

    public static toJson<T extends Field>( field: T ): any
    {
        if ( field instanceof BooleanField )
        {
            return BooleanField.toJson ( field );
        }
        else if ( field instanceof DateField )
        {
            return DateField.toJson ( field );
        }
        else if ( field instanceof EnumeratedField )
        {
            return EnumeratedField.toJson ( field );
        }
        else if ( field instanceof NumericField )
        {
            return NumericField.toJson ( field );
        }
        else if ( field instanceof StringField )
        {
            return StringField.toJson ( field );
        }
        else if ( field instanceof ParticipantField )
        {
            return ParticipantField.toJson ( field );
        }
        else if ( field instanceof FileField )
        {
            return FileField.toJson ( field );
        }
        else if ( field instanceof ListField )
        {
            return ListField.toJson ( field, FieldFactory.toJson );
        }
        else
        {
            console.error ( `Encountered unknown field type: ${field.Type}.` );
            return null;
        }
    }

    public static fromJson( json: any ): Field | null
    {
        const type = FieldTypeUtils.fromString ( json.type );
        switch ( type )
        {
        case FieldType.BOOLEAN:
            return BooleanField.fromJson ( json );
        case FieldType.DATE:
            return DateField.fromJson ( json );
        case FieldType.ENUMERATED:
            return EnumeratedField.fromJson ( json );
        case FieldType.NUMERIC:
            return NumericField.fromJson ( json );
        case FieldType.STRING:
            return StringField.fromJson ( json );
        case FieldType.PARTICIPANT:
                return ParticipantField.fromJson ( json );
        case FieldType.FILE:
            return FileField.fromJson ( json );
        case FieldType.LIST:
            return ListField.fromJson ( json, FieldFactory.fromJson );
        case FieldType.NONE:
        default:
            console.error ( `Encountered unknown field type: ${type}.` );
            return null;
        }
    }

    public static fromType ( type: FieldType ) : Field | null
    {
        switch ( type )
        {
        case FieldType.BOOLEAN:
            return new BooleanField ( );
        case FieldType.DATE:
            return new DateField ( );
        case FieldType.ENUMERATED:
            return new EnumeratedField ( );
        case FieldType.NUMERIC:
            return new NumericField ( );
        case FieldType.STRING:
            return new StringField ( );
        case FieldType.PARTICIPANT:
            return new ParticipantField ( );
        case FieldType.FILE:
            return new FileField ( );
        case FieldType.NONE:
        default:
            console.error ( `Encountered unknown field type: ${type}.` );
            return null;
        }
    }
}
