

export enum ErrorType
{
    NONE = 0,

    UNKNOWN = 1,

    NOT_FOUND = 2,

    NOT_AUTHORISED = 3,

    PRE_OP_VALIDATION = 4,

    DOWNLOAD_FAILED = 5,
}

export function ErrorType_fromString( value: string ): ErrorType
{
    return Object.entries(ErrorType).find(([key, val]) => key === value)?.[1] as ErrorType;
}
