import { createReducer, on } from '@ngrx/store';
import { StudyChangeRecordsState } from './study-change-records.state';
import { ChangeRecord } from '../../../model/study/ChangeRecord';
import { GET_CHANGE_RECORDS, GET_CHANGE_RECORDS_SUCCESS, GET_CHANGE_RECORDS_FAILURE } from './study-change-records.actions';

export const INITIAL_STATE = new StudyChangeRecordsState ( );

const _STUDY_CHANGE_RECORDS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_CHANGE_RECORDS, ( state, { op, start, end } ) => {
      const newState = { ...state };

      newState.loading = true;
      newState.start = start;
      newState.end = end;
      newState.records = new Array<ChangeRecord> ( );

      return newState;
    } ),
    on ( GET_CHANGE_RECORDS_SUCCESS, ( state, { op, records } ) => {
      const newState = { ...state };

      newState.records = new Array<ChangeRecord> ( ...records );
      newState.loading = false;
      
      return newState;
    } ),
    on ( GET_CHANGE_RECORDS_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.records = new Array<ChangeRecord> ( );
      newState.start = null;
      newState.end = null;
      newState.loading = false;

      return newState;
    } ),
);

export function STUDY_CHANGE_RECORDS_REDUCER( state: any, action: any )
{
  return _STUDY_CHANGE_RECORDS_REDUCER ( state, action );
}
