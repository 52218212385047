import { Participant } from '../../../model/study/participants/Participant';

export const STUDY_PARTICIPANTS_FEATURE_STATE_NAME = 'study-participants';

export class SiteSubState
{
    public static clone ( source: SiteSubState ) : SiteSubState
    {
        const dest = new SiteSubState ( );
        dest.participants = new Map<number, Participant> ( source.participants );
        dest.participantsLoading = source.participantsLoading;
        dest.participantsSaving = source.participantsSaving;
        return dest;
    }

    participants = new Map<number, Participant> ( );
    participantsLoading = false;
    participantsSaving = false;
    fullLoad = false;
}

export class TypeSubState
{
    public static clone ( source: TypeSubState ) : TypeSubState
    {
        const dest = new TypeSubState ( );
        dest.siteSubStates = new Map<number, SiteSubState> ( source.siteSubStates );
        return dest;
    }

    public static getSiteState ( source: TypeSubState, siteId: number ) : SiteSubState
    {
        if ( source.siteSubStates.has ( siteId ) == false )
        {
            source.siteSubStates.set ( siteId, new SiteSubState ( ) );
        }

        const subState = source.siteSubStates.get ( siteId );
        if ( subState )
        {
            return SiteSubState.clone ( subState );
        }

        throw new Error ( "Impossible situation." )
    }

    siteSubStates = new Map<number, SiteSubState> ( );
}

export class StudyParticipantsState
{ 
    public static getTypeState ( source: StudyParticipantsState, typeId: number ) : TypeSubState
    {
        if ( source.typeSubStates.has ( typeId ) == false )
        {
            source.typeSubStates.set ( typeId, new TypeSubState ( ) );
        }

        const subState = source.typeSubStates.get ( typeId );
        if ( subState )
        {
            return TypeSubState.clone ( subState );
        }

        throw new Error ( "Impossible situation." )
    }

    typeSubStates = new Map<number, TypeSubState> ( );
}
