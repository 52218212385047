import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap, filter } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ConfigService } from '../../../config/config.service';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { ON_ERROR } from '../../../errors/errors.actions';
import { SELECT_STUDY_SITES_SITES } from './study-sites.selectors';
import { Site } from '../../../model/study/sites/Site';
import { CREATE_SITE, CREATE_SITE_FAILURE, CREATE_SITE_FORM, CREATE_SITE_FORM_FAILURE, CREATE_SITE_FORM_SUCCESS, CREATE_SITE_SUCCESS, DELETE_SITE, DELETE_SITE_FAILURE, DELETE_SITE_SUCCESS, DOWNLOAD_SITE_FILE, GET_SITES, GET_SITES_FAILURE, GET_SITES_SUCCESS, ON_SITES_LIST, ON_SITES_SINGLE, UPDATE_SITE, UPDATE_SITE_FAILURE, UPDATE_SITE_FORM, UPDATE_SITE_FORM_FAILURE, UPDATE_SITE_FORM_SUCCESS, UPDATE_SITE_SUCCESS, UPLOAD_SITE_FILE, UPLOAD_SITE_FILE_FAILURE, UPLOAD_SITE_FILE_SUCCESS } from './study-sites.actions';
import { ErrorType } from '../../../errors/ErrorType';
import { FormValue } from '../../../model/study/FormValue';
import { saveAs } from 'file-saver';
import { GET_PARTICIPANTS } from '../participants/study-participants.actions';

@Injectable ( )
export class StudySitesEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                 private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_SITES_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_SITES_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_STUDY_SITES_SITES ) ),
        mergeMap ( ([action, sites]) => {
            if ( action.force || sites == null || sites.size == 0 )
            {
                return [ GET_SITES ( { op: 'list', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_SITES_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_SITES_SINGLE ),
        concatLatestFrom ( () => this.store.select ( SELECT_STUDY_SITES_SITES ) ),
        mergeMap ( ([action, sites]) => {
            if ( action.force || sites == null || sites.size == 0 )
            {
                return [ GET_SITES ( { op: 'single', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_SITES$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_SITES ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.study_api}/sites/`, { params } ) ),
            map ( response => {
                const sites = new Array<Site> ( );
                for ( const json of response )
                {
                    const site = Site.fromJson ( json );
                    if ( site )
                    {
                        sites.push ( site );
                    }
                }
                return sites;
            } ),
            map ( sites => GET_SITES_SUCCESS ( { op: action.op, sites } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_SITES_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );

    CREATE_SITE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_SITE ),
        map ( action => {
            return [ action.op, Site.toJson ( action.site ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.post<any> ( `${config.study_api}/sites/`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, Site.fromJson ( response ) ] as const ),
        map ( ( [ op, site ] ) => CREATE_SITE_SUCCESS ( { op, site } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( CREATE_SITE_FAILURE ( { op: CREATE_SITE.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );

    UPDATE_SITE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_SITE ),
        tap ( action => {
            if ( action.site.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update site without a valid id." );
            }
        } ),
        map ( action => {
            return [ action.op, Site.toJson ( action.site ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.put<any> ( `${config.study_api}/sites/`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, Site.fromJson ( response ) ] as const ),
        map ( ( [ op, site ] ) => UPDATE_SITE_SUCCESS ( { op, site } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( UPDATE_SITE_FAILURE ( { op: UPDATE_SITE.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );


    DELETE_SITE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DELETE_SITE ),
        tap ( action => {
            if ( action.id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete site without a valid id." );
            }
        } ),
        map ( action => {
            let params = new HttpParams ( );
            params = params.append ( 'id', String ( action.id ) );
            return [ action.op, action.id, params ] as const;
        } ),
        mergeMap ( ( [ op, id, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, id, config, params ] as const ) ) ),
        mergeMap ( ( [ op, id, config, params ] ) => this.http.delete<any> ( `${config.study_api}/sites/`, { params } ).pipe ( 
            map ( ( ) => [ op, id ] as const )
        ) ),
        map ( ( [ op, id ] ) => DELETE_SITE_SUCCESS ( { op, id } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( DELETE_SITE_FAILURE ( { op: DELETE_SITE.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );


    CREATE_SITE_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_SITE_FORM ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.site.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a site form without a valid site id." );
                }
                else if ( action.cp.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a site form without a valid collection point id." );
                }
                else if ( action.form.Id != null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to create a site form with a valid form id." );
                }
            } ),
            map ( action => {
                let maxFieldId: number | null = null;
                for ( const sv of action.form.SectionValues ? action.form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( maxFieldId == null && fv.Id != null )
                        {
                            maxFieldId = fv.Id;
                        }
                        else if ( maxFieldId != null && fv.Id != null && fv.Id > maxFieldId )
                        {
                            maxFieldId = fv.Id;
                        }
                    }
                }

                if ( maxFieldId == null )
                {
                    maxFieldId = 0;
                }

                return [ action, maxFieldId ] as const;
            } ),
            map ( ( [ action, maxFieldId ] ) => {

                let form = FormValue.clone ( action.form );
                for ( const sv of form.SectionValues ? form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( fv.Id == null )
                        {
                            fv.Id = ++maxFieldId;
                        }
                    }
                }

                return [ action, form ] as const;
            } ),
            map ( ( [ action, form ] ) => {
                return [ action.op, action.site, action.cp, FormValue.toJson ( form ) ] as const;
            } ),
            mergeMap ( ( [ op, site, cp, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, site, cp, config, body ] as const ) ) ),
            mergeMap ( ( [ op, site, cp, config, body] ) => this.http.post<any> ( `${config.study_api}/sites/forms/${site.Id}/`, body, 
                                                                                         { params: new HttpParams ( ).append ( "cp", cp.Id != null ? cp.Id : "" ).
                                                                                                                      append ( "site_type", site.MetaId ? site.MetaId : "" ) } ).pipe ( 
                map ( response => [ op, response ] as const )
            ) ),
            map ( ( [ op, response ] ) => [ op, Site.fromJson ( response ) ] as const ),
            map ( ( [ op, site ] ) => CREATE_SITE_FORM_SUCCESS ( { op, site } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( CREATE_SITE_FORM_FAILURE ( { op: CREATE_SITE_FORM.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );


    UPDATE_SITE_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_SITE_FORM ),
        switchMap ( action => of ( action ).pipe (
            tap ( action => {
                if ( action.site.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update site form without a valid site id." );
                }
                else if ( action.cpv.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update site form without a valid collection point id." );
                }
                else if ( action.form.Id == null )
                {
                    throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update site form without a valid form id." );
                }
            } ),
            map ( action => {
                let maxFieldId: number | null = null;
                for ( const sv of action.form.SectionValues ? action.form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( maxFieldId == null && fv.Id != null )
                        {
                            maxFieldId = fv.Id;
                        }
                        else if ( maxFieldId != null && fv.Id != null && fv.Id > maxFieldId )
                        {
                            maxFieldId = fv.Id;
                        }
                    }
                }

                if ( maxFieldId == null )
                {
                    maxFieldId = 0;
                }

                let form = FormValue.clone ( action.form );
                for ( const sv of form.SectionValues ? form.SectionValues : [] )
                {
                    for ( const fv of sv.FieldValues ? sv.FieldValues : [] )
                    {
                        if ( fv.Id == null )
                        {
                            fv.Id = ++maxFieldId;
                        }
                    }
                }

                return [ action, form ] as const;
            } ),
            map ( ( [ action, form ] ) => {
                return [ action.op, action.site, action.cpv, FormValue.toJson ( form ) ] as const;
            } ),
            mergeMap ( ( [ op, site, cpv, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, site, cpv, config, body ] as const ) ) ),
            mergeMap ( ( [ op, site, cpv, config, body] ) => this.http.put<any> ( `${config.study_api}/sites/forms/${site.Id}/${cpv.Id}/`, body, {observe: 'response'} ).pipe ( 
                map ( response => [ op, response ] as const )
            ) ),map ( ( [ op, response ] ) => [ op, response.body, response.headers.get ( "X-Participant-Refresh-Required" ) ] as const ),
            map ( ( [ op, response, refreshesRequired ] ) => [ op, Site.fromJson ( response ), refreshesRequired ] as const ),
            switchMap ( ( [ op, site, refreshesRequired ] ) => {
                let actions: Array<Action> = [ UPDATE_SITE_FORM_SUCCESS ( { op, site } ) ];
                if ( refreshesRequired && site.Id )
                {
                    for ( const participantType of refreshesRequired.split ( "," ) )
                    {
                        actions.push ( GET_PARTICIPANTS ( { op, siteId: site.Id, pType: +participantType, ids: null } ) );
                    }
                }

                return actions;
            } ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPDATE_SITE_FORM_FAILURE ( { op: UPDATE_SITE_FORM.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } ),
         ) )
    ) );


    UPLOAD_SITE_FILE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPLOAD_SITE_FILE ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let formData = new FormData ( );
                if ( action.site.Id ) formData.append ( 'site_id', action.site.Id.toString ( ) );
                if ( action.site.MetaId ) formData.append ( 'site_type', action.site.MetaId.toString ( ) );
                if ( action.cpv.Id ) formData.append ( 'cpv_id', action.cpv.Id.toString ( ) );
                if ( action.fv.Id ) formData.append ( 'form_value_id', action.fv.Id.toString ( ) );
                if ( action.field.Id ) formData.append ( 'field', action.field.Id.toString ( ) );
                if ( action.fieldValue.Id ) formData.append ( 'field_value', action.fieldValue.Id.toString ( ) );
                formData.append ( 'file', action.file, action.file.name );
                return [ action.site, action.field, action.fieldValue, formData ] as const;
            } ),
            mergeMap ( ( [ site, field, value, formData ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ site, field, value, config, formData ] as const ) ) ),
            mergeMap ( ( [ site, field, value, config, formData]) => this.http.post<any> ( `${config.study_api}/files/`, formData ).pipe (
                map ( response => [ site, field, value ] as const )
            ) ), // Make API request
            map ( ( [ site, field, fieldValue ] ) => UPLOAD_SITE_FILE_SUCCESS ( { op: UPLOAD_SITE_FILE.type, site, field, fieldValue } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( UPLOAD_SITE_FILE_FAILURE ( { op: UPLOAD_SITE_FILE.type, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );


    DOWNLOAD_SITE_FILE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DOWNLOAD_SITE_FILE ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                if ( action.site.Id ) params = params.set ( 'site_id', action.site.Id.toString ( ) );
                if ( action.site.MetaId ) params = params.set ( 'site_type', action.site.MetaId.toString ( ) );
                if ( action.cpv.Id ) params = params.set ( 'cpv_id', action.cpv.Id.toString ( ) );
                if ( action.fv.Id ) params = params.set ( 'form_value_id', action.fv.Id.toString ( ) );
                if ( action.field.Id ) params = params.set ( 'field', action.field.Id.toString ( ) );
                if ( action.fieldValue.Id ) params = params.set ( 'field_value', action.fieldValue.Id.toString ( ) );
                return [ action.site, action.field, action.fieldValue, params ] as const;
            } ),
            mergeMap ( ( [ site, field, value, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ value, config, params ] as const ) ) ),
            mergeMap ( ( [ value, config, params ] ) => this.http.get ( `${config.study_api}/files/`, { params, responseType: 'blob' } ).pipe (
                map ( response => [ response, value ] as const )
            ) ),
            filter ( ( [ response, value ] ) => value.Value != null ),
            tap ( ( [ response, value ]) => saveAs ( response, value.Value ? value.Value : "download.file" ) )
        ) )
    ), { dispatch: false } );
}
