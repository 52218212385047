import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DirectAccessGuard implements CanActivate
{
    constructor ( private router: Router )
    {
        // Null.
    }

    canActivate ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ) : Observable<boolean> | Promise<boolean> | boolean
    {
        // If the previous URL was blank, then the user is directly accessing this page
        if ( this.router.url === '/' )
        {
            // Navigate away to some other page
            this.router.navigate ( [ '' ] );
            return false;
        }

        return true;
    }
}