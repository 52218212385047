import { SectionValue } from "./SectionValue";
import * as moment from "moment";
import { Form } from "../published/Form";
import { FormValue } from "./FormValue";
import { SectionValueFactory } from "./SectionValueFactory";
import { ExpressionEngine } from "../../expressions/ExpressionEngine";
import { CurrentUserUtilsService } from "../../services/current.user.utils.service";
import { SubjectUtils } from "./SubjectUtils";


export class FormValueFactory
{
    readonly sectionValueFactory: SectionValueFactory;

    constructor ( private engine: ExpressionEngine, private currentUser: CurrentUserUtilsService, private userRoles: string[] )
    {
        this.sectionValueFactory = new SectionValueFactory ( engine );
    }

    fromMeta ( meta: Form, vals: Map<string, boolean | number | string | moment.Moment | null> ) : FormValue
    {
        let formValue = new FormValue ( );
        formValue.MetaId = meta.Id;
        formValue.SectionValues = new Array<SectionValue> ( )
        for ( const section of meta.Sections ? meta.Sections : [] )
        {
            // Only create sections that are allowed.
            if ( this.currentUser.hasSectionEditAccess ( section, this.userRoles ) )
            {
                const sectionValue = this.sectionValueFactory.fromMeta ( section, vals );
                if ( sectionValue )
                {
                    formValue.SectionValues.push ( sectionValue );
                }
            }
        }

        return formValue;
    }

    // NOTE: If cloning an existing Form, then if any sections are hidden from the user due to ACLs, these will be lost. Be warned! Use carefully.
    clone ( meta: Form, existing: FormValue, vals: Map<string, boolean | number | string | moment.Moment | null> ) : FormValue
    {
        let formValue = new FormValue ( );
        formValue.Id = existing.Id;
        formValue.Version = existing.Version;
        formValue.MetaId = existing.MetaId;
        formValue.Completed = existing.Completed;
        formValue.SectionValues = new Array<SectionValue> ( );
        for ( const existingSectionValue of existing.SectionValues ? existing.SectionValues : [] )
        {
            if ( existingSectionValue.MetaId != null )
            {
                const section = new SubjectUtils ( ).getSection ( meta, existingSectionValue.MetaId );
                if ( section )
                {
                    const sectionValue = this.sectionValueFactory.clone ( section, existingSectionValue, vals );
                    if ( sectionValue )
                    {
                        formValue.SectionValues.push ( sectionValue );
                    }
                }
            }
        }

        return formValue;
    }
} 