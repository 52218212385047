import { Field } from './Field';
import { FieldType } from './FieldType';

export class ListField extends Field
{
    public static clone( input: ListField, fieldClone: (field: Field) => Field | null ): ListField
    {
        let output = Field._clone ( input, new ListField ( ) );
        output.SubField = input.SubField ? fieldClone ( input.SubField ) : null;
        output.MinimumCount = input.MinimumCount;
        output.MaximumCount = input.MaximumCount;
        output.AddDescription = input.AddDescription;
        return output;
    }

    public static toJson( field: ListField, fieldToJson: (field: Field) => any ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.sub_field = field.SubField ? fieldToJson ( field.SubField ) : null;
        out.minimum_count = field.MinimumCount;
        out.maximum_count = field.MaximumCount;
        out.add_description = field.AddDescription;
        
        return out;
    }

    public static fromJson( json: any, fieldFromJson: (field: any) => Field | null ): ListField
    {
        let out = new ListField ( );

        out = Field._fromJson ( json, out );

        out.SubField = json.sub_field ? fieldFromJson ( json.sub_field ) : null;
        out.MinimumCount = json.minimum_count as number;
        out.MaximumCount = json.maximum_count as number;
        out.AddDescription = json.add_description as string;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private subField: Field | null  = null,
                        private minimumCount: number | null = null,
                        private maximumCount: number | null = null,
                        private addDescription: string | null = null, )
    {
       super ( id, version, seq, FieldType.LIST, variable, name, description, required );
    }

    public get SubField( ): Field | null
    {
        return this.subField;
    }

    public set SubField( subField: Field | null )
    {
        this.subField = subField;
    }

    public get MinimumCount( ): number | null
    {
        return this.minimumCount;
    }

    public set MinimumCount( minimumCount: number | null )
    {
        this.minimumCount = minimumCount;
    }

    public get MaximumCount( ): number | null
    {
        return this.maximumCount;
    }

    public set MaximumCount( maximumCount: number | null )
    {
        this.maximumCount = maximumCount;
    }

    public get AddDescription( ): string | null
    {
        return this.addDescription;
    }

    public set AddDescription( addDescription: string | null )
    {
        this.addDescription = addDescription;
    }
}
