import { Injectable } from "@angular/core";
import { combineLatest, forkJoin, merge, Observable, of } from "rxjs";
import { map, toArray } from "rxjs/operators";
import { Participant } from "../model/study/participants/Participant";
import { ParticipantMeta } from "../model/published/participants/ParticipantMeta";
import { ExpressionEngineFactory } from "../expressions/ExpressionEngineFactory";
import { PublishedParticipantsService } from "./published/published-participants.service";
import { StudyParticipantsService } from "./study/study-participants.service";
import { CollectionPoint } from "../model/published/CollectionPoint";
import { CollectionPointValue } from "../model/study/CollectionPointValue";
import { Form } from "../model/published/Form";
import { FormValue } from "../model/study/FormValue";
import { ParticipantUtils } from "../model/study/participants/ParticipantUtils";
import { FileField } from "../model/published/FileField";
import { FileFieldValue } from "../model/study/FileFieldValue";
import * as moment from "moment";
import { DocumentMeta } from "../model/published/documents/DocumentMeta";


@Injectable ( {
    providedIn: 'root'
} )
export class StudyParticipantsFascadeService
{
    constructor( private published: PublishedParticipantsService, private participants: StudyParticipantsService, private eFactory: ExpressionEngineFactory )
    {
        // Null.
    }

    public onList ( type: number, siteId: number, force = false )
    {
        this.published.onList ( force );
        this.participants.onList ( type, siteId, force );
    }

    public onSingle ( type: number, siteId: number, id: number | null = null, force = false )
    {
        // This needs optimising at somepoint...
        this.published.onSingle ( type, force );
        if ( id )
        {
            this.participants.onSingle ( type, siteId, id, force );
        }
    }

    getLoading( type: number ): Observable<boolean>
    {
        return combineLatest ( [ this.published.getLoading ( ), this.participants.getLoading ( type ) ] ).pipe (
            map ( ( [ published, participants ] ) => ( published || participants ) )  
        );
    }

    getSaving( type: number ): Observable<boolean>
    {
        return this.participants.getSaving ( type );
    }

    getAllLoading( ): Observable<boolean>
    {
        return combineLatest ( [ this.published.getLoading ( ), this.participants.getAllLoading ( ) ] ).pipe (
            map ( ( [ published, participants ] ) => ( published || participants ) )  
        );
    }

    onPublishedList ( force = false )
    {
        this.published.onList ( force );
    }

    getStudy ( ) : StudyParticipantsService
    {
        return this.participants;
    }

    getPublished ( ) : PublishedParticipantsService
    {
        return this.published;
    }

    getParticipant ( type: number, siteId: number, id: number ) : Observable<[Participant, ParticipantMeta] | null>
    {
        return this.getParticipants ( type, siteId ).pipe (
            map ( participants => {
                if ( participants )
                {
                    let participant = participants.get ( id );
                    if ( participant )
                    {
                        return participant;
                    }
                }

                return null;
            } )
        );
    }

    getParticipantList ( inputs: Array<{type: number, site: number, part: number}> ) : Observable<Map<number, [Participant, ParticipantMeta]> | null>
    {
        if ( inputs && inputs.length > 0 )
        {
            let reqs = new Array<Observable<[Participant, ParticipantMeta] | null>> ( );
            for ( const input of inputs )
            {
                reqs.push ( this.getParticipant ( input.type, input.site, input.part ) );
            }

            return combineLatest ( reqs ).pipe ( 
                // toArray ( ),
                map ( array => {
                    let mappy = new Map<number, [Participant, ParticipantMeta]> ( );

                    for ( const part of array )
                    {
                        if ( part && part[0] && part[0].Id && part[1] )
                        {
                            mappy.set ( part[0].Id, part );
                        }
                    }

                    return mappy;
                })
            );
        }
        else
        {
            return of ( null );
        }
    }

    public getAllParticipants ( ) : Observable<Map<number, [Participant, ParticipantMeta]> | null>
    {
        return combineLatest ( [ this.published.getParticipants ( ), this.participants.getAllParticipants ( ) ] ).pipe (
            map ( ( [ meta, participants ] ) => {
                if ( meta && participants )
                {
                    let out = new Map<number, [Participant, ParticipantMeta]> ( );
                    for ( let [id, participant] of participants )
                    {
                        if ( participant.MetaId )
                        {
                            let type = meta.get ( participant.MetaId );
                            if ( type )
                            {
                                out.set ( id, [participant, type] );
                            }
                        }
                    }

                    return out;
                }
                return null;
            } )
        );
    }

    public getParticipants ( type: number, siteId: number ) : Observable<Map<number, [Participant, ParticipantMeta]> | null>
    {
        return combineLatest ( [ this.published.getParticipants ( ), this.participants.getParticipants ( type, siteId ) ] ).pipe (
            map ( ( [ meta, participants ] ) => {
                if ( meta && participants )
                {
                    let out = new Map<number, [Participant, ParticipantMeta]> ( );
                    for ( let [id, participant] of participants )
                    {
                        if ( participant.MetaId )
                        {
                            let type = meta.get ( participant.MetaId );
                            if ( type )
                            {
                                out.set ( id, [participant, type] );
                            }
                        }
                    }

                    return out;
                }
                return null;
            } )
        );
    }

    getEligibleParticipants ( type: number, siteId: number ) : Observable<Map<number, [Participant, ParticipantMeta]> | null>
    {
        return this.getParticipants ( type, siteId ).pipe (
            map ( participants => {
                if ( participants )
                {
                    const eligibleParticipant = new Map<number, [Participant, ParticipantMeta]> ( );
                    for ( const participant of participants.values ( ) )
                    {
                        if ( participant[0].Id && this.isParticipantEligible ( participant[1], participant[0] ) )
                        {
                            eligibleParticipant.set ( participant[0].Id, participant );
                        }
                    }
                    return eligibleParticipant;
                }

                return null;
            } )
        );
    }

    public getParticipantForm ( participant$: Observable<[Participant, ParticipantMeta]| [Participant, ParticipantMeta, Map<number, [Participant, ParticipantMeta]>] | null>, formId: number ) : Observable<[FormValue, Form, CollectionPointValue, CollectionPoint] | null>
    {
        return participant$.pipe (
            map ( response => {
                if ( response && response[0] && response[1] )
                {
                    const participantUtils = new ParticipantUtils ( );
                    for ( const cpv of response[0].AllCollectionPointValues )
                    {
                        if ( cpv.MetaId != null )
                        {
                            const formValue = participantUtils.getFormValue ( cpv, formId );
                            if ( formValue && formValue.MetaId != null )
                            {
                                const cp = participantUtils.getCollectionPoint ( response[1], cpv.MetaId );
                                if ( cp )
                                {
                                    const form = participantUtils.getForm ( cp, formValue.MetaId );
                                    if ( form )
                                    {
                                        return [formValue, form, cpv, cp];
                                    }
                                }
                            }
                        }
                    }
                }
              
                return null;
            } )
        );
    }

    public getParticipantFormByName ( participant$: Observable<[Participant, ParticipantMeta] | null>, name: string ) : Observable<[FormValue, Form, CollectionPointValue, CollectionPoint] | null>
    {
        return participant$.pipe (
            map ( response => {
                if ( response && response[0] && response[1] )
                {
                    for ( const cp of response[1].AllCollectionPoints )
                    {
                        const cpFragment = `${cp.Variable}`;
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            const formFragment = `${cpFragment}.${form.Variable}`;
                            if ( name.startsWith ( formFragment ) )
                            {
                                for ( const cpv of response[0].AllCollectionPointValues )
                                {
                                    for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                    {
                                        if ( formValue.MetaId == form.Id )
                                        {
                                            return [formValue, form, cpv, cp];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
              
                return null;
            } )
        );
    }

    public getParticipantForms ( site$: Observable<[Participant, ParticipantMeta] | null> ) : Observable<Array<[FormValue | null, Form]>>
    {
        return site$.pipe (
            map ( response => {
                let results = new Array<[FormValue | null, Form]> ( );
                if ( response && response[0] && response[1] )
                {
                    for ( const cp of response[1].AllCollectionPoints )
                    {
                        for ( const form of cp.Forms ? cp.Forms : [] )
                        {
                            let found = false;
                            for ( const cpv of response[0].AllCollectionPointValues )
                            {
                                for ( const formValue of cpv.FormValues ? cpv.FormValues : [] )
                                {
                                    if ( formValue.MetaId == form.Id )
                                    {
                                        results.push ( [formValue, form] );
                                        found = true;
                                        break;
                                    }
                                }

                                if ( found )
                                {
                                    break;
                                }
                            }

                            if ( found == false )
                            {
                                results.push ( [ null, form ] );
                            }
                        }
                    }
                }
              
                return results;
            } )
        );
    }

    public isParticipantEligible ( type: ParticipantMeta | null, participant: Participant | null ) : boolean
    {
        let eligible = true; // Default to being eligible, as if no expression is defined, all sites are eligible
        if ( type && participant )
        {
            if ( type.EligibilityExpression && type.Variable )
            {
                const engine = this.eFactory.create ( );
                const values = new ParticipantUtils ( ).getSubjectVariableValues ( type, participant, type.Variable, null );
                const res = engine.eval ( type.EligibilityExpression, values );
                if ( typeof res == "boolean" )
                {
                    return res;
                }
                else
                {
                    // Type conversion issue!
                    return false;
                }
            }

        }
      
        return eligible;
    }

    public isParticipantEligible$ ( participant$: Observable<[Participant, ParticipantMeta] | null> ) : Observable<boolean>
    {
        return participant$.pipe (
            map ( response => {
                if ( response )
                {
                    return this.isParticipantEligible ( response[1], response[0]);
                }
              
                return false;
            } )
        );
    }
    
    public createParticipant ( participant: Participant, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.participants.createParticipant ( participant );
    }

    public updateParticipant ( participant: Participant, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.participants.updateParticipant ( participant );
    }

    public deleteParticipant ( participant: Participant )
    {
        if ( participant.MetaId != null && participant.SiteId != null && participant.Id != null )
        {
            this.participants.deleteParticipant ( participant.MetaId, participant.SiteId, participant.Id );
        }
    }

    public deleteParticipants ( type: number, siteId: number )
    {
        this.participants.deleteParticipants ( type, siteId );
    }

    public updateForm ( participant: Participant, cpv: CollectionPointValue, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.participants.updateForm ( participant, cpv, form, fileUploads );

        for ( const [fileField, fileFieldValue, file] of fileUploads ? fileUploads : [] )
        {
            this.participants.uploadFile ( participant, cpv, form, fileField, fileFieldValue, file );
        }
    }

    public createForm ( participant: Participant, cp: CollectionPoint, form: FormValue, fileUploads: Array<[FileField, FileFieldValue, File]> | null )
    {
        this.participants.createForm ( participant, cp, form, fileUploads );
    }

    public downloadFile ( participant: Participant, cpv: CollectionPointValue, fv: FormValue, field: FileField, fieldValue: FileFieldValue )
    {
        this.participants.downloadFile ( participant, cpv, fv, field, fieldValue );
    }

    public downloadDocument ( participant: Participant, doc: DocumentMeta )
    {
        this.participants.downloadDocument ( participant, doc );
    }
}