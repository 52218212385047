import { createAction, props } from '@ngrx/store';
import { ErrorDetails } from '../../../errors/ErrorDetails';
import { LandingPage } from '../../../model/published/pages/LandingPage';
import { SideBar } from '../../../model/published/pages/SideBar';
import { SitesPage } from '../../../model/published/pages/SitesPage';

export const ON_PUBLISHED_PAGES_LANDING   = createAction ( 'shared.published-pages.landing', props<{ force: boolean }> ( ) );
export const ON_PUBLISHED_PAGES_SITES     = createAction ( 'shared.published-pages.sites', props<{ force: boolean }> ( ) );
export const ON_PUBLISHED_PAGES_SIDE_BAR  = createAction ( 'shared.published-pages.side-bar', props<{ force: boolean }> ( ) );


export const GET_PUBLISHED_PAGES_LANDING         = createAction ( 'shared.published-pages.landing.get', props<{ op: string }> ( ) );
export const GET_PUBLISHED_PAGES_LANDING_SUCCESS = createAction ( 'shared.published-pages.landing.get.success', props<{ op: string, landingPage: LandingPage }> ( ) );
export const GET_PUBLISHED_PAGES_LANDING_FAILURE = createAction ( 'shared.published-pages.landing.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const GET_PUBLISHED_PAGES_SITES         = createAction ( 'shared.published-pages.sites.get', props<{ op: string }> ( ) );
export const GET_PUBLISHED_PAGES_SITES_SUCCESS = createAction ( 'shared.published-pages.sites.get.success', props<{ op: string, sitesPage: SitesPage }> ( ) );
export const GET_PUBLISHED_PAGES_SITES_FAILURE = createAction ( 'shared.published-pages.sites.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );


export const GET_PUBLISHED_PAGES_SIDE_BAR         = createAction ( 'shared.published-pages.side-bar.get', props<{ op: string }> ( ) );
export const GET_PUBLISHED_PAGES_SIDE_BAR_SUCCESS = createAction ( 'shared.published-pages.side-bar.get.success', props<{ op: string, sideBar: SideBar }> ( ) );
export const GET_PUBLISHED_PAGES_SIDE_BAR_FAILURE = createAction ( 'shared.published-pages.side-bar.get.failure', props<{ op: string, errorDetails: ErrorDetails }> ( ) );