import { Field } from './Field';
import { FieldType } from './FieldType';


export class BooleanField extends Field
{
    public static clone( input: BooleanField ): BooleanField
    {
        let output = Field._clone ( input, new BooleanField ( ) );
        output.YesLabel = input.YesLabel;
        output.YesNumeric = input.YesNumeric;
        output.NoLabel = input.NoLabel;
        output.NoNumeric = input.NoNumeric;
        return output;
    }

    public static toJson( field: BooleanField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.yes_label = field.YesLabel;
        out.yes_numeric = field.YesNumeric;
        out.no_label = field.NoLabel;
        out.no_numeric = field.NoNumeric;
        
        return out;
    }

    public static fromJson( json: any ): BooleanField
    {
        let out = new BooleanField ( );

        out = Field._fromJson ( json, out );

        out.YesLabel = json.yes_label as string;
        out.YesNumeric = json.yes_numeric as number;
        out.NoLabel = json.no_label as string;
        out.NoNumeric = json.no_numeric as number;

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private yesLabel: string | null  = 'Yes',
                        private yesNumeric: number | null = 1,
                        private noLabel: string | null  = 'No',
                        private noNumeric: number | null  = 0 )
    {
       super ( id, version, seq, FieldType.BOOLEAN, variable, name, description, required );
    }

    public get YesLabel( ): string | null
    {
        return this.yesLabel;
    }

    public set YesLabel( yesLabel: string | null )
    {
        this.yesLabel = yesLabel;
    }

    public get YesNumeric( ): number | null
    {
        return this.yesNumeric;
    }

    public set YesNumeric( yesNumeric: number | null )
    {
        this.yesNumeric = yesNumeric;
    }

    public get NoLabel( ): string | null
    {
        return this.noLabel;
    }

    public set NoLabel( noLabel: string | null )
    {
        this.noLabel = noLabel;
    }

    public get NoNumeric( ): number | null
    {
        return this.noNumeric;
    }

    public set NoNumeric( noNumeric: number | null )
    {
        this.noNumeric = noNumeric;
    }
}
