<form [formGroup]="form">
    <input type="hidden" formControlName="timestamp">

    <table class="table table-striped" formArrayName="fields">
        <tbody>
            <tr *ngFor="let field of Fields.controls; let i = index">
                <td scope="row" class="align-middle col-1 text-center">
                    <small class="text-muted">{{i + 1}}</small>
                </td>
                <td scope="row" class="align-middle my-auto col-10">
                    <ng-container [ngSwitch]="subMeta?.Field?.Type">
                                    
                        <lib-boolean-field *ngSwitchCase="FIELD_TYPE.BOOLEAN" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.BooleanField" [stateVector]="stateVector" [formControlName]="i"></lib-boolean-field>
        
                        <lib-date-field *ngSwitchCase="FIELD_TYPE.DATE" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.DateField" [stateVector]="stateVector" [formControlName]="i"></lib-date-field>
        
                        <lib-enumerated-field *ngSwitchCase="FIELD_TYPE.ENUMERATED" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.EnumeratedField" [stateVector]="stateVector" [formControlName]="i"></lib-enumerated-field>
        
                        <lib-numeric-field *ngSwitchCase="FIELD_TYPE.NUMERIC" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.NumericField" [stateVector]="stateVector" [formControlName]="i"></lib-numeric-field>
        
                        <lib-string-field *ngSwitchCase="FIELD_TYPE.STRING" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.StringField" [stateVector]="stateVector" [formControlName]="i"></lib-string-field>
        
                        <lib-participant-field *ngSwitchCase="FIELD_TYPE.PARTICIPANT" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.ParticipantField" [stateVector]="stateVector" [formControlName]="i" [siteId]="siteId"></lib-participant-field>
        
                        <!-- <lib-file-field *ngSwitchCase="FIELD_TYPE.FILE" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.FileField" [stateVector]="stateVector" [formControlName]="i" (fileUpload)="onFileUpload(field.FileField, i, $event)" (fileDownload)="onFileDownload($event[0], $event[1])"></lib-file-field> -->
        
                        <!-- <lib-list-field *ngSwitchCase="FIELD_TYPE.LIST" [permanentlyDisable]="permanentlyDisable || disabled" [meta]="subMeta.ListField" [stateVector]="stateVector" [formControlName]="i"></lib-list-field> -->
        
                        <div class="alert alert-warning" role="alert" *ngSwitchDefault>
                            The form type '{{subMeta?.Field?.Type}}' is not currently supported.
                        </div>
        
                    </ng-container>
                </td>
                <td scope="row" class="align-middle col-1 text-right" *ngIf="!fixedSize">
                    <button class="btn btn-sm btn-danger" (click)="onRemove(i)" [disabled]="disabled || (Fields && Fields.controls && meta && meta.MinimumCount && Fields.controls.length <= meta.MinimumCount)">Remove</button> 
                </td>
            </tr>
            <tr *ngIf="!fixedSize">
                <td class="col-1">&nbsp;</td>
                <td class="col-10 align-middle text-right">
                    <small *ngIf="(Fields && Fields.controls && meta && meta.MaximumCount && Fields.controls.length >= meta.MaximumCount); else minimumCount" class="text-muted">Maximum number of entries already created.</small>
                    <ng-template #minimumCount>
                        <small class="text-muted">
                            <ng-container *ngIf="meta && meta.AddDescription && meta.AddDescription.length > 0">
                                <div [innerHtml]="meta.AddDescription"></div>
                            </ng-container>
                            <ng-template #defaultAddDescription>
                                Click here to add another entry.
                            </ng-template>
                        </small>
                    </ng-template>
                </td>
                <td class="col-1 text-right">
                    <button class="btn btn-sm btn-secondary" (click)="onAdd()" [disabled]="disabled || (Fields && Fields.controls && meta && meta.MaximumCount && Fields.controls.length >= meta.MaximumCount)">Add</button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="form-group">
        <input type="hidden" [ngClass]="{'is-invalid': DoesntHaveRequiredNumber}">
        <div class="invalid-feedback">
            Value is required
        </div>
    </div>
</form>