import { ChangeDetectorRef, Component, forwardRef, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateFieldValue } from '../../model/study/DateFieldValue';
import { map } from 'rxjs/operators';
import { DateField } from '../../model/published/DateField';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { FieldComponent } from './field.component';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
 @Injectable()
 export class CustomAdapter extends NgbDateAdapter<string>
 {
   readonly DELIMITER = '/';
 
   fromModel ( value: string | null ): NgbDateStruct | null
   {
     if ( value )
     {
       if ( typeof value == "string" )
       {
        let date = value.split ( this.DELIMITER );
        return {
          day : parseInt(date[0], 10),
          month : parseInt(date[1], 10),
          year : parseInt(date[2], 10) < 100 ? parseInt(date[2], 10) + 2000 : parseInt(date[2], 10)
        };
      }
      else
      {
        return value;
      }
     }

     return null;
   }
 
   toModel ( date: NgbDateStruct | null ): string | null
   {
     return date ? `${String(date.day).padStart(2, "0")}${this.DELIMITER}${String(date.month).padStart(2, "0")}${this.DELIMITER}${date.year < 100 ? date.year + 2000 : date.year}` : null;
   }
 }
 
 /**
  * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
  */
 @Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter
 {
   readonly DELIMITER = '/';
 
   parse ( value: string ): NgbDateStruct | null
   {
     if ( value )
     {
      let date = value.split ( this.DELIMITER );
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10) < 100 ? parseInt(date[2], 10) + 2000 : parseInt(date[2], 10)
      };
     }

     return null;
   }
 
   format ( date: NgbDateStruct | null ): string
   {
     return date ? `${String(date.day).padStart(2, "0")}${this.DELIMITER}${String(date.month).padStart(2, "0")}${this.DELIMITER}${date.year < 100 ? date.year + 2000 : date.year}` : '';
   }
 }

@Component({
  selector: 'lib-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => DateFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => DateFieldComponent ),
      multi: true
    },
    {
      provide: NgbDateAdapter,
      useClass: CustomAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class DateFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: DateField | null = null;

  value: DateFieldValue | null = null;

  maximum = {year: new Date().getUTCFullYear(), month: new Date().getUTCDate(), day: new Date().getUTCMonth()+1};

  model!: NgbDateStruct;

  disabled = false;

  constructor ( fb: FormBuilder, private dateAdapter: NgbDateAdapter<string>, eFactory: ExpressionEngineFactory, private ref: ChangeDetectorRef, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  get ValueFormControl ( ) : FormControl
  { 
    return this.form.get ( 'value' ) as FormControl;
  }

  ngOnInit ( )
  {
    this.form = this.fb.group ( {
      value: [null, this.meta?.Required ? [ Validators.required ] : null ],
      timestamp: null
    } );
    this.maximum = { year: <any>this.meta?.Maximum?.year(),
                     month: <any>this.meta?.Maximum?.month() + 1,
                     day: <any>this.meta?.Maximum?.date() }
  }

  writeValue ( val: any )
  {
    this.value = DateFieldValue.clone ( val as DateFieldValue );
    if ( this.value && this.value.Value )
    {
      const date = this.value.Value;
      this.model = { year: date.year ( ),
                     month: date.month ( ) + 1,
                     day: date.date ( ) }
      this.form.patchValue ( { "value": this.model, "timestamp": this.value.Timestamp }, { emitEvent: false } );
    }
  }

  registerOnChange ( fn: any )
  {
    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          const val = this.dateAdapter.fromModel ( change['value'] );
          if ( val && !isNaN(val?.day) && !isNaN(val?.month) && !isNaN(val?.year) )
          {
            val['month'] = val['month'] - 1;
            this.value.Value = moment ( val );
          }
          else
          {
            this.value.Value = null;
          }
          this.value.Timestamp = change['timestamp'];
          return this.value
        }
        else
        {
          return change;
        }
      } ),
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    this.disabled = disable;
    if ( disable )
    {
      if (this.ValueFormControl.enabled) this.ValueFormControl.disable ( );
    }
    else
    {
      if (this.ValueFormControl.disabled) this.ValueFormControl.enable ( );
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    if ( results && "validityExpression" in results )
    {
      const combo = { ...this.ValueFormControl.errors, ... results };
      this.ValueFormControl.setErrors ( combo );
    }
    else if ( this.ValueFormControl.errors && Object.keys ( this.ValueFormControl.errors ).length > 0 )
    {
      let existing = { ...this.ValueFormControl.errors };
      delete existing["validityExpression"];
      this.ValueFormControl.setErrors ( existing );
    }
    else
    {
      this.ValueFormControl.setErrors ( null );
    }
  }
}
