import { createReducer, on } from '@ngrx/store';
import { SystemUsersState } from './system-users.state';
import { CREATE_USER, CREATE_USER_FAILURE, CREATE_USER_SUCCESS, DISABLE_USER, DISABLE_USER_FAILURE, DISABLE_USER_SUCCESS, ENABLE_USER, ENABLE_USER_FAILURE, ENABLE_USER_SUCCESS, GET_USERS, GET_USERS_FAILURE, GET_USERS_SUCCESS, RESET_USER_PASSWORD, RESET_USER_PASSWORD_FAILURE, RESET_USER_PASSWORD_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from './system-users.actions';
import { User } from '../../../model/system/users/User';

export const INITIAL_STATE = new SystemUsersState ( );

const _SYSTEM_USERS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_USERS, ( state, { op, ids } ) => {
      const newState = { ...state };

      newState.users = new Map<string, User> ( );
      newState.loading = true;

      return newState;
    } ),
    on ( GET_USERS_SUCCESS, ( state, { op, users } ) => {
      const newState = { ...state };

      newState.users = new Map<string, User> ( );
      newState.loading = false;

      for ( const user of users ? users : [] )
      {
        if ( user.Id )
        {
          newState.users.set ( user.Id, user );
        }
      }
      
      return newState;
    } ),
    on ( GET_USERS_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.users = new Map<string, User> ( );
      newState.loading = false;

      return newState;
    } ),


    on ( CREATE_USER, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = true;

      return newState;
    } ),
    on ( CREATE_USER_SUCCESS, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = false;
      if ( user.Id )
      {
        newState.users = new Map ( newState.users );
        newState.users.set ( user.Id, user );
      }
      
      return newState;
    } ),
    on ( CREATE_USER_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;

      return newState;
    } ),


    on ( UPDATE_USER, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = true;

      return newState;
    } ),
    on ( UPDATE_USER_SUCCESS, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = false;
      if ( user.Id )
      {
        newState.users = new Map ( newState.users );
        newState.users.set ( user.Id, user );
      }
      
      return newState;
    } ),
    on ( UPDATE_USER_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;

      return newState;
    } ),


    on ( DISABLE_USER, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = true;

      return newState;
    } ),
    on ( DISABLE_USER_SUCCESS, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = false;
      if ( user.Id )
      {
        newState.users = new Map ( newState.users );
        newState.users.set ( user.Id, user );
      }
      
      return newState;
    } ),
    on ( DISABLE_USER_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;

      return newState;
    } ),


    on ( ENABLE_USER, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = true;

      return newState;
    } ),
    on ( ENABLE_USER_SUCCESS, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = false;
      if ( user.Id )
      {
        newState.users = new Map ( newState.users );
        newState.users.set ( user.Id, user );
      }
      
      return newState;
    } ),
    on ( ENABLE_USER_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;

      return newState;
    } ),


    on ( RESET_USER_PASSWORD, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = true;

      return newState;
    } ),
    on ( RESET_USER_PASSWORD_SUCCESS, ( state, { op, user } ) => {
      const newState = { ...state };

      newState.saving = false;
      if ( user.Id )
      {
        newState.users = new Map ( newState.users );
        newState.users.set ( user.Id, user );
      }
      
      return newState;
    } ),
    on ( RESET_USER_PASSWORD_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;

      return newState;
    } ),
);

export function SYSTEM_USERS_REDUCER( state: any, action: any )
{
  return _SYSTEM_USERS_REDUCER ( state, action );
}
