import * as moment from 'moment';
import { ExpressionEngine } from '../../expressions/ExpressionEngine';
import { Field } from '../published/Field';
import { FieldType, FieldTypeUtils } from '../published/FieldType';
import { BooleanFieldValue } from './BooleanFieldValue';
import { DateFieldValue } from './DateFieldValue';
import { EnumeratedFieldValue } from './EnumeratedFieldValue';
import { FieldValue } from './FieldValue';
import { NumericFieldValue } from './NumericFieldValue';
import { ParticipantFieldValue } from './ParticipantFieldValue';
import { StringFieldValue } from './StringFieldValue';
import { FileFieldValue } from './FileFieldValue';
import { ListFieldValue } from './ListFieldValue';



export class FieldValueFactory
{
    public static clone<T extends FieldValue>( field: T ): FieldValue
    {
        if ( field instanceof BooleanFieldValue )
        {
            return BooleanFieldValue.clone ( field );
        }
        else if ( field instanceof DateFieldValue )
        {
            return DateFieldValue.clone ( field );
        }
        else if ( field instanceof EnumeratedFieldValue )
        {
            return EnumeratedFieldValue.clone ( field );
        }
        else if ( field instanceof NumericFieldValue )
        {
            return NumericFieldValue.clone ( field );
        }
        else if ( field instanceof StringFieldValue )
        {
            return StringFieldValue.clone ( field );
        }
        else if ( field instanceof ParticipantFieldValue )
        {
            return ParticipantFieldValue.clone ( field );
        }
        else if ( field instanceof FileFieldValue )
        {
            return FileFieldValue.clone ( field );
        }
        else if ( field instanceof ListFieldValue )
        {
            return ListFieldValue.clone ( field, FieldValueFactory.clone );
        }
        else
        {
            console.warn ( `Encountered unknown field type: ${field.Type}.` );
            return field;
        }
    }

    public static toJson<T extends FieldValue>( field: T ): any
    {
        if ( field instanceof BooleanFieldValue )
        {
            return BooleanFieldValue.toJson ( field );
        }
        else if ( field instanceof DateFieldValue )
        {
            return DateFieldValue.toJson ( field );
        }
        else if ( field instanceof EnumeratedFieldValue )
        {
            return EnumeratedFieldValue.toJson ( field );
        }
        else if ( field instanceof NumericFieldValue )
        {
            return NumericFieldValue.toJson ( field );
        }
        else if ( field instanceof StringFieldValue )
        {
            return StringFieldValue.toJson ( field );
        }
        else if ( field instanceof ParticipantFieldValue )
        {
            return ParticipantFieldValue.toJson ( field );
        }
        else if ( field instanceof FileFieldValue )
        {
            return FileFieldValue.toJson ( field );
        }
        else if ( field instanceof ListFieldValue )
        {
            return ListFieldValue.toJson ( field, FieldValueFactory.toJson );
        }
        else
        {
            console.error ( `Encountered unknown field type: ${field.Type}.` );
            return null;
        }
    }

    public static fromJson( json: any ): FieldValue | null
    {
        const type = FieldTypeUtils.fromString ( json.type );
        switch ( type )
        {
        case FieldType.BOOLEAN:
            return BooleanFieldValue.fromJson ( json );
        case FieldType.DATE:
            return DateFieldValue.fromJson ( json );
        case FieldType.ENUMERATED:
            return EnumeratedFieldValue.fromJson ( json );
        case FieldType.NUMERIC:
            return NumericFieldValue.fromJson ( json );
        case FieldType.STRING:
            return StringFieldValue.fromJson ( json );
        case FieldType.PARTICIPANT:
            return ParticipantFieldValue.fromJson ( json );
        case FieldType.FILE:
            return FileFieldValue.fromJson ( json );
        case FieldType.LIST:
            return ListFieldValue.fromJson ( json, FieldValueFactory.fromJson );
        case FieldType.NONE:
        default:
            console.error ( `Encountered unknown field type: ${type}.` );
            return null;
        }
    }

    public static fromType ( type: FieldType ) : FieldValue | null
    {
        switch ( type )
        {
        case FieldType.BOOLEAN:
            return new BooleanFieldValue ( );
        case FieldType.DATE:
            return new DateFieldValue ( );
        case FieldType.ENUMERATED:
            return new EnumeratedFieldValue ( );
        case FieldType.NUMERIC:
            return new NumericFieldValue ( );
        case FieldType.STRING:
            return new StringFieldValue ( );
        case FieldType.PARTICIPANT:
            return new ParticipantFieldValue ( );
        case FieldType.FILE:
            return new FileFieldValue ( );
        case FieldType.LIST:
            return new ListFieldValue ( );
        case FieldType.NONE:
        default:
            console.error ( `Encountered unknown field type: ${type}.` );
            return null;
        }
    }

    public constructor ( private engine: ExpressionEngine )
    {
        // Null.
    }

    public fromMeta ( meta: Field, vals: Map<string, boolean | number | string | moment.Moment | null> ) : FieldValue | null
    {
        let fieldValue = FieldValueFactory.fromType ( meta.Type );
        if ( fieldValue )
        {
            fieldValue.MetaId = meta.Id;

            // If there's a derivation expression, fire it off so that we can
            // default the value.
            if ( meta.DerivationExpression )
            {
                const defaultValue = this.engine.eval ( meta.DerivationExpression, vals );
                fieldValue.updateValue ( meta, defaultValue );
            }
        }

        return fieldValue;
    }

    // NOTE: This will (re)run the derivation expression, this is by design and required for handling registration form initialisation 
    // quirks. e.g. creating a new participant and then needing/wanting that value displayed on subsequent registration collection point forms.
    public clone ( meta: Field, existing: FieldValue, vals: Map<string, boolean | number | string | moment.Moment | null> ) : FieldValue | null
    {
        let fieldValue = FieldValueFactory.clone ( existing );
        if ( fieldValue )
        {
            // If there's a derivation expression, fire it off so that we can
            // default the value.
            if ( meta.DerivationExpression )
            {
                const defaultValue = this.engine.eval ( meta.DerivationExpression, vals );
                fieldValue.updateValue ( meta, defaultValue );
            }
        }

        return fieldValue;
    }
}
