import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Config } from './config';

@Injectable ( {
    providedIn: 'root'
  } )
export class ConfigService
{
    private static readonly URL = 'assets/config.json';

    private config: Config | null = null;

    public async getConfig( ): Promise<Config>
    {
        const response = await fetch ( ConfigService.URL );
        this.config = await response.json ( );
        return this.config as Config;
    }

    getConfigLazy( ): Observable<Config>
    {
        if ( this.config )
        {
            return of ( this.config );
        }
        else
        {
            return from ( fetch ( ConfigService.URL ) ).pipe (
                mergeMap ( response => response.json ( ) ),
                tap ( config => this.config = config ) );
        }
    }
}
