import * as moment from "moment";
import { SectionValue } from "./SectionValue";

export class FormValue
{
    public static clone( form: FormValue ): FormValue
    {
        const sectionValues = new Array<SectionValue> ( );
        for ( const sv of form.SectionValues ? form.SectionValues : [] )
        {
            sectionValues.push ( SectionValue.clone ( sv ) );
        }

        return new FormValue ( form.Id,
                               form.Version, 
                               form.MetaId,
                               sectionValues,
                               form.Completed );
    }

    public static copy( src: FormValue, dest: FormValue ): FormValue
    {
        dest.Id = src.Id;
        dest.MetaId = src.MetaId;
        dest.SectionValues = src.SectionValues;
        dest.Version = src.Version;
        dest.Completed = src.Completed;
        return dest;
    }

    public static toJson( form: FormValue ): any
    {
        let out: any = {};

        out.id = form.Id;
        // out.version = site.Version;
        out.meta_id = form.MetaId;
        out.section_values = new Array<any> ( );
        for ( const section of form.SectionValues ? form.SectionValues : [] )
        {
            out.section_values.push ( SectionValue.toJson ( section ) );
        }
        out.completed = form.Completed ? form.Completed.format ( ) : null;

        return out;
    }

    public static fromJson( json: any ): FormValue
    {
        let out = new FormValue ( );

        out.Id = json.id as number;
        out.Version = json.version as number;
        out.MetaId = json.meta_id as number;
        out.SectionValues = new Array<SectionValue> ( );
        for ( const section of json.section_values ? json.section_values : [] )
        {
            const parsed = SectionValue.fromJson ( section );
            if ( parsed != null )
            {
                out.SectionValues.push ( parsed );
            }
        }
        out.Completed = json.completed ? moment ( json.completed as string ) : null;

        return out;
    }

    public constructor( private id: number | null = null,
                        private version: number | null = null,
                        private metaId: number | null = null,
                        private sectionValues: Array<SectionValue> | null = null,
                        private completed: moment.Moment | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get SectionValues( ): Array<SectionValue> | null
    {
        return this.sectionValues;
    }

    public set SectionValues( sectionValues: Array<SectionValue> | null )
    {
        this.sectionValues = sectionValues;
    }

    public get Completed( ): moment.Moment | null
    {
        return this.completed;
    }

    public set Completed( completed: moment.Moment | null )
    {
        this.completed = completed;
    }
}
