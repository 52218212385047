import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudyDocumentsState, STUDY_DOCUMENTS_FEATURE_STATE_NAME } from './study-documents.state';

export const SELECT_STUDY_DOCUMENTS = createFeatureSelector<StudyDocumentsState> ( STUDY_DOCUMENTS_FEATURE_STATE_NAME );

export const SELECT_STUDY_DOCUMENT_TEMPLATES = createSelector ( SELECT_STUDY_DOCUMENTS, ( state: StudyDocumentsState ) => state.templates );

export const SELECT_STUDY_DOCUMENT_TEMPLATES_LOADING = createSelector ( SELECT_STUDY_DOCUMENTS, ( state: StudyDocumentsState ) => state.loading );

export const SELECT_STUDY_DOCUMENT_TEMPLATES_SAVING = createSelector ( SELECT_STUDY_DOCUMENTS, ( state: StudyDocumentsState ) => state.saving );
