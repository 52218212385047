import { Task } from '../../../model/study/Task';

export const STUDY_TASKS_FEATURE_STATE_NAME = 'study-tasks';

export class StudyTasksState
{
    tasks = new Map<number, Task> ( );
    loading = false;
    includesCompleted = false;
}
