import { createReducer, on } from '@ngrx/store';
import { FormLibraryState } from './form.library.state';
import { CREATE_FORM_LIBRARY_FORM, CREATE_FORM_LIBRARY_FORM_FAILURE, CREATE_FORM_LIBRARY_FORM_SUCCESS, DELETE_FORM_LIBRARY_FORM, DELETE_FORM_LIBRARY_FORM_FAILURE, DELETE_FORM_LIBRARY_FORM_SUCCESS, GET_FORM_LIBRARY, GET_FORM_LIBRARY_FAILURE, GET_FORM_LIBRARY_SUCCESS, UPDATE_FORM_LIBRARY_FORM, UPDATE_FORM_LIBRARY_FORM_FAILURE, UPDATE_FORM_LIBRARY_FORM_SUCCESS } from './form.library.actions';

export const INITIAL_STATE = new FormLibraryState ( );

const _FORM_LIBRARY_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_FORM_LIBRARY, ( state, { op, ids } ) => {
      const newState = { ...state };

      newState.formsLoading = true;

      return newState;
    } ),
    on ( GET_FORM_LIBRARY_SUCCESS, ( state, { op, forms } ) => {
      const newState = { ...state };

      newState.forms = forms;
      newState.formsLoading = false;

      return newState;
    } ),
    on ( GET_FORM_LIBRARY_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.forms = [];
      newState.formsLoading = false;

      return newState;
    } ),


    on ( CREATE_FORM_LIBRARY_FORM, ( state, { op, form } ) => {
      const newState = { ...state };

      newState.formSaving = true;

      return newState;
    } ),
    on ( CREATE_FORM_LIBRARY_FORM_SUCCESS, ( state, { op, form } ) => {
      const newState = { ...state };

      newState.forms.push ( form );
      newState.formSaving = false;

      return newState;
    } ),
    on ( CREATE_FORM_LIBRARY_FORM_FAILURE, ( state, { op, errorDetails } ) => {
      const newState = { ...state };

      newState.formSaving = false;

      return newState;
    } ),


    on ( UPDATE_FORM_LIBRARY_FORM, ( state, { op, form } ) => {
      const newState = { ...state };

      newState.formSaving = true;

      return newState;
    } ),
    on ( UPDATE_FORM_LIBRARY_FORM_SUCCESS, ( state, { op, form } ) => {
      const newState = { ...state };

      let found = false;
      for ( let i = 0; i < newState.forms.length; i++ )
      {
        let existing = newState.forms [ i ];
        if ( existing.Id === form.Id )
        {
          newState.forms [ i ] = form;
          found = true;
          break;
        }
      }

      if ( !found )
      {
        newState.forms.push ( form );
      }

      newState.formSaving = false;

      return newState;
    } ),
    on ( UPDATE_FORM_LIBRARY_FORM_FAILURE, ( state, { op, errorDetails } ) => {
      const newState = { ...state };

      newState.formSaving = false;

      return newState;
    } ),


    on ( DELETE_FORM_LIBRARY_FORM, ( state, { op, id } ) => {
      const newState = { ...state };

      newState.formDeleting = true;

      return newState;
    } ),
    on ( DELETE_FORM_LIBRARY_FORM_SUCCESS, ( state, { op, id } ) => {
      const newState = { ...state };

      let found = false;
      for ( let i = 0; i < newState.forms.length; i++ )
      {
        let existing = newState.forms [ i ];
        if ( existing.Id === id )
        {
          newState.forms.splice ( i, 1 );
          found = true;
          break;
        }
      }

      newState.formDeleting = false;

      return newState;
    } ),
    on ( DELETE_FORM_LIBRARY_FORM_FAILURE, ( state, { op, errorDetails } ) => {
      const newState = { ...state };

      newState.formDeleting = false;

      return newState;
    } ),
);

export function FORM_LIBRARY_REDUCER( state: any, action: any )
{
  return _FORM_LIBRARY_REDUCER ( state, action );
}
