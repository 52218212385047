import * as moment from 'moment';
import { Field } from '../published/Field';
import { FieldType } from '../published/FieldType';
import { FieldTypeUtils } from '../published/FieldType';


export abstract class FieldValue
{
    protected static _toJson<T extends FieldValue>( field: T, output: any ): any
    {
        output.id = field.Id;
        // output.version = field.Version;
        output.meta_id = field.MetaId;
        output.type = FieldTypeUtils.toString ( field.Type );
        output.timestamp = field.Timestamp ? field.Timestamp.format ( ) : null;

        return output;
    }

    protected static _fromJson<T extends FieldValue>( json: any, output: T ): T
    {
        output.Id = json.id as number;
        output.Version = json.version as number;
        output.MetaId = json.meta_id as number;
        output.Timestamp = moment ( json.timestamp as string );

        return output;
    }

    protected constructor( private id: number | null = null,
                           private version: number | null = null,
                           private metaId: number | null = null,
                           private type: FieldType = FieldType.NONE,
                           private timestamp: moment.Moment | null = null )
    {
        // Null.
    }

    public abstract getValues ( ) : Map<string, any> | null;

    public abstract get ValueAsString ( ) : string | null;

    public abstract updateValue ( meta: Field, value: any ) : void;

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get Type( ): FieldType
    {
        return this.type;
    }

    public set Type( type: FieldType )
    {
        this.type = type;
    }

    public get Timestamp( ): moment.Moment | null
    {
        return this.timestamp;
    }

    public set Timestamp( timestamp: moment.Moment | null )
    {
        this.timestamp = timestamp;
    }
}