import { TagGroup } from "./TagGroup";
import { SubjectType } from "../published/SubjectType";
import { CollectionPointValue } from "./CollectionPointValue";
import * as moment from "moment";

export abstract class SubjectValue
{
    protected static _clone<T extends SubjectValue>( subject: T, out: T ): T
    {
        out.Id = subject.Id;
        out.Version = subject.Version;
        out.MetaId = subject.MetaId;
        out.CreationTime = subject.CreationTime;
        out.State = subject.State;
        out.ExternalId = subject.ExternalId;
        out.RegistrationCollectionPointValue = subject.RegistrationCollectionPointValue ? CollectionPointValue.clone ( subject.RegistrationCollectionPointValue ) : null;
        out.BaselineCollectionPointValue = subject.BaselineCollectionPointValue ? CollectionPointValue.clone ( subject.BaselineCollectionPointValue ) : null;
        if ( subject.IntervalCollectionPointValues )
        {
            out.IntervalCollectionPointValues = new Array<CollectionPointValue> ( );
            for ( const cp of subject.IntervalCollectionPointValues )
            {
                out.IntervalCollectionPointValues.push ( CollectionPointValue.clone ( cp ) );
            }
        }
        else
        {
            out.IntervalCollectionPointValues = null;    
        }
        if ( subject.AdHocCollectionPointValues )
        {
            out.AdHocCollectionPointValues = new Array<CollectionPointValue> ( );
            for ( const cp of subject.AdHocCollectionPointValues )
            {
                out.AdHocCollectionPointValues.push ( CollectionPointValue.clone ( cp ) );
            }
        }
        else
        {
            out.AdHocCollectionPointValues = null;    
        }
        out.CompletionCollectionPointValue = subject.CompletionCollectionPointValue ? CollectionPointValue.clone ( subject.CompletionCollectionPointValue ) : null;
        if ( subject.TagGroups )
        {
            out.TagGroups = new Array<TagGroup> ( );
            for ( let tagGroup of subject.TagGroups )
            {
                out.TagGroups.push ( TagGroup.clone ( tagGroup ) );
            }
        }
        else
        {
            out.TagGroups = null;
        }

        return out;
    }

    protected static _toJson<T extends SubjectValue>( subject: T, output: any ): any
    {
        output.id = subject.Id;
        // out.version = site.Version;
        output.meta_id = subject.MetaId;
        output.creation_time = subject.CreationTime ? subject.CreationTime.format ( ) : null;
        output.state = subject.State;
        output.external_id = subject.ExternalId;
        output.registration_collection_point_value = subject.RegistrationCollectionPointValue ? CollectionPointValue.toJson ( subject.RegistrationCollectionPointValue ) : null;
        output.baseline_collection_point_value = subject.BaselineCollectionPointValue ? CollectionPointValue.toJson ( subject.BaselineCollectionPointValue ) : null;
        output.interval_collection_point_values = new Array<any> ( );
        for ( const cp of subject.IntervalCollectionPointValues ? subject.IntervalCollectionPointValues : [] )
        {
            output.interval_collection_point_values.push ( CollectionPointValue.toJson ( cp ) );
        }
        output.adhoc_collection_point_values = new Array<any> ( );
        for ( const cp of subject.AdHocCollectionPointValues ? subject.AdHocCollectionPointValues : [] )
        {
            output.adhoc_collection_point_values.push ( CollectionPointValue.toJson ( cp ) );
        }
        output.completion_collection_point_value = subject.CompletionCollectionPointValue ? CollectionPointValue.toJson ( subject.CompletionCollectionPointValue ) : null;
        output.tag_groups = new Array<any> ( );
        for ( const tagGroup of subject.TagGroups ? subject.TagGroups : [] )
        {
            output.tag_groups.push ( TagGroup.toJson ( tagGroup ) );
        }

        return output;
    }

    protected static _fromJson<T extends SubjectValue>( json: any, output: T ): T
    {
        output.Id = json.id as number;
        output.Version = json.version as number;
        output.MetaId = json.meta_id as number;
        output.CreationTime = moment ( json.creation_time as string );
        output.State = json.state as string;
        output.ExternalId = json.external_id;
        output.RegistrationCollectionPointValue = ("registration_collection_point_value" in json && json [ "registration_collection_point_value" ]) ? CollectionPointValue.fromJson ( json.registration_collection_point_value ) : null;
        output.BaselineCollectionPointValue = ("baseline_collection_point_value" in json && json [ "baseline_collection_point_value" ]) ? CollectionPointValue.fromJson ( json.baseline_collection_point_value ) : null;
        output.IntervalCollectionPointValues = new Array<CollectionPointValue> ( );
        for ( const cp of json.interval_collection_point_values ? json.interval_collection_point_values : [] )
        {
            const parsed = CollectionPointValue.fromJson ( cp );
            if ( parsed != null )
            {
                output.IntervalCollectionPointValues.push ( parsed );
            }
        }
        output.AdHocCollectionPointValues = new Array<CollectionPointValue> ( );
        for ( const cp of json.adhoc_collection_point_values ? json.adhoc_collection_point_values : [] )
        {
            const parsed = CollectionPointValue.fromJson ( cp );
            if ( parsed != null )
            {
                output.AdHocCollectionPointValues.push ( parsed );
            }
        }
        output.CompletionCollectionPointValue = ("completion_collection_point_value" in json && json [ "completion_collection_point_value" ]) ? CollectionPointValue.fromJson ( json.completion_collection_point_value ) : null;
        output.TagGroups = new Array<TagGroup> ( );
        for ( const tagGroup of json.tag_groups ? json.tag_groups : [] )
        {
            output.TagGroups.push ( TagGroup.fromJson ( tagGroup ) );
        }

        return output;
    }

    public constructor( private id: number | null = null,
                        private version: number | null = null,
                        private type: SubjectType = SubjectType.NONE,
                        private metaId: number | null = null,
                        private creationTime: moment.Moment | null = null,
                        private state: string | null = null,
                        private externalId: string | null = null,
                        private registrationCollectionPointValue: CollectionPointValue | null = null,
                        private baselineCollectionPointValue: CollectionPointValue | null = null,
                        private intervalCollectionPointValues: Array<CollectionPointValue> | null = null,
                        private adhocCollectionPointValues: Array<CollectionPointValue> | null = null,
                        private completionCollectionPointValue: CollectionPointValue | null = null,
                        private tagGroups: Array<TagGroup> | null = null)
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Type( ): SubjectType
    {
        return this.type;
    }

    public get ExternalId( ): string | null
    {
        return this.externalId;
    }

    public set ExternalId( externalId: string | null )
    {
        this.externalId = externalId;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get CreationTime( ): moment.Moment | null
    {
        return this.creationTime;
    }

    public set CreationTime( creationTime: moment.Moment | null )
    {
        this.creationTime = creationTime;
    }

    public get State( ): string | null
    {
        return this.state;
    }

    public set State( state: string | null )
    {
        this.state = state;
    }

    public get RegistrationCollectionPointValue( ): CollectionPointValue | null
    {
        return this.registrationCollectionPointValue;
    }

    public set RegistrationCollectionPointValue( registrationCollectionPointValue: CollectionPointValue | null )
    {
        this.registrationCollectionPointValue = registrationCollectionPointValue;
    }

    public get BaselineCollectionPointValue( ): CollectionPointValue | null
    {
        return this.baselineCollectionPointValue;
    }

    public set BaselineCollectionPointValue( baselineCollectionPointValue: CollectionPointValue | null )
    {
        this.baselineCollectionPointValue = baselineCollectionPointValue;
    }

    public get IntervalCollectionPointValues( ): Array<CollectionPointValue> | null
    {
        return this.intervalCollectionPointValues;
    }

    public set IntervalCollectionPointValues( intervalCollectionPointValues: Array<CollectionPointValue> | null )
    {
        this.intervalCollectionPointValues = intervalCollectionPointValues;
    }

    public get AdHocCollectionPointValues( ): Array<CollectionPointValue> | null
    {
        return this.adhocCollectionPointValues;
    }

    public set AdHocCollectionPointValues( adhocCollectionPointValues: Array<CollectionPointValue> | null )
    {
        this.adhocCollectionPointValues = adhocCollectionPointValues;
    }

    public get CompletionCollectionPointValue( ): CollectionPointValue | null
    {
        return this.completionCollectionPointValue;
    }

    public set CompletionCollectionPointValue( completionCollectionPointValue: CollectionPointValue | null )
    {
        this.completionCollectionPointValue = completionCollectionPointValue;
    }

    public get TagGroups( ): Array<TagGroup> | null
    {
        return this.tagGroups;
    }

    public set TagGroups( tagGroups: Array<TagGroup> | null )
    {
        this.tagGroups = tagGroups;
    }

    public get AllCollectionPointValues ( ) : Array<CollectionPointValue>
    {
        let cpvs = new Array<CollectionPointValue> ( );
        if ( this.RegistrationCollectionPointValue )
        {
            cpvs.push ( this.RegistrationCollectionPointValue );
        }

        if ( this.BaselineCollectionPointValue )
        {
            cpvs.push ( this.BaselineCollectionPointValue );
        }

        for ( const cp of this.IntervalCollectionPointValues ? this.IntervalCollectionPointValues : [] )
        {
            cpvs.push ( cp );
        }

        for ( const cp of this.AdHocCollectionPointValues ? this.AdHocCollectionPointValues : [] )
        {
            cpvs.push ( cp );
        }

        if ( this.CompletionCollectionPointValue )
        {
            cpvs.push ( this.CompletionCollectionPointValue );
        }

        return cpvs;
    }
}
