import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { StringField } from '../../model/published/StringField';
import { StringFieldValue } from '../../model/study/StringFieldValue';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ExpressionEngineFactory } from '../../expressions/ExpressionEngineFactory';
import { ExpressionEngine } from '../../expressions/ExpressionEngine';
import { FieldComponent } from './field.component';
import { Field } from '../../model/published/Field';
import { CurrentUserUtilsService } from '../../services/current.user.utils.service';


@Component({
  selector: 'lib-string-field',
  templateUrl: './string-field.component.html',
  styleUrls: ['./string-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( ( ) => StringFieldComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( ( ) => StringFieldComponent ),
      multi: true
    }
  ]
})
export class StringFieldComponent extends FieldComponent implements OnInit
{
  @Input()
  public meta: StringField | null = null;

  value: StringFieldValue | null = null;

  constructor ( fb: FormBuilder, eFactory: ExpressionEngineFactory, currentUser: CurrentUserUtilsService )
  {
    super ( fb, eFactory, currentUser );
  }

  get Meta ( ) : Field | null
  {
    return this.meta;
  }

  get ValueFormControl ( ) : FormControl
  { 
    return this.form.get ( 'value' ) as FormControl;
  }

  ngOnInit ( )
  {
    this.form = this.fb.group ( {
      value: [null, this.meta?.Required ? [ Validators.required ] : null ],
      timestamp: null
    } );
  }

  writeValue ( val: any )
  {
    this.value = StringFieldValue.clone ( val as StringFieldValue );
    if ( this.value )
    {
      this.form.patchValue ( { "value": this.value.Value, "timestamp": this.value.Timestamp }, { emitEvent: false } );
    }
  }

  registerOnChange ( fn: any )
  {
    this.form.valueChanges.pipe ( 
      map ( change => {
        change['timestamp'] = moment ( );
        if ( this.value )
        {
          if ( "value" in change )
          {
            this.value.Value = change['value'];
          }
          this.value.Timestamp = change['timestamp'];
          return this.value
        }
        else
        {
          return change;
        }
      }),
    ).subscribe ( fn );
  }

  onDisable ( disable: boolean )
  {
    if ( disable )
    {
      if ( this.ValueFormControl.enabled ) this.ValueFormControl.disable ( );
    }
    else
    {
      if ( this.ValueFormControl.disabled ) this.ValueFormControl.enable ( );
    }
  }

  protected onValidate ( results: ValidationErrors | null )
  {
    if ( results && "validityExpression" in results )
    {
      const combo = { ...this.ValueFormControl.errors, ... results };
      this.ValueFormControl.setErrors ( combo );
    }
    else if ( this.ValueFormControl.errors && Object.keys ( this.ValueFormControl.errors ).length > 0 )
    {
      let existing = { ...this.ValueFormControl.errors };
      delete existing["validityExpression"];
      this.ValueFormControl.setErrors ( existing );
    }
    else
    {
      this.ValueFormControl.setErrors ( null );
    }
  }

  get LineCount ( ) : number
  {
    if ( this.meta && this.meta.LineCount )
    {
      return this.meta.LineCount;
    }
    else
    {
      return 1;
    }
  }
}
