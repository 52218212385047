import { CollectionPoint } from "../published/CollectionPoint";
import { CollectionPointValue } from "./CollectionPointValue";
import { SubjectValue } from "./SubjectValue";
import { Subject } from "../published/Subject";
import { Form } from "../published/Form";
import * as moment from "moment";
import { FormUtils } from "./FormUtils";
import { FormValue } from "./FormValue";
import { ExpressionEngine } from "../../expressions/ExpressionEngine";

import { Field } from "../published/Field";
import { Section } from "../published/Section";
import { FieldValue } from "./FieldValue";


export class SubjectUtils
{
    private readonly formUtils = new FormUtils ( );

    getSubjectVariableValues ( type: Subject,
                               value: SubjectValue,
                               prefix: string,
                               siteId: string | null = null, 
                               references: Map<number, [SubjectValue, Subject]> = new Map<number, [SubjectValue, Subject]> ( ) ) : Map<string, boolean | number | string | moment.Moment | null>
    {
        let vals = new Map<string, boolean | number | string | moment.Moment | null> ( );

        vals.set ( `${prefix}.___.state`, value.State );

        vals.set ( `${prefix}.___.id`, this.getFormattedId ( type, value ) );
        vals.set ( `${prefix}.___.internal_id`, `${value.Id}` );

        for ( const tagGroup of value.TagGroups ? value.TagGroups : [] )
        {
            if ( tagGroup.MetaId != null )
            {
                const tag = type.TagsMap.get ( tagGroup.MetaId );
                if ( tag )
                {
                    vals.set ( `${prefix}.___.tags.${tag.Variable}`, true );
                    vals.set ( `${prefix}.___.tags.${tag.Variable}.timestamp`, tagGroup.Timestamp ? tagGroup.Timestamp : null );
                }
            }
        }

        if ( type.RegistrationCollectionPoint && type.RegistrationCollectionPoint.Variable && value.RegistrationCollectionPointValue )
        {
            for ( const [key, val] of this.getCollectionPointVariableValues ( type.RegistrationCollectionPoint, [value.RegistrationCollectionPointValue], false, prefix, references ) )
            {
                vals.set ( key, val );
            }
        }

        if ( type.BaselineCollectionPoint && type.BaselineCollectionPoint.Variable && value.BaselineCollectionPointValue )
        {
            for ( const [key, val] of this.getCollectionPointVariableValues ( type.BaselineCollectionPoint, [value.BaselineCollectionPointValue], false, prefix, references ) )
            {
                vals.set ( key, val );
            }
        }

        for ( const adHocCollectionPoint of type.AdHocCollectionPoints ? type.AdHocCollectionPoints : [] )
        {
            if ( adHocCollectionPoint.Variable && value.AdHocCollectionPointValues )
            {
                const cpvs = value.AdHocCollectionPointValues.filter ( cpv => cpv.MetaId == adHocCollectionPoint.Id );
                for ( const [key, val] of this.getCollectionPointVariableValues ( adHocCollectionPoint, cpvs, false, prefix, references ) )
                {
                    vals.set ( key, val );
                }

                vals.set ( prefix ? `${prefix}.${adHocCollectionPoint.Variable}.___.number_of` : `${adHocCollectionPoint.Variable}.___.number_of`, cpvs ? cpvs.length : 0 );

                let completeCount = 0;
                let incompleteCount = 0;
                let earliest: moment.Moment | null = null;
                let latest: moment.Moment | null = null;
                for ( const cpv of cpvs ? cpvs : [] )
                {
                    if ( !cpv.FormValues?.[0].Completed )
                    {
                        incompleteCount++;
                    }
                    else
                    {
                        completeCount++;
                    }

                    if ( cpv.TargetTimestamp )
                    {
                        if ( !earliest || cpv.TargetTimestamp.isBefore ( earliest ) )
                        {
                            earliest = cpv.TargetTimestamp;
                        }

                        if ( !latest || cpv.TargetTimestamp.isAfter ( latest ) )
                        {
                            latest = cpv.TargetTimestamp;
                        }
                    }
                }
                vals.set ( prefix ? `${prefix}.${adHocCollectionPoint.Variable}.___.complete` : `${adHocCollectionPoint.Variable}.___.complete`, completeCount );
                vals.set ( prefix ? `${prefix}.${adHocCollectionPoint.Variable}.___.incomplete` : `${adHocCollectionPoint.Variable}.___.incomplete`, incompleteCount );
                vals.set ( prefix ? `${prefix}.${adHocCollectionPoint.Variable}.___.earliest` : `${adHocCollectionPoint.Variable}.___.earliest`, earliest );
                vals.set ( prefix ? `${prefix}.${adHocCollectionPoint.Variable}.___.latest` : `${adHocCollectionPoint.Variable}.___.latest`, latest );
            }
        }

        for ( const intervalCollectionPoint of type.IntervalCollectionPoints ? type.IntervalCollectionPoints : [] )
        {
            if ( intervalCollectionPoint.Variable && value.IntervalCollectionPointValues )
            {
                const cpvs = value.IntervalCollectionPointValues.filter ( cpv => cpv.MetaId == intervalCollectionPoint.Id );
                for ( const [key, val] of this.getCollectionPointVariableValues ( intervalCollectionPoint, cpvs, true, prefix, references ) )
                {
                    vals.set ( key, val );
                }

                vals.set ( prefix ? `${prefix}.${intervalCollectionPoint.Variable}.___.number_of` : `${intervalCollectionPoint.Variable}.___.number_of`, cpvs ? cpvs.length : 0 );

                let completeCount = 0;
                let incompleteCount = 0;
                let earliest: moment.Moment | null = null;
                let latest: moment.Moment | null = null;
                for ( const cpv of cpvs ? cpvs : [] )
                {
                    if ( !cpv.FormValues?.[0].Completed )
                    {
                        incompleteCount++;
                    }
                    else
                    {
                        completeCount++;
                    }

                    if ( cpv.TargetTimestamp )
                    {
                        if ( !earliest || cpv.TargetTimestamp.isBefore ( earliest ) )
                        {
                            earliest = cpv.TargetTimestamp;
                        }

                        if ( !latest || cpv.TargetTimestamp.isAfter ( latest ) )
                        {
                            latest = cpv.TargetTimestamp;
                        }
                    }
                }
                vals.set ( prefix ? `${prefix}.${intervalCollectionPoint.Variable}.___.complete` : `${intervalCollectionPoint.Variable}.___.complete`, completeCount );
                vals.set ( prefix ? `${prefix}.${intervalCollectionPoint.Variable}.___.incomplete` : `${intervalCollectionPoint.Variable}.___.incomplete`, incompleteCount );
                vals.set ( prefix ? `${prefix}.${intervalCollectionPoint.Variable}.___.earliest` : `${intervalCollectionPoint.Variable}.___.earliest`, earliest );
                vals.set ( prefix ? `${prefix}.${intervalCollectionPoint.Variable}.___.latest` : `${intervalCollectionPoint.Variable}.___.latest`, earliest );
            }
        }

        if ( type.CompletionCollectionPoint && type.CompletionCollectionPoint.Variable && value.CompletionCollectionPointValue )
        {
            for ( const [key, val] of this.getCollectionPointVariableValues ( type.CompletionCollectionPoint, [value.CompletionCollectionPointValue], false, prefix, references ) )
            {
                vals.set ( key, val );
            }
        }

        return vals;
    }

    private getCollectionPointVariableValues ( cp: CollectionPoint, cpvs: Array<CollectionPointValue>, latestCompleted: boolean, prefix: string, references: Map<number, [SubjectValue, Subject]> ) : Map<string, boolean | number | string | moment.Moment | null>
    {
        let vals = new Map<string, boolean | number | string | moment.Moment | null> ( );

        const filtered = ( cpvs.length > 0 ) ? cpvs.filter ( value => value.MetaId == cp.Id ) : [];

        const cpv = ( filtered.length > 0 ) ? filtered.reduce ( ( previousValue, currentValue ) => {
            if ( currentValue && previousValue )
            {
                if ( latestCompleted )
                {
                    if ( currentValue.FormValues?.[0].Completed != null && previousValue.FormValues?.[0].Completed != null )
                    {
                        if ( currentValue.TargetTimestamp?.isSameOrAfter ( previousValue.TargetTimestamp ) )
                        {
                            return currentValue;
                        }
                        else
                        {
                            return previousValue;
                        }
                    }
                    else if ( currentValue.FormValues?.[0].Completed != null )
                    {
                        return currentValue;
                    }
                    else
                    {
                        return previousValue;
                    }
                }
                else
                {
                    if ( currentValue.TargetTimestamp?.isSameOrAfter ( previousValue.TargetTimestamp ) )
                    {
                        return currentValue;
                    }
                    else
                    {
                        return previousValue;
                    }
                }
            }
            else if ( currentValue )
            {
                return currentValue;
            }
            else
            {
                return previousValue;
            }
        } ) : null;

        if ( cpv )
        {
            if ( cpv.TargetTimestamp )
            {
                vals.set ( prefix ? `${prefix}.${cp.Variable}.___.timestamp` : `${cp.Variable}.___.timestamp`, cpv.TargetTimestamp );
            }

            for ( const form of cp.Forms ? cp.Forms : [] )
            {
                const formValue = cpv.FormValues?.find ( formValue => formValue.MetaId == form.Id );
                if ( formValue )
                {
                    for ( const [key, value] of this.formUtils.getVariableValues ( this, form, formValue, prefix ? `${prefix}.${cp.Variable}` : cp.Variable, references ) )
                    {
                        vals.set ( key, value );
                    }
                }
            }
        }

        return vals;
    }

    // Method should be overridden in subclass, thus a meaningful answer 'should' come out... 
    getFormattedId ( type: Subject, value: SubjectValue | null ) : string | null
    {
        return null;
    }

    getCollectionPoint ( type: Subject, id: number ) : CollectionPoint | null
    {
        const res = type.AllCollectionPoints.find ( cp => cp.Id == id );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getForm ( cp: CollectionPoint, id: number ) : Form | null
    {
        const res = cp.Forms?.find ( form => form.Id == id );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getSection ( form: Form, id: number ) : Section | null
    {
        const res = form.Sections?.find ( section => section.Id == id );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getField ( formSection: Form | Section, id: number ) : Field | null
    {
        if ( formSection instanceof Form )
        {
            for ( const section of formSection.Sections ? formSection.Sections : [] )
            {
                const res = section.Fields?.find ( field => field.Id == id );
                if ( res )
                {
                    return res;
                }
            }
        }
        else
        {
            const res = formSection.Fields?.find ( field => field.Id == id );
            if ( res )
            {
                return res;
            }
        }

        return null;
    }

    getCollectionPointValue ( subject: SubjectValue, id: number ) : CollectionPointValue | null
    {
        const res = subject.AllCollectionPointValues.find ( cp => cp.Id == id );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getCollectionPointValueByMetaId ( subject: SubjectValue, metaId: number, targetTimestamp: moment.Moment | null = null ) : CollectionPointValue | null
    {
        if ( targetTimestamp )
        {
            const res = subject.AllCollectionPointValues.find ( cp => cp.MetaId == metaId && cp.TargetTimestamp == targetTimestamp );
            if ( res )
            {
                return res;
            }
            else
            {
                return null;
            }
        }
        else
        {
            const res = subject.AllCollectionPointValues.find ( cp => cp.MetaId == metaId );
            if ( res )
            {
                return res;
            }
            else
            {
                return null;
            }
        }
    }

    setCollectionPointValue<T extends SubjectValue> ( subject: T, cpv: CollectionPointValue ) : T
    {
        if ( subject.RegistrationCollectionPointValue && subject.RegistrationCollectionPointValue.Id == cpv.Id )
        {
            subject.RegistrationCollectionPointValue = cpv;
            return subject;
        }

        if ( subject.BaselineCollectionPointValue && subject.BaselineCollectionPointValue.Id == cpv.Id )
        {
            subject.BaselineCollectionPointValue = cpv;
            return subject;
        }

        if ( subject.IntervalCollectionPointValues )
        {
            const cpvs = new Array<CollectionPointValue> ( );
            for ( const v of subject.IntervalCollectionPointValues )
            {
                if ( v.Id == cpv.Id )
                {
                    cpvs.push ( cpv );
                }
                else
                {
                    cpvs.push ( v );
                }
            }
            return subject;
        }

        if ( subject.CompletionCollectionPointValue && subject.CompletionCollectionPointValue.Id == cpv.Id )
        {
            subject.CompletionCollectionPointValue = cpv;
            return subject;
        }

        return subject;
    }

    getFormValue ( cpv: CollectionPointValue, id: number ) : FormValue | null
    {
        const res = cpv.FormValues?.find ( form => form.Id == id );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getFormValueByMetaId ( cpv: CollectionPointValue, metaId: number ) : FormValue | null
    {
        const res = cpv.FormValues?.find ( form => form.MetaId == metaId );
        if ( res )
        {
            return res;
        }
        else
        {
            return null;
        }
    }

    getFieldValueByMetaId ( fv: FormValue, metaId: number ) : FieldValue | null
    {
        for ( const sv of fv.SectionValues ? fv.SectionValues : [] )
        {
            const res = sv.FieldValues?.find ( field => field.MetaId == metaId );
            if ( res )
            {
                return res;
            }
        }

        return null;
    }

    setFormValue ( cpv: CollectionPointValue, formValue: FormValue ) : CollectionPointValue
    {
        const fvs = new Array<FormValue> ( );
        for ( const fv of cpv.FormValues ? cpv.FormValues : [] )
        {
            if ( fv.Id == formValue.Id )
            {
                fvs.push ( formValue );
            }
            else
            {
                fvs.push ( fv );
            }
        }
        cpv.FormValues = fvs;
        return cpv;
    }

    /**
     * This function gets the displayable label for the subject from the field value as 
     * defined within the meta data.
     * 
     * NOTE: THIS CAN BE DONE BETTER, AND SHOULD BE, ONE DAY!
     * 
     * @param type
     * @param value 
     * @returns 
     */
    getSubjectLabel ( engine: ExpressionEngine, type: Subject, value: SubjectValue, siteId: string | null, references: Map<number, [SubjectValue, Subject]> = new Map<number, [SubjectValue, Subject]> ( ) ) : string
    {
        if ( type.LabelExpression && type.Variable )
        {
            const values = this.getSubjectVariableValues ( type, value, type.Variable, siteId, references );
            const res = engine.eval ( type.LabelExpression, values );
            if ( typeof res == "string" )
            {
                return res;
            }
        }

        return "None";
    }
}