import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BooleanFieldComponent } from './boolean-field.component';
import { DateFieldComponent } from './date-field.component';
import { EnumeratedFieldComponent } from './enumerated-field.component';
import { FileFieldComponent } from './file-field.component';
import { NumericFieldComponent } from './numeric-field.component';
import { ParticipantFieldComponent } from './participant-field.component';
import { StringFieldComponent } from './string-field.component';
import { ListFieldComponent } from './list-field.component';

@NgModule({
  imports: [
    CommonModule,    
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule
  ],
  providers: [ ],
  declarations: [ EnumeratedFieldComponent, NumericFieldComponent, StringFieldComponent, BooleanFieldComponent, DateFieldComponent, ParticipantFieldComponent, FileFieldComponent, ListFieldComponent ],
  exports: [ EnumeratedFieldComponent, NumericFieldComponent, StringFieldComponent, BooleanFieldComponent, DateFieldComponent, ParticipantFieldComponent, FileFieldComponent, ListFieldComponent ]
})
export class FieldsModule { }
