import { createReducer, on } from '@ngrx/store';
import { StudyTasksState } from './study-tasks.state';
import { Task } from '../../../model/study/Task';
import { GET_TASKS, GET_TASKS_FAILURE, GET_TASKS_SUCCESS } from './study-tasks.actions';

export const INITIAL_STATE = new StudyTasksState ( );

const _STUDY_TASKS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_TASKS, ( state, { op, completed } ) => {
      const newState = { ...state };

      newState.loading = true;
      newState.includesCompleted = completed;
      newState.tasks = new Map<number, Task> ( );

      return newState;
    } ),
    on ( GET_TASKS_SUCCESS, ( state, { op, tasks } ) => {
      const newState = { ...state };

      newState.tasks = new Map<number, Task> ( );
      newState.loading = false;

      for ( const task of tasks ? tasks : [] )
      {
        if ( task.Id )
        {
          newState.tasks.set ( task.Id, task );
        }
      }
      
      return newState;
    } ),
    on ( GET_TASKS_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.tasks = new Map<number, Task> ( );
      newState.loading = false;

      return newState;
    } ),
);

export function STUDY_TASKS_REDUCER( state: any, action: any )
{
  return _STUDY_TASKS_REDUCER ( state, action );
}
