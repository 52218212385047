import { createReducer, on } from '@ngrx/store';
import { StudyDocumentsState } from './study-documents.state';
import { CREATE_DOCUMENT_TEMPLATE, CREATE_DOCUMENT_TEMPLATE_FAILURE, CREATE_DOCUMENT_TEMPLATE_SUCCESS, DELETE_DOCUMENT_TEMPLATE, DELETE_DOCUMENT_TEMPLATE_FAILURE, DELETE_DOCUMENT_TEMPLATE_SUCCESS, GET_DOCUMENT_TEMPLATES, GET_DOCUMENT_TEMPLATES_FAILURE, GET_DOCUMENT_TEMPLATES_SUCCESS, UPDATE_DOCUMENT_TEMPLATE, UPDATE_DOCUMENT_TEMPLATE_FAILURE, UPDATE_DOCUMENT_TEMPLATE_SUCCESS } from './study-documents.actions';
import { DocumentTemplate } from '../../../model/study/documents/DocumentTemplate';

export const INITIAL_STATE = new StudyDocumentsState ( );

const _STUDY_DOCUMENTS_REDUCER = createReducer ( INITIAL_STATE,

    on ( GET_DOCUMENT_TEMPLATES, ( state, { op } ) => {
      const newState = { ...state };

      newState.loading = true;

      return newState;
    } ),
    on ( GET_DOCUMENT_TEMPLATES_SUCCESS, ( state, { op, templates } ) => {
      const newState = { ...state };

      newState.loading = false;
      newState.templates = new Map<number, Map<number, DocumentTemplate>> ( );

      for ( const template of templates ? templates : [] )
      {
        if ( template.MetaId != null && template.DocumentId != null )
        {
          if ( newState.templates.has ( template.MetaId ) == false ) 
          {
            newState.templates.set ( template.MetaId, new Map<number, DocumentTemplate> ( ) );
          }

          newState.templates.get ( template.MetaId )?.set ( template.DocumentId, template );
        }
      }

      return newState;
    } ),
    on ( GET_DOCUMENT_TEMPLATES_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.loading = false;
      newState.templates = new Map<number, Map<number, DocumentTemplate>> ( );

      return newState;
    } ),


    on ( CREATE_DOCUMENT_TEMPLATE, ( state, { op, metaId, documentId } ) => {
      const newState = { ...state };

      newState.saving = true;
      
      return newState;
    } ),
    on ( CREATE_DOCUMENT_TEMPLATE_SUCCESS, ( state, { op, template } ) => {
      const newState = { ...state };

      newState.templates = new Map ( newState.templates );
      newState.saving = false;
      if ( template.MetaId != null && template.DocumentId != null )
      {
        if ( newState.templates.has ( template.MetaId ) == false ) 
        {
          newState.templates.set ( template.MetaId, new Map<number, DocumentTemplate> ( ) );
        }

        newState.templates.get ( template.MetaId )?.set ( template.DocumentId, template );
      }
      
      return newState;
    } ),
    on ( CREATE_DOCUMENT_TEMPLATE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;
      
      return newState;
    } ),


    on ( UPDATE_DOCUMENT_TEMPLATE, ( state, { op, template } ) => {
      const newState = { ...state };

      newState.saving = true;
      
      return newState;
    } ),
    on ( UPDATE_DOCUMENT_TEMPLATE_SUCCESS, ( state, { op, template } ) => {
      const newState = { ...state };

      newState.saving = false;
      newState.templates = new Map ( newState.templates );
      if ( template.MetaId != null && template.DocumentId != null )
      {
        if ( newState.templates.has ( template.MetaId ) == false ) 
        {
          newState.templates.set ( template.MetaId, new Map<number, DocumentTemplate> ( ) );
        }

        newState.templates.get ( template.MetaId )?.set ( template.DocumentId, template );
      }
      
      return newState;
    } ),
    on ( UPDATE_DOCUMENT_TEMPLATE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;
      
      return newState;
    } ),

    
    on ( DELETE_DOCUMENT_TEMPLATE, ( state, { op, template } ) => {
      const newState = { ...state };

      newState.saving = true;
      
      return newState;
    } ),
    on ( DELETE_DOCUMENT_TEMPLATE_SUCCESS, ( state, { op, template } ) => {
      const newState = { ...state };

      newState.saving = false;
      newState.templates = new Map ( newState.templates );
      if ( template.MetaId != null && template.DocumentId != null )
      {
        if ( newState.templates.has ( template.MetaId ) == false ) 
        {
          newState.templates.set ( template.MetaId, new Map<number, DocumentTemplate> ( ) );
        }

        newState.templates.get ( template.MetaId )?.delete ( template.DocumentId );
      }
      
      return newState;
    } ),
    on ( DELETE_DOCUMENT_TEMPLATE_FAILURE, ( state, { errorDetails } ) => {
      const newState = { ...state };

      newState.saving = false;
      
      return newState;
    } ),
);

export function STUDY_DOCUMENTS_REDUCER( state: any, action: any )
{
  return _STUDY_DOCUMENTS_REDUCER ( state, action );
}
