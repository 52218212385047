import { Field } from './Field';
import { FieldType } from './FieldType';
import * as moment from 'moment';

export class DateField extends Field
{
    public static clone( input: DateField ): DateField
    {
        let output = Field._clone ( input, new DateField ( ) );
        output.Minimum = input.Minimum;
        output.Maximum = input.Maximum;
        return output;
    }

    public static toJson( field: DateField ): any
    {
        let out: any = {};

        out = Field._toJson ( field, out );

        out.minimum = field.Minimum ? field.Minimum.format ( 'YYYY-MM-DD' ) : null;
        out.maximum = field.Maximum ? field.Maximum.format ( 'YYYY-MM-DD' ) : null;
        
        return out;
    }

    public static fromJson( json: any ): DateField
    {
        let out = new DateField ( );

        out = Field._fromJson ( json, out );

        out.Minimum = moment ( json.minimum as string );
        out.Maximum = moment ( json.maximum as string );

        return out;
    }

    public constructor( id: number | null  = null,
                        version: number | null = null,
                        seq: number | null = null,
                        variable: string | null = null,
                        name: string | null = null,
                        description: string | null = null,
                        required: boolean | null = null,
                        private minimum: moment.Moment | null = null,
                        private maximum: moment.Moment | null = null )
    {
       super ( id, version, seq, FieldType.DATE, variable, name, description, required );
    }

    public get Minimum( ): moment.Moment | null
    {
        return this.minimum;
    }

    public set Minimum( minimum: moment.Moment | null )
    {
        this.minimum = minimum;
    }

    public get Maximum( ): moment.Moment | null
    {
        return this.maximum;
    }

    public set Maximum( maximum: moment.Moment | null )
    {
        this.maximum = maximum;
    }
}
