import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { CREATE_FORM_LIBRARY_FORM, CREATE_FORM_LIBRARY_FORM_FAILURE, CREATE_FORM_LIBRARY_FORM_SUCCESS, DELETE_FORM_LIBRARY_FORM, DELETE_FORM_LIBRARY_FORM_FAILURE, DELETE_FORM_LIBRARY_FORM_SUCCESS, GET_FORM_LIBRARY, GET_FORM_LIBRARY_FAILURE, GET_FORM_LIBRARY_SUCCESS, ON_FORM_LIBRARY_LIST, ON_FORM_LIBRARY_SINGLE, UPDATE_FORM_LIBRARY_FORM, UPDATE_FORM_LIBRARY_FORM_FAILURE, UPDATE_FORM_LIBRARY_FORM_SUCCESS } from './form.library.actions';
import { SELECT_FORM_LIBRARY_FORMS } from './form-library.selectors';
import { FormMeta } from '../../model/meta/FormMeta';
import { ConfigService } from '../../config/config.service';
import { ErrorDetails } from '../../errors/ErrorDetails';
import { ON_ERROR } from '../../errors/errors.actions';
import { ErrorType } from '../../errors/ErrorType';

@Injectable ( )
export class FormLibraryEffects
{
    constructor( private config: ConfigService, private http: HttpClient,
                  private actions: Actions, private store: Store )
    {
        // Null.
    }

    ON_FORM_LIBRARY_LIST$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_FORM_LIBRARY_LIST ),
        concatLatestFrom ( () => this.store.select ( SELECT_FORM_LIBRARY_FORMS ) ),
        mergeMap ( ([action, forms]) => {
            if ( action.force || forms == null || forms.length == 0 )
            {
                return [ GET_FORM_LIBRARY ( { op: 'list', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    ON_FORM_LIBRARY_SINGLE$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( ON_FORM_LIBRARY_SINGLE ),
        concatLatestFrom ( () => this.store.select ( SELECT_FORM_LIBRARY_FORMS ) ),
        mergeMap ( ([action, forms]) => {
            if ( action.force || forms == null || forms.length == 0 )
            {
                return [ GET_FORM_LIBRARY ( { op: 'single', ids: null } ) ];
            }
            else
            {
                return new Array<Action> ( );
            }
        } ),
    ), { dispatch: true } );

    GET_FORM_LIBRARY$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( GET_FORM_LIBRARY ),
        switchMap ( action => of ( action ).pipe (
            map ( action => {
                let params = new HttpParams ( );
                for ( const id of action.ids ? action.ids : [] )
                {
                    params = params.append ( 'id', String ( id ) );
                }

                return params;
            } ),
            mergeMap ( params => this.config.getConfigLazy ( ).pipe ( map ( config => [config, params ] as const ) ) ),
            mergeMap ( ( [config, params] ) => this.http.get<any[]> ( `${config.meta_api}/form-library`, { params } ) ),
            map ( response => {
                const forms = new Array<FormMeta> ( );
                for ( const json of response )
                {
                    const form = FormMeta.fromJson ( json );
                    if ( form )
                    {
                        forms.push ( form );
                    }
                }
                return forms;
            } ),
            map ( forms => GET_FORM_LIBRARY_SUCCESS ( { op: action.op, forms } ) ),
            catchError ( err => {
                const error = ErrorDetails.fromError ( err );

                return of ( GET_FORM_LIBRARY_FAILURE ( { op: action.op, errorDetails: error } ),
                            ON_ERROR ( { error } ) );
            } )
        ) )
    ) );



    CREATE_FORM_LIBRARY_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( CREATE_FORM_LIBRARY_FORM ),
        map ( action => {
            action.form.Id = null;
            return [ action.op, FormMeta.toJson ( action.form ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.post<any> ( `${config.meta_api}/form-library`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, FormMeta.fromJson ( response ) ] as const ),
        map ( ( [ op, form ] ) => CREATE_FORM_LIBRARY_FORM_SUCCESS ( { op, form } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( CREATE_FORM_LIBRARY_FORM_FAILURE ( { op: CREATE_FORM_LIBRARY_FORM.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );

    UPDATE_FORM_LIBRARY_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( UPDATE_FORM_LIBRARY_FORM ),
        tap ( action => {
            if ( action.form.Id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to update form without a valid id." );
            }
        } ),
        map ( action => {
            action.form.Id = null;
            return [ action.op, FormMeta.toJson ( action.form ) ] as const;
        } ),
        mergeMap ( ( [ op, body ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, config, body ] as const ) ) ),
        mergeMap ( ( [ op, config, body] ) => this.http.put<any> ( `${config.meta_api}/form-library`, body ).pipe ( 
            map ( response => [ op, response ] as const )
        ) ),
        map ( ( [ op, response ] ) => [ op, FormMeta.fromJson ( response ) ] as const ),
        map ( ( [ op, form ] ) => UPDATE_FORM_LIBRARY_FORM_SUCCESS ( { op, form } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( UPDATE_FORM_LIBRARY_FORM_FAILURE ( { op: UPDATE_FORM_LIBRARY_FORM.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );


    DELETE_FORM_LIBRARY_FORM$ = createEffect ( ( ) => this.actions.pipe (
        ofType ( DELETE_FORM_LIBRARY_FORM ),
        tap ( action => {
            if ( action.id == null )
            {
                throw ErrorDetails.fromString ( ErrorType.PRE_OP_VALIDATION, "Attempted to delete form without a valid id." );
            }
        } ),
        map ( action => {
            let params = new HttpParams ( );
            params = params.append ( 'id', String ( action.id ) );
            return [ action.op, action.id, params ] as const;
        } ),
        mergeMap ( ( [ op, id, params ] ) => this.config.getConfigLazy ( ).pipe ( map ( config => [ op, id, config, params ] as const ) ) ),
        mergeMap ( ( [ op, id, config, params ] ) => this.http.delete<any> ( `${config.meta_api}/form-library`, { params } ).pipe ( 
            map ( ( ) => [ op, id ] as const )
        ) ),
        map ( ( [ op, id ] ) => DELETE_FORM_LIBRARY_FORM_SUCCESS ( { op, id } ) ),
        catchError ( err => {
            const error = ErrorDetails.fromError ( err );

            return of ( DELETE_FORM_LIBRARY_FORM_FAILURE ( { op: DELETE_FORM_LIBRARY_FORM.type, errorDetails: error } ),
                        ON_ERROR ( { error } ) );
        } ),
    ) );
}
