import * as moment from "moment";

export class TagGroup
{
    public static clone( tagGroup: TagGroup ): TagGroup
    {
        return new TagGroup ( tagGroup.Id,
                              tagGroup.MetaId,);
                            //   tagGroup.SiteId,
                            //   tagGroup.Subjects );
    }

    public static toJson( tagGroup: TagGroup ): any
    {
        let out: any = {};
        out.tag_group = {};

        out.tag_group.id = tagGroup.Id;
        out.tag_group.meta_id = tagGroup.MetaId;
        // out.site_id = tagGroup.SiteId;
        // out.subjects = new Array<any> ( );
        // for ( const subjectId of tagGroup.Subjects ? tagGroup.Subjects : [] )
        // {
        //     out.subjects.push ( { id: subjectId } );
        // }
        
        return out;
    }

    public static fromJson( json: any ): TagGroup
    {
        let out = new TagGroup ( );

        if ( "tag_group" in json && json [ "tag_group" ] )
        {
            out.Id = json.tag_group.id as number;
            out.MetaId = json.tag_group.meta_id as number;
        }

        out.timestamp = "timestamp" in json && json.timestamp ? moment ( json.timestamp as string ) : null;

        return out;
    }

    public constructor( private id: number | null = null,
                        private metaId: number | null = null, 
                        private timestamp: moment.Moment | null = null )
    {
        // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }

    public get Timestamp( ): moment.Moment | null
    {
        return this.timestamp;
    }

    public set Timestamp( timestamp: moment.Moment | null )
    {
        this.timestamp = timestamp;
    }
}