import * as moment from "moment";
import { SubjectType } from "../../published/SubjectType";

export class DocumentTemplate
{
    public static clone ( input: DocumentTemplate ) : DocumentTemplate
    {
        const output = new DocumentTemplate ( );
        output.Id = input.Id;
        output.Version = input.Version;
        output.SubjectType = input.SubjectType;
        output.MetaId = output.MetaId;
        output.DocumentId = input.DocumentId;
        output.Validity = input.Validity;
        output.Variables = input.Variables ? new Array<string> ( ...input.Variables ) : input.Variables;
        output.FileName = output.FileName;
        output.MimeType = output.MimeType;
        output.Timestamp = output.Timestamp;
        return output;
    }

    public static toJson ( document: DocumentTemplate ): any
    {
        let out: any = {};

        out.id = document.Id;
        out.version = document.Version;
        out.type = document.SubjectType ? SubjectType[document.SubjectType] : null;
        out.meta_id = document.MetaId;
        out.document_id = document.DocumentId;
        out.validity = document.Validity;
        out.variables = document.Variables;
        out.file_name = document.FileName;
        out.mime_type = document.MimeType;
        out.timestamp = document.Timestamp ? document.Timestamp.format ( ) : null;

        return out;
    }

    public static fromJson( json: any ): DocumentTemplate
    {
        let out = new DocumentTemplate ( );

        out.Id = json.id;
        out.Version = json.version;
        out.SubjectType = (<any>SubjectType)[json.type as string];
        out.MetaId = json.meta_id;
        out.DocumentId = json.document_id;
        out.Validity = json.validity;
        out.Variables = json.variables ? new Array<string> ( ...json.variables ) : null;
        out.FileName = json.file_name;
        out.MimeType = json.mime_type;
        out.Timestamp = moment ( json.timestamp as string );

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private subjectType: SubjectType | null  = null,
                        private metaId: number | null  = null, 
                        private documentId: number | null  = null, 
                        private validity: string | null = null,
                        private variables: Array<string> | null = null,
                        private fileName: string | null = null,
                        private mimeType: string | null  = null,
                        private timestamp: moment.Moment | null = null )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    } 

    public get SubjectType( ): SubjectType | null
    {
        return this.subjectType;
    }

    public set SubjectType( subjectType: SubjectType | null )
    {
        this.subjectType = subjectType;
    }  

    public get MetaId( ): number | null
    {
        return this.metaId;
    }

    public set MetaId( metaId: number | null )
    {
        this.metaId = metaId;
    }
    
    public get DocumentId( ): number | null
    {
        return this.documentId;
    }

    public set DocumentId( documentId: number | null )
    {
        this.documentId = documentId;
    }

    public get Validity( ): string | null
    {
        return this.validity;
    }

    public set Validity( validity: string | null )
    {
        this.validity = validity;
    }

    public get Variables( ): Array<string> | null
    {
        return this.variables;
    }

    public set Variables( variables: Array<string> | null )
    {
        this.variables = variables;
    }

    public get FileName( ): string | null
    {
        return this.fileName;
    }

    public set FileName( fileName: string | null )
    {
        this.fileName = fileName;
    }

    public get MimeType( ): string | null
    {
        return this.mimeType;
    }

    public set MimeType( mimeType: string | null )
    {
        this.mimeType = mimeType;
    }

    public get Timestamp( ): moment.Moment | null
    {
        return this.timestamp;
    }

    public set Timestamp( timestamp: moment.Moment | null )
    {
        this.timestamp = timestamp;
    }

    public get isValid ( ) : boolean
    {
        return this.FileName != null && this.FileName.length > 0 && ( this.validity == null || this.validity.length == 0 );
    }
}