import { ApplicationRef, DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFrown, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faBook, faCity, faCogs, faDatabase, faFlask, faHome, faPlus, faSitemap, faTrash, faUsers, faCalendarAlt, faFileImport, faRedoAlt, faEnvelope, faEdit, faUserSlash, faTasks, faLock, faEllipsisH, faFileDownload, faFileUpload, faExclamationTriangle, faFileExport, faFileArchive, faUnlock, faCaretUp, faCaretDown, faFileWord, faUserSecret, faTimesCircle, faCopy, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { ConfigService, SharedModule } from 'shared';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManagementSharedModule } from './shared/shared.module';
import * as Sentry from "@sentry/angular";
import { Router, RouteReuseStrategy } from '@angular/router';
import { NoReuseStrategy } from './NoReuseStrategy';
import { ErrorComponent } from './error.component';
import { HandyErrorHandler } from './handy.error.handler';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AutoSignoutComponent } from './auto.signout.component';
import { ClipboardModule } from 'ngx-clipboard';

const keycloakService = new KeycloakService ( );
@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    AutoSignoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    StoreModule.forRoot({}, {}),
    environment.production ? [ ] : StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    FontAwesomeModule,
    SharedModule,
    ManagementSharedModule,
    KeycloakAngularModule,
    NgIdleKeepaliveModule.forRoot ( ),
    ClipboardModule
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    environment.production ? {
      provide: ErrorHandler,
      useClass: HandyErrorHandler,
    } : [ ],
    environment.production ? {
      provide: Sentry.TraceService,
      deps: [ Router ],
    } : [ ],
    {
      provide: RouteReuseStrategy,
      useClass: NoReuseStrategy,
    }
  ],
  entryComponents: [ AppComponent ]
})
export class AppModule implements DoBootstrap
{
  constructor( private injector: Injector, library: FaIconLibrary )
  {
    library.addIcons ( faHome, faCogs, faDatabase, faUsers, faSitemap, faBook, 
                       faTrash, faPlus, faFlask, faCity, faCalendarAlt, faFileImport,
                       faQuestionCircle, faRedoAlt, faEnvelope, faEdit, faUserSlash,
                       faTasks, faFrown, faLock, faEllipsisH, faFileDownload, faFileUpload,
                       faExclamationTriangle, faFileExport, faFileArchive, faUnlock,
                       faCaretUp, faCaretDown, faFileWord, faUserSecret, faTimesCircle, faCopy,
                       faTasks, faFileContract, faExclamationTriangle );
  }

  async ngDoBootstrap( appRef: ApplicationRef )
  {
    const configService = this.injector.get ( ConfigService );

    if ( environment.production )
    {
      const tracer = this.injector.get ( Sentry.TraceService );
    }


    try
    {
      // Load config
      const config = await configService.getConfig ( );
      const keycloakConfig = config.keycloak;
      const release = environment.build;

      // Iniialise keycloak
      await keycloakService.init ( { 
        config: keycloakConfig, 
        initOptions: { onLoad: 'check-sso', silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html' }
        // enableBearerInterceptor: true
      } );

      if ( !environment.production )
      {
        const token = await keycloakService.getToken ( );
            console.log ( token );
            
        keycloakService.keycloakEvents$.subscribe ( async event => {
          if ( event.type == KeycloakEventType.OnAuthSuccess )
          {
            const token = await keycloakService.getToken ( );
            console.log ( token );
          }
        } );
      }
    }
    catch ( error )
    {
      if ( !environment.production )
      {
        console.error ( `Authentication initialisation failed. Error: ${error}` );
      }
    }
    finally
    {
      // Bootstrap the application
      appRef.bootstrap ( AppComponent );
    }
  }
}
